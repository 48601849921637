<template>
  <div
    :class="{
      'vac-box-search': showSearchBar,
      'vac-box-empty': !showSearchBar,
    }"
  >
    <template v-if="showSearch">
      <div v-if="!loadingRooms" class="vac-icon-search" @click="enterSearch" style="cursor: pointer">
        <slot name="search-icon">
          <svg-icon name="search" />
        </slot>
      </div>
      <input
        ref="roomTextarea"
        v-if="!loadingRooms"
        type="search"
        :placeholder="'Press enter to search'"
        class="vac-input"
        @input="onChangeInput"
        @keydown.enter.exact.prevent="enterSearch"
        v-model="localSearch"
      />
    </template>
    <v-tooltip v-if="isNotValidSearch" bottom color="warning" class="my-auto">
      <template #activator="{ on, attrs }">
        <v-icon v-bind="attrs" color="warning" v-on="on" class="ml-2">
          {{ icons.mdiAlert }}
        </v-icon>
      </template>
      <span>{{ searchAlertText }}</span>
    </v-tooltip>
    <!-- <div
      v-if="showAddRoom && !loadingRooms && rooms.length"
      class="ms-2 vac-search-button vac-add-icon"
    >
      <v-btn
        class="action-btn"
        dense
        outlined
        :style="$vuetify.theme.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
        color="primary"
        @click="openConfig"
      >
        <v-icon
          color="primary"
          size="24"
        >
          {{ icons.mdiCogOutline }}
        </v-icon>
      </v-btn>

    </div> -->
    <div v-if="showAddRoom && !loadingRooms" class="ms-2 vac-search-button vac-add-icon">
      <v-btn
        class="action-btn"
        dense
        outlined
        :style="$vuetify.theme.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
        color="primary"
      >
        <v-icon class="mx-auto" color="primary" @click="openFilter">
          {{ icons.mdiFilterVariant }}
        </v-icon>
      </v-btn>
    </div>
    <div v-if="showAddRoom && !loadingRooms && isOnFilter" class="ms-2 vac-search-button vac-add-icon">
      <v-btn
        class="action-btn"
        dense
        outlined
        :style="$vuetify.theme.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
        color="primary"
      >
        <v-icon class="mx-auto" color="primary" @click="clearFilter">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiAlert, mdiClose, mdiCogOutline, mdiFilterVariant } from '@mdi/js'
import SvgIcon from '../../../components/SvgIcon/SvgIcon'
const debounce = (func, delay) => {
  let inDebounce

  return function () {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}
export default {
  name: 'RoomsSearch',
  components: { SvgIcon },

  props: {
    textMessages: { type: Object, required: true },
    showSearch: { type: Boolean, required: true },
    showAddRoom: { type: Boolean, required: true },
    isOnFilter: { type: Boolean, required: true },
    rooms: { type: Array, required: true },
    loadingRooms: { type: Boolean, required: true },
    roomsMessages: { type: Array },
    roomsChats: { type: Array },
    isNotValidSearch: { type: Boolean, default: false },
  },

  data() {
    return {
      icons: { mdiCogOutline, mdiFilterVariant, mdiClose, mdiAlert },
      localSearch: '',
    }
  },

  emits: ['search-room', 'clear-filter', 'open-config', 'open-filter', 'add-room'],
  // watch: {
  //   testSearch(newVal) {
  //     console.log(newVal, '<<this nes val')
  //     if (newVal === '') {
  //       this.$emit('search-room', '')
  //     }
  //   },
  // },
  computed: {
    searchAlertText() {
      return `Search box must contain at least three letters`
    },
    showSearchBar() {
      return this.showSearch || this.showAddRoom
    },
    getSearch() {
      return this.$store.getters['global/getSearch']
    },
  },

  methods: {
    openConfig() {
      this.$emit('open-config', true)
    },
    openFilter() {
      this.$emit('open-filter', true)
    },
    clearFilter() {
      this.$emit('clear-filter', true)
    },
    onChangeInput: debounce(function (e) {
      if (e?.target?.value || e?.target?.value === '') {
        this.testSearch = e
      }
      if (!this.localSearch) {
        this.$emit('search-room', this.testSearch)
      }
      // this.keepKeyboardOpen = true
    }, 100),
    enterSearch() {
      this.$emit('search-room', this.testSearch)
    },
  },
}
</script>

<style scoped>
.v-btn.action-btn {
  width: 36px;
  min-width: 36px;
}
</style>
