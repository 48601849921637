<template>
  <div
    v-show="(isMobile && !showRoomsList) || !isMobile || singleRoom"
    class="vac-col-messages"
    @touchstart="touchStart"
  >
    <slot v-if="showNoRoom" name="no-room-selected">
      <div class="vac-container-center vac-room-empty">
        <div>{{ textMessages.ROOM_EMPTY }}</div>
      </div>
    </slot>

    <room-header
      v-else
      :current-user-id="currentUserId"
      :text-messages="textMessages"
      :single-room="singleRoom"
      :show-rooms-list="showRoomsList"
      :is-mobile="isMobile"
      :is-floating="isFloating"
      :room-info-enabled="roomInfoEnabled"
      :menu-actions="menuActions"
      :assigned-member="room.assign_to"
      :room="room"
      @toggle-rooms-list="$emit('toggle-rooms-list')"
      @room-info="$emit('room-info', $event)"
      @update-room="$emit('update-room', $event)"
      @assign-chat-dropdown="$emit('assign-chat-dropdown', $event)"
      @menu-action-handler="$emit('menu-action-handler', $event)"
    >
      <template v-for="(i, name) in $scopedSlots" #[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </room-header>

    <div
      v-if="!showNoRoom"
      id="messages-list"
      ref="scrollContainer"
      class="vac-container-scroll"
      :style="{ display: messages.length === 0 ? 'block' : 'block' }"
      @scroll="onContainerScroll"
    >
      <loader :show="loadingMessages">
        <template v-for="(idx, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </loader>
      <!-- :style="Object.keys(room).length > 0 && room.instance && room.instance.color ? `background: ${convertHexToAlpha(room.instance.color)} !important;` : ''" -->
      <div class="vac-messages-container">
        <div :class="{ 'vac-messages-hidden': loadingMessages }">
          <transition name="vac-fade-message">
            <div>
              <div v-if="showNoMessages" class="vac-text-started">
                <slot name="messages-empty">
                  {{ textMessages.MESSAGES_EMPTY }}
                </slot>
              </div>
              <div v-if="showMessagesStarted" class="vac-text-started">
                {{ textMessages.CONVERSATION_STARTED }} {{ messages[0].date }}
              </div>
            </div>
          </transition>
          <transition name="vac-fade-message">
            <InfiniteLoading
              v-if="messages.length"
              :class="{ 'vac-infinite-loading': !messagesLoaded }"
              :distance="40"
              direction="top"
              force-use-infinite-wrapper=".vac-container-scroll"
              spinner="spiral"
              web-component-name="vue-advanced-chat"
              @infinite="loadMoreMessages"
            >
              <template #spinner>
                <loader :infinite="true" :show="true" />
              </template>
              <template #no-results>
                <div />
              </template>
              <template #no-more>
                <div />
              </template>
            </InfiniteLoading>
          </transition>
          <!-- <div
            v-if="messages.length && !messagesLoaded"
            id="infinite-loader-messages"
          >
            <loader
              :show="true"
              :infinite="true"
            >
              <template
                v-for="(idx, name) in $scopedSlots"
                #[name]="data"
              >
                <slot
                  :name="name"
                  v-bind="data"
                />
              </template>
            </loader>
          </div> -->
          <!-- <transition-group
            :key="roomId"
            name="vac-fade-message"
            tag="div"
          >
          </transition-group> -->
          <div v-for="(m, i) in messages" :key="m._id || m.indexId">
            <message
              :is-group="room.phone_number_show === 'Group' ? true : false"
              :current-user-id="currentUserId"
              :message="m"
              :index="i"
              :all-contacts="allContacts"
              :messages="messages"
              :edited-message="editedMessage"
              :message-actions="messageActions"
              :room-users="room.users"
              :room-name="room && room.roomName ? room.roomName : ''"
              :text-messages="textMessages"
              :room-footer-ref="$refs.roomFooter"
              :new-messages="newMessages"
              :show-checkbox="showCheckbox"
              :show-reaction-emojis="showReactionEmojis"
              :show-new-messages-divider="showNewMessagesDivider"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              :hide-options="hideOptions"
              :index-message-search="indexMessageSearch"
              :index-message-hashtag="indexMessageHashtag"
              :from-chatwoot="room.source === 'chatwoot' ? true : false"
              @message-added="onMessageAdded"
              @message-action-handler="messageActionHandler"
              @open-file="openFile"
              @open-user-tag="openUserTag"
              @send-message-reaction="sendMessageReaction"
              @hide-options="hideOptions = $event"
            >
              <template v-for="(idx, name) in $scopedSlots" #[name]="data">
                <slot :name="name" v-bind="data" />
              </template>
            </message>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loadingMessages">
      <transition name="vac-bounce">
        <div v-if="scrollIcon" class="vac-icon-scroll" @click="scrollToBottom">
          <transition name="vac-bounce">
            <div v-if="scrollMessagesCount" class="vac-badge-counter vac-messages-count">
              {{ scrollMessagesCount }}
            </div>
          </transition>
          <slot name="scroll-icon">
            <svg-icon name="dropdown" param="scroll" />
          </slot>
        </div>
      </transition>
    </div>

    <div
      v-show="Object.keys(room).length && showFooter && !loadingMessages && showCheckbox"
      id="fixing-footer-2"
      ref="roomFooter"
      :class="{
        'vac-app-box-shadow': shadowFooter,
      }"
      class="vac-room-footer"
    >
      <div class="vac-box-footer">
        <div class="vac-icon-textarea-left">
          <div class="vac-svg-button">
            <v-icon color="primary" @click="selectMessagesActionHandler('close')">
              {{ icons.mdiClose }}
            </v-icon>
          </div>
        </div>
        <div class="vac-checkbox-footer">{{ selectedMessages.length }} selected</div>
        <div class="vac-icon-textarea">
          <div class="vac-svg-button" :class="{ 'vac-disabled': selectedMessages.length == 0 }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  :color="selectedMessages.length > 0 ? 'primary' : 'secondary'"
                  v-on="on"
                  @click="selectMessagesActionHandler('labelMessages')"
                >
                  {{ icons.mdiLabel }}
                </v-icon>
              </template>
              <span>{{ $t('inbox.labelMessages') }}</span>
            </v-tooltip>
          </div>
        </div>
        <div class="vac-icon-textarea">
          <div class="vac-svg-button" :class="{ 'vac-disabled': selectedMessages.length == 0 }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  :color="selectedMessages.length > 0 ? 'primary' : 'secondary'"
                  v-on="on"
                  @click="selectMessagesActionHandler('exportMessages')"
                >
                  {{ icons.mdiExport }}
                </v-icon>
              </template>
              <span>{{ $t('inbox.exportMessages') }}</span>
            </v-tooltip>
          </div>
        </div>
        <div class="vac-icon-textarea">
          <div class="vac-svg-button" :class="{ 'vac-disabled': selectedMessages.length == 0 }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  :color="selectedMessages.length > 0 ? 'primary' : 'secondary'"
                  v-on="on"
                  @click="selectMessagesActionHandler('forwardMessages')"
                >
                  {{ icons.mdiShare }}
                </v-icon>
              </template>
              <span>Forward message</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>

    <div
      v-show="Object.keys(room).length && showFooter && !loadingMessages && !showCheckbox"
      ref="roomFooter"
      class="vac-room-footer"
      :class="{
        'vac-app-box-shadow': shadowFooter,
      }"
    >
      <room-emojis
        :filtered-emojis="filteredEmojis"
        :select-item="selectEmojiItem"
        :active-up-or-down="activeUpOrDownEmojis"
        @select-emoji="selectEmoji($event)"
        @activate-item="activeUpOrDownEmojis = 0"
      />

      <room-users-tag
        :filtered-users-tag="filteredUsersTag"
        :select-item="selectUsersTagItem"
        :active-up-or-down="activeUpOrDownUsersTag"
        @select-user-tag="selectUserTag($event)"
        @activate-item="activeUpOrDownUsersTag = 0"
      />

      <room-templates-text
        v-show="
          !user.chat_mode ||
          user.chat_mode === 'Full Access' ||
          ((user.chat_mode === 'Write to assigned only' || user.chat_mode === 'Read and Write to assigned only') &&
            room.assign_to &&
            room.expand.assign_to.email === user.email)
        "
        :filtered-templates-text="filteredTemplatesText"
        :select-item="selectTemplatesTextItem"
        :active-up-or-down="activeUpOrDownTemplatesText"
        :load-send-message="loadSendMessage"
        :message="message"
        :from-chatwoot="room.source === 'chatwoot' ? true : false"
        @reset-select="selectTemplatesTextItem = null"
        @select-template-text="selectTemplateText($event, true)"
        @activate-item="activeUpOrDownTemplatesText = 0"
      />

      <room-quick-replies
        :active-up-or-down="activeUpOrDownQuickReplies"
        :filtered-quick-replies="filteredQuickReplies"
        :select-item="selectQuickRepliesItem"
        :room="room"
        @select-quick-reply="selectTemplateText($event, true)"
        @activate-item="activeUpOrDownQuickReplies = 0"
        @openQuickReply="e => openQuickReplyCheck(e)"
      />

      <room-message-reply
        :room="room"
        :message-reply="messageReply"
        :text-formatting="textFormatting"
        :link-options="linkOptions"
        @reset-message="resetMessage"
      >
        <template v-for="(i, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </room-message-reply>

      <room-files :files="files" @remove-file="removeFile" @reset-message="resetMessage">
        <template v-for="(i, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </room-files>

      <div
        v-show="
          !user.chat_mode ||
          user.chat_mode === 'Full Access' ||
          ((user.chat_mode === 'Write to assigned only' || user.chat_mode === 'Read and Write to assigned only') &&
            room.assign_to &&
            room.expand.assign_to.email === user.email)
        "
        class="vac-box-footer"
        :class="{ 'vac-box-footer-border': !files.length }"
      >
        <div v-if="showAudio && !files.length" class="vac-icon-textarea-left">
          <div
            v-show="
              !user.chat_mode ||
              user.chat_mode === 'Full Access' ||
              ((user.chat_mode === 'Write to assigned only' || user.chat_mode === 'Read and Write to assigned only') &&
                room.assign_to &&
                room.expand.assign_to.email === user.email)
            "
          ></div>

          <div
            class="vac-svg-button"
            @click="
              filteredQuickReplies && filteredQuickReplies.length ? resetFooterList() : openFooter('quickReplies=')
            "
          >
            <v-icon>{{ icons.mdiCommentText }}</v-icon>
          </div>

          <!-- <template v-if="isRecording">
            <div
              class="vac-svg-button vac-icon-audio-stop"
              @click="stopRecorder"
            >
              <slot name="audio-stop-icon">
                <svg-icon name="close-outline" />
              </slot>
            </div>

            <div class="vac-dot-audio-record" />

            <div class="vac-dot-audio-record-time">
              {{ recordedTime }}
            </div>

            <div
              class="vac-svg-button vac-icon-audio-confirm"
              @click="toggleRecorder(false)"
            >
              <slot name="audio-stop-icon">
                <svg-icon name="checkmark" />
              </slot>
            </div>
          </template>

          <div
            v-else
            class="vac-svg-button"
            @click="toggleRecorder(true)"
          >
            <slot name="microphone-icon">
              <svg-icon
                name="microphone"
                class="vac-icon-microphone"
              />
            </slot>
          </div> -->

          <div v-if="editedMessage._id" class="vac-svg-button" @click="resetMessage">
            <slot name="edit-close-icon">
              <svg-icon name="close-outline" />
            </slot>
          </div>

          <emoji-picker-container
            v-if="showEmojis"
            v-click-outside="() => (emojiOpened = false)"
            :emoji-opened="emojiOpened"
            :position-top="true"
            :position-left="true"
            @add-emoji="addEmoji"
            @open-emoji="emojiOpened = $event"
          >
            <template v-for="(i, name) in $scopedSlots" #[name]="data">
              <slot :name="name" v-bind="data" />
            </template>
          </emoji-picker-container>

          <!-- <div
            v-if="showFiles"
            class="vac-svg-button"
            style="transform: matrix(-0.82, -0.57, -0.57, 0.82, 0, 0); !important;"
            @click="launchFilePicker"
          >
            <slot name="paperclip-icon">
              <svg-icon name="paperclip" />
            </slot>
          </div>

          <div v-if="showFiles" class="vac-svg-button" @click="openFileManager">
            <slot name="mdiBullhornOutline-icon">
              <v-icon color="#5C9DDE">
                {{ icons.mdiBullhornOutline }}
              </v-icon>
            </slot>
          </div> -->

          <div
            ref="triggerIcon"
            class="vac-svg-button"
            style="transform: matrix(-0.82, -0.57, -0.57, 0.82, 0, 0); !important;"
            @click.stop="handleOpenAttachment"
          >
            <slot name="paperclip-icon">
              <svg-icon name="paperclip" />
            </slot>
          </div>
          <transition v-if="openAttachment" name="vac-slide-left">
            <div class="vac-menu-options" style="top: -98px; left: 80px">
              <div class="vac-menu-list">
                <div v-for="(item, index) in openAttachmentItems" :key="index">
                  <div class="vac-menu-item" @click="attachmentAction(item.action)">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <div v-if="textareaActionEnabled" class="vac-svg-button" @click="textareaActionHandler">
            <slot name="custom-action-icon">
              <svg-icon name="deleted" />
            </slot>
          </div>

          <input
            v-if="showFiles"
            ref="file"
            type="file"
            multiple
            :accept="acceptedFiles"
            style="display: none"
            @change="onFileChange($event.target.files)"
          />
        </div>

        <textarea
          ref="roomTextarea"
          :placeholder="textMessages.TYPE_MESSAGE"
          class="vac-textarea"
          :class="{
            'vac-textarea-outline': editedMessage._id,
          }"
          :style="{
            'min-height': `20px`,
            'padding-left': `12px`,
          }"
          @input="onChangeInput"
          @keydown.esc="escapeTextarea"
          @keydown.enter.exact.prevent="selectItem"
          @paste="onPasteImage"
          @keydown.tab.exact.prevent=""
          @keydown.tab="selectItem"
          @keydown.up.exact.prevent=""
          @keydown.up="updateActiveUpOrDown(-1)"
          @keydown.down.exact.prevent=""
          @keydown.down="updateActiveUpOrDown(1)"
        />
        <!-- @keyup.enter.exact="sendMessage" -->
        <div class="vac-icon-textarea">
          <v-progress-circular
            v-if="loadSendMessage"
            indeterminate
            color="primary"
            size="22"
            class="mr-4"
          ></v-progress-circular>
          <div
            v-if="showSendIcon && isMessageEmpty"
            class="vac-svg-button"
            :class="{ 'vac-send-disabled': isMessageEmpty && loadSendMessage }"
          >
            <slot name="send-icon">
              <svg-icon name="send" :param="isMessageEmpty ? 'disabled' : ''" />
            </slot>
          </div>
          <div
            v-if="showSendIcon && !isMessageEmpty"
            class="vac-svg-button"
            :class="{ 'vac-send-disabled': isMessageEmpty && loadSendMessage }"
            @click="selectTemplateText"
          >
            <slot name="send-icon">
              <svg-icon name="send" :param="isMessageEmpty ? 'disabled' : ''" />
            </slot>
          </div>
        </div>
      </div>
      <div
        v-show="user.chat_mode && user.chat_mode !== 'Full Access' && user.chat_mode !== 'View Only' && !room.assign_to"
      >
        <div class="d-flex mx-auto my-auto">
          <v-btn
            color="primary"
            large
            class="mx-auto"
            :class="$vuetify.breakpoint.mdAndUp ? 'mb-2' : ''"
            @click="
              $emit('assign-chat', {
                roomId: room.roomId,
                selectedMember: user,
                status: mappingId ? 'msg' : roomsChats.length ? 'chat' : 'room',
                mappingId,
              })
            "
          >
            <v-icon class="mr-2">
              {{ icons.mdiMessageTextOutline }}
            </v-icon>
            start chat
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fileManagerModal } from '@/helpers/mini-file-manager'
import { default as clientPocketBase } from '@/helpers/pocketbase'
import fileManagerOptions from '@/views/MyAssets/options/optionsFileManager.js'
import '@/views/MyAssets/style.css'
import {
  mdiBullhornOutline,
  mdiClose,
  mdiCommentText,
  mdiExport,
  mdiLabel,
  mdiMessageTextOutline,
  mdiShare,
} from '@mdi/js'
import { Database } from 'emoji-picker-element'
import vClickOutside from 'v-click-outside'
import InfiniteLoading from 'vue-infinite-loading'
import EmojiPickerContainer from '../../components/EmojiPickerContainer/EmojiPickerContainer'
import Loader from '../../components/Loader/Loader'
import SvgIcon from '../../components/SvgIcon/SvgIcon'
import filteredItems from '../../utils/filter-items'
import Recorder from '../../utils/recorder'
import Message from '../Message/Message'
import RoomEmojis from './RoomEmojis/RoomEmojis'
import RoomFiles from './RoomFiles/RoomFiles'
import RoomHeader from './RoomHeader/RoomHeader'
import RoomMessageReply from './RoomMessageReply/RoomMessageReply'
import RoomQuickReplies from './RoomQuickReplies/RoomQuickReplies'
import RoomTemplatesText from './RoomTemplatesText/RoomTemplatesText'
import RoomUsersTag from './RoomUsersTag/RoomUsersTag'

const { detectMobile, iOSDevice } = require('../../utils/mobile-detection')

const debounce = (func, delay) => {
  let inDebounce

  return function () {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

export default {
  name: 'Room',
  components: {
    Loader,
    SvgIcon,
    EmojiPickerContainer,
    RoomHeader,
    RoomFiles,
    RoomMessageReply,
    RoomUsersTag,
    RoomEmojis,
    RoomTemplatesText,
    Message,
    InfiniteLoading,
    RoomQuickReplies,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    currentUserId: { type: [String, Number], required: true },
    mappingId: { type: String, default: '' },
    textMessages: { type: Object, required: true },
    singleRoom: { type: Boolean, required: true },
    isFloating: { type: Boolean, required: true },
    loadSendMessage: { type: Boolean, required: true },
    showRoomsList: { type: Boolean, required: true },
    roomInfoEnabled: { type: Boolean, required: false },
    isMobile: { type: Boolean, required: true },
    rooms: { type: Array, required: true },
    roomId: { type: [String, Number], required: true },
    loadFirstRoom: { type: Boolean, required: true },
    messages: { type: Array, required: true },
    allContacts: { type: Array, required: true },
    roomMessage: { type: String, default: null },
    messagesLoaded: { type: Boolean, required: true },
    menuActions: { type: Array, required: true },
    messageActions: { type: Array, required: true },
    showSendIcon: { type: Boolean, required: true },
    showFiles: { type: Boolean, required: true },
    showAudio: { type: Boolean, required: true },
    audioBitRate: { type: Number, required: true },
    audioSampleRate: { type: Number, required: true },
    showEmojis: { type: Boolean, required: true },
    showReactionEmojis: { type: Boolean, required: true },
    roomsChats: { type: Array, default: () => [] },
    roomsMessages: { type: Array, default: () => [] },
    showNewMessagesDivider: { type: Boolean, required: true },
    showFooter: { type: Boolean, required: true },
    acceptedFiles: { type: String, required: true },
    textFormatting: { type: Object, required: true },
    linkOptions: { type: Object, required: true },
    loadingRooms: { type: Boolean, required: true },
    textareaActionEnabled: { type: Boolean, required: true },
    scrollDistance: { type: Number, required: true },
    templatesText: { type: Array, default: null },
    showCheckbox: { type: Boolean, default: false },
    quickReplies: { type: Array, default: null },
    indexMessageSearch: { type: Number, default: -1 },
    indexMessageHashtag: { type: Number, default: -1 },
  },

  emits: [
    'update-room',
    'toggle-rooms-list',
    'room-info',
    'assign-chat',
    'assign-chat-dropdown',
    'menu-action-handler',
    'edit-message',
    'send-message',
    'delete-message',
    'message-action-handler',
    'fetch-messages',
    'send-message-reaction',
    'typing-message',
    'open-file',
    'open-user-tag',
    'textarea-action-handler',
    'send-quick-reply',
    'select-messages-action-handler',
    'show-dialog-quick-reply',
  ],

  data() {
    return {
      openAttachment: false,
      openAttachmentItems: [
        { title: 'My Asset', action: 'myasset' },
        { title: 'Upload Files', action: 'filepicker' },
      ],
      message: '',
      editedMessage: {},
      messageReply: null,
      infiniteState: null,
      loadingMessages: false,
      observer: null,
      showLoader: true,
      loadingMoreMessages: false,
      files: [],
      fileDialog: false,
      emojiOpened: false,
      hideOptions: true,
      scrollIcon: false,
      scrollMessagesCount: 0,
      newMessages: [],
      keepKeyboardOpen: false,
      filteredEmojis: [],
      filteredUsersTag: [],
      selectedUsersTag: [],
      icons: {
        mdiCommentText,
        mdiLabel,
        mdiExport,
        mdiClose,
        mdiMessageTextOutline,
        mdiShare,
        mdiBullhornOutline,
      },
      filteredQuickReplies: [],

      filteredTemplatesText: [],
      selectEmojiItem: null,
      selectUsersTagItem: null,
      selectTemplatesTextItem: null,
      selectQuickRepliesItem: null,
      activeUpOrDownEmojis: null,
      activeUpOrDownUsersTag: null,
      activeUpOrDownTemplatesText: null,
      activeUpOrDownQuickReplies: null,
      textareaCursorPosition: null,
      cursorRangePosition: null,
      emojisDB: new Database(),
      recorder: this.initRecorder(),
      isRecording: false,
      format: 'mp3',
      queryTemplateText: '',
      flagButtonTemplate: false,
    }
  },

  computed: {
    room() {
      return (
        this.rooms.find(room => room?.roomId === this.roomId) ||
        this.roomsMessages.find(room => room?.mappingId === this.roomId) ||
        {}
      )
    },
    initialFilteredTemps() {
      return filteredItems(this.templatesText, 'tag', '', true)
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    showNoMessages() {
      return this.room && this.room.roomId && !this.messages.length && !this.loadingMessages && !this.loadingRooms
    },
    showNoRoom() {
      const noRoomSelected =
        (!this.rooms.length && !this.roomsMessages.length && !this.loadingRooms) ||
        (this.room && !this.room.roomId && !this.loadFirstRoom)

      if (noRoomSelected) {
        this.loadingMessages = false /* eslint-disable-line vue/no-side-effects-in-computed-properties */
      }

      return noRoomSelected
    },
    showMessagesStarted() {
      return this.messages.length && this.messagesLoaded
    },
    isMessageEmpty() {
      return !this.files.length && !this.message.trim()
    },
    recordedTime() {
      return new Date(this.recorder.duration * 1000).toISOString().substr(14, 5)
    },
    shadowFooter() {
      return (
        !!this.filteredEmojis.length || !!this.filteredUsersTag.length || !!this.files.length || !!this.messageReply
      )
    },
    selectedMessages() {
      return this.messages.filter(message => message.selected === true)
    },
  },

  watch: {
    message(val) {
      this.getTextareaRef().value = val
    },
    quickReplies(val, oldVal) {
      if (val?.length && !oldVal.length) {
        this.textareaCursorPosition = null
      }
    },

    templatesText(val) {
      this.filteredTemplatesText = filteredItems(val, 'tag', '', true)
    },
    loadingMessages(val) {
      if (val) {
        this.infiniteState = null
      } else {
        if (this.infiniteState) this.infiniteState.loaded()
        this.focusTextarea(true)
        setTimeout(() => this.initIntersectionObserver())
      }
    },
    room: {
      immediate: true,
      handler(newVal, oldVal) {
        if (
          newVal &&
          oldVal &&
          !newVal.mappingId &&
          newVal.roomId &&
          oldVal.roomId &&
          (!oldVal || newVal.roomId !== oldVal.roomId)
        ) {
          this.onRoomChanged()
          this.filteredTemplatesText = this.initialFilteredTemps
        } else if (oldVal && Object.keys(oldVal).length === 0) {
          this.onRoomChanged()
          this.filteredTemplatesText = this.initialFilteredTemps
        } else if (newVal && oldVal && newVal.mappingId && (!oldVal || newVal.mappingId !== oldVal.mappingId)) {
          this.onRoomChanged()
          this.filteredTemplatesText = this.initialFilteredTemps
        }
      },
    },
    roomMessage: {
      immediate: true,
      handler(val) {
        if (val) this.message = this.roomMessage
      },
    },
    messages: {
      deep: true,
      handler(newVal, oldVal) {
        newVal.forEach((message, i) => {
          if (this.showNewMessagesDivider && !message.seen && message.senderId !== this.currentUserId) {
            this.newMessages.push({
              _id: message._id,
              index: i,
            })
          }
        })
        if (oldVal?.length === newVal?.length - 1) {
          this.newMessages = []
        }
        if (this.infiniteState) {
          this.infiniteState.loaded()
        }
        setTimeout(() => (this.loadingMoreMessages = false))
      },
    },
    messagesLoaded(val) {
      if (val) this.loadingMessages = false
      if (this.infiniteState) this.infiniteState.complete()
    },
    queryTemplateText: {
      immediate: true,
      deep: true,
      handler(val) {
        this.filteredTemplatesText = filteredItems(this.templatesText, 'tag', val, true)
      },
    },
  },

  mounted() {
    this.newMessages = []
    const isMobile = detectMobile()
    const updateFooterListDebounced = debounce(e => {
      if (e.key === 'Enter' && !e.shiftKey && !this.fileDialog) {
        if (isMobile) {
          this.message += '\n'
          setTimeout(() => this.onChangeInput())
        } else if (!this.filteredEmojis.length && !this.filteredUsersTag.length && !this.flagButtonTemplate) {
          this.sendMessage()
        }
      }

      setTimeout(() => {
        if (!this.message) {
          this.filteredTemplatesText = this.initialFilteredTemps

          return
        }
        this.updateFooterList('@')
        this.updateFooterList(':')
        this.updateFooterList('/')
      }, 60)

      const lastSlashIndex = this.message.lastIndexOf('/')
      if (lastSlashIndex !== -1) {
        const query = this.message.substring(lastSlashIndex + 1).trim()
        this.queryTemplateText = query
      }
    }, 50)

    this.getTextareaRef().addEventListener('keyup', updateFooterListDebounced)

    this.getTextareaRef().addEventListener('click', () => {
      if (isMobile) this.keepKeyboardOpen = true

      this.updateFooterList('@')
      this.updateFooterList(':')
      this.updateFooterList('/')
    })

    this.getTextareaRef().addEventListener('blur', () => {
      this.resetFooterList()
      if (isMobile) setTimeout(() => (this.keepKeyboardOpen = false))
    })
  },

  beforeDestroy() {
    this.stopRecorder()
  },

  methods: {
    attachmentAction(action) {
      switch (action) {
        case 'myasset':
          this.openFileManager()
          this.openAttachment = false
          break
        case 'filepicker':
          this.launchFilePicker()
          this.openAttachment = false
          break
        default:
          break
      }
    },
    handleOpenAttachment() {
      this.openAttachment = !this.openAttachment
    },
    openFileManager() {
      fileManagerModal(fileManagerOptions(this.user.sub_id), this.saveFile)
    },
    async saveFile(data) {
      for (let i = 0; i < data.length; i++) {
        const file = data[i]

        const url = `https://fm.prod.marketa.id/uploads/${file.uploadRelativePath}`

        //   const fetchUrl = await fetch(url)
        // const blob = await fetchUrl.blob()
        // const type = file.mimeType
        // const blobFile = new Blob([blob], { type })
        const typeIndex = file.filename.lastIndexOf('.')
        const extension = file.filename.substring(typeIndex + 1)

        let preview

        if (extension === 'pdf') {
          preview = '/file-manager/icons/application-pdf.svg'
        } else if (extension === 'csv') {
          preview = 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
        } else if (extension === 'xlsx') {
          preview = '/file-manager/icons/application-vnd.ms-excel.svg'
        } else if (extension === 'txt') {
          preview = 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
        } else if (extension === 'docx') {
          preview = '/file-manager/icons/application-msword-template.svg'
        } else {
          preview = '/file-manager/icons/text.svg'
        }

        this.files.push({
          // blob: blobFile,
          name: file.filename.substring(0, typeIndex),
          size: file.size,
          type: file.mimeType,
          preview,
          extension,
          localUrl: url,
          url,
          fromFileManager: true,

          // ...file,
        })
      }
    },
    convertHexToAlpha(hex) {
      const rgb = hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
        .substring(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16))

      return `rgba(${rgb.join(', ')}, 0.15)`
    },
    initIntersectionObserver() {
      if (this.observer) {
        this.showLoader = true
        this.observer.disconnect()
      }

      const loader = document.getElementById('infinite-loader-messages')

      if (loader) {
        const options = {
          root: document.getElementById('messages-list'),
          rootMargin: `${this.scrollDistance}px`,
          threshold: 0,
        }

        this.observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
            this.loadMoreMessages()
          }
        }, options)

        this.observer.observe(loader)
      }
    },
    preventTopScroll() {
      const container = this.$refs.scrollContainer
      const prevScrollHeight = container.scrollHeight

      const observer = new ResizeObserver(_ => {
        if (container.scrollHeight !== prevScrollHeight) {
          this.$refs.scrollContainer.scrollTo({
            top: container.scrollHeight - prevScrollHeight,
          })
          observer.disconnect()
        }
      })

      for (let i = 0; i < container.children.length; i++) {
        observer.observe(container.children[i])
      }
    },
    getTextareaRef() {
      return this.$refs.roomTextarea
    },
    touchStart(touchEvent) {
      if (this.singleRoom) return

      if (touchEvent.changedTouches.length === 1) {
        const posXStart = touchEvent.changedTouches[0].clientX
        const posYStart = touchEvent.changedTouches[0].clientY

        addEventListener('touchend', touchEvent => this.touchEnd(touchEvent, posXStart, posYStart), { once: true })
      }
    },
    touchEnd(touchEvent, posXStart, posYStart) {
      if (touchEvent.changedTouches.length === 1) {
        const posXEnd = touchEvent.changedTouches[0].clientX
        const posYEnd = touchEvent.changedTouches[0].clientY

        const swippedRight = posXEnd - posXStart > 100
        const swippedVertically = Math.abs(posYEnd - posYStart) > 50

        if (swippedRight && !swippedVertically) {
          this.$emit('toggle-rooms-list')
        }
      }
    },
    onRoomChanged() {
      this.loadingMessages = true
      this.scrollIcon = false
      this.scrollMessagesCount = 0
      this.resetMessage(true, true)

      if (this.roomMessage) {
        this.message = this.roomMessage
        setTimeout(() => this.onChangeInput())
      }

      if (!this.messages.length && this.messagesLoaded) {
        this.loadingMessages = false
      }

      const unwatch = this.$watch(
        () => this.messages,
        val => {
          if (!val || !val.length) return

          const element = this.$refs.scrollContainer
          if (!element) return

          unwatch()

          setTimeout(() => {
            element.scrollTo({ top: element.scrollHeight })
            this.loadingMessages = false
          })
        },
      )
    },
    onMessageAdded({ message, index, ref }) {
      if (index !== this.messages.length - 1) return

      const autoScrollOffset = ref.offsetHeight + 60

      setTimeout(() => {
        if (this.getBottomScroll(this.$refs.scrollContainer) < autoScrollOffset) {
          this.scrollToBottom()
        } else if (message.senderId === this.currentUserId) {
          this.scrollToBottom()
        } else {
          this.scrollIcon = true
          this.scrollMessagesCount++
        }
      })
    },
    onContainerScroll(e) {
      this.hideOptions = true

      if (!e.target) return

      const bottomScroll = this.getBottomScroll(e.target)
      if (bottomScroll < 60) this.scrollMessagesCount = 0
      this.scrollIcon = bottomScroll > 500 || this.scrollMessagesCount
    },
    updateFooterList(tagChar) {
      // Guard condition to check if the textarea reference is valid
      const textareaRef = this.getTextareaRef()
      if (!textareaRef) return

      // Return early for certain conditions
      if (tagChar === '@' && (!this.room.users || this.room.users.length <= 2)) return
      if (tagChar === '/' && !this.templatesText) return

      // Get the current cursor position in the textarea
      const currentCursorPosition = textareaRef.selectionStart

      // If the cursor position hasn't changed, do not proceed
      if (this.textareaCursorPosition === currentCursorPosition) return

      // Update the stored cursor position
      this.textareaCursorPosition = currentCursorPosition

      // Find the last occurrence of the tagChar or a whitespace character
      let position = currentCursorPosition
      while (
        position > 0 &&
        this.message.charAt(position - 1) !== tagChar &&
        this.message.charAt(position - 1) !== ' '
      ) {
        position--
      }

      // Check if the found character is the tagChar, and if the character before is valid
      const beforeTag = this.message.charAt(position - 2)
      const notLetterNumber = !beforeTag.match(/^[0-9a-zA-Z]+$/)
      const isTagChar = this.message.charAt(position - 1) === tagChar

      if (isTagChar && (!beforeTag || beforeTag === ' ' || notLetterNumber)) {
        // Extract the query text from the message
        const query = this.message.substring(position, currentCursorPosition)

        // Call the appropriate method based on the tagChar
        if (tagChar === ':') {
          this.updateEmojis(query)
        } else if (tagChar === '@') {
          this.updateShowUsersTag(query)
        } else if (tagChar === '/') {
          this.updateShowTemplatesText(query)
        }
      } else if (tagChar === 'quickReplies=') {
        this.updateShowQuickReplies()
      } else {
        // Reset the footer list if the found character is not the tagChar
        this.resetFooterList(tagChar)
      }
    },
    openFooter(tagChar) {
      if (!this.quickReplies.length) {
        this.$emit('show-dialog-quick-reply')
      }
      if (!this.getTextareaRef()) return

      if (tagChar === '@' && (!this.room.users || this.room.users.length <= 2)) {
        return
      }

      if (tagChar === '/' && !this.templatesText) {
        return
      }

      if (this.textareaCursorPosition === this.getTextareaRef().selectionStart) {
        return
      }

      this.textareaCursorPosition = this.getTextareaRef().selectionStart

      let position = this.textareaCursorPosition

      while (
        position > 0 &&
        this.message.charAt(position - 1) !== tagChar &&
        this.message.charAt(position - 1) !== ' '
      ) {
        position--
      }

      const beforeTag = this.message.charAt(position - 2)
      const notLetterNumber = !beforeTag.match(/^[0-9a-zA-Z]+$/)

      if (this.message.charAt(position - 1) === tagChar && (!beforeTag || beforeTag === ' ' || notLetterNumber)) {
        const query = this.message.substring(position, this.textareaCursorPosition)

        if (tagChar === ':') {
          this.updateEmojis(query)
        } else if (tagChar === '@') {
          this.updateShowUsersTag(query)
        } else if (tagChar === '/') {
          this.updateShowTemplatesText(query)
        }
      } else if (tagChar === 'quickReplies=') {
        this.updateShowQuickReplies()
      } else {
        this.resetFooterList(tagChar)
      }
    },
    getCharPosition(tagChar) {
      const cursorPosition = this.getTextareaRef().selectionStart

      let position = cursorPosition
      while (position > 0 && this.message.charAt(position - 1) !== tagChar) {
        position--
      }

      let endPosition = position
      while (this.message.charAt(endPosition) && this.message.charAt(endPosition).trim()) {
        endPosition++
      }

      return { position, endPosition }
    },
    async updateEmojis(query) {
      if (!query) return

      const emojis = await this.emojisDB.getEmojiBySearchQuery(query)
      this.filteredEmojis = emojis.map(emoji => emoji.unicode)
    },
    selectEmoji(emoji) {
      this.selectEmojiItem = false

      if (!emoji) return

      const { position, endPosition } = this.getCharPosition(':')

      this.message =
        this.message.substr(0, position - 1) + emoji + this.message.substr(endPosition, this.message.length - 1)

      this.cursorRangePosition = position
      this.focusTextarea()
    },
    updateShowUsersTag(query) {
      this.filteredUsersTag = this.room.users
        .filter(user => user._id !== this.currentUserId)
        .map(user => {
          if (user.username === user._id) {
            user.username = this.formatPhoneNumber(user._id)
          }

          return user
        })

      if (query) {
        query = query.toString().toLowerCase()
        const queryRegex = new RegExp(query, 'i')
        const queryWithCountryCodeRegex = new RegExp(`62${query.slice(1)}`, 'i')

        this.filteredUsersTag = this.filteredUsersTag.filter(userTag => {
          return (
            (queryRegex.test(userTag._id) && queryWithCountryCodeRegex.test(userTag._id)) ||
            userTag.username.toLowerCase().includes(query)
          )
        })
      }
    },
    formatPhoneNumber(phoneNumber, group = true) {
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}`
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      } else if (group) {
        phoneNumber = 'Group'
      }

      return phoneNumber
    },
    selectUserTag(user, editMode = false) {
      this.selectUsersTagItem = false

      if (!user) return

      const { position, endPosition } = this.getCharPosition('@')

      const space = this.message.substr(endPosition, endPosition).length ? '' : ' '

      this.message =
        this.message.substr(0, position) +
        user.username +
        space +
        this.message.substr(endPosition, this.message.length - 1)

      this.selectedUsersTag = [...this.selectedUsersTag, { ...user }]

      if (!editMode) {
        this.cursorRangePosition = position + user.username.length + space.length + 1
      }

      this.focusTextarea()
    },
    updateShowTemplatesText(query) {
      this.queryTemplateText = query.trim()
      if (this.queryTemplateText === '') {
        this.filteredTemplatesText = this.initialFilteredTemps
      } else {
        this.filteredTemplatesText = filteredItems(this.initialFilteredTemps, 'tag', this.queryTemplateText, true)
      }
    },

    async selectTemplateText(template, isFromButton) {
      this.selectTemplatesTextItem = false
      this.$emit('send-quick-reply', true)

      // If no template provided, emit event to stop sending quick reply and return
      if (!template) {
        this.$emit('send-quick-reply', false)

        return
      }
      console.log(this.message, 'cek message nyund')

      // Check if message starts with "/" or if it's an empty message and the template is being selected from a button
      if (this.message.startsWith('/') || (this.message === '' && template && isFromButton)) {
        console.log(
          "🚀 nyund ~ file: Room.vue:1280 ~ selectTemplateText ~ this.message === '' && template && isFromButton:",
          this.message === '' && template && isFromButton,
        )
        console.log(
          "🚀 nyund ~ file: Room.vue:1280 ~ selectTemplateText ~ this.message.startsWith('/'):",
          this.message.startsWith('/'),
        )

        this.flagButtonTemplate = true
      }

      // Check if template is trusted and find matching template from `filteredTemplatesText`
      if (template.isTrusted) {
        template = this.filteredTemplatesText.find(ele =>
          ele.tag.toLowerCase().includes(this.message.slice(1).toLowerCase()),
        )
      }

      // If flagButtonTemplate is set to true, process template
      if (this.flagButtonTemplate && !template.isDisabled) {
        // If template has `messages_templates`, get message template and process it
        if (template.messages_templates) {
          const msgTemplate = await clientPocketBase
            .collection('crm_message_templates')
            .getOne(`${template.messages_templates}`, { $autoCancel: false })

          let nameFile = msgTemplate.attachments.map(attachment => attachment.filename)

          nameFile = JSON.stringify(nameFile)

          const obj = {
            receiver:
              this.room.phone_number.toString().length > 16 ? this.room.roomId.split('-')[1] : this.room.phone_number,
            message: msgTemplate.message
              .replace('{{phone_number}}', this.room.phone_number)
              .replace('{{name}}', this.room.roomName),
            attachments: msgTemplate.attachments,
            endpoint: `${this.user.sub_id}-${this.room.instance._id}`,
            operator: this.user.email,
            fromChatwoot: template.fromChatwoot,
            account_id: this.room.instance.account_id,
            instance_id: this.room.instance._id,
            access_token: this.room.instance.access_token,
            conversation_id: this.room.phone_number,
            nameFile,
            subId: this.user.sub_id,
          }

          // Add optional properties from `msgTemplate` to `obj`
          if (msgTemplate.hasOwnProperty('buttonFooter')) {
            obj.buttonFooter = msgTemplate.buttonFooter
          }
          if (msgTemplate.hasOwnProperty('buttonHeader')) {
            obj.buttonHeader = msgTemplate.buttonHeader
          }
          if (msgTemplate.hasOwnProperty('listButton')) {
            obj.buttons = msgTemplate.listButton
          }
          if (
            this.messages &&
            this.messages.length !== 0 &&
            this.messages[this.messages.length - 1].fromMe === false &&
            this.room.assign_to &&
            this.room.expand.assign_to.email == this.user.email
          ) {
            obj.reply_id_message = this.messages[this.messages.length - 1].source_id
            obj.reply_timestamp = this.messages[this.messages.length - 1].seconds * 1000
          }

          this.$emit('typing-message', true)

          await this.$store.dispatch('broadcast/sendQuickReplyWithBroadcast', obj)
          this.$emit('send-quick-reply', false)
        } else {
          this.sendMessage(template)
        }
      } else {
        this.sendMessage()
      }
      this.filteredTemplatesText = this.initialFilteredTemps
      this.resetMessage()
      this.flagButtonTemplate = false
      this.filteredQuickReplies = []
      this.focusTextarea()
    },

    updateActiveUpOrDown(direction) {
      if (this.filteredEmojis.length) {
        this.activeUpOrDownEmojis = direction
      } else if (this.filteredUsersTag.length) {
        this.activeUpOrDownUsersTag = direction
      } else if (this.filteredTemplatesText.length) {
        this.activeUpOrDownTemplatesText = direction
      }
    },
    selectItem() {
      if (this.filteredEmojis.length) {
        this.selectEmojiItem = true
      } else if (this.filteredUsersTag.length) {
        this.selectUsersTagItem = true
      } else if (this.filteredTemplatesText.length) {
        this.selectTemplatesTextItem = true
      }
    },
    resetFooterList(tagChar = null) {
      if (tagChar === ':') {
        this.filteredEmojis = []
      } else if (tagChar === '@') {
        this.filteredUsersTag = []
      } else if (tagChar === '/') {
      } else {
        this.filteredEmojis = []
        this.filteredUsersTag = []
        this.filteredQuickReplies = []
      }

      this.textareaCursorPosition = null
    },
    escapeTextarea() {
      if (this.filteredEmojis.length) this.filteredEmojis = []
      else if (this.filteredUsersTag.length) this.filteredUsersTag = []
      else if (this.filteredTemplatesText.length) {
      } else this.resetMessage()
    },
    resetMessage(disableMobileFocus = false, initRoom = false) {
      if (!initRoom) {
        this.$emit('typing-message', null)
      }

      this.selectedUsersTag = []
      this.resetFooterList()
      this.resetTextareaSize()
      this.message = ''
      this.editedMessage = {}
      this.messageReply = null
      this.files = []
      this.emojiOpened = false
      this.preventKeyboardFromClosing()
      setTimeout(() => this.focusTextarea(disableMobileFocus))
    },
    resetTextareaSize() {
      if (this.getTextareaRef()) {
        this.getTextareaRef().style.height = '20px'
      }
    },
    focusTextarea(disableMobileFocus) {
      if (detectMobile() && disableMobileFocus) return
      if (!this.getTextareaRef()) return
      this.getTextareaRef().focus()

      if (this.cursorRangePosition) {
        setTimeout(() => {
          this.getTextareaRef().setSelectionRange(this.cursorRangePosition, this.cursorRangePosition)
          this.cursorRangePosition = null
        })
      }
    },
    preventKeyboardFromClosing() {
      if (this.keepKeyboardOpen) this.getTextareaRef().focus()
    },
    async sendMessage(quickReply = null) {
      let message = this.message.trim()

      if (quickReply) {
        if (quickReply.hasOwnProperty('text')) {
          message = quickReply.text.trim()
        } else if (quickReply.hasOwnProperty('tag')) {
          message = quickReply.name.trim()
        }
      }

      if (!this.files.length && !message) return

      this.selectedUsersTag.forEach(user => {
        message = message.replace(`@${user.username}`, `<usertag>${user._id}</usertag>`)
      })

      const files = this.files.length ? this.files : null

      if (this.editedMessage._id) {
        if (this.editedMessage.content !== message || this.editedMessage.files?.length || this.files.length) {
          this.$emit('edit-message', {
            messageId: this.editedMessage._id,
            newContent: message,
            files,
            replyMessage: this.messageReply,
            usersTag: this.selectedUsersTag,
          })
        }
      } else {
        this.$emit('send-message', {
          content: message,
          files,
          replyMessage: this.messageReply,
          usersTag: this.selectedUsersTag,
          quickReply,
        })
      }
      this.resetMessage(true)
    },
    loadMoreMessages(infiniteState) {
      if (this.loadingMessages) {
        this.infiniteState = infiniteState

        return
      }

      setTimeout(
        () => {
          if (this.loadingMoreMessages) return

          if (this.messagesLoaded || !this.room.roomId) {
            return infiniteState.complete()
          }

          this.infiniteState = infiniteState
          this.$emit('fetch-messages')
          this.loadingMoreMessages = true
        },

        // prevent scroll bouncing issue on iOS devices
        iOSDevice() ? 500 : 0,
      )
    },
    messageActionHandler({ action, message }) {
      switch (action.name) {
        case 'replyMessage':
          return this.replyMessage(message)
        case 'editMessage':
          return this.editMessage(message)
        case 'deleteMessage':
          return this.$emit('delete-message', message)
        default:
          return this.$emit('message-action-handler', { action, message })
      }
    },
    sendMessageReaction(messageReaction) {
      this.$emit('send-message-reaction', messageReaction)
    },
    replyMessage(message) {
      this.editedMessage = {}
      this.messageReply = message
      this.focusTextarea()
    },
    editMessage(message) {
      this.resetMessage()

      this.editedMessage = { ...message }

      let messageContent = message.content
      const initialContent = messageContent

      const firstTag = '<usertag>'
      const secondTag = '</usertag>'

      const usertags = [...messageContent.matchAll(new RegExp(firstTag, 'gi'))].map(a => a.index)

      usertags.forEach(index => {
        const userId = initialContent.substring(index + firstTag.length, initialContent.indexOf(secondTag, index))

        const user = this.room.users.find(user => user._id === userId)

        messageContent = messageContent.replace(`${firstTag}${userId}${secondTag}`, `@${user?.username || 'unknown'}`)

        this.selectUserTag(user, true)
      })

      this.message = messageContent

      if (message.files) {
        this.files = [...message.files]
      }

      setTimeout(() => this.resizeTextarea())
    },
    getBottomScroll(element) {
      const { scrollHeight, clientHeight, scrollTop } = element

      return scrollHeight - clientHeight - scrollTop
    },
    scrollToBottom() {
      setTimeout(() => {
        const element = this.$refs.scrollContainer
        element.classList.add('vac-scroll-smooth')
        element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' })
        setTimeout(() => element.classList.remove('vac-scroll-smooth'))
      }, 50)
    },
    onChangeInput: debounce(function (e) {
      if (e?.target?.value || e?.target?.value === '') {
        this.message = e.target.value
      }
      this.keepKeyboardOpen = true
      this.resizeTextarea()
      this.$emit('typing-message', this.message)
    }, 100),
    resizeTextarea() {
      const el = this.getTextareaRef()

      if (!el) return

      const padding = window.getComputedStyle(el, null).getPropertyValue('padding-top').replace('px', '')

      el.style.height = 0
      el.style.height = `${el.scrollHeight - padding * 2}px`
    },
    addEmoji(emoji) {
      this.message += emoji.unicode
      this.focusTextarea(true)
    },
    launchFilePicker() {
      this.$refs.file.value = ''
      this.$refs.file.click()
    },
    onPasteImage(pasteEvent) {
      const items = pasteEvent.clipboardData?.items
      if (items) {
        Array.from(items).forEach(item => {
          if (item.type.includes('image')) {
            const blob = item.getAsFile()
            this.onFileChange([blob])
          }
        })
      }
    },
    async onFileChange(files) {
      this.fileDialog = true
      this.focusTextarea()

      Array.from(files).forEach(async file => {
        const fileURL = URL.createObjectURL(file)

        const blobFile = await fetch(fileURL).then(res => res.blob())
        const typeIndex = file.name.lastIndexOf('.')
        const extension = file.name.substring(typeIndex + 1)
        let preview

        if (extension === 'pdf') {
          preview = '/file-manager/icons/application-pdf.svg'
        } else if (extension === 'csv') {
          preview = 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
        } else if (file.type.includes('spreadsheetml') || file.name.includes('xlsx')) {
          preview = '/file-manager/icons/application-vnd.ms-excel.svg'
        } else if (extension === 'txt') {
          preview = 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
        } else if (file.type.includes('document')) {
          preview = '/file-manager/icons/application-msword-template.svg'
        } else {
          preview = '/file-manager/icons/text.svg'
        }

        this.files.push({
          blob: blobFile,
          name: file.name.substring(0, typeIndex),
          size: file.size,
          type: file.type,
          preview,
          extension,
          localUrl: fileURL,
        })
      })

      setTimeout(() => (this.fileDialog = false), 500)
    },
    removeFile(index) {
      this.files.splice(index, 1)
      this.focusTextarea()
    },
    initRecorder() {
      this.isRecording = false

      return new Recorder({
        bitRate: this.audioBitRate,
        sampleRate: this.audioSampleRate,
        beforeRecording: null,
        afterRecording: null,
        pauseRecording: null,
        micFailed: this.micFailed,
      })
    },
    micFailed() {
      this.isRecording = false
      this.recorder = this.initRecorder()
    },
    toggleRecorder(recording) {
      this.isRecording = recording

      if (!this.recorder.isRecording) {
        setTimeout(() => this.recorder.start(), 200)
      } else {
        try {
          this.recorder.stop()

          const record = this.recorder.records[0]

          this.files.push({
            blob: record.blob,
            name: `audio.${this.format}`,
            size: record.blob.size,
            duration: record.duration,
            type: record.blob.type,
            audio: true,
            localUrl: URL.createObjectURL(record.blob),
          })

          this.recorder = this.initRecorder()
          this.sendMessage()
        } catch {
          setTimeout(() => this.stopRecorder(), 100)
        }
      }
    },
    stopRecorder() {
      if (this.recorder.isRecording) {
        try {
          this.recorder.stop()
          this.recorder = this.initRecorder()
        } catch {
          setTimeout(() => this.stopRecorder(), 100)
        }
      }
    },
    openFile({ message, file }) {
      this.$emit('open-file', { message, file })
    },
    openUserTag(user) {
      this.$emit('open-user-tag', user)
    },
    textareaActionHandler() {
      this.$emit('textarea-action-handler', this.message)
    },
    selectMessagesActionHandler(action) {
      this.$emit('select-messages-action-handler', {
        action,
        messages: this.selectedMessages,
      })
    },
    updateShowQuickReplies() {
      this.filteredQuickReplies = this.quickReplies
    },
    openQuickReplyCheck(header = null) {
      if (header) {
        if (this.filteredQuickReplies.length) {
          this.updateFooterList('quickReplies=')
        } else {
          this.resetFooterList()
        }
      } else {
        this.filteredQuickReplies.length ? this.resetFooterList() : this.updateFooterList('quickReplies=')
      }
    },
  },
}
</script>
