<template>
  <div :class="isFloating || isMobile ? 'vac-room-header-floating' : 'vac-room-header'" class="vac-app-border-b">
    <v-dialog v-model="nameEditField" persistent width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">Edit</h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />

        <v-card-text class="heigth-dialog">
          <v-snackbar v-model="showSnackbarErr" color="error" :timeout="2000" :top="true" style="z-index: 5 !important">
            {{ errMessage }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="closeShowSnackbarErr()">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-text-field v-model="currentName" label="Name" class="mr-4 mt-4"></v-text-field>
          <!-- <v-text-field
            v-model="nickname"
            label="Nickname"
            class="mr-4 mt-2"
          ></v-text-field> -->
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="" @click="nameEditField = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>

          <v-btn color="success" @click="saveEdit">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <slot name="room-header" v-bind="{ room, typingUsers, userStatus }">
      <div class="vac-room-header">
        <div
          v-if="Object.keys(room).length > 0 && !singleRoom"
          :class="{ 'vac-rotate-icon': !showRoomsList && !isMobile && !roomInfoEnabled }"
          class="vac-svg-button vac-toggle-button"
          @click="$emit('toggle-rooms-list')"
        >
          <v-icon size="36" color="primary">
            {{ icons.mdiChevronLeft }}
          </v-icon>
          <!-- <slot name="toggle-icon">
              <svg-icon name="toggle" />
            </slot> -->
        </div>
        <v-row>
          <v-col cols="8" md="6">
            <div class="vac-info-wrapper mt-1 pb-2">
              <!-- :class="{ 'vac-item-clickable': !roomInfoEnabled }" -->
              <!-- @click="openContact(room.roomId)" -->
              <slot name="room-header-avatar" v-bind="{ room }">
                <div
                  v-if="room.avatar"
                  class="vac-avatar-frame"
                  :style="`background: ${room && room.instance && room.instance.color ? room.instance.color : ''};`"
                >
                  <v-avatar
                    size="50"
                    class="vac-avatar mx-auto d-flex"
                    :style="{
                      height: '50px',
                      'min-width': '50px',
                      width: '50px',
                      'background-image': `url('${room.avatar}')`,
                    }"
                  >
                  </v-avatar>
                </div>
              </slot>
              <slot name="room-header-info" v-bind="{ room, typingUsers, userStatus }">
                <div v-if="Object.keys(room).length > 0 && !singleRoom" class="vac-text-ellipsis">
                  <div class="vac-room-name vac-text-ellipsis">
                    {{ room.roomName }}
                    <span class="ml-2">
                      <!-- <div class="edit-text"> -->
                      <v-icon class="ml-1 mb-1" size="20" color="primary" @click.stop="editName">
                        {{ icons.mdiPencil }}
                      </v-icon>
                      <!-- </div> -->
                    </span>
                    <div
                      class="room-contact-name font-weight-black me-1 mb-1 mt-1"
                      :style="`color: ${
                        room && room.instance && room.instance.color ? room.instance.color : ''
                      }; font-size: 12px;`"
                    >
                      {{
                        formatPhoneNum(room.phone_number) +
                        ' - ' +
                        (room &&
                        room.instance &&
                        room.instance.label &&
                        (room.instance.label.includes('-') ||
                          room.instance.label.includes('Default') ||
                          room.instance.label.includes('62'))
                          ? room.instance.label_server
                          : room.instance.label)
                      }}
                    </div>
                  </div>
                  <div v-if="typingUsers" class="vac-room-info vac-text-ellipsis">
                    {{ typingUsers }}
                  </div>
                  <div v-else class="vac-room-info vac-text-ellipsis">
                    {{ userStatus }}
                  </div>
                </div>
              </slot>
            </div>
          </v-col>
          <v-col cols="4" md="6" class="justify-end">
            <slot v-if="room.roomId" name="room-options">
              <!-- <div
              v-if="menuActions.length"
              class="vac-svg-button vac-room-options"
              @click="menuOpened = !menuOpened"
            >
              <slot name="menu-icon">
                <svg-icon name="menu" />
              </slot>
            </div> -->

              <div
                v-if="!roomInfoEnabled && !isFloating && !$vuetify.breakpoint.xs"
                class="d-flex"
                :class="isFloating || isMobile ? '' : 'mt-6'"
              >
                <v-btn
                  v-if="!roomInfoEnabled && !isFloating && !$vuetify.breakpoint.xs && !room.lastMessage.fromMe"
                  class="action-btn"
                  dense
                  outlined
                  :style="$vuetify.theme.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
                  color="primary"
                  @click="markUnread"
                >
                  <v-icon color="primary">
                    {{icons.mdiEmail}}
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="!roomInfoEnabled && !isFloating && !$vuetify.breakpoint.xs"
                  class="action-btn"
                  dense
                  outlined
                  :style="$vuetify.theme.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
                  color="primary"
                  @click="changePinStatus"
                >
                  <v-icon color="primary" style="transform: rotate(40deg)">
                    {{ room.pinned ? icons.mdiPinOff : icons.mdiPin }}
                  </v-icon>
                </v-btn>

                <v-row>
                  <v-col>
                    <v-select
                      v-if="!isFloating && !$vuetify.breakpoint.xs"
                      v-model="room.assign_to"
                      class="mx-2"
                      dense
                      outlined
                      :disabled="user.chat_mode !== 'Full Access'"
                      :items="teamMembers"
                      placeholder="Assign To "
                      item-text="email"
                      item-value="id"
                      return-object
                      @change="assignChatDropdown"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-btn
                  v-click-outside="closeMenu"
                  class="action-btn"
                  dense
                  outlined
                  :style="$vuetify.theme.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
                  color="primary"
                  @click="menuOpened = !menuOpened"
                >
                  <v-icon color="primary">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </div>

              <div
                v-if="!roomInfoEnabled && !isFloating && $vuetify.breakpoint.xs"
                class="d-flex justify-end mt-4 me-4"
              >
                <v-btn
                  v-click-outside="closeMenu"
                  class="action-btn"
                  dense
                  outlined
                  :style="$vuetify.theme.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
                  color="primary"
                  @click="menuOpened = !menuOpened"
                >
                  <v-icon color="primary">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </div>
              <transition v-if="menuActions.length" name="vac-slide-left">
                <div v-if="menuOpened" v-click-outside="closeMenu" class="vac-menu-options">
                  <div class="vac-menu-list">
                    <div v-for="action in menuActions" :key="action.name">
                      <div class="vac-menu-item" @click.stop="menuActionHandler(action)">
                        {{ action.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </slot>
          </v-col>
        </v-row>
      </div>

      <!-- On Floating chat -->
      <!-- <div
        v-if="isFloating"
        :class="isFloating || isMobile ? 'vac-room-menu-floating' : 'vac-room-menu'"
      >
        <v-row>
          <v-col cols="2">
            <v-btn
              v-if="!roomInfoEnabled"
              class="action-btn me-4"
              dense
              outlined
              :style="$vuetify.theme.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
              color="primary"
              @click="changePinStatus"
            >
              <v-icon
                color="primary"
                style="transform: rotate(40deg);"
              >
                {{ room.pinned ? icons.mdiPinOff : icons.mdiPin }}
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <v-select
              v-model="room.assign_to"
              style="z-index: 999999999;"
              class=""
              dense
              outlined
              :items="teamMembers"
              :disabled="user.chat_mode !== 'Full Access'"
              label="Assign To"
              item-text="email"
              item-value="email"
              return-object
              @change="assignChat"
            >
            </v-select>
          </v-col>
        </v-row>
      </div> -->

      <!-- Not on floating chat, but on XS screen -->
      <!-- <div
        v-if="!isFloating && $vuetify.breakpoint.xs"
        :class="isFloating || isMobile? 'vac-room-menu-floating mt-1 my-auto' : 'vac-room-menu'"
      >
        <v-row>
          <v-col cols="2">
            <v-btn
              v-if="!roomInfoEnabled"
              class="action-btn me-4"
              dense
              outlined
              :style="$vuetify.theme.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
              color="primary"
              @click="changePinStatus"
            >
              <v-icon
                color="primary"
                style="transform: rotate(40deg);"
              >
                {{ room.pinned ? icons.mdiPinOff : icons.mdiPin }}
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <v-select
              v-model="room.assign_to"
              class="me-4"
              dense
              outlined
              :items="teamMembers"
              label="Assign To"
              :disabled="user.chat_mode !== 'Full Access'"
              item-text="email"
              item-value="email"
              return-object
              persistent-hint
              hide-details
              @change="assignChat"
            >
            </v-select>
          </v-col>
        </v-row>
      </div> -->
    </slot>
  </div>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { mdiChevronLeft, mdiChevronRight, mdiDotsVertical, mdiPencil, mdiPin, mdiPinOff, mdiEmail } from '@mdi/js'
import vClickOutside from 'v-click-outside'
import SvgIcon from '../../../components/SvgIcon/SvgIcon'
import typingText from '../../../utils/typing-text'

export default {
  name: 'RoomHeader',
  components: {
    SvgIcon,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    currentUserId: { type: [String, Number], required: true },
    textMessages: { type: Object, required: true },
    singleRoom: { type: Boolean, required: true },
    isFloating: { type: Boolean, required: true },
    showRoomsList: { type: Boolean, required: true },
    isMobile: { type: Boolean, required: true },
    roomInfoEnabled: { type: Boolean, required: true },
    menuActions: { type: Array, required: true },
    room: { type: Object, required: true },
  },

  emits: ['toggle-rooms-list', 'assign-chat-dropdown', 'room-info', 'update-room', 'menu-action-handler'],

  data() {
    return {
      teamMembers: [],
      menuOpened: false,
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiPin,
        mdiPinOff,
        mdiDotsVertical,
        mdiPencil,
        mdiEmail
      },
      selectedRoomId: '',
      nameEditField: false,
      currentName: '',
      selectedMember: this.assignedMember || null,
      nickname: '',
      showSnackbarErr: false,
      errMessage: '',
      instances: [],
    }
  },

  computed: {
    // teamMembers() {
    //   // //console.log(
    //   //   "🚀 ~ file: RoomHeader.vue ~ line 397 ~ teamMembers ~ this.$store.getters['auth/getListUsers']",
    //   //   this.$store.getters['auth/getListUsers'],
    //   // )

    //   return this.$store.getters['auth/getListUsers']
    // },
    // instances() {
    //   const inst = this.$store.getters['auth/getListInstance']

    //   // //console.log(inst, `INI INSTANCE`)
    //   if (inst) {
    //     return inst.value
    //   }

    //   return []
    // },
    typingUsers() {
      return typingText(this.room, this.currentUserId, this.textMessages)
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    userStatus() {
      if (!this.room.users || this.room.users.length !== 2) return

      const user = this.room.users.find(u => u._id !== this.currentUserId)

      if (!user || !user.status) return

      let text = ''

      if (user.status.state === 'online') {
        text = this.textMessages.IS_ONLINE
      } else if (user.status.lastChanged) {
        text = this.textMessages.LAST_SEEN + user.status.lastChanged
      }

      return text
    },
  },

  watch: {
    room(val) {
      // //console.log(val, 'ini roomheader')
      // close header pas ganti room di floating
      if (!Object.entries(val).length) {
        this.menuOpened = false
      }
      if (val && val.assign_to !== undefined) this.selectedMember = val
    },
  },

  async mounted() {
    this.getTeamMembers()
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.instances = instances?.value || []
  },

  methods: {
    formatPhoneNum(phone) {
      console.log(phone, 'helo<')
      phone = phone.toString()
      phone = phone.replace(/[^\d]/g, '')
      if (phone.length == 12) {
        // validate 11 digit nomor handphone
        return phone.replace(/(\d{2})(\d{3})(\d{4})(\d{3})/, '+$1 $2-$3-$4')
      }
      if (phone.length == 13) {
        // validate 12 digit nomor handphone
        return phone.replace(/(\d{2})(\d{3})(\d{4})(\d{4})/, '+$1 $2-$3-$4')
      }
      if (phone.length == 14) {
        // validate 13 digit nomor handphone
        return phone.replace(/(\d{2})(\d{3})(\d{4})(\d{5})/, '+$1 $2-$3-$4')
      }
      if (phone.length == 11) {
        // validate 10 digit nomor handphone
        return phone.replace(/(\d{2})(\d{3})(\d{4})(\d{1})/, '+$1 $2-$3-$4')
      }

      return this.room.phone_number_show
    },
    async getTeamMembers() {
      const members = await clientPocketBase.collection('users').getFullList(400, {
        filter: `sub_id = '${this.user.sub_id}' && isActive = true`,
      })
      this.teamMembers = members
    },
    async assignChatDropdown() {
      const room = JSON.parse(JSON.stringify(this.room))

      // console.log({ roomId: this.room.roomId, selectedMember: this.room.assign_to }, 'payload nyund')
      this.$emit('assign-chat-dropdown', {
        roomId: this.room.roomId,
        selectedMember: this.room.assign_to,
        status: 'room',
      })
    },
    async markUnread() {
      await clientPocketBase.collection('crm_contact').update( this.room.id, {
        unread_count: 100,
      });
    },
    async changePinStatus() {
      const room = JSON.parse(JSON.stringify(this.room))
      if (room.pinned) {
        room.pinned = false
        if (room.pinnedFor && room.pinnedFor.length) {
          const index = room.pinnedFor.findIndex(ele => ele === this.user.id)
          room.pinnedFor.splice(index, 1)
        }
      } else {
        room.pinned = true

        // if (!room.pinnedFor) {
        //   room.pinnedFor = {}
        //   room.pinnedFor[room.roomId] = true
        // } else {
        //   room.pinnedFor[room.roomId] = true
        // }
        if (!room.pinnedFor || !room.pinnedFor.length) {
          room.pinnedFor = [this.user.id]
        } else {
          room.pinnedFor = [...room.pinnedFor, this.user.id]
        }
      }

      // const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/rooms/update-room-info`
      // const body = {
      //   subId: this.user.sub_id,
      //   roomId: room.roomId,
      //   roomInfo: {
      //     pinned: room.pinned,
      //   },
      // }
      try {
        // const res = await this.$axiosLimit.post(endpoint, body)
        const res = await clientPocketBase.collection('crm_contact').update(room.id, {
          // pinned: room.pinned,
          pinnedFor: room.pinnedFor,
        })

        // //console.log('🚀 ~ file: RoomHeader.vue ~ line 227 ~ changePinStatus ~ res', res)
      } catch (err) {
        // console.log('🚀 ~ file: RoomHeader.vue ~ line 230 ~ changePinStatus ~ err', err)
      }

      this.$emit('update-room', room)
    },
    menuActionHandler(action) {
      this.closeMenu()
      this.$emit('menu-action-handler', action)
    },
    closeMenu() {
      this.menuOpened = false
    },
    openContact(room) {
      // console.log(room, 'ini roombs')
      if (room.roomId === this.room.roomId && !this.isMobile) return
      if (!this.isMobile) this.selectedRoomId = room.roomId
      this.$emit('room-info', { room })
    },
    showRoom() {
      this.isRoomInfo = false
      this.$emit('toggle-rooms-list')
    },
    async editName() {
      // this.backupRoomName = this.getContactsNameRoom(this.room.phone_number, this.room.roomName)
      // if (this.room.roomContactName) {
      // //console.log(this.room, 'ini brooms edit name')
      this.currentName = this.room.roomName ? this.room.roomName : this.room.phone_number
      this.nickname = this.room.nickname ? this.room.nickname : this.room.name
      this.nameEditField = true

      // }
      // this.$emit('room-info')
    },
    closeShowSnackbarErr() {
      this.showSnackbarErr = false

      // //console.log(this.showSnackbarErr)
    },
    async saveEdit() {
      if (!this.currentName) {
        this.errMessage = 'Invalid Input Name'
        this.showSnackbarErr = true
      } else if (!this.nickname) {
        this.errMessage = 'Invalid Input Nickname'
        this.showSnackbarErr = true
      } else {
        try {
          const editName = await clientPocketBase.collection('crm_contact').update(this.room.id, {
            // nickname: this.nickname,
            name: this.currentName,
            roomName: this.currentName,
          })
          const getContactId = await clientPocketBase
            .collection('crm_contact_phones')
            .getFirstListItem(`phone_number= '${this.room.phone_number}'`, {
              expand: 'contact_id',
            })
          const updateContacts = await clientPocketBase.collection('crm_contacts').update(getContactId.contact_id, {
            // nickname: this.nickname,
            editedName: this.currentName,
          })

          // //console.log(this.instances, 'ini instances')
          this.nameEditField = false
          this.showSnackbarErr = false
        } catch (err) {
          console.log(err)
        }

        // if (this.room.roomContactList && this.room.roomContactList.length !== 0) {
        //   const res = await this.$store.dispatch('global/getContactLists', {
        //   sub_id: this.user.sub_id,
        //   token: this.user.token,
        // })
        // const currentContactOnRoom = this.room.roomContactName.split(', ')

        // let currentList = res.data.find(contact => contact.list_id === 'default')
        // if (currentList) {
        //   const currentContact = currentList.contacts.find(contact => contact.phone_number === this.room.phone_number)
        //   if (currentContact) {
        //     currentContact.name = this.currentName
        //     currentContact.nickname = this.nickname

        //     for (let contact of currentList.contacts) {
        //       if (contact.phone_number === currentContact.phone_number) {
        //         currentContact.updated_at = Date.now()
        //         contact = currentContact
        //       }
        //     }
        // await this.$store.dispatch('global/insertOrUpdateContactList', {
        //   sub_id: this.user.sub_id,
        //   token: this.user.token,
        //   ...currentList,
        // })
        //     } else {
        //       const contact = JSON.parse(JSON.stringify(this.room))
        //       contact.has_wa = 'Yes'
        //       contact.created_at = Date.now()
        //       contact.updated_at = Date.now()
        //       contact.name = this.currentName
        //       contact.nickname = this.nickname
        //       contact.type = contact.type ? contact.type : 'Whatsapp'
        //       currentList.contacts = currentList.contacts.concat(contact)

        //       // //console.log(currentList.currentList.contacts, `INI KONTAK`)
        //       await this.$store.dispatch('global/insertOrUpdateContactList', {
        //         sub_id: this.user.sub_id,
        //         token: this.user.token,
        //         ...currentList,
        //       })
        //     }
        //   } else {
        //     const contact = JSON.parse(JSON.stringify(this.room))

        //     const settings = {
        //       _id: 'defaultcolumns',
        //       key: 'defaultcolumns',
        //       value: [
        //         {
        //           id: 2,
        //           name: 'name',
        //           align: 'left',
        //           label: 'Name',
        //           field: 'name',
        //           value: 'name',
        //           text: 'Name',
        //           sortable: true,
        //           type: 'text',
        //           show: true,
        //           showable: false,
        //           formatDate: '',
        //           deleteable: false,
        //           isRequired: true,
        //           defaultValue: '',
        //           editable: true,
        //           order_number: 3,
        //         },
        //         {
        //           id: 3,
        //           name: 'phone_number',
        //           align: 'left',
        //           label: 'Phone Number',
        //           field: 'phone_number',
        //           value: 'phone_number',
        //           text: 'Phone Number',
        //           sortable: true,
        //           type: 'text',
        //           show: false,
        //           showable: true,
        //           formatDate: '',
        //           deleteable: false,
        //           isRequired: true,
        //           defaultValue: '',
        //           editable: false,
        //           order_number: 2,
        //         },
        //         {
        //           id: 14,
        //           name: 'phone_number_show',
        //           align: 'center',
        //           label: 'Phone Number',
        //           field: 'phone_number_show',
        //           value: 'phone_number_show',
        //           text: 'Phone Number',
        //           sortable: true,
        //           type: 'text',
        //           show: true,
        //           showable: true,
        //           formatDate: '',
        //           deleteable: false,
        //           isRequired: true,
        //           defaultValue: '',
        //           editable: false,
        //           order_number: 2,
        //         },
        //       ],
        //     }

        //     currentList = {
        //       _id: 'default',
        //       list_id: 'default',
        //       list_name: 'default',
        //       contacts: [
        //         {
        //           name: this.currentName,
        //           nickname: this.nickname,
        //           type: contact.type ? contact.type : 'Whatsapp',
        //           _id: contact.phoneNumber,
        //           phone_number: contact.phoneNumber,
        //           phone_number_show: contact.phoneNumber,
        //           _uid: this.$nanoid(),
        //           ...contact,
        //         },
        //       ],
        //       columns: settings,

        //       // last_updated: new Date().getTime(),
        //     }

        //     await this.$store.dispatch('global/insertOrUpdateContactList', {
        //       sub_id: this.user.sub_id,
        //       token: this.user.token,
        //       ...currentList,
        //     })
        //   }

        //   // this.$emit('update-room')

        //   // //console.log(currentList, currentContact)
        //   // }
        //   await db.showedRooms.where({ roomId: this.room.roomId }).modify({
        //     name: this.nickname,
        //     roomName: this.currentName,
        //   })

        //   await db.rooms.where({ roomId: this.room.roomId }).modify({
        //     name: this.nickname,
        //     roomName: this.currentName,
        //   })
        //   this.nameEditField = false
        //   this.currentName = ''
        //   this.nickname = ''
      }
    },
  },
}
</script>

<style scoped>
.v-btn.action-btn {
  width: 32px !important;
  min-width: 32px !important;
}
.vac-avatar {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: #ddd;
  height: 50px;
  width: 50px;
  margin-top: 1.2px;
  min-height: 50px;
  min-width: 50px;
  margin-right: 15px;
  border-radius: 50%;
}
.vac-avatar-frame {
  height: 52px;
  width: 52px;
  min-height: 52px;
  min-width: 52px;
  margin-right: 15px;
  border-radius: 50%;
  margin-bottom: -1px;
}
</style>
