<template>
  <!-- <v-row>
    <v-col>
  <v-card> -->
  <div>
    <div>
      <div v-if="roomNum" class="room-info">Total Rooms: {{ roomNum }}</div>
    </div>
    <chat-window
      id="chat"
      height="90vh"
      :current-user-id="currentUserId"
      :rooms="rooms"
      :rooms-loaded="roomsLoaded"
      :single-room="false"
      :load-first-room="false"
      :load-send-message="loadSendMessage"
      :loading-rooms="loadingRooms"
      :loading-check-rooms="loadingCheckRooms"
      :realtime-status="realtimeStatus"
      :templates-text="templatesText"
      :messages="messages"
      :room-id="roomId"
      :room-actions="roomActions"
      :message-actions="messageActions"
      :menu-actions="menuActions"
      :show-checkbox="showCheckbox"
      :rooms-chats="roomsChats"
      :rooms-messages="roomsMessages"
      :rooms-contacts="roomsContacts"
      :contact-groups="contactGroups"
      :all-contacts="contactListNames"
      :messages-loaded="messagesLoaded"
      :selected-labels="selectedLabels"
      :selected-channel="selectedChannel"
      :mapped-rooms="mappedRooms"
      :is-on-filter="isOnFilter"
      :selected-contact-groups="selectedContactGroups"
      :filter-arr="filterArr"
      :sort-setting="sortSetting"
      :theme="$vuetify.theme.isDark ? 'dark' : 'light'"
      :quick-replies="quickReplies"
      :loading-search="loadingSearch"
      :no-room-search="noRoomSearch"
      :is-not-valid-search="isNotValidSearch"
      :index-message-search="indexMessageSearch"
      :index-message-hashtag="indexMessageHashtag"
      :open-add-room="openAddRoom"
      :assignment="assignment"
      @change-room-id="changeRoomId"
      @message-action-handler="messageActionHandler"
      @room-action-handler="roomActionHandler"
      @menu-action-handler="menuActionHandler"
      @fetch-messages="fetchMessages"
      @send-message="sendMessage"
      @send-quick-reply="sendQuickReply"
      @open-file="actionFile"
      @fetch-more-rooms="fetchMoreRooms"
      @fetch-more-search="fetchMoreSearch"
      @filter-rooms="filterRooms"
      @toggle-rooms-list="toggleRoomsList"
      @add-room="openDialogAddRoom"
      @update-room="updateRoomInfo"
      @search-rooms="searchRooms"
      @open-config="openConfig"
      @open-filter="openFilter"
      @assign-chat="assignChat"
      @assign-chat-dropdown="assignChatDropdown"
      @clear-filter="clearFilter"
      @select-messages-action-handler="selectMessagesActionHandler"
      @filtering-labels="filteringLabels"
      @show-dialog-quick-reply="showDialogQuickReply"
    >
      <template #messages-empty>
        <div class="vac-text-started">
          {{ $t('inbox.chatEmpty') }}
        </div>
      </template>
      <template #rooms-empty>
        <div class="vac-rooms-empty">
          {{ $t('inbox.contactEmpty') }}
        </div>
      </template>
      <template #no-room-selected>
        <div class="vac-container-center vac-room-empty">
          <div>
            <v-img src="@/assets/images/no room selected.png"></v-img>
            <span class="text--white">{{ $t('inbox.noChatSelected') }}</span>
          </div>
        </div>
      </template>
    </chat-window>

    <v-dialog v-model="dialogLabelsRoomMenu" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ $t('select') }} Label</h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />
        <v-card-text class="height-dialog">
          <v-list :key="keyLabels" flat>
            <v-list-item v-for="label in labels" :key="label.id">
              <v-list-item-action>
                <v-checkbox v-model="selectedLabels" :value="label"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon :color="label.color">
                    {{ icons.mdiLabel }}
                  </v-icon>
                  {{ label.label }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-3" color="#ffeeee" @click="dialogLabelsRoomMenu = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="saveLabelsRoomMenu">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogLabels" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ $t('select') }} Label</h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />
        <v-card-text class="height-dialog">
          <v-list :key="keyLabels" flat>
            <v-list-item v-for="label in labels" :key="label.id">
              <v-list-item-action>
                <v-checkbox v-model="selectedLabels" :value="label"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon :color="label.color">
                    {{ icons.mdiLabel }}
                  </v-icon>
                  {{ label.label }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-3" color="#ffeeee" @click="dialogLabels = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="saveLabels">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFilter" persistent>
      <v-card class="mx-auto" width="500px">
        <v-card-title>
          <v-icon @click="closeDialogFilter">
            {{ icons.mdiClose }}
          </v-icon>
          <span class="mx-auto font-weight-black text-h5">Filter</span>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="filter.labels"
            outlined
            :items="optionsLabel"
            item-text="name"
            item-value="id"
            label="Label"
            multiple
            return-object
            :clearable="true"
            dense
            class="mt-4"
            @click="selectTag"
          >
            <template v-slot:selection="{ item, index }">
              <!-- <span v-show="!filter.labels.length"> {{optionsLabel[0].name}}</span> -->
              <span v-if="index < maxDisplay" class="me-1">
                <v-icon v-if="item.color" dark :style="{ color: item.color }">
                  {{ icons.mdiLabel }}
                </v-icon>
                <!-- <v-icon
                  v-if="item.label_color"
                  dark
                  :style="{ color: item.label_color }"
                  class="me-1"
                >
                  {{ icons.mdiLabelVariant }}
                </v-icon> -->
                {{ $vuetify.breakpoint.xs ? '' : item.name }}
              </span>
              <span v-if="index === maxDisplay" class="grey--text caption">{{
                `(+${filter.labels.length - maxDisplay})`
              }}</span>
            </template>
            <template slot="item" slot-scope="{ item }">
              <v-icon v-if="item.color" dark :style="{ color: item.color }" class="me-1">
                {{ icons.mdiLabel }}
              </v-icon>
              <!-- <v-icon
                v-if="item.label_color"
                dark
                :style="{ color: item.label_color }"
                class="me-1"
              >
                {{ icons.mdiLabelVariant }}
              </v-icon> -->
              {{ item.name }}
            </template>
          </v-select>
          <!-- <v-select
            v-model="filter.contactGroups"
            outlined
            :items="optionsContactGroup"
            item-text="group_name"
            item-value="id"
            label="Customer Group"
            :clearable="true"
            dense
          >
            <template v-slot:selection="{ item, index }">
              <span v-show="filter.contactGroups"> {{ item.group_name }}</span>
              <span v-show="!filter.contactGroups"> All Customer Group</span>
            </template>

            <template slot="item" slot-scope="{ item }">
              <span> {{ item.group_name }}</span>
            </template>
          </v-select> -->
          <v-select
            v-model="filter.roomAssignTo"
            outlined
            :items="optionsAssignTo"
            item-text="name"
            item-value="id"
            label="Assign To"
            :clearable="true"
            dense
          >
            <template v-slot:selection="{ item, index }">
              <span v-show="filter.roomAssignTo"> {{ item.name }}</span>
              <span v-show="!filter.roomAssignTo"> All </span>
            </template>

            <template slot="item" slot-scope="{ item }">
              <span> {{ item.name }}</span>
            </template>
          </v-select>
          <v-select
            v-model="filter.channels"
            outlined
            :items="optionsChannels"
            item-text="label"
            item-value="_id"
            label="Channels"
            :clearable="true"
            dense
          >
            <template v-slot:selection="{ item, index }">
              <span v-show="filter.channels" :style="{ color: item.color }">
                {{
                  item.instance_label_name && item.expand.instance_label_name.edited_name
                    ? item.expand.instance_label_name.edited_name
                    : item.label
                }}</span
              >
              <span v-show="!filter.channels"> All Channels</span>
            </template>

            <template slot="item" slot-scope="{ item }" :style="{ color: item.color }">
              <span :style="{ color: item.color }">{{
                item.instance_label_name && item.expand.instance_label_name.edited_name
                  ? item.expand.instance_label_name.edited_name
                  : item.label
              }}</span>
            </template>
          </v-select>
          <span class="font-weight-bold text-subtitle-1">Sort</span>
          <v-radio-group v-model="filter.sort" row>
            <v-radio label="Newest" value="newest"></v-radio>
            <v-radio label="Oldest" value="oldest"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            outlined
            :style="$vuetify.theme.isDark ? { 'border-color': '#1F979E' } : { 'border-color': '#1F979E' }"
            color="primary"
            class="text-capitalize"
            @click="closeDialogFilter"
          >
            <span class="primary--text">Cancel</span>
          </v-btn>
          <v-btn class="text-capitalize" color="primary" @click="saveFilter">
            <span class="text--white white--text">Save</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isOpened" width="700px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('inbox.newChat') }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn class="hide-buton" icon @click="isOpened = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-select
            v-model="selectedInstanceNewChat"
            outlined
            :items="instances.filter(ele => ele.type !== 'chatwoot' && !ele.broadcast_group && ele.status)"
            item-text="label"
            label="Channels"
            dense
            return-object
            hide-details
            :class="{ 'light-bg': $vuetify.theme.isDark, 'dark-bg': !$vuetify.theme.isDark }"
            class="mb-4"
          >
            <template slot="item" slot-scope="{ item }">
              <div v-if="item.color" class="state-circle" :style="{ 'background-color': item.color }" />
              {{ item.label }}
            </template>
          </v-select>
          <v-expansion-panels v-model="panelPhone" accordion focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t('inbox.enterPhoneNumber') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="m-2">
                  <VuePhoneNumberInput
                    v-model="phoneNumberContact"
                    color="#1F979E"
                    size="large"
                    valid-color="#1F979E"
                    error-color="#FF4C51"
                    default-country-code="ID"
                    clearable
                    :error="errors.length > 0 ? true : false"
                    :translations="{
                      phoneNumberLabel: 'Enter Phone Number',
                    }"
                    no-example
                    :dark="$vuetify.theme.isDark"
                    @update="resultContact = $event"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t('inbox.selectFromExistingContact') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  v-model="selectedTableData"
                  item-key="id"
                  :headers="customHeaders"
                  :items="contacts"
                  :loading="tableLoading"
                  :single-select="true"
                  :server-items-length="totalContacts"
                  loading-text="Loading... Please wait"
                  :options.sync="options"
                  show-select
                  class="text-no-wrap"
                  :footer-props="{
                    'items-per-page-text': $t('rowsPerPageLabel'),
                    'items-per-page-options': [5, 10, 25, 50, 100],
                  }"
                >
                  <template v-slot:top>
                    <v-row class="justify-end mb-2">
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="filterByUser"
                          :label="$t('search')"
                          outlined
                          dense
                          single-line
                          hide-details
                          :append-icon="icons.mdiMagnify"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </template>
                  <template slot="no-data">
                    {{ $t('noDataLabel') }}
                  </template>
                  <template #[`item.name`]="{ item }">
                    <!-- <div class="display-lg align-center">
                      <v-row>
                        <v-col>
                          <v-avatar v-if="item ? (item.avatar ? true : false) : false" size="40">
                            <v-img :src="item.avatar || ''" height="40" width="40"> </v-img>
                          </v-avatar>
                          <div class="display-lg flex-column ms-3 display-mt-lg">
                            <span class="d-block font-weight-semibold text-truncate text--primary sm-text">{{
                              item.name
                            }}</span>
                          </div>
                        </v-col>
                        <v-col>
                          <span class="d-block font-weight-semibold text-truncate text--primary sm-text">{{
                            item.name
                          }}</span>
                        </v-col>
                      </v-row>
                    </div> -->
                    <div class="d-flex align-center">
                      <v-avatar size="40">
                        <v-img :lazy-src="defaultProfPic" :src="item.avatar" height="40" width="40"> </v-img>
                      </v-avatar>
                      <div class="d-flex flex-column ms-3">
                        <span
                          style="text-align: left"
                          class="d-block font-weight-semibold text-truncate text--primary phone-number"
                          >{{ item.name }}</span
                        >
                      </div>
                    </div>
                  </template>
                </v-data-table>
                <!-- <v-data-table
                  v-model="selectedContactRoom"
                  item-key="_uid"
                  :headers="headerContactsRoom"
                  :items="tableContactsRoom"
                  :single-select="true"
                  show-select
                  :items-per-page="5"
                  :footer-props="{
                    'items-per-page-text': $t('rowsPerPageLabel'),
                    'items-per-page-options': [5, 10, 30, 50, 100],
                  }"
                  :search="searchContactRoom"
                  class="table-contacts"
                  @item-selected="actionNewChat"
                >
                  <template v-slot:top>
                    <v-row class="justify-end mb-2">
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="searchContactRoom"
                          :label="$t('search')"
                          outlined
                          dense
                          single-line
                          hide-details
                          :append-icon="icons.mdiMagnify"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </template>
                  <template slot="no-data">
                    {{ $t('noDataLabel') }}
                  </template>
                  <template #[`item.name`]="{ item }">
                    <div class="display-lg align-center">
                      <v-avatar v-if="item ? (item.profile_picture ? true : false) : false" size="40">
                        <v-img :src="item.profile_picture || ''" height="40" width="40"> </v-img>
                      </v-avatar>
                      <div class="display-lg flex-column ms-3 display-mt-lg">
                        <span class="d-block font-weight-semibold text-truncate text--primary sm-text">{{
                          item.name
                        }}</span>
                      </div>
                    </div>
                  </template>
                </v-data-table> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-snackbar v-model="errorNewChat" color="error" :top="true" style="z-index: 1 !important">
          {{ joinedError }}
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="closeErrorNewChatSnackbar">
              {{ $t('close') }}
            </v-btn>
          </template>
        </v-snackbar>
        <v-card-actions>
          <v-btn block class="text-capitalize" color="primary" @click="addRoom">
            <span>Send</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Config -->

    <!-- Dialog Config -->

    <alert-dialog
      :dialog="alertDialog"
      :title="alertTitle"
      :text="alertText"
      :selected-room-id="passedInstance"
      @close="alertDialog = false"
    />

    <dialogSelectList ref="dialogSelectList"></dialogSelectList>

    <!-- Dialog Quick Reply -->

    <v-dialog v-model="dialogShownSavedReplies" width="600px">
      <messageTemplate
        ref="message-template-dialog"
        :update-category="updateCategory"
        @close-dialog="e => runCloseTemplate(e)"
        @show-category="showCategory(true)"
        @update-category="updateCategory = false"
      />

      <category ref="edit_dialog_category" @update-category="updateCategory = true" />

      <v-card>
        <v-card-actions>
          <div class="flex">
            <!-- <h3 class="font-weight-bold">{{ $t('inbox.quickReplies') }}</h3> -->
            <h3 class="font-weight-bold">
              {{ $t('inbox.quickReplies') }}
            </h3>
            <p>{{ $t('inbox.quickRepliesNote') }}</p>
          </div>

          <v-spacer></v-spacer>
          <v-btn icon @click="dialogShownSavedReplies = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <div class="">
            <v-btn color="primary" class="" @click="openDialogSavedReplies('new')">
              <!-- <v-icon left>
                {{ icons.mdiPlus }}
              </v-icon> -->
              {{ $t('add') }}
            </v-btn>
            <v-btn class="ml-2" color="primary" @click="dialogSavedRepliesTemplates = true">
              <!-- <v-icon left>
                {{ icons.mdiPlus }}
              </v-icon> -->
              {{ $t('messageTemplates.fromTemplates') }}
            </v-btn>
          </div>
          <!-- <v-row class="bottom-action">
            <v-col cols="12">
              <v-btn
                color="primary"
                class="block-condition"
                @click="openDialogSavedReplies('new')"
              >
                <v-icon left>
                  {{ icons.mdiPlus }}
                </v-icon>
                {{ $t('add') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="block-condition"
                color="primary"
                @click="dialogSavedRepliesTemplates = true"
              >
                <v-icon left>
                  {{ icons.mdiPlus }}
                </v-icon>
                {{ $t('messageTemplates.fromTemplates') }}
              </v-btn>
            </v-col>
          </v-row> -->
          <div v-if="loadingQuickReplies">
            <v-skeleton-loader
              v-for="loader in savedReplies.value.length === 0 ? 3 : savedReplies.value.length"
              :key="loader"
              v-bind="attrs"
              type="list-item"
            ></v-skeleton-loader>
          </div>
          <v-list v-if="savedReplies && savedReplies.value && savedReplies.value.length > 0 && !loadingQuickReplies">
            <!-- <v-list v-if="quickReplies && quickReplies.length > 0 && !loadingQuickReplies"> -->
            <v-list-item v-for="replies in quickReplies" :key="replies.id">
              <v-list-item-content>
                <v-list-item-title v-if="replies.message_templates">
                  <span class="font-weight-bold">/{{ replies.message_templates.template_name }}</span>
                  <span>&nbsp;{{ replies.message_templates.template_name }}</span>
                </v-list-item-title>
                <v-list-item-title v-else>
                  <span v-if="replies.tag" class="font-weight-bold">/{{ replies.tag }}</span>
                  <span>&nbsp;{{ replies.name }}</span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-btn
                  v-if="!replies.message_templates && replies.user_id === user.id"
                  icon
                  @click.stop="openDialogSavedReplies('edit', replies)"
                >
                  <v-icon>{{ icons.mdiPencil }}</v-icon>
                </v-btn>
                <v-btn
                  v-if="replies.user_id === user.id"
                  icon
                  @click.stop="deleteSavedReplies(replies.id, replies.message_templates)"
                >
                  <v-icon>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSavedReplies" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ isNewModeSavedReplies ? $t('add') : $t('edit') }} Quick Reply</h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-text-field v-model="savedRepliesTag" dense outlined :label="$t('tag')"></v-text-field>
          <v-textarea v-model="savedRepliesName" dense outlined rows="3" :label="$t('text')"></v-textarea>
        </v-card-text>
        <v-divider />
        <v-card-actions class="text-end">
          <v-spacer></v-spacer>
          <v-btn class="mr-3" color="error" @click="dialogSavedReplies = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="success" class="" @click="saveSavedReplies">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSavedRepliesTemplates" width="600px">
      <v-snackbar v-model="errorTemplateExists" color="error" :top="true" style="z-index: 1 !important">
        Quick Reply with selected message template is already exists
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="closeErrorTemplateExists">
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ $t('select') }} {{ $t('messageTemplates.fromTemplates') }}</h3>
          <v-spacer></v-spacer>
          <!-- <v-btn
            class="mr-3 hide-buton"
            color="error"
            @click="dialogSavedRepliesTemplates = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveSavedRepliesTemplates"
          >
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <!-- <pre>{{savedRepliesTemplates}}</pre> -->
          <v-select
            v-model="savedRepliesTemplate"
            :hint="savedRepliesTemplate ? savedRepliesTemplate.message : ''"
            :items="savedRepliesTemplates"
            item-text="template_name"
            label="Message Template"
            persistent-hint
            return-object
            class="mb-2 mt-1"
          ></v-select>
          <v-btn color="primary" @click="showHideMessageTemplate()">
            <v-icon dark left>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ $t('create') }} {{ $t('new') }} {{ $t('messageTemplatesRead') }}
          </v-btn>
        </v-card-text>
        <messageTemplate
          ref="message-template"
          :update-category="updateCategory"
          @close-dialog="e => runCloseTemplate(e)"
          @show-category="showCategory"
          @update-category="updateCategory = false"
        />
        <category ref="dialog_category" @update-category="updateCategory = true" />

        <v-divider />
        <v-card-actions class="text-end">
          <v-spacer></v-spacer>
          <v-btn class="mr-3" color="#ffeeee" @click="dialogSavedRepliesTemplates = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" @click="runCloseTemplate(savedRepliesTemplate)">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- addBroadcast Dialog -->
    <v-dialog v-model="addBroadcastDialog" width="1000" scrollable>
      <template v-slot:default="">
        <v-card elevation="0">
          <v-toolbar color="primary">
            <div class="text-h5 white--text">
              {{ $t('broadcasts.createBroadcast') }}
            </div>
            <v-spacer></v-spacer>
            <v-btn class="pa-8" icon @click="closeDialogAddBroadcast()">
              <v-icon x-large color="white">
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <add-broadcast
              :key="newBroadcast"
              :data-contacts-from-api="dataContactsFromApi"
              :from-chat="true"
              @close-dialog="closeDialogAddBroadcast()"
            ></add-broadcast>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="dialogForwardMenu" width="1000" scrollable>
      <template v-slot:default="">
        <v-card elevation="0">
          <v-toolbar color="primary">
            <div class="text-h5 white--text">Forward message to</div>
            <v-spacer></v-spacer>
            <v-btn class="pa-8" icon @click="closeDialogForward()">
              <v-icon x-large color="white">
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <forward-message
              :key="newForwardMessage"
              :data-contacts-from-api="dataContactsFromApi"
              :total-contacts="totalContactsForward"
              @change-options="fetchWaContacts"
              @close-dialog="closeDialogForward()"
            ></forward-message>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <dialogSetTags
      ref="dialogSetTags"
      @selected-fromWA="saveLabelsRoomMenu"
      @update-selectedWa="updateLabelRoomMenu"
    ></dialogSetTags>
    <!--
    <dialogAddResponse
      ref="dialogAddResponse"
      :contactIdResponse="contactIdResponse"
      @add-response="addResponse"
    ></dialogAddResponse> -->

    <v-dialog
      v-model="dialogFormResponse"
      width="900px"
      style="z-index: 1 !important; background-color: #fff !important"
    >
      <v-card height="800px">
        <iframe
          id="responseChat"
          ref="iFrame"
          :src="`/survey.html`"
          width="100%"
          height="100%"
          title="description"
          frameborder="0"
        >
        </iframe>
      </v-card>
      <v-snackbar
        v-model="errorResponseValidationSnackbar"
        color="error"
        :top="true"
        style="z-index: 100000 !important"
      >
        Response Must Be Filled
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errorResponseValidationSnackbar = false">
            {{ $t('Close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
    <dialogAddSurvey
      ref="dialogAddSurvey"
      :success-response-validation-snackbar="successResponseValidationSnackbar"
      @save-response="toFormResponse"
    ></dialogAddSurvey>
    <v-snackbar v-model="errorChatNotOnWhatsapp" color="error" :top="true" style="z-index: 100000 !important">
      {{ errorChatMessage }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="errorChatNotOnWhatsapp = false">
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="errorStartChatValidation" color="error" :top="true" style="z-index: 100000 !important">
      This room is assigned to other user
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="errorStartChatValidation = false">
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <!-- :errorResponseValidationSnackbar="errorResponseValidationSnackbar" -->

    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import AlertDialog from '@/components/dialog/AlertDialog.vue'
import category from '@/components/dialog/message_template/category.vue'
import messageTemplate from '@/components/dialog/message_template/message_template.vue'
import { db } from '@/helpers/db'
import { default as clientPocketBase } from '@/helpers/pocketbase'
import ChatWindow from '@/plugins/vue-advanced-chat-single/lib/ChatWindow.vue'
import '@/plugins/vue-advanced-chat-single/styles/vue-advanced-chat.css'
import AddBroadcast from '@/views/broadcast/AddBroadcast.vue'
import ForwardMessage from '@/views/broadcast/ForwardMessage.vue'
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem'
import { LocalNotifications } from '@capacitor/local-notifications'
import { formatTimestamp } from '@demo/utils/dates'
import { mdiClose, mdiCloseCircle, mdiDelete, mdiLabel, mdiLabelVariant, mdiMagnify, mdiPencil } from '@mdi/js'
import { RealtimeClient } from '@supabase/realtime-js'
import axios from 'axios'
import _orderBy from 'lodash/orderBy'
import _uniqBy from 'lodash/uniqBy'
import { StylesManager } from 'survey-core'
import 'survey-core/defaultV2.min.css'
import VuePhoneNumberInput from 'vue-phone-number-input'
import Confirm from '../component/Confirm.vue'
import dialogAddResponse from '../component/DialogAddResponse.vue'
import dialogAddSurvey from '../component/DialogAddSurvey.vue'
import dialogSelectList from '../component/DialogSelectList.vue'
import dialogSetTags from '../component/DialogSetTags.vue'

StylesManager.applyTheme('defaultV2')
export default {
  name: 'Chat',

  components: {
    ChatWindow,
    Confirm,
    AddBroadcast,
    AlertDialog,
    dialogSelectList,
    category,
    messageTemplate,
    ForwardMessage,
    dialogSetTags,
    dialogAddResponse,
    dialogAddSurvey,
    VuePhoneNumberInput,
  },

  setup() {
    return {
      icons: {
        mdiLabel,
      },
    }
  },
  data() {
    return {
      updateCategory: false,
      currentUserId: '',
      roomId: '',
      loadSendMessage: false,
      isOnFilter: false,
      dialogConfig: false,
      messageActions: [],
      dialogFilter: false,
      roomNum: 0,
      icons: {
        mdiClose,
        mdiLabel,
        mdiLabelVariant,
        mdiCloseCircle,
        mdiDelete,
        mdiPencil,
        mdiMagnify,
      },
      maxDisplay: 1,
      lastCouchTimestamp: 0,
      selectedNotificationSetting: { name: this.$t('notifFromChannel'), data: 2 },
      notificationSettings: [
        { name: this.$t('notifFromAll'), data: 1 },
        { name: this.$t('notifFromChannel'), data: 2 },
        { name: this.$t('notifFromAssigned'), data: 3 },
        { name: this.$t('none'), data: null },
      ],
      sortSetting: 'newest',
      readAcknowledge: false,
      assignOnReply: false,
      filter: {
        labels: [{ name: 'All Labels', id: 'All' }],
        contactGroups: {
          group_name: 'All Customer Group',
          id: 'All',
        },
        filters: [],
        roomAssignTo: {
          name: 'All',
          id: 'All',
        },
        sort: 'newest',
        channels: {
          label: 'All Channels',
          _id: 'All',
        },
      },

      currentOffset: 0,
      databaseChanges: null,

      // rooms: useObservable(liveQuery(() => db.showedRooms.toArray())),
      roomsChats: [],
      roomsContacts: [],
      roomsMessages: [],
      roomsChatsOrigin: [],
      roomsMessagesOrigin: [],
      loadingCheckRooms: false,
      realtimeStatus: false,

      rooms: [],
      roomsLoaded: false,
      messagesLoaded: false,
      loadingRooms: false,
      messages: [],
      contactListNames: [],
      labelsOrigin: [],
      labels: [],
      selectedLabels: [],
      contactId: '',
      tagMessage: '',
      contactIdResponse: {
        name: '',
        id: '',
      },
      newResponseId: '',
      tagId: '',
      selectedContactGroups: [],
      selectedOptionsAssign: '',
      label: [],
      contactGroups: [],

      // selectedLabels: [],
      selectedLabelFilter: [],
      dialogLabelsRoomMenu: false,
      keyLabels: 0,
      dialogLabels: false,
      currentAction: '',
      currentActionRoomMenu: '',
      selectedRoom: '',
      showCheckbox: false,
      defaultChannel: {
        label: 'All Channels',
        _id: 'All',
      },
      defaultTag: {
        name: 'All Labels',
        id: 'All',
      },
      defaultContactGroup: {
        group_name: 'All Customer Group',
        id: 'All',
      },
      defaultAssignTo: [
        {
          name: 'All',
          id: 'All',
        },
        {
          name: 'Assigned To Me',
          id: 'toMe',
        },
        {
          name: 'Not Assigned',
          id: 'notAnyone',
        },
      ],
      userData: {},
      selectedChannel: 'All',
      selectedTag: 'All',
      mappedRooms: [],
      addBroadcastDialog: false,
      dataContactsFromApi: null,
      newBroadcast: 0,
      filterArr: [],
      hashedInstance: '',
      assignment: 'All',
      selectedRoomRoomMenu: '',

      // alert
      alertDialog: false,
      alertTitle: null,
      alertText: null,
      passedInstance: '',

      // saved replies
      // quickReplies: [],
      loadingQuickReplies: false,
      savedReplies: {
        value: [],
      },
      messageTemplates: {
        value: [],
      },
      dialogShownSavedReplies: false,
      dialogSavedReplies: false,
      dialogSavedRepliesTemplates: false,
      activeSavedReplies: null,
      isNewModeSavedReplies: false,
      savedRepliesTag: null,
      savedRepliesName: null,
      savedRepliesTemplate: null,
      globChannel: {},
      attrs: {},
      savedRepliesTemplates: [],

      // forward messages
      dialogForwardMenu: false,
      selectedMessages: [],
      newForwardMessage: 1,
      loadingSearch: false,
      noRoomSearch: false,
      indexMessageSearch: -1,
      customHeaders: [
        {
          text: 'Name',
          value: 'user',
        },
      ],
      allPhoneNumber: [],
      currentRoom: null,
      allTag: [],
      filterLabels: [],
      dialogFormResponse: false,
      allResponse: [],
      formData: {},
      surveyJson: {
        elements: [],
      },
      surveyResponse: '',
      errorResponseValidationSnackbar: false,
      errorChatNotOnWhatsapp: false,
      errorStartChatValidation: false,
      errorChatMessage: '',
      successResponseValidationSnackbar: false,

      // messageObj: {},
      labelMessageObj: {
        block: 'mantap',
      },
      isEmpty: false,
      childHeight: 0,
      indexMessageHashtag: -1,

      // instances: [],
      dataContact: {},

      /// DIALOG ADD ROOM
      openAddRoom: false,
      isOpened: false,
      panelPhone: 0,
      options: {},
      phoneNumberContact: '',
      resultContact: {},
      errors: [],
      selectedInstanceNewChat: {},
      tableLoading: false,
      totalContacts: 0,
      contacts: [],
      filterByUser: '',
      selectedTableData: [],
      customHeaders: [
        {
          text: 'Phone Number',
          value: 'phoneNumber',
        },
        {
          text: 'Name',
          value: 'name',
        },
      ],
      joinedError: '',
      errorNewChat: false,
      errorTemplateExists: false,
      totalContactsForward: 0,
      currentOffsetSearch: 0,
      isNotValidSearch: false,
      nameFromContacts: '',

      // instances: [],
      instances: [],
    }
  },

  computed: {
    isScanning() {
      return this.$store.getters['global/getIsScanning']
    },
    getSearch() {
      const search = this.$store.getters['global/getSearch']

      return search
    },
    defaultProfPic() {
      return `${process.env.VUE_APP_WA_API_URL}ppic/@whatsapp.net/2414214242414/a.jpg`
    },
    templatesText: {
      get() {
        const templatesText = []

        if (this.savedReplies && this.savedReplies.value) {
          const savedReplies = JSON.parse(JSON.stringify(this.savedReplies.value))

          savedReplies.forEach(item => {
            if (item.tag === undefined) {
              item.tag = ''
            }

            if (item.text === undefined) {
              item.text = item.name
              delete item.name
            }

            if (item.message_templates) {
              item.tag = item.message_templates.template_name
            }

            if (item.message_templates) {
              if (!item.message_templates.hasOwnProperty('operator') || !item.message_templates.operator) {
                templatesText.push(item)
              } else if (item.message_templates.operator === this.user.email) {
                templatesText.push(item)
              }
            }

            // check if user role admin or not

            // check if user id equal to user id in quick reply
            if (item.user_id === this.user.id || item.is_global === true) {
              templatesText.push(item)
            }

            //   templatesText.push(item)
          })
        }

        // console.log(templatesText, 'ini cek nyund')
        return templatesText
      },
      set(value) {
        return value
      },
    },
    getGlobalChannel() {
      return this.$store.getters['global/getGlobalChannel']
    },
    optionsLabel() {
      if (this.filter.labels.length) {
        for (let i = 0; i < this.filter.labels.length; i++) {
          const label = this.filter.labels[i]
          if (label.name === 'All Labels' && i !== 0) {
            this.filter.labels = [this.filter.labels[i]]
          } else if (label.name === 'All Labels' && i == 0 && this.filter.labels.length > 1) {
            this.filter.labels.shift()
          }
        }
      }
      if (!this.filter.labels.length) {
        this.filter.labels.unshift(this.defaultTag)
      }
      this.allTag.unshift(this.defaultTag)

      return this.allTag
    },
    optionsFilter() {
      return [...this.optionsLabel, ...this.optionsContactGroup]
    },

    // optionsContactGroup() {
    //   if (this.contactGroups) {
    //     this.contactGroups.unshift(this.defaultContactGroup)
    //     return this.contactGroups
    //   }
    // },
    lastInserted() {
      return this.$store.getters['global/getLastInserted']
    },
    optionsChannels() {
      if (this.instances) {
        let newInstances = JSON.parse(JSON.stringify(this.instances))
        newInstances = newInstances.filter(ele => ele.status && !ele.broadcast_group)
        newInstances.unshift(this.defaultChannel)

        return newInstances
      }
    },
    optionsAssignTo() {
      return this.defaultAssignTo

      // if(this.rooms.length){
      //   for(let i = 0; i < this.rooms.length; i++){
      //     const element = this.rooms[i]
      //     if(element.assign_to){
      //       this.roomAssignTo
      //     }
      //   }
      // }
      // console.log(this.rooms, '<<vira room test assign')
    },
    checkOnlineStatus() {
      return window.navigator.onLine
    },

    // currentOffset() {
    //   return this.$store.getters['inbox/getCurrentOffset']
    // },
    // instances() {
    //   // if (this.$store.getters['auth/getListInstance']?.value?.length !== 0) {
    //   // }

    //   return this.$store.getters['auth/getListInstance']?.value || []

    //   // return []

    //   // return this.$store.getters['auth/getListInstance']
    // },
    user() {
      // //console.log(
      //   "🚀 ~ file: Chat.vue ~ line 929 ~ user ~ this.$store.getters['auth/getUser']",
      //   this.$store.getters['auth/getUser'],
      // )

      return this.$store.getters['auth/getUser']
    },
    allContactData() {
      return this.$store.getters['global/getAllContactData']
    },
    filterRoom() {
      return this.$store.getters['inbox/getFilterRoom']
    },
    roomActions() {
      const roomActions = [
        {
          name: 'addLabel',
          title: `${this.$t('add')} Label`,
        },

        // {
        //   name: 'archiveContact',
        //   title: `${this.$t('inbox.archiveContact')}`,
        // },
        {
          name: 'deleteRoom',
          title: `${this.$t('inbox.deleteRoom')}`,
        },

        // {
        //   name: 'addToContactGroup',
        //   title: `${this.$t('inbox.addToContactGroup')}`,
        // },
        {
          name: 'addResponse',
          title: `${this.$t('add')} Response`,
        },
      ]

      const indexArchiveContact = roomActions.findIndex(action => action.name == 'archiveContact')

      if (indexArchiveContact >= 0) {
        roomActions[indexArchiveContact].title = this.$t('inbox.archiveContact')

        if (this.currentRoomContact) {
          if (this.currentRoomContact.archived) {
            if (this.currentRoomContact.archived == 1) {
              roomActions[indexArchiveContact].title = this.$t('inbox.unarchiveContact')
            }
          }
        }
      }

      // //console.log(this.surveyForms, `INI SURVEY FORMS`)
      // if (this.surveyForms.length !== 0) {
      //   for (const form of this.surveyForms) {
      //     // //console.log(form, `INI FORM`)
      //     roomActions.push({
      //       name: `addToSurvey~${form._id}`,
      //       title: `${this.$t('eform.addTo')} ${form.title}`,
      //     })
      //   }
      // }

      // check package
      if (this.user.package == 'business' || this.user.package == 'business_plus') {
        // roomActions.push({
        //   name: 'shareRoom',
        //   title: `${this.$t('inbox.shareRoom')}`,
        // })
      }

      return roomActions
    },

    // messageActions() {
    //           return [
    //             {
    //               name: 'replyMessage',
    //               title: this.$t('reply'),
    //             },
    //             {
    //               name: 'labelMessage',
    //               title: `Label ${this.$t('message')}`,
    //             },
    //             {
    //               name: 'noteMessage',
    //               title: `${this.$t('inbox.noteMessage')}`,
    //             },
    //             {
    //               name: 'unsendMessage',
    //               title: `${this.$t('inbox.unsendMessage')}`,
    //             },
    //             {
    //               name: 'broadcastMessage',
    //               title: `Broadcast ${this.$t('message')}`,
    //             },
    //             {
    //               name: 'forwardMessage',
    //               title: `Forward ${this.$t('message')}`,
    //             },
    //           ]
    // },
    menuActions() {
      const menuActions = [
        {
          name: 'selectMessages',
          title: `${this.$t('inbox.selectMessages')}`,
        },
        {
          name: 'addLabel',
          title: `${this.$t('add')} Label`,
        },
        {
          name: 'quickReply',
          title: `${this.$t('inbox.quickRepliesAction')}`,
        },

        // {
        //   name: 'archiveContact',
        //   title: `${this.$t('inbox.archiveContact')}`,
        // },
        // {
        //   name: 'addToContactGroup',
        //   title: `${this.$t('inbox.addToContactGroup')}`,
        // },
      ]

      return menuActions
    },

    // getEmptyLabel() {
    //   return this.$store.getters['inbox/getEmptyLabel']
    // },
    // getLabelChanged() {
    //   return this.$store.getters['inbox/getIsLabelChanged']
    // },

    defaultRooms() {
      // //console.log(this.rooms.value, '<<chatchat')
      return this.rooms.value
        ? this.rooms.value.map(room => {
            return {
              ...room,
              users: room.users.map(user => {
                return {
                  ...user,
                  label: this.getContactListLabelRoom(user._id),
                }
              }),
            }
          })
        : []
    },

    /// QUICK REPLIES START

    savedRepliesValue() {
      // Check if the current user is an Admin, or if the operator of the saved reply matches the email of the current user
      const isAuthorizedUser = this.user.role === 'Admin' || item.operator === this.user.email

      // Check if the saved reply channel ID matches either the phone number of the global channel or the current user ID
      const isMatchingChannel =
        item.channelId === this.getGlobalChannel.value.phone_number || item.channelId === this.currentUserId

      // Filter the saved replies based on whether the current user is authorized, or if the saved reply channel matches
      const filteredReplies = this.savedReplies.value.filter(item => {
        if (item.message_templates || !item.message_template) {
          // If the current user is not an Admin, check if they are an authorized user and if the channel matches
          if (!this.user.role === 'Admin') {
            return isAuthorizedUser && isMatchingChannel
          }

          // If the user is an Admin, return all saved replies
          return true
        }

        // If neither message_templates nor message_template exist, return false
        return false
      })

      return filteredReplies
    },

    quickReplies: {
      get() {
        let quickReplies = []
        let savedReplies = []

        // Check if savedReplies exists and has value
        if (this.savedReplies && this.savedReplies.value) {
          // Make a deep copy of savedReplies to avoid modifying the original object
          savedReplies = JSON.parse(JSON.stringify(this.savedReplies.value))
        }

        // Filter savedReplies to keep only valid items
        quickReplies = savedReplies.filter(item => {
          // If item doesn't have tag property, assign an empty string
          if (!item.tag) item.tag = ''

          // If item doesn't have name property, assign the value of text property
          if (!item.name) {
            item.name = item.text

            // Remove text property from item
            delete item.text
          }

          // Check if item has message_templates property
          if (item.message_templates) {
            item.tag = item.message_templates.template_name

            // If user role is not Admin, apply additional filter
            if (this.user.role !== 'Admin') {
              if (item.operator && item.operator === this.user.email) {
                // Check if channelId matches either phone_number or currentUserId
                if (
                  (item.channelId &&
                    this.getGlobalChannel.value.phone_number &&
                    item.channelId === this.getGlobalChannel.value.phone_number) ||
                  (item.channelId && this.currentUserId && item.channelId === this.currentUserId)
                ) {
                  return true
                }

                return false
              }

              // If item doesn't have operator, return true
              if (!item.operator) return true

              return false
            }

            return true
          }

          // Check if item has message_template property
          if (!item.message_template) {
            // If user role is not Admin, apply additional filter
            if (this.user.role !== 'Admin') {
              // Check if channelId matches either phone_number or currentUserId
              if (
                (item.channelId &&
                  this.getGlobalChannel.value.phone_number &&
                  item.channelId === this.getGlobalChannel.value.phone_number) ||
                (item.channelId && this.currentUserId && item.channelId === this.currentUserId)
              ) {
                return true
              }

              // If item doesn't have operator, return true
              if (!item.operator) return true

              return false
            }

            return true
          }
        })

        // Filter quick reply if role is not Admin
        const result = quickReplies.filter(quickReply => {
          // Keep quick reply if user_id matches user.id or is_global is true
          if (quickReply.user_id === this.user.id || quickReply.is_global === true) {
            return quickReply
          }
        })

        return result || quickReplies
      },
      set(value) {
        return value
      },
    },

    /// QUICK REPLIES END

    messageObj() {
      const a = this.$store.getters['global/getMessageObj']

      return this.$store.getters['global/getMessageObj']
    },
  },

  watch: {
    checkOnlineStatus(val) {
      this.realtimeStatus = val
      if (!val) {
        setTimeout(async () => {
          this.startSock()
        }, 5000)
      }
    },
    filterByUser() {
      this.fetchContacts()
    },
    options: {
      handler() {
        // this.getDataFromApi()
        this.fetchContacts()
      },
      deep: true,
    },
    panelPhone(val) {
      if (val === 1) {
        this.phoneNumberContact = ''
      } else if (val === 0 && this.isOpened) {
        this.selectedTableData = []
      }
    },
    isOpened(val) {
      this.filterByUser = ''
      if (!val) {
        this.selectedTableData = {}
        this.phoneNumberContact = ''
        this.selectedInstanceNewChat = {}
        this.errorNewChat = false
        this.nameFromContacts = ''
        if (this.$route.query.phone_number) {
          this.$router.replace('/inbox')
        }
      }
      this.panelPhone = 0
    },
    isScanning(val) {},
    getSearch(val) {
      if (!val) {
        this.roomsChatsOrigin = []
        this.roomsMessagesOrigin = []
      }
    },
    realtimeStatus(val) {
      if (!val) {
        setTimeout(async () => {
          this.startSock()
        }, 5000)
      }
    },
    formData() {
      this.loadResponse()
    },
    errorChatNotOnWhatsapp(val, newVal) {
      if (!val) {
        this.errorChatMessage = ''
      }
    },

    // dialogFormResponse() {
    //   if (!this.dialogFormResponse) {
    //     this.deleteResponse()
    //   }
    // },
    // async getEmptyLabel(varnew) {
    //   /// untuk trigger list label ketika add dari chat!!
    //   if (varnew === false) {
    //     await this.getSettingPg()
    //   }
    // },
    // async getLabelChanged(varnew) {
    //   if (varnew) {
    //     await this.getSettingPg()
    //     this.$store.commit('inbox/setIsTagChanged', false)
    //   }
    // },
  },

  async created() {
    const self = this
    window.onpopstate = function (event) {
      // "event" object seems to contain value only when the back button is clicked
      // and if the pop state event fires due to clicks on a button
      // or a link it comes up as "undefined"
      if (event && self.$vuetify.breakpoint.xs) {
        self.toggleRoomsList({ opened: true })

        // Code to handle back button or prevent from navigation
      }
    }
    window.addEventListener('beforeunload', await this.checkRooms(false))
  },

  beforeDestroy() {
    // this.databaseChanges.unsubscribe()
    // clientPocketBase.realtime.unsubscribe('contact')

    this.$eventBus.$off('showDialogShownSavedReplies')
    this.$store.commit('global/setSearch', '')

    // this.dialogShownSavedReplies = false
    // this.databaseChanges = null
  },

  async mounted() {
    // Set realtime status to true
    this.realtimeStatus = true

    // Subscribe to PocketBase collections
    clientPocketBase.collection('crm_contact').subscribe('*', e => this.eventUpdatePocketBase(e))
    clientPocketBase.collection('crm_contacts').subscribe('*', e => this.updateRoomLabelsRoomMenu(e))

    // clientPocketBase.collection('crm_contact_tags').subscribe('*', e => this.getTagsPb(e))
    // clientPocketBase.collection('crm_contact_tags').subscribe('*', e => this.updateContactLabel(e))
    clientPocketBase.collection('crm_message_templates').subscribe('*', e => this.getTemplates())
    clientPocketBase.collection('crm_quick_reply').subscribe('*', e => this.getQuickReplies())
    clientPocketBase.collection('crm_messages').subscribe('*', e => this.updateMessageEvent(e))
    clientPocketBase.collection('crm_instances').subscribe('*', e => this.updateInstance(e))
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.instances = instances?.value || []

    // const self = this
    // try {
    //   await this.checkRooms(false)
    // } catch (err) {}
    // this.realtimeStatus = true
    // const REALTIME_URL = 'wss://realtime.superwa.io/socket'
    // const client = new RealtimeClient(REALTIME_URL)
    // client.connect(() => self.checkRooms(true))
    // client.onOpen(() => console.log('Socket Opened For Inbox'))
    // client.onClose(() => {
    //   if (this.realtimeStatus) self.checkRooms(false)
    // })
    // client.onError(() =>
    //   setTimeout(async () => {
    //     this.startSock()
    //   }, 5000),
    // )

    // this.databaseChanges = client.channel(`realtime:${this.user.sub_id}:rooms`)
    // this.databaseChanges.on('INSERT', e => this.insertRoom(e))
    // this.databaseChanges.on('UPDATE', e => this.updateRoom(e))
    // this.databaseChanges.on('DELETE', e => this.deleteRoom(e))

    // this.databaseChanges.subscribe()

    // await this.getSettingPg()

    /// /COBA NYALAIN INI
    // const listInstances = await clientPocketBase.collection('crm_instances').getFullList(20, { $autoCancel: false })
    // if (listInstances) {
    //   const mappedInstances = JSON.parse(JSON.stringify(listInstances))
    //   mappedInstances.forEach(ele => {
    //     console.log(ele, ele.edited_name, 'cok<')
    //     if (ele.edited_name) {
    //       ele.label = ele.edited_name
    //       ele.label_server = ele.edited_name
    //     }
    //   })
    //   this.instances = mappedInstances
    // } else {
    //   this.instances = this.$store.getters['auth/getListInstance']?.value || []
    // }
    try {
      await this.getTagsPb()
    } catch (error) {
      console.log(error, 'cok<')
    }

    // Reset the global search
    this.$store.commit('global/setSearch', '')

    // Fetch the rooms based on the filter
    await this.fetchRooms({ filterRoom: this.filterRoom })

    // If the roomId is present in the route query, go to the detail to chat page
    if (this.$route.query.roomId) {
      this.detailToChat(this.$route.query.source_id)
    }

    // if(this.$route.query.source_id) {
    //   this.detailToHashtag(this.$route.query.source_id)
    // }

    if (this.$route.query.phone_number) {
      // this.openAddRoom = true
      this.isOpened = true
      this.phoneNumberContact = this.$route.query.phone_number
      this.nameFromContacts = this.$route.query.name

      // console.log(this.openAddRoom, 'cuy<')
    }

    // Listen for the showDialogShownSavedReplies event
    this.$eventBus.$on('showDialogShownSavedReplies', async payload => {
      this.dialogShownSavedReplies = true
    })

    // Find all the contact data for the instances
    try {
      const instanceIds = this.instances.map(el => this.$Md5Hash(el._id))
      await this.$store.dispatch('global/findAllContactData', {
        sub_id: this.user.sub_id,
        instances: instanceIds,
      })
    } catch (err) {
      console.error(err)
    }

    // try {
    //   const listInstances = await clientPocketBase.collection('crm_instances').getFullList(200, { $autoCancel: false })
    //   this.instances = listInstances
    // } catch (err) {
    //   console.log(err)
    // }
    // Get templates and quick replies
    this.getTemplates()
    this.getQuickReplies()

    // Fetch the response
    // this.fetchAllResponse()

    // Listen for messages from other windows
    window.addEventListener('message', event => {
      if (event.data.response_data) {
        this.instances = instances.value
        this.saveEditResponse(event.data)
      }
    })
    this.$store.commit('inbox/setLoadingDownloadFile', false)

    // Set the loadingCheckRooms status to false
    this.loadingCheckRooms = false
  },

  methods: {
    showCategory(editDialog = false) {
      const refs = editDialog ? this.$refs.edit_dialog_category : this.$refs.dialog_category
      refs.dialog.category = true
      refs.getDataCategory(true)
    },
    changeRoomId(data) {
      this.roomId = data
    },

    // async fetchBcGroup() {
    //   const fetchBcGroup = await clientPocketBase.collection('crm_contact_group').getFullList(400)
    //   this.contactGroups = fetchBcGroup
    // },
    async deleteResponse() {
      if (!this.allResponse[0].response_data) {
        const deleteResponse = await clientPocketBase
          .collection('crm_form_response')
          .delete(this.allResponse[0].id, { $autoCancel: false })
        this.newResponseId = ''
      }
    },
    openDialogAddRoom(data) {
      this.isOpened = data
      this.phoneNumberContact = ''
      this.selectedTableData = []
    },
    closeErrorNewChatSnackbar() {
      this.errorNewChat = false
      this.joinedError = ''
    },
    closeErrorTemplateExists() {
      this.errorTemplateExists = false
    },
    selectTag() {
      if (this.filter.labels[0].name === 'All') {
        this.filter.labels.shift()
      }
    },
    async getQuickReplies() {
      try {
        // fetch quick replies
        const foundQuickReplies = await clientPocketBase.collection('crm_quick_reply').getFullList(400, {
          expand: 'messages_templates',
          filter: `user_id = '${this.user.id}'`,
          $autoCancel: false,
        })

        // console.log(foundQuickReplies, '<<viraa get')
        this.savedReplies.value = JSON.parse(JSON.stringify(foundQuickReplies))

        // this.quickReplies.get()
      } catch (error) {
        console.log(error, 'error fetch quick reply')
      }
    },
    async updateInstance({ record, action }) {
      /// kalo ngedelete dari hp
      if (action === 'delete') {
        this.instances = this.instances.filter(ele => ele.id !== record.id)
      } else if (action === 'update') {
        this.rooms.forEach((obj, index) => {
          if (obj?.instance?.id === record.id) {
            this.rooms[index].instance = record
          }
        })
      }
    },
    async getTemplates() {
      const foundTemplates = await clientPocketBase.collection('crm_message_templates').getFullList(400, {
        $autoCancel: false,
      })
      this.savedRepliesTemplates = foundTemplates
      this.messageTemplates.value = foundTemplates
    },
    async detailToChat(source_id = '') {
      const foundRoom = await clientPocketBase.collection('crm_contact').getList(1, 10, {
        expand: 'assign_to',
        filter: `roomId = '${this.$route.query.roomId}' && broadcast_group = false`,
        $autoCancel: false,
      })
      this.rooms.push(this.mappingRooms([foundRoom.items[0]]))
      this.roomId = this.$route.query.roomId
      this.loadingRooms = false
      setTimeout(() => {
        this.fetchMessages({
          room: foundRoom.items[0],
          options: { reset: true, matchedSource: source_id, noScroll: true },
        })
      }, 100)

      // this.fetchMessages({
      //   room: foundRoom.items[0],
      //   options: { reset: true, matchedSource: source_id },
      // })
      // if (this.messages.findIndex(ele => ele.source_id === this.$route.query.source_id) === -1) {
      //   console.log(this.messages.length,'masuk sini gak<')
      //   this.fetchMessages({ room: foundRoom.items[0] })
      //   console.log(this.messages.length,'masuk sini gak<')
      // }
    },
    async detailToHashtag(source_id = '') {
      const foundRoom = await clientPocketBase.collection('crm_contact').getList(1, 10, {
        expand: 'assign_to',
        filter: `roomId = '${this.$route.query.roomId}' && broadcast_group = false`,
        $autoCancel: false,
      })
      this.rooms.push(this.mappingRooms([foundRoom.items[0]]))
      this.roomId = this.$route.query.roomId
      this.loadingRooms = false
      let count = 0
      do {
        this.fetchMessages({
          room: foundRoom.items[0],
          options: { reset: !count, matchedSource: source_id, noScroll: true },
        })
        count++
      } while (this.indexMessageHashtag < 0 && count < 10)

      // console.log('helo<<')

      setTimeout(() => {
        const element = document.getElementById('hashtag-message-container')

        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          })
        }
      }, 500)
      if (this.$route.query.roomId) {
        this.$router.replace('/inbox')
      }

      // setTimeout(() => {
      // this.fetchMessages({
      //   room: foundRoom.items[0],
      //   options: { reset: true, matchedSource: source_id },
      // })
      // }, 100)
    },
    async updateMessageEvent({ record, action }) {
      // console.log('🚀 ~ file: Chat.vue ~ line 1390 ~ updateMessageEvent ~ record', action, record)
      let roomIndex = -1
      let getRoom
      if (this.rooms.length > 0) {
        roomIndex = this.rooms.findIndex(room => {
          return room?.id === record?.dbRoomId
        })

        getRoom = this.rooms[roomIndex]
      }

      // console.log(getRoom, 'ini cuy<')
      // if (action === 'update') {
      //   const payload = JSON.parse(JSON.stringify(record))
      //   const indexRoom = this.rooms.findIndex(room => room.id === payload.dbRoomId)
      //   if (indexRoom > -1) {
      //     delete payload['@collectionId']
      //     delete payload['@collectionName']
      //     delete payload.expand

      //     const message = this.formatMessage(this.rooms[indexRoom], JSON.parse(JSON.stringify(record)), true)
      //     const indexMessage = this.messages.findIndex(msg => msg.source_id === payload.source_id)
      //     if (indexMessage > -1 && message) {
      //       this.messages[indexMessage] = message
      //     } else {
      //       this.messages = this.messages.concat([message])
      //     }
      //   }
      // }
      // if (action === 'create') {
      //   // if (!this.messageObj.hasOwnProperty(record.dbRoomId)) {
      //   //   // this.messageObj[record.dbRoomId] = [record.source_id]
      //   //   let arr = []
      //   //   arr.push(record.source_id)
      //   //   this.$store.commit('global/setMessageObj', { key: record.dbRoomId, payload: arr })
      //   // } else {
      //   //   let arr = JSON.parse(JSON.stringify(this.messageObj[record.dbRoomId]))
      //   //   if (arr?.findIndex(ele => ele === record.source_id) === -1) {
      //   //     arr.push(record.source_id)
      //   //     // this.messageObj[record.dbRoomId].push(record.source_id)
      //   //     // let arr = JSON.parse(JSON.stringify(this.messageObj[record.dbRoomId]))
      //   //     this.$store.commit('global/setMessageObj', { key: record.dbRoomId, payload: arr })
      //   //   }
      //   // }
      //   const payload = JSON.parse(JSON.stringify(record))

      //   // console.log(payload, 'create nih nyund')
      //   // const indexRoom = this.rooms.findIndex(room => room.id === payload.dbRoomId)

      //   if (getRoom) {
      //     delete payload['@collectionId']
      //     delete payload['@collectionName']
      //     delete payload.expand
      //     const message = this.formatMessage(getRoom, JSON.parse(JSON.stringify(record)), true)
      //     const tempTime = message.timestamp
      //     message.timestamp = { _seconds: message.seconds }
      //     const lastMessage = this.formatLastMessage(message, getRoom)

      //     // console.log(lastMessage, 'last message nyund')
      //     message.timestamp = tempTime

      //     /// /nyalain bawah kalo pengen message lgsg masuk
      //     // if (this.messages.length) {
      //     //   if (payload.dbRoomId === this.messages[0]?.dbRoomId) {
      //     //     const indexMessage = this.messages.findIndex(msg => msg.source_id === payload.source_id)
      //     //     if (indexMessage > -1 && message) {
      //     //       this.messages[indexMessage] = message
      //     //     } else {
      //     //       this.messages = this.messages.concat([message])
      //     //     }
      //     //   }
      //     // } else if (this.currentRoom !== null && record.dbRoomId && record.dbRoomId === this.currentRoom.id) {
      //     //   const messageIndex = this.messages.findIndex(msg => msg.source_id === payload.source_id)

      //     //   if (messageIndex == -1) {
      //     //     this.messages = this.messages.concat([message])
      //     //   } else {
      //     //     this.messages[messageIndex] = message
      //     //   }
      //     // }
      //     this.rooms[roomIndex].lastMessage =
      //       Object.keys(lastMessage).length > 0 ? lastMessage : this.rooms[roomIndex].lastMessage
      //     this.rooms[roomIndex].formattedTimestamp = lastMessage.formattedTimestamp
      //     if (getRoom.index < lastMessage.seconds) {
      //       this.rooms[roomIndex].index = lastMessage.seconds
      //     }
      //     this.rooms[roomIndex].unreadCount = 0

      //     // this.$store.commit('inbox/setNewChatContent', getRoom)
      //     if (this.messages.length) {
      //       if (payload.dbRoomId === this.messages[0]?.dbRoomId) {
      //         const indexMessage = this.messages.findIndex(msg => msg.source_id === payload.source_id)
      //         if (indexMessage > -1 && message) {
      //           this.messages[indexMessage] = message
      //         } else {
      //           this.messages = this.messages.concat([message])
      //         }
      //       }
      //     } else if (this.currentRoom !== null && record.dbRoomId && record.dbRoomId === this.currentRoom.id) {
      //       const messageIndex = this.messages.findIndex(msg => msg.source_id === payload.source_id)

      //       if (messageIndex == -1) {
      //         this.messages = this.messages.concat([message])
      //       } else {
      //         this.messages[messageIndex] = message
      //       }
      //     }
      //   }

      //   // if (indexRoom > -1 && payload.dbRoomId === this.messages[0].dbRoomId) {
      //   //   delete payload['@collectionId']
      //   //   delete payload['@collectionName']
      //   //   delete payload.expand

      //   //   const message = this.formatMessage(this.rooms[indexRoom], JSON.parse(JSON.stringify(record)), true)
      //   //   const indexMessage = this.messages.findIndex(msg => msg.source_id === payload.source_id)
      //   //   if (indexMessage > -1 && message) {
      //   //     this.messages[indexMessage] = message
      //   //   } else {
      //   //     this.messages = this.messages.concat([message])
      //   //   }
      //   // }

      //   /// COBA GANTI URUTAN!
      //   if (!this.messageObj.hasOwnProperty(record.dbRoomId)) {
      //     // this.messageObj[record.dbRoomId] = [record.source_id]
      //     const arr = []
      //     arr.push(record.source_id)
      //     this.$store.commit('global/setMessageObj', { key: record.dbRoomId, payload: arr })
      //   } else {
      //     const arr = JSON.parse(JSON.stringify(this.messageObj[record.dbRoomId]))
      //     if (arr?.findIndex(ele => ele === record.source_id) === -1) {
      //       arr.push(record.source_id)

      //       // this.messageObj[record.dbRoomId].push(record.source_id)
      //       // let arr = JSON.parse(JSON.stringify(this.messageObj[record.dbRoomId]))
      //       this.$store.commit('global/setMessageObj', { key: record.dbRoomId, payload: arr })
      //     }
      //   }
      // }
      // if (action === 'delete') {
      //   // console.log('delete nih nyund')
      //   if (this.messageObj.hasOwnProperty(record.dbRoomId)) {
      //     // let arr = JSON.parse(JSON.stringify(this.messageObj[record.dbRoomId]))
      //     const arr = JSON.parse(JSON.stringify(this.messageObj[record.dbRoomId]))
      //     if (arr?.length) {
      //       // const index = this.messageObj[record.dbRoomId].findIndex(ele => ele === record.source_id)
      //       // if (index) {
      //       //   this.messageObj[record.dbRoomId].splice(index, 1)
      //       // }
      //       const index = arr.findIndex(ele => ele === record.source_id)
      //       if (index) {
      //         // this.messageObj[record.dbRoomId].splice(index, 1)
      //         arr.splice(index, 1)
      //         this.$store.commit('global/setMessageObj', { key: record.dbRoomId, payload: arr })
      //       }
      //     } else {
      //       delete this.messageObj[record.dbRoomId]
      //     }
      //   }
      // }
    },
    async eventUpdatePocketBase({ record, action }) {
      // check if assign to exist and add expanded assign to
      if (record?.assign_to) {
        const fetchUserData = await clientPocketBase
          .collection('users')
          .getOne(record.assign_to, { $autoCancel: false })
        record.expand.assign_to = fetchUserData
      }

      // console.log(
      //   '🚀 ~ file: FloatingChat.vue ~ line 1418 ~ eventUpdatePocketBase ~ record, action ini dia did chat',
      //   record,
      //   action,
      //   this.isScanning,
      // )
      // if (!this.isScanning) {
      const payload = JSON.parse(JSON.stringify(record))
      delete payload['@collectionId']
      delete payload['@collectionName']

      // delete payload.expand

      if (action === 'update') {
        if (this.isScanning && time - this.lastInserted > 5000) {
          this.$store.commit('global/setIsScanning', false)
        }
        if (record.lastMessage) {
          await this.updateRoom({ record: payload })
        }

        // if(payload.subId === this.user.sub_id) {
        //   await this.updateRoom({ record: payload })
        // }
      }

      if (action === 'create') {
        // console.log(record, 'helo broK<')
        if (record.lastMessage) {
          await this.insertRoom({ record: payload })
        }
      }

      if (action === 'delete') {
        await this.deleteRoom({ old_record: payload })
      }

      /// guarding bcgroup
      if (
        action !== 'delete' &&
        record.type === 'rooms' &&
        !record.broadcast_group &&
        this.instances.some(instance => instance.id === record.instance)
      ) {
        this.$store.commit('inbox/setNewChatContent', record)
      }

      // } else {
      //   if(action === 'create' || action === 'update') {
      //     const time = new Date().getTime()
      //     if(action === 'update' && time - this.lastInserted > 5000) {
      //       this.$store.commit('global/setIsScanning', false)
      //     }
      //   }
      // }
    },
    async assignChatDropdown({ roomId, selectedMember, status, mappingId }) {
      // declare and set initial value for room index
      const subidInstance = `${this.user.sub_id}-${roomId.substring(0, roomId.indexOf('-'))}`
      const dataRoom = status === 'msg' ? this.roomsMessages : status === 'chat' ? this.roomsChats : this.rooms
      let index = -1
      if (status !== 'msg') {
        index = dataRoom.findIndex(x => x.roomId === roomId)
      } else if (status === 'msg') {
        index = this.roomsMessages.findIndex(x => x.mappingId === mappingId)
      }
      
      console.log(dataRoom[index], { selectedMember, roomId, status, mappingId }, '<<< assign chat dropdown')
      let assign
      try {
        const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/assignChatDropdown`
        const payload = {
          subId: this.user.sub_id,
          selectedMember,
          roomId,
          email: this.user.email,
          subidInstance,
        }
        const { data: assignChat } = await axios.post(endpoint, payload)
        assign = assignChat.data

        if (assignChat.status) {
          if (index > -1) {
            dataRoom[index].assign_to = selectedMember.id
            const expand = {
              assign_to: {
                email: selectedMember.email,
              },
            }
            dataRoom[index].expand = expand
          }
        } else {
          // this.errorStartChatValidation = true
        }

        // console.log(assignChat, '<<viraa')
      } catch (err) {
        console.log(err, 'err fetch template')

        // this.errorStartChatValidation = true
      }
    },
    async assignChat({ roomId, selectedMember, status, mappingId }) {
      // declare and set initial value for room index
      // console.log(this.rooms, this.roomsMessages, { selectedMember, roomId, mappingId }, '<<< start chat')
      const subidInstance = `${this.user.sub_id}-${roomId.substring(0, roomId.indexOf('-'))}`
      const dataRoom = status === 'msg' ? this.roomsMessages : status === 'chat' ? this.roomsChats : this.rooms
      let index = -1
      if (status !== 'msg') {
        index = dataRoom.findIndex(x => x.roomId === roomId)
      } else if (status === 'msg') {
        index = this.roomsMessages.findIndex(x => x.mappingId === mappingId)
      }

      let assign
      // console.log(dataRoom[index], '<<test nihh')
      try {
        const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/assignChat`
        const payload = {
          subId: this.user.sub_id,
          selectedMember,
          roomId,
          email: this.user.email,
          subidInstance,
          instanceId: dataRoom[index].instance._id,
          instance: dataRoom[index].instance.id,
          newPhone: dataRoom[index].phone_number,
        }
        const { data: assignChat } = await axios.post(endpoint, payload)
        assign = assignChat.data

        if (assignChat.status) {
          if (index > -1) {
            dataRoom[index].assign_to = selectedMember.id
            const expand = {
              assign_to: {
                email: selectedMember.email,
              },
            }
            dataRoom[index].expand = expand
          }
        } else {
          this.errorStartChatValidation = true
        }

        // console.log(assignChat, '<<viraa')
      } catch (err) {
        console.log(err, 'err fetch template')
        this.errorStartChatValidation = true
      }
    },

    // async assignChat({ roomId, selectedMember, status, mappingId }) {
    //   // declare and set initial value for room index
    //   console.log(this.rooms, this.roomsMessages, { selectedMember, roomId, mappingId }, '<<<viraaaaaa')
    //   let roomIndex = -1

    //   // iterate through rooms state
    //   if (status === 'room') {
    //     this.rooms.forEach((room, index) => {
    //       // check if element isnt undefined and have roomId property with the same value as roomId argument
    //       if (room?.roomId === roomId) {
    //         // reassign room index
    //         roomIndex = index
    //       }
    //     })
    //   } else if (status === 'msg') {
    //     this.roomsMessages.forEach((room, index) => {
    //       // check if element isnt undefined and have roomId property with the same value as roomId argument
    //       if (room?.mappingId === mappingId) {
    //         // reassign room index
    //         roomIndex = index
    //       }
    //     })
    //     // const index = this.rooms.findIndex(x => x.roomId === roomId)
    //   } else if (status === 'chat') {
    //     this.roomsChats.forEach((room, index) => {
    //       // check if element isnt undefined and have roomId property with the same value as roomId argument
    //       if (room?.roomId === roomId) {
    //         // reassign room index
    //         roomIndex = index
    //       }
    //     })
    //   }
    //   const dataRoom = status === 'msg' ? this.roomsMessages : status === 'chat' ? this.roomsChats : this.rooms
    //   if (roomIndex > -1) {
    //     try {
    //       // const assignChat = await clientPocketBase
    //       //   .collection('crm_contact')
    //       //   .update(this.$Md5Hash(`${this.rooms[roomIndex].instance._id}-${this.rooms[roomIndex].phone_number}`), {
    //       //     assign_to: selectedMember.id,
    //       //   })
    //       dataRoom[roomIndex].assign_to = selectedMember.id
    //       const expand = {
    //         assign_to: {
    //           email: selectedMember.email,
    //         },
    //       }

    //       // dataRoom[roomIndex].lastMessage = null
    //       dataRoom[roomIndex].expand = expand
    //       const id = dataRoom[roomIndex].contacts
    //       let isExist
    //       try {
    //         const updateContact = await clientPocketBase.collection('crm_contacts').update(
    //           id,
    //           {
    //             assignedTo: selectedMember.id,
    //           },
    //           { $autoCancel: false },
    //         )
    //         if (updateContact) isExist = true
    //       } catch (error) {
    //         if (error.status == 404) {
    //           isExist = false
    //         } else {
    //           // console.log(error)
    //         }
    //       }

    //       // if (!isExist) {
    //       //   const duplicateAlert = []
    //       //   const validPhone = []

    //       //   // const allPhoneNumber = await this.fetchAllPhoneNumberContact
    //       //   let isRegistered

    //       //   // const allPhoneNumber = await this.fetchAllPhoneNumber
    //       //   const inputPhone = dataRoom[roomIndex].phone_number
    //       //   const formatInputPhone = inputPhone.split(' ').join('')
    //       //   if (!isNaN(formatInputPhone)) {
    //       //     try {
    //       //       const getPhoneContacts = await clientPocketBase
    //       //         .collection('crm_contact_phones')
    //       //         .getFirstListItem(`phone_number = '${formatInputPhone}'`, {
    //       //           expand: 'contact_id',
    //       //           $autoCancel: false,
    //       //         })

    //       //       if (getPhoneContacts) {
    //       //         this.errorPhoneValidationSnackbar = { status: true, phone: formatInputPhone.toString() }
    //       //         isRegistered = true
    //       //         const updateContact = await clientPocketBase
    //       //           .collection('crm_contacts')
    //       //           .update(getPhoneContacts.contact_id, {
    //       //             assignedTo: selectedMember.id,
    //       //           }, {$autoCancel: false})
    //       //       }
    //       //     } catch (err) {
    //       //       if (err.status == 404) {
    //       //         isRegistered = false
    //       //       } else {
    //       //         console.log(err)
    //       //       }
    //       //     }

    //       //     // if (!isRegistered) {
    //       //     //   this.loading = true
    //       //     //   const newContact = await clientPocketBase.collection('crm_contacts').create({
    //       //     //     id,
    //       //     //     name: dataRoom[roomIndex].name,
    //       //     //     avatar: dataRoom[roomIndex].profile_picture,
    //       //     //     nickname: dataRoom[roomIndex].nickname,
    //       //     //     subId: this.user.sub_id,
    //       //     //     assignedTo: selectedMember.id,
    //       //     //   })
    //       //     //   const newPhoneNumber = await clientPocketBase.collection('crm_contact_phones').create({
    //       //     //     id: this.$Md5Hash(`${this.user.sub_id}-${formatInputPhone}`),
    //       //     //     phone_number: formatInputPhone,
    //       //     //     contact_id: id,
    //       //     //     sub_id: this.user.sub_id,
    //       //     //   })
    //       //     //   this.dialogContact = false
    //       //     // }
    //       //   }

    //       const assignChat = await clientPocketBase.collection('crm_contact').update(
    //         this.$Md5Hash(`${dataRoom[roomIndex].instance._id}-${dataRoom[roomIndex].phone_number}`),
    //         {
    //           assign_to: selectedMember.id,
    //         },
    //         { $autoCancel: false },
    //       )

    //       // this.roomsMessages[roomIndex] = dataRoom

    //       // this.$set(this.roomsMessages, roomIndex, dataRoom[roomIndex])
    //       //   // console.log(assignChat, 'haha')
    //       // }
    //     } catch (err) {
    //       console.log(err, 'helo')
    //       dataRoom[roomIndex].assign_to = selectedMember.id
    //       const expand = {
    //         assign_to: {
    //           email: selectedMember.email,
    //         },
    //       }

    //       // dataRoom[roomIndex].lastMessage = null
    //       dataRoom[roomIndex].expand = expand

    //       // console.log({ ...dataRoom[roomIndex] }, 'ini brok<')

    //       // const created =  await clientPocketBase
    //       //   .collection('crm_contact')
    //       //   .create(this.$Md5Hash(`${dataRoom[roomIndex].instance._id}-${dataRoom[roomIndex].phone_number}`), {
    //       //     assign_to: selectedMember.id,
    //       //   })
    //       // dataRoom[roomIndex].instance = dataRoom[roomIndex].instance.id

    //       // //console.log('🚀 ~ file: Chat.vue ~ line 1476 ~ assignChat ~ {...dataRoom[roomIndex]}', {
    //       //   ...dataRoom[roomIndex],
    //       // })
    //       if (err.status === 404) {
    //         // await clientPocketBase
    //         //   .collection('crm_contact')
    //         //   .create({ ...dataRoom[roomIndex] }, { $autoCancel: false })
    //         // const assignChat = await clientPocketBase.collection('crm_contact').update(
    //         //   this.$Md5Hash(`${dataRoom[roomIndex].instance._id}-${dataRoom[roomIndex].phone_number}`),
    //         //   {
    //         //     assign_to: selectedMember.id,
    //         //   },
    //         //   { $autoCancel: false },
    //         // )
    //         // dataRoom[roomIndex].expand = expand
    //       }

    //       // console.log('🚀 ~ file: Chat.vue ~ line 1408 ~ assignChat ~ err', err)
    //     }

    //     const instPhone = dataRoom[roomIndex].instance.phone_number

    //     // `${this.user.sub_id}-${dataRoom[roomIndex].phone_number}`
    //     try {
    //       const updateAssignment = await clientPocketBase.collection('crm_contact_assignment').update(
    //         this.$Md5Hash(`${this.user.sub_id}-${instPhone}-${dataRoom[roomIndex].phone_number}`),
    //         {
    //           assign_to: selectedMember.id,
    //           phone_number: dataRoom[roomIndex].phone_number,
    //         },
    //         { $autoCancel: false },
    //       )
    //     } catch (err) {
    //       if (err.status === 404) {
    //         try {
    //           // await clientPocketBase.collection('crm_contact_assignment').create({
    //           //   id: this.$Md5Hash(`${this.user.sub_id}-${dataRoom[roomIndex].phone_number}`),
    //           //   phone_number: dataRoom[roomIndex].phone_number,
    //           //   email: selectedMember.email,
    //           //   subId: this.user.sub_id,
    //           // })
    //           await clientPocketBase.collection('crm_contact_assignment').create(
    //             {
    //               id: this.$Md5Hash(`${this.user.sub_id}-${instPhone}-${dataRoom[roomIndex].phone_number}`),
    //               phone_number: dataRoom[roomIndex].phone_number,
    //               assign_to: selectedMember.id,
    //               subId: this.user.sub_id,
    //               channel_phone: `${instPhone}-${dataRoom[roomIndex].phone_number}`,
    //             },
    //             { $autoCancel: false },
    //           )
    //         } catch (err) {
    //           // console.log('🚀 ~ file: Chat.vue ~ line 1421 ~ assignChat ~ err', err)
    //         }
    //       }
    //     }
    //   }

    //   // const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/rooms/update-room-info`
    //   // const body = {
    //   //   subId: this.user.sub_id,
    //   //   roomId,
    //   //   roomInfo: {
    //   //     assign_to: selectedMember,
    //   //   },
    //   // }
    //   // try {
    //   //   await this.$axiosLimit.post(endpoint, body)

    //   //   // //console.log('🚀 ~ file: RoomHeader.vue ~ line 227 ~ changePinStatus ~ res', res)
    //   // } catch (err) {
    //   //   // //console.log('🚀 ~ file: RoomHeader.vue ~ line 230 ~ changePinStatus ~ err', err)
    //   // }
    // },
    closeDialogAddBroadcast() {
      this.addBroadcastDialog = false
      this.$store.dispatch('broadcast/setDefaultCustomMessage', null)
    },
    closeDialogForward() {
      this.clearSelectedMessages()
      this.dialogForwardMenu = false
      this.showCheckbox = false
      this.$store.dispatch('broadcast/setForwardMessages', null)
    },
    sendQuickReply(status) {
      this.loadSendMessage = status
    },
    async clearFilter() {
      this.filter = {
        labels: [{ name: 'All Labels', id: 'All' }],
        contactGroups: [],
        sort: 'newest',
        filters: [],
        channels: {
          label: 'All Channels',
          _id: 'All',
        },
        roomAssignTo: {
          name: 'All',
          id: 'All',
        },
      }
      this.currentOffset = 0
      this.roomsLoaded = false
      await this.fetchRooms({ filterRoom: this.filterRoom })
      const { labels, contactGroups, sort, channels } = JSON.parse(JSON.stringify(this.filter))

      // this.selectedLabels = labels
      // this.selectedContactGroups = contactGroups
      this.sortSetting = sort
      this.selectedChannel = 'All'
      this.selectedTag = 'All'
      this.selectedOptionsAssign = 'All'
      this.filterArr = []
      this.currentUserId = ''
      this.roomId = ''
      this.assignment = 'All'

      // //console.log(this.mappedRooms, this.rooms, filters, this.filterArr, 'pada saat clear')
      this.mappedRooms = []
      this.hashedInstance = ''
      this.isOnFilter = false

      // this.$store.commit('inbox/setCurrentOffset', 1)
    },
    async saveFilter(label = null) {
      const { labels, contactGroups, sort, channels, roomAssignTo } = JSON.parse(JSON.stringify(this.filter))

      // this.selectedLabels = labels
      // this.selectedContactGroups = contactGroups
      this.selectedOptionsAssign = roomAssignTo
      this.sortSetting = sort
      this.filterArr = []
      this.roomsLoaded = false
      this.currentUserId = ''
      this.roomId = ''
      this.loadingRooms = true
      if (!channels || (channels._id && channels._id === 'All')) {
        this.selectedChannel = 'All'
      } else {
        this.selectedChannel = channels
      }

      if (!labels.length || labels[0].name === 'All Labels') {
        this.selectedTag = 'All'
      } else {
        this.selectedTag = ''
        this.selectedLabelFilter = labels
      }

      const roomIds = []
      const phoneIds = []
      const foundRooms = []

      let sign
      if (this.sortSetting === 'newest') {
        sign = '-'
      } else {
        sign = '+'
      }
      let assignment = 'All'
      if (roomAssignTo === 'toMe') {
        assignment = this.user.id
      } else if (roomAssignTo === 'notAnyone') {
        assignment = 'empty'
      }
      this.assignment = assignment
      const payload = {
        token: this.user.token,
        labels: JSON.stringify(labels.map(ele => ele.id)),
        contactGroups,
        sort,
        channels,
        assignment,
        instances: this.instances,
        sub_id: this.user.sub_id,
      }

      // const test = await this.$store.dispatch('rooms/filterRooms', payload)
      this.fetchRooms({ filterRoom: this.filterRoom })
      this.isOnFilter = true
      this.dialogFilter = false
    },
    async saveConfig() {
      this.dialogConfig = false

      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'auto_assign',
        value: this.assignOnReply,
      })
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'bold_unreplied',
        value: this.readAcknowledge,
      })
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'notif_all_or_assigned',
        value: this.selectedNotificationSetting,
      })
    },
    async closeDialogConfig() {
      this.dialogConfig = false

      const assignOnReply = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'auto_assign',
      })
      if (assignOnReply.status) {
        this.assignOnReply = assignOnReply.data.value
      }

      const readAcknowledge = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'bold_unreplied',
      })
      if (readAcknowledge.status) {
        this.readAcknowledge = readAcknowledge.data.value
      }

      const selectedNotificationSetting = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'notif_all_or_assigned',
      })

      if (selectedNotificationSetting.status && selectedNotificationSetting.data) {
        this.selectedNotificationSetting = selectedNotificationSetting.data.value
      }
    },
    async closeDialogFilter() {
      this.dialogFilter = false
      if (this.isEmpty) {
        this.rooms = []
      }
      if (this.selectedTag === 'All') {
        this.filter.labels = [{ name: 'All Labels', id: 'All' }]
      }
      if (this.selectedChannel === 'All') {
        this.filter.channels = {
          label: 'All Channels',
          _id: 'All',
        }
      }
      if (this.selectedOptionsAssign === 'All') {
        this.filter.roomAssignTo = {
          name: 'All',
          id: 'All',
        }
      }
    },
    openConfig(value) {
      this.dialogConfig = value
    },
    async openFilter(value) {
      this.dialogFilter = value
      if (!this.rooms.length) {
        this.isEmpty = true

        // await this.fetchRooms({ filterRoom: this.filterRoom })
      } else {
        this.isEmpty = false
      }
    },
    async fetchContacts() {
      try {
        this.tableLoading = true
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        let getContact = []
        let getContactPb
        if (this.filterByUser === '') {
          getContactPb = await clientPocketBase.collection('crm_contacts').getList(page, itemsPerPage, {
            $autoCancel: false,
          })
        } else {
          getContactPb = await clientPocketBase.collection('crm_contacts').getList(page, itemsPerPage, {
            filter: `name ~ '${this.filterByUser}'`,
            $autoCancel: false,
          })
        }
        if (getContactPb) {
          getContactPb.items.forEach(ele => {
            ele.phoneNumber.length ? (ele.phoneNumber = ele.phoneNumber[0]) : '-'
          })
          getContact = getContactPb.items
          this.totalContacts = getContactPb.totalItems
        }
        this.contacts = getContact || []

        this.selectedAction = ''
        this.tableLoading = false
      } catch (error) {
        console.log(error, error.data, 'cok<')
      }
    },
    async mappingRoomSearch(result, fetchMore = false) {
      const { contactData, messages, roomMessages, roomsName } = result

      // this.roomsChats = []
      // this.roomsContacts = []
      // this.roomsMessages = []
      const roomsChats = []
      const roomsContacts = []

      // //console.log(
      //   '🚀 ~ file: Chat.vue ~ line 153 ~ mappingRoomSearch ~ contactData, messages, roomMessages,roomsName',
      //   contactData,
      //   messages,
      //   roomMessages,
      //   roomsName,
      // )

      contactData.forEach(room => {
        if (this.instances.find(instance => instance.id === room.instance)) {
          if (!room.lastMessage) {
            room.lastMessage = {
              content: '',
              files: [],
              couch_timestamp: Date.now(),
              timestamp: {
                _seconds: Date.now() / 1000,
                _nanoseconds: 0,
              },
              data: {},
            }
          }

          if (!room.users || room.users === 'unchanged_toast') {
            room.users = []
          }

          if (!room.typingUsers) {
            room.typingUsers = []
          }

          if (!room.roomStatus) {
            room.roomStatus = 'on_queue'
          }

          if (!room.archived) {
            room.archived = false
          }

          if (!room.assign_to) {
            room.assign_to = false
          }

          if (!room.last_interaction) {
            room.last_interaction = null
          }

          if (!room.notes) {
            room.notes = []
          }

          if (!room.pinned) {
            room.pinned = false
          }

          room.instance = this.instances.find(instance => instance.id === room.instance)
          let vacIndex
          if (room.lastMessage?.timestamp?._seconds || room.lastMessage?.seconds) {
            // //console.log(room.lastMessage.timestamp._seconds, `KDKFKSDFKSKFSKDFKSDFK`)
            vacIndex = room.lastMessage.timestamp._seconds || room.lastMessage?.seconds
            const format = this.$moment.unix(room.lastMessage.timestamp._seconds).isSame(this.$moment(), 'day')
              ? 'HH:mm'
              : 'DD/MM/YYYY'

            room.lastMessage.formattedTimestamp = this.$moment
              .unix(room.lastMessage.timestamp._seconds || room.lastMessage?.seconds)
              .format(format)
          }

          if (room.lastMessage?.msg) {
            vacIndex = room.lastMessage?.timestamp / 1000
            const format = this.$moment.unix(room.lastMessage?.timestamp / 1000).isSame(new Date(), 'day')
              ? 'HH:mm'
              : 'DD/MM/YYYY'

            room.lastMessage.formattedTimestamp = this.$moment.unix(room.lastMessage?.timestamp / 1000).format(format)
          }

          room.labels = this.setLabels(room.roomId, room)
          room.roomContactName = this.getContactListRoom(room.phone_number)
          room.roomContactListLabel = this.getContactListLabelRoom(room.phone_number)
          room.roomContactList = this.getContactListRoom(room.phone_number, true)
          room.index = vacIndex
          room.lastMessage = this.formatLastMessage(room.lastMessage, room)
          room.avatar = room.profile_picture ? room.profile_picture : require('@/plugins/demo/assets/avatar.png')

          room.roomName = this.getRoomName(room)
          room.unreadCount = room.unread_count

          roomsContacts.push(room)
        }
      })

      // let roomsMessages = roomMessages.filter(room => {
      //   if (this.instances.find(instance => instance.id === room.instance)) {
      //     if (!room.users || room.users === 'unchanged_toast') {
      //       room.users = []
      //     }

      //     if (!room.typingUsers) {
      //       room.typingUsers = []
      //     }

      //     if (!room.roomStatus) {
      //       room.roomStatus = 'on_queue'
      //     }

      //     if (!room.archived) {
      //       room.archived = false
      //     }

      //     if (!room.assign_to) {
      //       room.assign_to = false
      //     }

      //     if (!room.last_interaction) {
      //       room.last_interaction = null
      //     }

      //     if (!room.notes) {
      //       room.notes = []
      //     }

      //     if (!room.pinned) {
      //       room.pinned = false
      //     }

      //     const messageIndex = messages.findIndex(message => message.dbRoomId === room.id)

      //     room.instance = this.instances.find(instance => instance.id === room.instance)
      //     let vacIndex
      //     room.lastMessage = this.formatLastMessage(messages[messageIndex], room)
      //     if (room.lastMessage?.seconds) {
      //       vacIndex = room.lastMessage.seconds
      //       const format = this.$moment.unix(room.lastMessage.seconds).isSame(this.$moment(), 'day')
      //         ? 'HH:mm'
      //         : 'DD/MM/YYYY'

      //       room.lastMessage.formattedTimestamp = this.$moment.unix(room.lastMessage.seconds).format(format)
      //     }

      //     if (room.lastMessage?.msg) {
      //       vacIndex = room.lastMessage?.timestamp / 1000
      //       const format = this.$moment.unix(room.lastMessage?.timestamp / 1000).isSame(new Date(), 'day')
      //         ? 'HH:mm'
      //         : 'DD/MM/YYYY'

      //       room.lastMessage.formattedTimestamp = this.$moment.unix(room.lastMessage?.timestamp / 1000).format(format)
      //     }
      //     room.labels = this.setLabels(room.roomId)
      //     room.roomContactName = this.getContactListRoom(room.phone_number)
      //     room.roomContactListLabel = this.getContactListLabelRoom(room.phone_number)
      //     room.roomContactList = this.getContactListRoom(room.phone_number, true)
      //     room.index = vacIndex
      //     room.avatar = room.profile_picture ? room.profile_picture : require('@/plugins/demo/assets/avatar.png')
      //     room.roomName = this.getRoomName(room)
      //     room.unreadCount = room.unread_count

      //     return true
      //   }

      //   return false
      // })

      let roomsMessages = []
      let indexRoom = 0
      roomMessages.forEach(room => {
        if (this.instances.find(instance => instance.id === room.instance)) {
          if (!room.users || room.users === 'unchanged_toast') {
            room.users = []
          }

          if (!room.typingUsers) {
            room.typingUsers = []
          }

          if (!room.roomStatus) {
            room.roomStatus = 'on_queue'
          }

          if (!room.archived) {
            room.archived = false
          }

          if (!room.assign_to) {
            room.assign_to = false
          }

          if (!room.last_interaction) {
            room.last_interaction = null
          }

          if (!room.notes) {
            room.notes = []
          }

          if (!room.pinned) {
            room.pinned = false
          }

          // const messageIndex = messages.findIndex(message => message.dbRoomId === room.id)
          const messageIndex = indexRoom

          room.instance = this.instances.find(instance => instance.id === room.instance)
          let vacIndex
          room.lastMessage = this.formatLastMessage(messages[messageIndex], room)
          if (room.lastMessage?.seconds) {
            vacIndex = room.lastMessage.seconds
            const format = this.$moment.unix(room.lastMessage.seconds).isSame(this.$moment(), 'day')
              ? 'HH:mm'
              : 'DD/MM/YYYY'

            room.lastMessage.formattedTimestamp = this.$moment.unix(room.lastMessage.seconds).format(format)
          }

          if (room.lastMessage?.msg) {
            vacIndex = room.lastMessage?.timestamp / 1000
            const format = this.$moment.unix(room.lastMessage?.timestamp / 1000).isSame(new Date(), 'day')
              ? 'HH:mm'
              : 'DD/MM/YYYY'

            room.lastMessage.formattedTimestamp = this.$moment.unix(room.lastMessage?.timestamp / 1000).format(format)
          }
          room.labels = this.setLabels(room.roomId, room)
          room.roomContactName = this.getContactListRoom(room.phone_number)
          room.roomContactListLabel = this.getContactListLabelRoom(room.phone_number)
          room.roomContactList = this.getContactListRoom(room.phone_number, true)
          room.index = vacIndex
          room.avatar = room.profile_picture ? room.profile_picture : require('@/plugins/demo/assets/avatar.png')
          room.phone_number = room.phone_number.toString()
          room.roomName = this.getRoomName(room)
          room.unreadCount = room.unread_count

          room.mappingId = `${room.roomId}~${room.lastMessage.source_id}`
          indexRoom++
          roomsMessages = [...roomsMessages, room]
        }
      })

      // console.log(
      //   roomsMessages.map(ele => ele.roomId),
      //   'ini',
      // )

      for (const room of roomsName) {
        // const findRoom = await db.rooms.get({ roomId: room.roomId })
        // const findRoom = await clientPocketBase.collection('crm_contact').getList(1, 10, {
        //   expand: 'assign_to',
        //   filter: `roomId = '${room}' && type ~ 'rooms' && broadcast_group = false`,
        // })
        const formattedRoom = await this.mappingRoom(room)
        if (formattedRoom) {
          roomsChats.push(formattedRoom)
        }

        // if (findRoom?.items?.length) {
        //   // //console.log('🚀 ~ file: Chat.vue ~ line 341 ~ roomsNames ~ findRoom', findRoom)
        //   // const formattedRoom = await this.mappingRoom(findRoom.items[0])
        //   const formattedRoom = await this.mappingRoom(room)
        //   if (formattedRoom) {
        //     roomsChats.push(formattedRoom)
        //   }

        //   // return findRoom
        // }
      }

      // if (this.filterRoom === 'unread') {
      //   roomsChats = roomsChats.filter(room => room.unread_count > 0)
      //   roomsContacts = roomsContacts.filter(room => room.unread_count > 0)
      //   roomsMessages = roomsMessages.filter(room => room.unread_count > 0)
      // }

      if (fetchMore) {
        this.roomsChats = [...this.roomsChats, ..._uniqBy(roomsChats, 'roomId')]
        this.roomsContacts = [...this.roomsContacts, ..._uniqBy(roomsContacts, 'roomId')]

        // this.roomsMessages = [...this.roomsMessages, ..._uniqBy(roomsMessages, 'roomId')]
        this.roomsMessages = [...this.roomsMessages, ...roomsMessages]
      } else {
        this.roomsChats = [..._uniqBy(roomsChats, 'roomId')]
        this.roomsContacts = [..._uniqBy(roomsContacts, 'roomId')]
        this.roomsMessages = [...roomsMessages]

        // this.roomsMessages = [..._uniqBy(roomsMessages, 'roomId')]
      }

      this.roomsChats = _orderBy(
        this.roomsChats,
        ['pinned', 'index'],
        ['desc', this.sortSetting === 'newest' ? 'desc' : 'asc'],
      )
      this.roomsMessages = _orderBy(
        this.roomsMessages,
        ['pinned', 'index'],
        ['desc', this.sortSetting === 'newest' ? 'desc' : 'asc'],
      )

      this.roomsChatsOrigin = JSON.parse(JSON.stringify(this.roomsChats))
      this.roomsMessagesOrigin = JSON.parse(JSON.stringify(this.roomsMessages))
    },
    async searchRooms(value) {
      this.noRoomSearch = false
      if (value && value.length < 3) {
        this.isNotValidSearch = true
        this.roomsChats = this.rooms
      } else if (value && value.length >= 3) {
        this.isNotValidSearch = false
        if (!isNaN(value)) {
          if (value.startsWith('0')) {
            value = `62${value.slice(1)}`
          }
        }
        this.roomsLoaded = false
        this.roomsChats = []
        this.roomsContacts = []
        this.roomsMessages = []
        this.currentOffsetSearch = 0
        const payload = {
          token: this.user.token,
          subId: this.user.sub_id,
          searchBy: value,
          offset: this.currentOffsetSearch,
          limit: 10,
          instances: this.instances.filter(ele => !ele.broadcast_group),
          user: this.user,
        }
        const rooms = this.rooms.filter(room => {
          if (this.user.role !== 'Admin') {
            return (
              String(room.name).toLowerCase().includes(value) &&
              (!room.assign_to || (room.assign_to && room.expand.assign_to.email === this.user.email))
            )
          }

          return String(room.name).toLowerCase().includes(value)
        })
        if (rooms) {
          this.roomsChats = [...rooms]
        }

        // console.log(this.roomsChats, '<<data room chat')
        this.loadingSearch = true
        const roomsFromPg = await this.$store.dispatch('rooms/searchRooms', payload)
        const { roomsName, contactData, roomMessages } = roomsFromPg?.data

        // console.log(roomsFromPg?.data, '<<data room chat')
        let noRoom = false
        if (!roomsName?.length && !contactData?.length && !roomMessages?.length) {
          noRoom = true
          this.noRoomSearch = true
        }
        if (roomsFromPg?.status && !noRoom) {
          await this.mappingRoomSearch(roomsFromPg?.data)
        }
        this.loadingSearch = false
        this.rooms = []
        if (roomMessages.length < 10) {
          this.roomsLoaded = true
        }
      } else {
        this.loadingRooms = true
        this.roomsMessages = []
        this.roomsChats = []
        this.roomsContacts = []
        await this.fetchRooms({ filterRoom: this.filterRoom })
        this.loadingRooms = false
      }
    },
    async updateRoomInfo(room) {
      const roomIndex = this.rooms.findIndex(ele => ele.roomId === room.roomId)
      if (roomIndex > -1) {
        this.rooms[roomIndex].pinned = room.pinned
      }

      // //console.log('🚀 ~ file: Chat.vue ~ line 1328 ~ updateRoomInfo ~ room', room.pinned)
      // await db.rooms.update(room.id, { pinned: room.pinned })
      // await db.showedRooms.update(room.id, { pinned: room.pinned })
      // const roomIndex = this.rooms.findIndex(showedRoom => showedRoom.roomId === room.roomId)
      // if (roomIndex > -1) {
      //   this.rooms[roomIndex].pinned = room.pinned
      // }
      // //console.log('🚀 ~ file: Chat.vue ~ line 148 ~ updateRoomInfo ~ room', test, test2, room.pinned)
      // try {
      // } catch (err) {
      //   //console.log('🚀 ~ file: FloatingChat.vue ~ line 159 ~ updateRoomInfo ~ err', err)
      // }
    },
    async addRoom() {
      // const { phone_number: phoneNumber, instance: selectedInstance } = data

      try {
        const selectedInstance = this.selectedInstanceNewChat
        const formattedPhoneNumber =
          this.selectedTableData[0]?.phoneNumber || this.resultContact.formattedNumber.slice(1) || ''
        const name = this.selectedTableData[0]?.name
        const error = []

        if (Object.keys(selectedInstance).length === 0 && selectedInstance.constructor === Object) {
          error.push('Channel')
        }
        if (!formattedPhoneNumber) {
          error.push('Phone')
        }
        if (error.length) {
          throw error
        }

        // const formattedPhoneNumber = this.resultContact.formattedNumber.slice(1)
        // this.isOpened = false
        const roomId = `${selectedInstance._id}-${formattedPhoneNumber}`
        const getRoom = await clientPocketBase.collection('crm_contact').getFullList(10, {
          expand: 'assign_to',
          filter: `roomId = '${roomId}' && type ~ 'rooms'`,
          $autoCancel: false,
        })

        // const getShowedRoom = this.rooms.find(room => room.phone_number === formattedPhoneNumber)
        const getShowedRoom = this.rooms.find(room => room.roomId === roomId)

        // //console.log('🚀 ~ file: FloatingChat.vue ~ line 146 ~ addRoom ~ getRoom', getRoom, '=== ', getShowedRoom)
        if (getRoom?.length) {
          if (
            this.user.chat_mode !== 'Full Access' &&
            getRoom[0].assign_to &&
            getRoom[0].expand?.assign_to?.email !== this.user.email
          ) {
            throw `Chat is already assigned to another operator`
          }
          if (getShowedRoom) {
            this.roomId = getShowedRoom.roomId
          } else {
            if (!getRoom[0].lastMessage) {
              getRoom[0].lastMessage = {
                content: '',
                files: [],
                couch_timestamp: Date.now(),
                timestamp: {
                  _seconds: Date.now() / 1000,
                  _nanoseconds: 0,
                },
                data: {},
              }
              this.messagesLoaded = true
            }
            const roomMap = getRoom[0]

            // this.instances.forEach(instance => {
            //   if (instance && instance.status === 1 && instance.type !== 'chatwoot' && !instance.broadcast_group) {
            //     const filtered = getRoom.filter(
            //       room => room.id && room.lastMessage && room.instance === this.$Md5Hash(instance._id),
            //     )
            //     if (filtered?.length) {
            //       roomMap = JSON.parse(JSON.stringify(filtered))[0]
            //     }
            //   }
            // })

            const mappedRoom = await this.mappingRoom(roomMap)
            if (mappedRoom) {
              const newRoom = JSON.parse(JSON.stringify(mappedRoom))
              this.rooms = [...this.rooms, newRoom]
              this.roomId = roomMap.roomId
            } else {
              // const currentInstance = this.instances.find(ele => ele.type !== 'chatwoot' && !ele.broadcast_group)
              const roomPayload = {
                id: this.$Md5Hash(`${selectedInstance._id}-${formattedPhoneNumber}`),
                archived: false,
                assign_to: null,
                channel_source: null,
                createdAt: new Date(),
                updatedAt: new Date(),
                instance: this.$Md5Hash(selectedInstance._id),
                lastMessage: {
                  content: '',
                  files: [],
                  couch_timestamp: Date.now(),
                  timestamp: {
                    _seconds: Date.now() / 1000,
                    _nanoseconds: 0,
                  },
                  data: {},
                },
                last_interaction: null,
                last_message: 0,
                last_message_status: 0,
                last_reply: 0,
                message_from_me: 0,
                name: this.formatPhoneNumber(formattedPhoneNumber),
                phone_number: formattedPhoneNumber,
                phone_number_show: formattedPhoneNumber,
                pinned: false,
                profile_picture: require('@/plugins/demo/assets/avatar.png'),
                roomId: `${selectedInstance._id}-${formattedPhoneNumber}`,
                roomName: this.formatPhoneNumber(formattedPhoneNumber),
                roomOwnerId: selectedInstance.phone_number,
                roomOwnerName: selectedInstance.label,
                roomStatus: 'on_queue',
                subId: this.user.sub_id,
                sync_firestore: false,
                unread_count: 0,
                unreplied: false,
                contact_id: `${selectedInstance._id}-${formattedPhoneNumber}`,
                type: 'rooms',
                source: 'wa',
                users: [
                  {
                    username: formattedPhoneNumber,
                    _id: formattedPhoneNumber,
                    avatar: require('@/plugins/demo/assets/avatar.png'),
                    status: null,
                  },
                  {
                    _id: selectedInstance.phone_number,
                    status: null,
                    avatar: `${process.env.VUE_APP_WA_SERVICES_URL}ppic/${this.user.sub_id}-${selectedInstance.instance_id}/${selectedInstance.phone_number}/a.jpg`,
                    username: selectedInstance.label,
                  },
                ],
              }

              const newRoom = await this.mappingRoom(roomPayload)

              // //console.log('🚀 ~ file: FloatingChat.vue ~ line 2134 ~ addRoom ~ newRoom', newRoom)
              if (newRoom) {
                this.rooms = [...this.rooms, newRoom]

                this.roomId = newRoom.roomId
              }
            }
          }
        } else {
          // const currentInstance = this.instances.find(
          //   ele => ele.type !== 'chatwoot' && !ele.broadcast_group && ele.status === 1,
          // )
          // //console.log('🚀 ~ file: Chat.vue ~ line 2212 ~ addRoom ~ currentInstance', currentInstance)
          this.messagesLoaded = true
          const roomPayload = {
            id: this.$Md5Hash(`${selectedInstance._id}-${formattedPhoneNumber}`),
            archived: false,
            assign_to: null,
            channel_source: null,
            createdAt: new Date(),
            updatedAt: new Date(),
            instance: this.$Md5Hash(selectedInstance._id),
            lastMessage: {
              content: '',
              files: [],
              couch_timestamp: Date.now(),
              timestamp: {
                _seconds: Date.now() / 1000,
                _nanoseconds: 0,
              },
              data: {},
            },
            last_interaction: null,
            last_message: 0,
            last_message_status: 0,
            last_reply: 0,
            message_from_me: 0,
            name: this.nameFromContacts
              ? this.nameFromContacts
              : String(name) === String(formattedPhoneNumber)
              ? this.formatPhoneNumber(formattedPhoneNumber)
              : name,
            phone_number: formattedPhoneNumber,
            phone_number_show: formattedPhoneNumber,
            pinned: false,
            profile_picture: require('@/plugins/demo/assets/avatar.png'),
            roomId: `${selectedInstance._id}-${formattedPhoneNumber}`,
            roomName: this.formatPhoneNumber(formattedPhoneNumber),
            roomOwnerId: selectedInstance.phone_number,
            roomOwnerName: selectedInstance.label,
            roomStatus: 'on_queue',
            subId: this.user.sub_id,
            sync_firestore: false,
            unread_count: 0,
            unreplied: false,
            contact_id: `${selectedInstance._id}-${formattedPhoneNumber}`,
            type: 'rooms',
            source: 'wa',
            users: [
              {
                username: formattedPhoneNumber,
                _id: formattedPhoneNumber,
                avatar: require('@/plugins/demo/assets/avatar.png'),
                status: null,
              },
              {
                _id: selectedInstance.phone_number,
                status: null,
                avatar: `${process.env.VUE_APP_WA_SERVICES_URL}ppic/${this.user.sub_id}-${selectedInstance.instance_id}/${selectedInstance.phone_number}/a.jpg`,
                username: selectedInstance.label,
              },
            ],
          }

          // try {
          //   await db.rooms.add(roomPayload)
          // } catch (err) {}

          const newRoom = await this.mappingRoom(roomPayload)
          if (newRoom) {
            this.rooms = [...this.rooms, newRoom]

            // try {
            //   await db.showedRooms.add(newRoom)
            // } catch (err) {}

            this.roomId = newRoom.roomId
          }
        }
        this.isOpened = false
        this.selectedInstanceNewChat = {}
        this.selectedTableData = []
        this.resultContact = {}
        this.phoneNumberContact = ''
        this.panelPhone = 0
      } catch (error) {
        if (Array.isArray(error)) {
          this.errorNewChat = true
          this.joinedError = `${error.join(', ')} cannot be empty`
        } else if (error === 'Chat is already assigned to another operator') {
          this.errorNewChat = true
          this.joinedError = error
        }
      }
    },
    async startSock() {
      this.databaseChanges = null
      const REALTIME_URL = 'wss://realtime.superwa.io/socket'
      const client = new RealtimeClient(REALTIME_URL)
      client.connect(() => this.checkRooms(true))

      // client.onOpen(() => console.log('Socket Opened On Reconnect'))
      this.realtimeStatus = true

      // client.onClose(() => this.checkRooms(false))
      // client.onError(() => this.startSock(false))

      this.databaseChanges = client.channel(`realtime:${this.user.sub_id}:rooms`)
      this.databaseChanges.on('INSERT', e => this.insertRoom(e))
      this.databaseChanges.on('UPDATE', e => this.updateRoom(e))
      this.databaseChanges.on('DELETE', e => this.deleteRoom(e))

      this.databaseChanges.subscribe()
    },
    async toggleRoomsList({ opened }) {
      if (opened) {
        // //console.log(opened, this.roomsLoaded, this.currentOffset, this.$vuetify.breakpoint.xs, 'ini open chat')
        if (this.$vuetify.breakpoint.xs) {
          this.roomId = ''
          this.messages = []
          this.roomsLoaded = false

          // this.$store.commit('inbox/setCurrentOffset', 0)
          this.currentOffset = 0
          try {
            const showedRooms = (await db.showedRooms.toArray()).map(showedRoom => {
              return showedRoom.id
            })
            const oldRooms = (await db.showedRooms.toArray()).filter(showedRoom => showedRoom.unread_count > 0)
            await db.showedRooms.bulkDelete(showedRooms)
            if (this.filterRoom === 'unread') {
              await db.showedRooms.bulkAdd(oldRooms)
            }
            await this.fetchRooms({ filterRoom: this.filterRoom })
          } catch (err) {
            // console.log('🚀 ~ file: FloatingChat.vue ~ line 102 ~ filterRoom ~ err', err)
          }
        }
      }
    },
    async deleteMessage(msg, roomId, roomOwnerId = '') {
      const instanceId = roomId.split('-')[0]
      let phoneNumber = roomId.split('-')[1]
      const messageDeleted = JSON.parse(JSON.stringify(msg.original_message.key))
      const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/delete?id=${this.user.sub_id}-${instanceId}`
      const isLastMessage =
        this.messages.findIndex(message => message.source_id === msg.source_id) === this.messages.length - 1
      if (phoneNumber.split('@')[0].split(':')[0].length > 16) {
        phoneNumber = `${phoneNumber}@g.us`
        messageDeleted.participant = msg.original_message.participant
      } else {
        phoneNumber = `${phoneNumber}@s.whatsapp.net`
      }

      // //console.log(
      //   '🚀 ~ file: Chat.vue ~ line 2295 ~ deleteMessage ~ msg.original_message',
      //   msg.original_message,
      //   messageDeleted,
      //   phoneNumber,
      // )

      const response = await this.$axios.post(endpoint, {
        receiver: phoneNumber,
        message: messageDeleted,
        roomOwnerId,
        isLastMessage,
      })

      // //console.log(response, 'ini response<<<')

      if (response.data.success) {
        const indexMsg = this.messages.findIndex(message => message.source_id === response?.data?.data?.id)
        if (indexMsg > -1) {
          this.messages[indexMsg].deleted = true

          // //console.log(this.messages[indexMsg], 'ini diadelete<')
        }
      }
    },
    async filterRooms() {
      this.roomsLoaded = false

      // this.$store.commit('inbox/setCurrentOffset', 0)
      this.currentOffset = 0
      try {
        // const showedRooms = (await db.showedRooms.toArray()).map(showedRoom => {
        //   return showedRoom.id
        // })
        // const oldRooms = (await db.showedRooms.toArray()).filter(showedRoom => showedRoom.unread_count > 0)
        // await db.showedRooms.bulkDelete(showedRooms)
        // if (this.filterRoom === 'unread') {
        //   await db.showedRooms.bulkAdd(oldRooms)
        // }
        await this.fetchRooms({ filterRoom: this.filterRoom })
      } catch (err) {
        // console.log('🚀 ~ file: FloatingChat.vue ~ line 102 ~ filterRoom ~ err', err)
      }
    },
    actionFile({ message, file }) {
      console.log('sayang log', file)

      // this.$store.commit('inbox/setLoadingDownloadFile', true)
      if (file.file.type == 'contact') {
        console.log('sayang log 1')
        const blob = new Blob([file.file.url], {
          type: 'text/plain;charset=utf-8',
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${file.file.name}.vcf`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      } else if (file.action === 'preview') {
        console.log('sayang log 2')

        // //console.log('🚀 ~ file: Chat.vue ~ line 114 ~ actionFile ~ file', file.file.url)
        window.open(file.file.url, '_blank')
      } else {
        console.log('sayang log 3')
        this.$store.commit('inbox/setLoadingDownloadFile', true)

        this.$axios({
          url: `${file.file.url}`,
          method: 'GET',
          responseType: 'arraybuffer',
        }).then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${file.file.name}`)
          document.body.appendChild(link)
          link.click()
          console.log('ini setelah dpt link1<')
          this.$store.commit('inbox/setLoadingDownloadFile', false)

          /// DISINI INITIATE LOADING
        })
      }
    },
    async insertRoom({ record }) {
      if (record && record.assign_to !== 'unchanged_toast') {
        record.assign_to = record.assign_to
      }
      if (record.lastMessage) {
        let lastMessage = {}
        if (record.lastMessage !== 'unchanged_toast') {
          if (record.pinned === 'f') {
            record.pinned = false
          } else if (record.pinned === 't') {
            record.pinned = true
          }

          const parsedLastMessage = record.lastMessage
          let newRoom = JSON.parse(JSON.stringify(record))

          // try {
          //   await db.rooms.add(newRoom)
          // } catch (err) {}
          if (record.phone_number.length > 16) {
            // const response = await this.$store.dispatch('inboxNavbar/getUsers', {
            //   subId: this.user.sub_id,
            //   roomId: record.roomId,
            // })
            if (record?.users) {
              record.users = record.users.map(user => {
                user.username = this.getUsername(user)

                return user
              })
            }

            newRoom.users = record.users
          } else if (record.phone_number.length < 16) {
            if (record.users && record.users !== 'unchanged_toast' && newRoom && newRoom.users) {
              record.users = record.users
              newRoom.users = record.users
            }
          }
          if (newRoom && !newRoom.users) {
            newRoom.users = []
          }

          lastMessage = this.formatLastMessage(parsedLastMessage, record)

          // //console.log('🚀 ~ file: Chat.vue ~ line 1514 ~ insertRoom ~ lastMessage', newRoom, newRoom.instance_id)

          // }
          if (Object.keys(lastMessage).length !== 0) {
            newRoom = await this.mappingRoom(newRoom, null, null, null, true)

            // //console.log('🚀 ~ file: FloatingChat.vue ~ line 2287 ~ insertRoom ~ newRoom', newRoom)
            if (newRoom) {
              newRoom.lastMessage = lastMessage
              newRoom.labels = this.setLabels(record.roomId, record)
              newRoom.roomContactName = this.getContactListRoom(record.phone_number)
              newRoom.roomContactListLabel = this.getContactListLabelRoom(record.phone_number)
              newRoom.roomContactList = this.getContactListRoom(record.phone_number, true)
              newRoom.unread_count = +record.unread_count
              newRoom.unreadCount = +record.unread_count

              // //console.log(
              //   '🚀 ~ file: Chat.vue ~ line 1518 ~ insertRoom ~ newRoom',
              //   lastMessage?.seconds,
              //   record.lastMessage?.seconds,
              // )

              if (!newRoom.index) {
                newRoom.index = +lastMessage?.seconds
              }
              const currentRoom = JSON.parse(JSON.stringify(newRoom))

              let pass = false
              if (this.selectedLabelFilter.length === 0) {
                pass = true
              } else if (this.selectedLabelFilter.length > 0 && currentRoom.labels.length) {
                for (let i = 0; i < this.selectedLabelFilter.length; i++) {
                  if (currentRoom.labels.findIndex(label => label.id === this.selectedLabelFilter[i].id)) {
                    pass = false
                    break
                  }
                }
              }
              if (!pass) return

              try {
                if (this.user.chat_mode === 'Read and Write to assigned only') {
                  if (currentRoom && currentRoom.assign_to && currentRoom.expand.assign_to.email === this.user.email) {
                    // await db.showedRooms.add(currentRoom)
                    if (this.rooms.length < 20) {
                      this.rooms = [...this.rooms, currentRoom]
                    } else {
                      let marker = -1
                      for (let i = 0; i < this.rooms.length; i++) {
                        const room = this.rooms[i]
                        if (currentRoom.index > room.index) {
                          marker = i
                          break
                        }
                      }
                      if (marker === -1) {
                        this.rooms.unshift(currentRoom)
                      } else {
                        this.rooms.splice(marker, 0, currentRoom)
                      }
                    }
                  } else if (currentRoom && !currentRoom.assign_to) {
                    // await db.showedRooms.add(currentRoom)
                    if (this.rooms.length < 20) {
                      this.rooms = [...this.rooms, currentRoom]
                    } else {
                      let marker = -1
                      for (let i = 0; i < this.rooms.length; i++) {
                        const room = this.rooms[i]
                        if (currentRoom.index > room.index) {
                          marker = i
                          break
                        }
                      }
                      if (marker === -1) {
                        this.rooms.unshift(currentRoom)
                      } else {
                        this.rooms.splice(marker, 0, currentRoom)
                      }
                    }
                  }
                } else {
                  // await db.showedRooms.add(currentRoom)
                  if (this.rooms.length < 20) {
                    this.rooms = [...this.rooms, currentRoom]
                  } else {
                    let marker = -1
                    for (let i = 0; i < this.rooms.length; i++) {
                      const room = this.rooms[i]
                      if (currentRoom.index > room.index) {
                        marker = i
                        break
                      }
                    }
                    if (marker === -1) {
                      this.rooms.unshift(currentRoom)
                    } else {
                      this.rooms.splice(marker, 0, currentRoom)
                    }
                  }
                }

                /// INI YG START NEW CHAT LGSG FETCH MESSAGES
                if (
                  this.currentRoom !== null &&
                  record.roomId === this.currentRoom.roomId &&
                  record.roomId &&
                  record.lastMessage
                ) {
                  const message = parsedLastMessage
                  const formattedMessage = this.formatMessage(currentRoom, message, true)
                  const messageIndex = this.messages.findIndex(m => m._id == message.source_id)

                  if (messageIndex == -1) {
                    // console.log("MSG:ADDED:4:"+this.messages.length)
                    this.messages = this.messages.concat([formattedMessage])
                  } else {
                    this.messages[messageIndex] = formattedMessage
                  }
                }

                // let { currentOffset } = this
                // currentOffset += 1
                // this.$store.commit('inbox/setCurrentOffset', currentOffset)
              } catch (err) {}
            }
          }
        }

        // //console.log('🚀 ~ file: Chat.vue ~ line 126 ~ updateRoom ~ data', lastMessage)
      }
    },
    async updateRoom({ record }) {
      // const getRoom = await db.showedRooms.get({ roomId: record.roomId })
      // if (this.rooms.length > 0) {

      // }
      let roomIndex = -1
      let getRoom
      if (this.rooms.length > 0) {
        roomIndex = this.rooms.findIndex(room => {
          return room?.roomId === record?.roomId
        })
        getRoom = this.rooms[roomIndex]
      }

      // console.log('🚀 ~ file: Chat.vue ~ line 3846 ~ updateRoom ~ record.users', this.rooms, roomIndex, record)
      let lastMessage = {}
      let parsedLastMessage = {}

      // if (record.phone_number.length > 16 && record.users === 'unchanged_toast') {
      //   const response = await this.$store.dispatch('inboxNavbar/getUsers', {
      //     subId: this.user.sub_id,
      //     roomId: record.roomId,
      //   })
      //   record.users = response.data.map(user => {
      //     user.username = this.getUsername(user)

      //     return user
      //   })
      // } else if (record.phone_number.length < 16) {
      // }
      if (record && record.assign_to !== 'unchanged_toast') {
        record.assign_to = record.assign_to
      }
      if (record.users && record.users !== 'unchanged_toast') {
        record.users = record.users
      } else if (getRoom && record.users === 'unchanged_toast') {
        record.users = getRoom.users
      }

      if (record?.users) {
        record.users = record.users.map(user => {
          user.username = this.getUsername(user)

          return user
        })
      }

      if (record.lastMessage && record.lastMessage !== 'unchanged_toast') {
        parsedLastMessage = record.lastMessage
        record.lastMessage = parsedLastMessage
        lastMessage = this.formatLastMessage(parsedLastMessage, record)
      }

      // //console.log('🚀 ~ file: Chat.vue ~ line 2263 ~ updateRoom ~ lastMessage', record.lastMessage, getRoom)

      console.log(getRoom, 'ini broom<')

      if (getRoom) {
        getRoom.users = record.users
        record.unread_count = +record.unread_count
        if (record.pinned === 'f') {
          record.pinned = false
        } else if (record.pinned === 't') {
          record.pinned = true
        }

        if (record.roomName) {
          getRoom.roomName = record.roomName
        }

        if (getRoom && !getRoom.users) {
          getRoom.users = []
        }
        const timeIndex = new Date(record.last_message).getTime() / 1000
        let tempIndex = 0
        if (record.lastMessage?.timestamp?._seconds > timeIndex) {
          tempIndex = record.lastMessage?.timestamp?._seconds

          // getRoom.index = record.lastMessage?.timestamp?._seconds
        } else {
          tempIndex = timeIndex

          // getRoom.index = new Date(record.last_message).getTime() / 1000
        }
        if (getRoom.index < tempIndex) {
          getRoom.index = tempIndex
        }
        if (record.source === 'chatwoot') {
          // console.log("CW:"+JSON.stringify(record))
          getRoom.lastMessage = Object.keys(lastMessage).length > 0 ? lastMessage : getRoom.lastMessage
          getRoom.formattedTimestamp = lastMessage.formattedTimestamp
        }

        // if (!getRoom.labels || getRoom.labels.length === 0) {
        //   const foundRoom = await clientPocketBase.collection('crm_contact').getOne(record.id, {
        //     expand: 'contacts',
        //     $autoCancel: false,
        //   })
        //   const arrTags = foundRoom?.expand?.contacts ? foundRoom.expand.contacts.tags : []
        //   getRoom.labels = this.setLabels(record.roomId, record, arrTags)
        // }
        getRoom.roomContactName = this.getContactListRoom(record.phone_number)
        getRoom.roomContactListLabel = this.getContactListLabelRoom(record.phone_number)
        getRoom.roomContactList = this.getContactListRoom(record.phone_number, true)
        getRoom.pinned =
          record.pinnedFor?.length > 0 ? record.pinnedFor.findIndex(ele => ele === this.user.id) > -1 : false
        getRoom.pinnedFor = record.pinnedFor
        if (!this.roomId || (this.roomId && record.roomId !== this.roomId) || record.unread_count == 100) {
          getRoom.unreadCount = record.unread_count
        } else {
          try {
            const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/read-messages`
            const { data: test } = await axios.post(endpoint, {
              sub_id: this.user.sub_id,
              id: getRoom.id,
            })
            getRoom.unreadCount = 0
          } catch (error) {
            console.log(error, 'err<')
          }
        }
        getRoom.lastMessage = this.formatLastMessage(record.lastMessage, getRoom)

        getRoom.avatar = record.profile_picture || require('@/plugins/demo/assets/avatar.png')
        getRoom.unreplied = record.unreplied
        getRoom.message_container = record.message_container
        getRoom.roomId = record.roomId
        getRoom.assign_to = record.assign_to

        // fetch contact to get expanded relation
        if (record.lastMessage.deleted) {
          const lastMessage = this.formatLastMessage(record.lastMessage, getRoom)
          getRoom.lastMessage = lastMessage
        }

        // assign expand
        // getRoom.expand = foundRoom.expand
        const currentRoom = JSON.parse(JSON.stringify(getRoom))
        let pass = false
        if (this.selectedLabelFilter.length === 0) {
          pass = true
        } else if (this.selectedLabelFilter.length > 0 && getRoom.labels.length) {
          for (let i = 0; i < this.selectedLabelFilter.length; i++) {
            if (getRoom.labels.findIndex(label => label.id === this.selectedLabelFilter[i].id)) {
              pass = false
              break
            }
          }
        }
        if (!pass) return

        // if(this.selectedLabelFilter.length === 0 || (this.selectedLabelFilter.length > 0 &&))
        if (this.filterRoom === 'unread' && getRoom.unread_count > 0) {
          try {
            if (this.user.chat_mode === 'Read and Write to assigned only') {
              if (
                (currentRoom && currentRoom.assign_to && currentRoom.expand.assign_to.email === this.user.email) ||
                !currentRoom.assign_to
              ) {
                try {
                  this.rooms.splice(roomIndex, 1)
                  this.rooms.splice(roomIndex, 0, currentRoom)

                  // await db.showedRooms.add(currentRoom)
                } catch (err) {}
              } else if (
                currentRoom &&
                currentRoom.assign_to &&
                currentRoom.expand.assign_to.email !== this.user.email
              ) {
                this.rooms = this.rooms.filter(room => room.roomId !== currentRoom.roomId)

                // try {

                //   await db.showedRooms.where('roomId').equals(currentRoom.roomId).delete()
                // } catch (err) {
                //   // //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
                // }
              }
            } else {
              this.rooms.splice(roomIndex, 1)
              this.rooms.splice(roomIndex, 0, currentRoom)

              // this.rooms = [...this.rooms, currentRoom]

              // try {
              //   await db.showedRooms.add(currentRoom)
              // } catch (err) {}
            }
          } catch (err) {}

          try {
            if (this.user.chat_mode === 'Read and Write to assigned only') {
              if (
                (currentRoom && currentRoom.assign_to && currentRoom.expand.assign_to.email === this.user.email) ||
                !currentRoom.assign_to
              ) {
                this.rooms.splice(roomIndex, 1)
                this.rooms.splice(roomIndex, 0, currentRoom)

                // this.rooms = [...this.rooms, currentRoom]
              } else if (
                currentRoom &&
                currentRoom.assign_to &&
                currentRoom.expand.assign_to.email !== this.user.email
              ) {
                this.rooms = this.rooms.filter(room => room.roomId !== currentRoom.roomId)
              }
            } else {
              this.rooms.splice(roomIndex, 1)
              this.rooms.splice(roomIndex, 0, currentRoom)

              // try {
              //   await db.showedRooms.update(currentRoom.id, currentRoom)
              // } catch (err) {}
            }
          } catch (err) {}
          this.$store.commit('inbox/setNewChat', true)
          if (this.messages.length > 0) {
            if (this.messages[0].dbRoomId == this.$Md5Hash(record.roomId) && Object.keys(lastMessage).length > 0) {
              const message = parsedLastMessage
              const formattedMessage = this.formatMessage(currentRoom, message, true)
              const messageIndex = this.messages.findIndex(m => m._id == message.source_id)

              if (messageIndex == -1) {
                // console.log("MSG:ADDED:1:"+this.messages.length)
                this.messages = this.messages.concat([formattedMessage])
              } else {
                this.messages[messageIndex] = formattedMessage
              }

              // this.messages = _orderBy(this.messages, ['couch_timestamp'], ['asc'])
              // this.messages = _uniqBy(this.messages, '_id')
            }
          } else if (
            this.currentRoom !== null &&
            record.roomId === this.currentRoom.roomId &&
            record.roomId &&
            record.lastMessage &&
            record.source !== 'chatwoot'
          ) {
            const message = parsedLastMessage
            const formattedMessage = this.formatMessage(currentRoom, message, true)
            const messageIndex = this.messages.findIndex(m => m._id == message.source_id)
            if (messageIndex == -1) {
              console.log(`MSG:ADDED:2:${this.messages.length}`)
              this.messages = this.messages.concat([formattedMessage])
            } else {
              this.messages[messageIndex] = formattedMessage
            }
          }
        } else if (this.filterRoom === 'all' || this.filterRoom === 'unreplied') {
          try {
            if (this.user.chat_mode === 'Read and Write to assigned only') {
              if (
                (currentRoom && currentRoom.assign_to && currentRoom.expand.assign_to.email === this.user.email) ||
                !currentRoom.assign_to
              ) {
                if (this.selectedChannel === currentRoom.instance._id) {
                  this.rooms.splice(roomIndex, 1)
                  this.rooms.splice(roomIndex, 0, currentRoom)

                  // this.rooms = [...this.rooms, currentRoom]
                }

                // try {
                //   await db.showedRooms.add(currentRoom)
                // } catch (err) {}
              } else if (
                currentRoom &&
                currentRoom.assign_to &&
                currentRoom.expand.assign_to.email !== this.user.email
              ) {
                this.rooms = this.rooms.filter(room => room.roomId !== currentRoom.roomId)

                // try {
                //   await db.showedRooms.where('roomId').equals(currentRoom.roomId).delete()
                // } catch (err) {
                //   // //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
                // }
              }
            } else {
              // //console.log(
              //   '🚀 ~ file: FloatingChat.vue ~ line 2393 ~ updateRoom ~ this.rooms.find(room => room.roomId === record.roomId)',
              //   this.rooms.find(room => room.roomId === record.roomId),
              //   this.rooms[roomIndex],
              // )
              // if(this.selectedInstance ==)
              // this.rooms.splice(roomIndex, 1)
              // this.rooms.splice(roomIndex, 0, currentRoom)

              this.rooms = [...this.rooms, currentRoom]

              // this.rooms = this.rooms.filter(room => room.roomId !== currentRoom.roomId)
              // try {
              //   await db.showedRooms.add(currentRoom)
              // } catch (err) {}
            }
          } catch (err) {}
          this.rooms = _orderBy(
            this.rooms,
            ['pinned', 'index'],
            ['desc', this.sortSetting === 'newest' ? 'desc' : 'asc'],
          )

          // try {
          //   if (this.user.chat_mode === 'Read and Write to assigned only') {
          //     if (
          //       (currentRoom && currentRoom.assign_to && currentRoom.assign_to.email === this.user.email) ||
          //       !currentRoom.assign_to
          //     ) {
          //       this.rooms = [...this.rooms, currentRoom]

          //       // try {
          //       //   await db.showedRooms.update(currentRoom.id, currentRoom)
          //       // } catch (err) {}
          //     } else if (currentRoom && currentRoom.assign_to && currentRoom.assign_to.email !== this.user.email) {
          //       this.rooms = this.rooms.filter(room => room.roomId !== currentRoom.roomId)

          //       // try {
          //       //   await db.showedRooms.where('roomId').equals(currentRoom.roomId).delete()
          //       // } catch (err) {
          //       //   // //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
          //       // }
          //     }
          //   } else {
          //     this.rooms = [...this.rooms, currentRoom]

          //     // try {
          //     //   await db.showedRooms.update(currentRoom.id, currentRoom)
          //     // } catch (err) {}
          //   }
          // } catch (err) {}
          this.$store.commit('inbox/setNewChat', true)
          if (this.messages.length > 0) {
            if (this.messages[0].dbRoomId == this.$Md5Hash(record.roomId) && Object.keys(lastMessage).length > 0) {
              const message = parsedLastMessage
              const formattedMessage = this.formatMessage(currentRoom, message, true)
              const messageIndex = this.messages.findIndex(m => m._id == message.source_id)

              if (messageIndex == -1) {
                // console.log("MSG:ADDED:1:"+this.messages.length)
                this.messages = this.messages.concat([formattedMessage])
              } else {
                this.messages[messageIndex] = formattedMessage
              }

              // this.messages = _orderBy(this.messages, ['couch_timestamp'], ['asc'])
              // this.messages = _uniqBy(this.messages, '_id')
            }
          } else if (
            this.currentRoom !== null &&
            record.roomId === this.currentRoom.roomId &&
            record.roomId &&
            record.lastMessage &&
            record.source !== 'chatwoot'
          ) {
            const message = parsedLastMessage
            const formattedMessage = this.formatMessage(currentRoom, message, true)
            const messageIndex = this.messages.findIndex(m => m._id == message.source_id)
            if (messageIndex == -1) {
              console.log(`MSG:ADDED:2:${this.messages.length}`)
              this.messages = this.messages.concat([formattedMessage])
            } else {
              this.messages[messageIndex] = formattedMessage
            }
          }

          // if (record.source === 'chatwoot') {
          // }

          // //console.log(
          //   '🚀 ~ file: FloatingChat.vue ~ line 2393 ~ updateRoom ~ this.rooms.find(room => room.roomId === record.roomId)',
          //   this.rooms.find(room => room.roomId === record.roomId),
          //   this.rooms[roomIndex],
          // )
        }
      } else {
        let newRoom = JSON.parse(JSON.stringify(record))
        if (Object.keys(lastMessage).length > 0) {
          newRoom.lastMessage = lastMessage
        }

        // if (newRoom.pinned === 'f') {
        //   newRoom.pinned = false
        // } else if (newRoom.pinned === 't') {
        //   newRoom.pinned = true
        // }

        newRoom.pinned =
          newRoom.pinnedFor?.length > 0 ? newRoom.pinnedFor.findIndex(ele => ele === this.user.id) > -1 : false
        newRoom.pinnedFor = newRoom.pinnedFor

        // if (newRoom.users && newRoom.users !== 'unchanged_toast') {
        //   newRoom.users = JSON.parse(newRoom.users)
        // }
        // if (newRoom && !newRoom.users) {
        //   newRoom.users = []
        // }
        newRoom.index = newRoom.lastMessage?.seconds
        newRoom.unread_count = +newRoom.unread_count

        // try {
        //   await db.rooms.add(newRoom.id, newRoom)
        // } catch (err) {}
        // try {
        //   await db.rooms.update(newRoom.id, newRoom)
        // } catch (err) {}

        // lastMessage = this.formatLastMessage(parsedLastMessage, newRoom)

        // }
        newRoom = await this.mappingRoom(newRoom, null, null, null, true)

        // const foundRoom = await clientPocketBase.collection('crm_contact').getOne(record.id, {
        //   expand: 'contacts',
        //   $autoCancel: false,
        // })
        console.log(`REC:${JSON.stringify(record)}`)
        const arrTags = [] // foundRoom?.expand?.contacts ? foundRoom.expand.contacts.tags : []
        newRoom.labels = this.setLabels(record.roomId, record, arrTags)
        let currentRoom
        if (newRoom) {
          currentRoom = JSON.parse(JSON.stringify(newRoom))
        }

        let pass = false
        if (this.selectedLabelFilter.length === 0) {
          pass = true
        } else if (this.selectedLabelFilter.length > 0 && currentRoom.labels.length) {
          for (let i = 0; i < this.selectedLabelFilter.length; i++) {
            if (currentRoom.labels.findIndex(label => label.id === this.selectedLabelFilter[i].id)) {
              pass = false
              break
            }
          }
        }
        if (!pass) return

        if (this.filterRoom === 'unread' && newRoom.unread_count > 0) {
          try {
            if (this.user.chat_mode === 'Read and Write to assigned only') {
              if (
                (currentRoom && currentRoom.assign_to && currentRoom.expand.assign_to.email === this.user.email) ||
                !currentRoom.assign_to
              ) {
                // this.rooms.splice(roomIndex, 1)

                this.rooms = [...this.rooms, currentRoom]

                // try {
                //   await db.showedRooms.add(currentRoom)
                // } catch (err) {}
              } else if (
                currentRoom &&
                currentRoom.assign_to &&
                currentRoom.expand.assign_to.email !== this.user.email
              ) {
                this.rooms = this.rooms.filter(room => room.roomId !== currentRoom.roomId)

                // try {
                //   await db.showedRooms.where('roomId').equals(currentRoom.roomId).delete()
                // } catch (err) {
                //   // //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
                // }
              }
            } else {
              // this.rooms.splice(roomIndex, 1)
              this.rooms = [...this.rooms, currentRoom]

              // try {
              //   await db.showedRooms.add(currentRoom)
              // } catch (err) {}
            }
          } catch (err) {}

          try {
            if (this.user.chat_mode === 'Read and Write to assigned only') {
              if (
                (currentRoom && currentRoom.assign_to && currentRoom.expand.assign_to.email === this.user.email) ||
                !currentRoom.assign_to
              ) {
                // this.rooms[(roomIndex = currentRoom)]
                this.rooms = [...this.rooms, currentRoom]

                // try {
                //   await db.showedRooms.update(currentRoom.id, currentRoom)
                // } catch (err) {}
              } else if (
                currentRoom &&
                currentRoom.assign_to &&
                currentRoom.expand.assign_to.email !== this.user.email
              ) {
                this.rooms = this.rooms.filter(room => room.roomId !== currentRoom.roomId)

                // try {
                //   await db.showedRooms.where('roomId').equals(currentRoom.roomId).delete()
                // } catch (err) {
                //   // //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
                // }
              }
            } else {
              // this.rooms.splice(roomIndex, 1)
              this.rooms = [...this.rooms, currentRoom]

              // try {
              //   await db.showedRooms.update(currentRoom.id, currentRoom)
              // } catch (err) {}
            }
          } catch (err) {}
          this.$store.commit('inbox/setNewChat', true)
        } else if (this.filterRoom === 'all' || this.filterRoom === 'unreplied') {
          try {
            if (this.user.chat_mode === 'Read and Write to assigned only') {
              if (
                (currentRoom && currentRoom.assign_to && currentRoom.expand.assign_to.email === this.user.email) ||
                !currentRoom.assign_to
              ) {
                // this.rooms.splice(roomIndex, 1)
                this.rooms = [...this.rooms, currentRoom]

                /// COBA MATIIN
                // let marker = -1
                // for (let i = 0; i < this.rooms.length; i++) {
                //   const room = this.rooms[i]
                //   if (currentRoom.index > room.index) {
                //     marker = i
                //     break
                //   }
                // }
                // if (marker === -1) {
                //   this.rooms.unshift(currentRoom)
                // } else {
                //   this.rooms.splice(marker, 0, currentRoom)
                // }

                // try {
                //   await db.showedRooms.add(currentRoom)
                // } catch (err) {}
              } else if (
                currentRoom &&
                currentRoom.assign_to &&
                currentRoom.expand.assign_to.email !== this.user.email
              ) {
                this.rooms = this.rooms.filter(room => room.roomId !== currentRoom.roomId)

                // try {
                //   await db.showedRooms.where('roomId').equals(currentRoom.roomId).delete()
                // } catch (err) {
                //   // //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
                // }
              }
            } else {
              // if (this.rooms.length < 20) {
              // this.rooms.splice(roomIndex, 1)
              // }
              this.rooms = [...this.rooms, currentRoom]

              // let marker = -1
              // for (let i = 0; i < this.rooms.length; i++) {
              //   const room = this.rooms[i]
              //   if (currentRoom.index > room.index) {
              //     marker = i
              //     break
              //   }
              // }
              // if (marker === -1) {
              //   this.rooms.unshift(currentRoom)
              // } else {
              //   this.rooms.splice(marker, 0, currentRoom)
              // }

              // try {
              //   await db.showedRooms.add(currentRoom)
              // } catch (err) {}
            }
          } catch (err) {}

          try {
            if (this.user.chat_mode === 'Read and Write to assigned only') {
              if (
                (currentRoom && currentRoom.assign_to && currentRoom.expand.assign_to.email === this.user.email) ||
                !currentRoom.assign_to
              ) {
                // this.rooms.splice(roomIndex, 1)
                // this.rooms = [...this.rooms, currentRoom]

                let marker = -1
                for (let i = 0; i < this.rooms.length; i++) {
                  const room = this.rooms[i]
                  if (currentRoom.index > room.index) {
                    marker = i
                    break
                  }
                }
                if (marker === -1) {
                  this.rooms.unshift(currentRoom)
                } else {
                  this.rooms.splice(marker, 0, currentRoom)
                }

                // try {
                //   await db.showedRooms.update(currentRoom.id, currentRoom)
                // } catch (err) {}
              } else if (
                currentRoom &&
                currentRoom.assign_to &&
                currentRoom.expand.assign_to.email !== this.user.email
              ) {
                this.rooms = this.rooms.filter(room => room.roomId !== currentRoom.roomId)

                // try {
                //   await db.showedRooms.where('roomId').equals(currentRoom.roomId).delete()
                // } catch (err) {
                //   // //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
                // }
              }
            } else {
              // if (this.rooms.length < 20) {
              //   this.rooms.splice(roomIndex, 1)
              //   this.rooms = [...this.rooms, currentRoom]
              // }
              this.rooms = [...this.rooms, currentRoom]

              // let marker = -1
              // for (let i = 0; i < this.rooms.length; i++) {
              //   const room = this.rooms[i]
              //   if (currentRoom.index > room.index) {
              //     marker = i
              //     break
              //   }
              // }
              // if (marker === -1) {
              //   this.rooms.unshift(currentRoom)
              // } else {
              //   this.rooms.splice(marker, 0, currentRoom)
              // }
              // try {
              //   await db.showedRooms.update(currentRoom.id, currentRoom)
              // } catch (err) {}
            }
          } catch (err) {}
          this.$store.commit('inbox/setNewChat', true)
        }
      }
      if (record.source === 'chatwoot') {
        this.loadSendMessage = false
      }

      // this.rooms = _orderBy(this.rooms, ['pinned', 'index'], ['desc', this.sortSetting === 'newest' ? 'desc' : 'asc'])
    },
    async deleteRoom({ old_record }) {
      // try {
      //   await db.rooms.where('id').equals(old_record.id).delete()
      // } catch (err) {
      //   // //console.log('🚀 ~ file: Chat.vue ~ line 864 ~ deleteRoom ~ err', err)
      // }
      // if (old_record.roomId) {
      //   try {
      //     await db.rooms.where('roomId').equals(old_record.roomId).delete()
      //   } catch (err) {
      //     // //console.log('🚀 ~ file: Chat.vue ~ line 864 ~ deleteRoom ~ err', err)
      //   }

      //   try {
      //     await db.showedRooms.where('roomId').equals(old_record.roomId).delete()
      //   } catch (err) {
      //     // //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
      //   }
      // }
      // try {
      //   await db.showedRooms.where('id').equals(old_record.id).delete()
      // } catch (err) {
      //   // //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
      // }

      const roomIndex = this.rooms.findIndex(room => room.roomId === old_record.roomId)
      if (roomIndex > -1) {
        this.rooms.splice(roomIndex, 1)
      } else if (roomIndex === -1) {
        this.rooms = this.rooms.filter(room => room.roomId !== old_record.roomId)
      }

      // let { currentOffset } = this
      // currentOffset -= 1
      // this.$store.commit('inbox/setCurrentOffset', currentOffset)
    },
    async checkRooms(realtimeStatus = false) {
      this.loadingCheckRooms = true
      this.realtimeStatus = realtimeStatus
      const lastRoom = _orderBy(await db.showedRooms.reverse().toArray(), ['last_message'], ['desc'])[0]
      if (lastRoom) {
        const rooms = await this.$store.dispatch('rooms/getRoomsFromLastMessage', {
          token: this.user.token,
          sub_id: this.user.sub_id,
          last_message: lastRoom.last_message,
        })

        // //console.log('🚀 ~ file: AdvancedChatMDPostgres.vue ~ line 3099 ~ checkRooms ~ rooms', rooms)
        try {
          await db.rooms.bulkAdd(rooms.data.data)
        } catch (err) {
          await db.rooms.bulkPut(rooms.data.data)
        }

        const roomsFromPg = await db.rooms
          .where('last_message')
          .above(lastRoom.last_message)
          .limit(10)
          .reverse()
          .toArray()

        if (roomsFromPg?.length > 1) {
          this.currentOffset += 1

          // let { currentOffset } = this
          // currentOffset += roomsFromPg.length
          // this.$store.commit('inbox/setCurrentOffset', currentOffset)
        }

        const { label, labels } = await this.getSettingPg()
        const { contacts } = await this.loadContentContactsList()
        const showedRooms = await db.showedRooms.toArray()

        // await this.mappingRooms([...roomsFromPg, showedRooms], label, labels, contacts)
        this.loadingCheckRooms = false
      }
    },
    async fetchRooms({ filterRoom = 'all' }) {
      // this.$store.commit('inbox/setCurrentOffset', 1)
      this.rooms = []
      setTimeout(async () => {
        this.loadingRooms = true

        // let roomsFromPg
        const queryInstance = ''
        const temp = 0

        // for (let i = 0; i < this.instances.length; i++) {
        //   const instance = this.instances[i]
        //   if (instance.status === 1 && !instance.broadcast_group) {
        //     if (temp === 0) {
        //       if (instance.type === 'chatwoot') {
        //         queryInstance += `instance = '${instance.id}' && inbox_id = '${instance.inbox_id}'`
        //       } else {
        //         queryInstance += `instance = '${instance.id}'`
        //       }
        //       temp++
        //     } else {
        //       if (!queryInstance) {
        //         queryInstance += `instance = '${instance.id}'`
        //       } else {
        //         queryInstance += `|| instance = '${instance.id}'`
        //       }
        //     }
        //   }
        // }
        if (this.getSearch) {
          if (filterRoom === 'all' || filterRoom === 'unreplied') {
            this.roomsChats = JSON.parse(JSON.stringify(this.roomsChatsOrigin))
            this.roomsMessages = JSON.parse(JSON.stringify(this.roomsMessagesOrigin))
          } else {
            this.roomsChats = this.roomsChatsOrigin.filter(ele => ele.unread_count > 0)
            this.roomsMessages = this.roomsMessagesOrigin.filter(ele => ele.unread_count > 0)
          }
        }
        const { labels, sort, channels, roomAssignTo } = JSON.parse(JSON.stringify(this.filter))

        this.selectedOptionsAssign = roomAssignTo
        this.sortSetting = sort
        this.filterArr = []
        this.roomsLoaded = false
        this.currentUserId = ''
        this.roomId = ''
        if (!channels || (channels._id && channels._id === 'All')) {
          this.selectedChannel = 'All'
        } else {
          this.selectedChannel = channels
        }

        if (!labels.length || labels[0].name === 'All Labels') {
          this.selectedTag = 'All'
        } else {
          this.selectedTag = ''
        }

        let assignment = 'All'
        if (roomAssignTo === 'toMe') {
          assignment = this.user.id
        } else if (roomAssignTo === 'notAnyone') {
          assignment = 'empty'
        }
        const payload = {
          filterRoom,
          token: this.user.token,
          labels: JSON.stringify(labels.map(ele => ele.id)),
          sort,
          channels,
          assignment,
          instances: this.instances,
          sub_id: this.user.sub_id,
          currentOffset: 0,
          id_user: this.user.id,
        }

        const roomsFromPg = {
          items: [],

          // totalPages: 0,
        }

        const test = await this.$store.dispatch('rooms/fetchChats', payload)
        if (test.status && test.data && test.data.chats) {
          roomsFromPg.items = test.data.chats
          this.roomNum = test.data.totalPages
          // roomsFromPg.totalPages = test.data.totalPages
        }

        // if (filterRoom === 'all') {
        //   // && (instance = ${})
        //   try {
        //     if (this.getSearch) {
        //       this.roomsChats = JSON.parse(JSON.stringify(this.roomsChatsOrigin))
        //       this.roomsMessages = JSON.parse(JSON.stringify(this.roomsMessagesOrigin))
        //     } else {
        //       roomsFromPg = await clientPocketBase.collection('crm_contact').getList(1, 20, {
        //         expand: 'assign_to',
        //         filter: `type ~ 'rooms' && lastMessage != '' && (${queryInstance}) && broadcast_group = false`,
        //         sort: `-pinned,${this.sortSetting === 'newest' ? '-' : '+'}last_message`,
        //         $autoCancel: false,
        //       })
        //     }
        //     // //console.log(roomsFromPg, '<<<ok vira')
        //   } catch (err) {
        //     console.log(err.isAbort)
        //   }

        //   // await db.rooms
        //   //   .where('last_message')
        //   //   .above(0)
        //   //   .offset(this.currentOffset)
        //   //   .limit(10)
        //   //   .reverse()
        //   //   .toArray()
        // } else {
        //   try {
        //     if (this.getSearch) {
        //       this.roomsChats = this.roomsChatsOrigin.filter(ele => ele.unread_count > 0)
        //       this.roomsMessages = this.roomsMessagesOrigin.filter(ele => ele.unread_count > 0)
        //     } else {
        //       roomsFromPg = await clientPocketBase.collection('crm_contact').getList(1, 20, {
        //         expand: 'assign_to',
        //         filter: `type ~ 'rooms' && unread_count > '0' && lastMessage != '' && (${queryInstance}) && broadcast_group = false`,
        //         sort: `-pinned,${this.sortSetting === 'newest' ? '-' : '+'}last_message`,
        //         $autoCancel: false,
        //       })
        //     }
        //   } catch (err) {
        //     roomsFromPg = await clientPocketBase.collection('crm_contact').getList(1, 20, {
        //       expand: 'assign_to',
        //       filter: `type ~ 'rooms' && unread_count > '0' && lastMessage != '' && (${queryInstance}) && broadcast_group = false`,
        //       sort: `-pinned,${this.sortSetting === 'newest' ? '-' : '+'}last_message`,
        //       $autoCancel: false,
        //     })
        //   }

        //   // roomsFromPg = await db.rooms.where('unread_count').above(0).offset(this.currentOffset).limit(10).toArray()
        // }
        // console.log(roomsFromPg, 'cokss<')
        // //console.log('🚀 ~ file: FloatingChat.vue ~ line 2719 ~ setTimeout ~ roomsFromPg', roomsFromPg)

        // //console.log('🚀 ~ file: FloatingChat.vue ~ line 437 ~ setTimeout ~ roomsFromPg', roomsFromPg)
        if (roomsFromPg?.items?.length) {
          // this.$store.commit('inbox/setCurrentOffset', 1)
          this.currentOffset = 1
          await this.mappingRooms(roomsFromPg?.items)
          this.loadingRooms = false
        } else {
          this.roomsLoaded = true
          this.loadingRooms = false
        }
      })
    },
    fetchMoreRooms() {
      if (this.loadingRooms) return
      setTimeout(async () => {
        // this.loadingRooms = true
        this.roomsLoaded = false

        // this.currentOffset += 1
        // let currentOffset = 2

        // if (this.currentOffset > 1) {
        //   currentOffset = this.currentOffset
        // }
        // if (currentOffset === 2) {
        //   this.currentOffset = 2
        // }
        const { currentOffset } = this
        const { labels, sort, channels, roomAssignTo } = JSON.parse(JSON.stringify(this.filter))

        this.selectedOptionsAssign = roomAssignTo
        this.sortSetting = sort
        this.filterArr = []
        this.roomsLoaded = false

        // this.currentUserId = ''
        // this.roomId = ''
        if (!channels || (channels._id && channels._id === 'All')) {
          this.selectedChannel = 'All'
        } else {
          this.selectedChannel = channels
        }

        if (!labels.length || labels[0].name === 'All Labels') {
          this.selectedTag = 'All'
        } else {
          this.selectedTag = ''
        }

        let assignment = 'All'
        if (roomAssignTo === 'toMe') {
          assignment = this.user.id
        } else if (roomAssignTo === 'notAnyone') {
          assignment = 'empty'
        }
        const payload = {
          filterRoom: this.filterRoom,
          token: this.user.token,
          labels: JSON.stringify(labels.map(ele => ele.id)),
          sort,
          channels,
          assignment,
          instances: this.instances,
          sub_id: this.user.sub_id,
          currentOffset,
          id_user: this.user.id,
        }

        const roomsFromPg = {
          items: [],
          totalPages: 0,
        }

        const test = await this.$store.dispatch('rooms/fetchChats', payload)
        if (test.status && test.data && test.data.chats) {
          roomsFromPg.items = test.data.chats
          roomsFromPg.totalPages = test.data.totalPages
          this.roomNum = test.data.totalPages
        }

        // let roomsFromPg
        // let queryInstance = ''
        // for (let i = 0; i < this.instances.length; i++) {
        //   const instance = this.instances[i]
        //   if (instance.status === 1 && !instance.broadcast_group) {
        //     if (i === 0) {
        //       if (instance.type === 'chatwoot') {
        //         queryInstance += `instance = '${instance.id}' && inbox_id = '${instance.inbox_id}'`
        //       } else {
        //         queryInstance += `instance = '${instance.id}'`
        //       }
        //     } else {
        //       if (!queryInstance) {
        //         queryInstance += `instance = '${instance.id}'`
        //       } else {
        //         queryInstance += `|| instance = '${instance.id}'`
        //       }
        //     }
        //   }
        // }

        // if (this.filterRoom === 'all') {
        //   if (this.hashedInstance) {
        //     roomsFromPg = await clientPocketBase.collection('crm_contact').getList(currentOffset, 20, {
        //       expand: 'assign_to',
        //       filter: `type = 'rooms' && last_message != '' && instance = '${this.hashedInstance}' && broadcast_group = false`,
        //       sort: `-pinned,${this.sortSetting === 'newest' ? '-' : '+'}last_message`,
        //       $autoCancel: false,
        //     })
        //   } else {
        //     try {
        //       roomsFromPg = await clientPocketBase.collection('crm_contact').getList(currentOffset, 20, {
        //         expand: 'assign_to',
        //         filter: `type = 'rooms' && last_message != '' && (${queryInstance}) && broadcast_group = false`,
        //         sort: `-pinned,${this.sortSetting === 'newest' ? '-' : '+'}last_message`,
        //         $autoCancel: false,
        //       })
        //     } catch (err) {
        //       console.log(err)
        //     }
        //   }
        // } else {
        //   if (this.hashedInstance) {
        //     roomsFromPg = await clientPocketBase.collection('crm_contact').getList(currentOffset, 20, {
        //       expand: 'assign_to',
        //       filter: `type = 'rooms' && last_message != '' && unread_count > '0' && instance = '${this.hashedInstance}' && broadcast_group = false`,
        //       sort: `-pinned,${this.sortSetting === 'newest' ? '-' : '+'}last_message`,
        //       $autoCancel: false,
        //     })
        //   } else {
        //     roomsFromPg = await clientPocketBase.collection('crm_contact').getList(currentOffset, 20, {
        //       expand: 'assign_to',
        //       filter: `type = 'rooms' && unread_count > '0' && last_message != '' && (${queryInstance}) && broadcast_group = false`,
        //       sort: `-pinned,${this.sortSetting === 'newest' ? '-' : '+'}last_message`,
        //       $autoCancel: false,
        //     })
        //   }
        // }
        // console.log(
        //   '🚀 ~ file: Chat.vue ~ line 2667 ~ setTimeout ~ this.currentOffset === roomsFromPg.totalPages',
        //   roomsFromPg,
        //   this.currentOffset,
        //   roomsFromPg?.totalPages,
        //   this.currentOffset === roomsFromPg?.totalPages,
        //   currentOffset,
        // )

        if (currentOffset * 20 === roomsFromPg?.totalPages || currentOffset * 20 > roomsFromPg?.totalPages) {
          // //console.log(
          //   '🚀 ~ file: FloatingChat.vue ~ line 2860 ~ setTimeout ~ roomsFromPg',
          //   this.currentOffset,
          //   roomsFromPg,
          // )
          // this.loadingRooms = false
          await this.mappingRooms(roomsFromPg?.items)
          this.roomsLoaded = true
        } else {
          await this.mappingRooms(roomsFromPg?.items)
          this.currentOffset += 1

          // this.currentOffsetSearch += 1
          // let { currentOffset } = this
          // currentOffset += 1
          // //console.log(currentOffset, 'ini offset')
          // this.$store.commit('inbox/setCurrentOffset', currentOffset)
        }
      })
    },
    fetchMoreSearch() {
      setTimeout(async () => {
        this.currentOffsetSearch += 10
        this.loadingSearch = true
        const payload = {
          token: this.user.token,
          subId: this.user.sub_id,
          searchBy: this.getSearch,
          offset: this.currentOffsetSearch,
          limit: 10,
          instances: this.instances.filter(ele => !ele.broadcast_group),
          user: this.user,
        }

        const roomsFromPg = await this.$store.dispatch('rooms/searchRooms', payload)
        const { roomsName, contactData, roomMessages } = roomsFromPg?.data

        // console.log(roomsFromPg?.data, '<<data room chat')
        const noRoom = false

        // if (!roomsName?.length && !contactData?.length && !roomMessages?.length) {
        //   noRoom = true
        //   this.noRoomSearch = true
        // }
        if (roomsFromPg?.status && !noRoom) {
          await this.mappingRoomSearch(roomsFromPg?.data, true)
        }

        this.loadingSearch = false
        if (roomMessages.length < 10) this.roomsLoaded = true
      })
    },
    fetchMessages({
      room,
      options = {
        reset: false,
        matchedSource: '',
        noScroll: false,
        fromRoomsMessages: false,
      },
    }) {
      // const tildeIndex = room.roomId.indexOf('~')

      // if (tildeIndex !== -1) {
      //   room.roomId = room.roomId.substring(0, tildeIndex)
      // }
      // console.log(tildeIndex, room, 'woiii<')

      if (room.source !== 'chatwoot') {
        if (room.phone_number_show === 'Group') {
          this.messageActions = [
            {
              name: 'replyMessage',
              title: this.$t('reply'),
            },

            // {
            //   name: 'labelMessage',
            //   title: `Label ${this.$t('message')}`,
            // },

            // {
            //   name: 'noteMessage',
            //   title: `${this.$t('inbox.noteMessage')}`,
            // },
            {
              name: 'unsendMessage',
              title: `${this.$t('inbox.unsendMessage')}`,
            },
            {
              name: 'broadcastMessage',
              title: `Broadcast ${this.$t('message')}`,
            },
            {
              name: 'forwardMessage',
              title: `Forward ${this.$t('message')}`,
            },
          ]
        } else {
          this.messageActions = [
            {
              name: 'replyMessage',
              title: this.$t('reply'),
            },
            {
              name: 'labelMessage',
              title: `Label ${this.$t('message')}`,
            },

            // {
            //   name: 'noteMessage',
            //   title: `${this.$t('inbox.noteMessage')}`,
            // },
            {
              name: 'unsendMessage',
              title: `${this.$t('inbox.unsendMessage')}`,
            },
            {
              name: 'broadcastMessage',
              title: `Broadcast ${this.$t('message')}`,
            },
            {
              name: 'forwardMessage',
              title: `Forward ${this.$t('message')}`,
            },
          ]
        }
      } else {
        this.messageActions = [
          {
            name: 'replyMessage',
            title: this.$t('reply'),
          },
        ]
      }
      if (this.rooms.findIndex(ele => ele?.roomId === room?.roomId) === -1 && !options.fromRoomsMessages) {
        this.rooms = [...this.rooms, room]
      }

      this.currentUserId = String(room.roomOwnerId) || String(room.instance.phone_number)
      const roomId = options.fromRoomsMessages ? `${room.roomId}~${room.lastMessage.source_id}` : room.roomId
      if (this.roomId !== roomId) {
        this.roomId = roomId
      }

      // this.roomId = room.roomId
      this.currentRoom = room
      if (options.reset) {
        this.messages = []
        this.messagesLoaded = false
        this.lastCouchTimestamp = 0

        this.$store.commit('inbox/setLoadingDownloadFile', false)

        // clientPocketBase.collection('crm_messages').unsubscribe()
      }
      let roomsMessageIndex
      if (this.roomsMessages?.length) {
        roomsMessageIndex = this.roomsMessages.findIndex(roomMessage => roomMessage.roomId === room.roomId)
      }
      setTimeout(async () => {
        const fetchMessages = {
          data: [],
        }
        const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}messages`
        if (options.reset) {
          const query = ''

          try {
            const index = this.rooms.findIndex(ele => ele?.roomId === room?.roomId)
            if (index !== -1 && this.rooms[index].unreadCount) {
              this.rooms[index].unreadCount = 0
              const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/read-messages`
              const { data: test } = await axios.post(endpoint, {
                sub_id: this.user.sub_id,
                id: this.rooms[index].id,
              })
            }
          } catch (err) {
            console.log(err, 'cuk')
          }
          const body = {
            subId: this.user.sub_id,
            dbRoomId: room.roomId,
          }
          if (this.getSearch) body.couch_timestamp = room.lastMessage.seconds * 1000

          // if (this.getSearch) {
          //   // query = `couch_timestamp <= '${room.lastMessage.seconds * 1000}' and`
          //   // query = [`[couch_timestamp <= '${room.lastMessage.seconds * 1000}'], dbRoomId = '${room.id}'`]
          //   query = `couch_timestamp <= '${room.lastMessage.seconds * 1000}' AND dbRoomId = '${room.id}'`
          // } else {
          //   query = `dbRoomId = '${room.id}'`
          // }
          try {
            const { data: pgMessages } = await this.$axios.post(endpoint, body)
            if (pgMessages.status) {
              fetchMessages.data = pgMessages.data
            }
          } catch (err) {
            console.log(err.data, err, 'err fetch meili<')
          }
        } else {
          const query = ''
          const body = {
            subId: this.user.sub_id,
            dbRoomId: room.roomId,
            couch_timestamp: this.lastCouchTimestamp,
          }

          // if (this.getSearch) {
          //   query = `couch_timestamp <= '${this.lastCouchTimestamp}' AND dbRoomId = '${room.id}'`
          // } else {
          //   query = `couch_timestamp <= '${this.lastCouchTimestamp}' AND dbRoomId = '${room.id}'`
          // }

          try {
            const { data: pgMessages } = await this.$axios.post(endpoint, body)
            if (pgMessages.status) {
              fetchMessages.data = pgMessages.data
            }
          } catch (err) {
            console.log(err.data, err)
          }
        }

        if (fetchMessages && fetchMessages.data?.length > 0) {
          this.lastCouchTimestamp = +fetchMessages.data[fetchMessages.data.length - 1].couch_timestamp

          if (
            !options.reset &&
            this.messages.length > 0 &&
            fetchMessages.data[fetchMessages.data.length - 1].source_id === this.messages[0].source_id
          ) {
            this.messagesLoaded = true
          }
        } else {
          this.messagesLoaded = true
        }

        // const lastMessage = this.messages[0]._id
        const chats = []
        let curMessages = fetchMessages.data

        // console.log("DT",fetchMessages.data)
        let msgPb = []
        let queryPb = ''
        if (this.messageObj.hasOwnProperty(room.id)) {
          const sourceIds = this.messageObj[room.id]
          if (sourceIds.length) {
            for (let i = 0; i < sourceIds.length; i++) {
              const sourceId = sourceIds[i]
              try {
                if (!queryPb) {
                  queryPb = `source_id = '${sourceId}'`
                } else {
                  // queryPb += ` OR source_id = '${sourceId}'`
                  queryPb += ` || source_id = '${sourceId}'`
                }
              } catch (err) {}

              // console.log(msg, 'ini cuk<<')
            }
            try {
              // const msg = await this.$axiosLimit.post(`${process.env.VUE_APP_POCKETBASE_URL}/q`, {
              //   q: `SELECT * FROM crm_messages WHERE dbRoomId = '${room.id}' AND (${queryPb}) ORDER BY created DESC ;`,
              // })
              const msg = await clientPocketBase.collection('crm_messages').getFullList(200, {
                filter: `dbRoomId = '${room.id}' && (${queryPb}) `,
                $autoCancel: false,
              })

              msgPb = JSON.parse(JSON.stringify(msg))

              // if (msg?.data) {
              //   // msgPb = msg.data
              // }
            } catch (err) {
              console.log(err, 'ini err fetch mess pb')
            }
          }
        }
        if (msgPb.length) {
          curMessages = [...curMessages, ...msgPb]
        }

        // curMessages = curMessages.filter(
        //   msg =>
        //     msg.content ||
        //     (msg.files && msg.files.length > 0) ||
        //     (msg.data && msg.data.hasOwnProperty('type') && msg.data.type === 'buttons'),
        // )
        const filteredChats = _orderBy(curMessages, ['couch_timestamp'], ['asc'])

        filteredChats.forEach(message => {
          if (room.msg_id) {
            message.source_id = message.reply_id ? message.reply_id.toString() : message.source_id.toString()
          }

          // if (this.messages.length > 0 && this.messages[0].roomId === message.roomId) {
          message.content = message.content ? message.content.toString() : {}
          const formattedMessage = this.formatMessage(room, message, true)
          if (this.currentRoom?.id === message.dbRoomId) {
            if (!room.msg_id) {
              const messageIndex = this.messages.findIndex(m => m.source_id === message.source_id)
              if (messageIndex === -1) {
                // console.log("MSG:ADDED:3:"+this.messages.length)
                this.messages = this.messages.concat([formattedMessage])
              } else {
                this.messages[messageIndex] = formattedMessage
              }
            } else {
              this.messages = JSON.parse(JSON.stringify([formattedMessage]))
            }
          }

          // console.log("MSG:ADDED:"+this.messages.length)
          // console.trace();
          if (!this.stealthMode) {
            chats.push(message.original_message.key)
          }

          // }
        })
        this.messages = _orderBy(this.messages, ['couch_timestamp'], ['asc'])
        this.messages = _uniqBy(this.messages, '_id')

        /// Rekursif utk hashtag
        if (
          options.matchedSource &&
          this.messages.findIndex(message => message.source_id === options.matchedSource) === -1
        ) {
          this.fetchMessages({
            room,
            options: { matchedSource: options.matchedSource, noScroll: true },
          })
        }
        let chosenSource = ''
        if (
          Object.keys(this.labelMessageObj).find(ele => {
            chosenSource = ele

            return ele.includes(room.id)
          })
        ) {
          const indexMsg = this.messages.findIndex(ele => ele.source_id === chosenSource.split('-')[1])
          this.messages[indexMsg].labels = this.labelMessageObj[chosenSource]
          this.messages[indexMsg].labels = this.messages[indexMsg].labels.filter(
            (item, index, that) => index === that.findIndex(t => t.id === item.id),
          )
        }
        const findMsgHashtag = this.messages.findIndex(message => message.source_id === options.matchedSource)
        if (findMsgHashtag > -1) {
          this.indexMessageHashtag = findMsgHashtag
        }

        // this.indexMessageHashtag = -1

        const findMsgIndex = this.messages.findIndex(
          msg => msg.content === this.roomsMessages[roomsMessageIndex]?.lastMessage.content,
        )

        if (findMsgIndex > -1) {
          this.indexMessageSearch = findMsgIndex
        }

        if (room.source === 'chatwoot' && room.unread_count > 0) {
          // await clientPocketBase.collection('crm_contact').update( room.id, {
          //   unread_count: 0,
          // })
        } else if (chats.length !== 0 && !this.stealthMode && room.unread_count > 0) {
          this.setReadMessages(chats, room, room.instance.phone_number)
        }

        if (options.matchedSource && this.indexMessageHashtag > -1) {
          setTimeout(() => {
            const element = document.getElementById('hashtag-message-container')

            // console.log(element, 'helo<')

            if (element) {
              element.scrollIntoView({
                behavior: 'smooth',
              })
            }
          }, 1500)
          if (this.$route.query.roomId) {
            this.$router.replace('/inbox')
          }
        } else if (options.reset && !options.noScroll && !this.$route.query.source_id) {
          if (this.getSearch) {
            setTimeout(() => {
              const element = document.getElementById('searched-message-container')
              if (element) {
                element.scrollIntoView({
                  behavior: 'smooth',
                })
              }
            }, 500)
          } else {
            setTimeout(() => {
              const element = document.getElementById('last-message-container')
              if (element) {
                element.scrollIntoView({
                  behavior: 'smooth',
                })
              }
            }, 500)
            if (this.$route.query.roomId) {
              this.$router.replace('/inbox')
            }
          }
        }

        // else if (options.matchedSource && this.indexMessageHashtag > 0) {
        //   setTimeout(() => {
        //     const element = document.getElementById('hashtag-message-container')

        //     if (element) {
        //       element.scrollIntoView({
        //         behavior: 'smooth',
        //       })
        //     }
        //   }, 500)
        //   this.$router.replace('/inbox')
        // }
      })
    },
    async sendMessage({ content, roomId, room, files, replyMessage }) {
      // console.log(content, roomId, room, files, replyMessage, '<<viraa req')
      this.loadSendMessage = true
      let instance_id
      let recipient_id
      if (roomId.split('-').length === 2) {
        instance_id = roomId.split('-')[0] || ''
        recipient_id = roomId.split('-')[1] || ''
      } else {
        instance_id = roomId.split('-')[0] || ''
        recipient_id = roomId.split('-').slice(1).join('-')
      }

      console.log('sayang log files', files)

      // const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      //   key: 'instances',
      // })
      const listInstances = await clientPocketBase.collection('crm_instances').getFullList(20)

      const instance = listInstances.find(el => el._id === instance_id)

      //! old instances
      // if (instance.phone_number_id) {
      //   const url = ''
      //   const { sub_id } = instance
      //   const payload = {
      //     content,
      //     sub_id,
      //     instance_id,
      //     room_id: room,
      //     recipient_number: recipient_id,
      //   }
      //   const res = await this.$store.dispatch('auth/sendWaCloudChat', payload)
      //   this.loadSendMessage = false

      //   // //console.log('response :', response)
      // } else if (instance.username) {
      //   const chatPayload = {
      //     sub_id: this.user.sub_id,
      //     instance_id,
      //     sub_id: this.user.sub_id,
      //     room_id: this.selectedRoom,
      //     content: {
      //       text: content,
      //     },
      //     recipient_id,
      //   }

      //   const res = await this.$store.dispatch('auth/sendTwitterChat', chatPayload)
      //   //console.log('res :', res)

      //   // //console.log('chat payload :', chatPayload)
      //   // //console.log('res :', res)
      //   this.loadSendMessage = false
      // } else if (instance.id_page) {
      //   const messageBody = {
      //     to: recipient_id,
      //     platform: 'facebook',
      //     message: content,
      //     pageAccessToken: instance.pagesAccessToken,
      //   }

      //   const messageParams = {
      //     subId: this.user.sub_id,
      //     instanceId: instance_id,
      //   }

      //   const res = await this.$store.dispatch('auth/sendFacebookChat', {
      //     body: messageBody,
      //     params: messageParams,
      //   })
      //   if (res.status) {
      //     this.loadSendMessage = false
      //   } else {
      //     this.loadSendMessage = false
      //   }
      // } else if (room && room.msg_id) {
      //   const chatPayload = {
      //     sub_id: this.user.sub_id,
      //     clientSecret: room.clientSecret,
      //     clientId: room.clientId,
      //     fs_id: room.fs_id,
      //     shop_id: room.shop_id.toString(),
      //     msg_id: room.msg_id.toString(),
      //     instance_id: room.instance_id,
      //     message: content,
      //   }

      //   const res = await this.$store.dispatch('auth/sendTokopediaChat', chatPayload)

      //   // //console.log(res, `ini res nya`)
      //   if (res.status) {
      //     this.loadSendMessage = false
      //   } else {
      //     this.loadSendMessage = false
      //   }
      // } else {
      const formData = new FormData()
      let fileNames = []
      formData.append('operator', this.user.email)
      if (files && files.length > 0) {
        const indexMyAsset = files.findIndex(el => el.fromFileManager)
        if (indexMyAsset > -1) {
          formData.append('attachmentsFileManager', JSON.stringify(files))

          const names = files.map(el => `${el.name}.${el.extension}`)
          fileNames = [...names]
        } else {
          for (let index = 0; index < files.length; index++) {
            const file = files[index]

            // console.log('sayang blob', file.blob)
            // formData.append('attachmentUrls', file.url)
            formData.append('attachments', file.blob)

            // console.log('sayang file', file)
            fileNames.push(`${file.name}.${file.extension}`)
          }
        }

        formData.append('nameFile', JSON.stringify(fileNames))
      }

      // const endpoint = `${process.env.VUE_APP_API_GENERAL}messages/send-new`
      let endpoint
      if (instance.type === 'chatwoot') {
        endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chatwoot/messages`
        const reply = replyMessage?.original_message
        formData.append('conversation_id', recipient_id)
        formData.append('message', content)
        formData.append('account_id', room.instance.account_id)
        formData.append('instance', instance_id)
        formData.append('subId', this.user.sub_id)
        formData.append('access_token', room.instance.access_token)
        if (reply) {
          formData.append('reply', JSON.stringify(reply))
        }
      } else {
        endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/send?id=${this.user.sub_id}-${instance_id}`
        const reply = replyMessage?.original_message

        formData.append('receiver', recipient_id)
        formData.append('message', content)
        if (reply) {
          formData.append('reply', JSON.stringify(reply))
        }
      }

      try {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }

        // console.log(formData, recipient_id, roomId, files, 'ini formz')
        const { data } = await this.$axios.post(endpoint, formData, config)

        // console.log(data, 'ini formz')

        if (data && !data.success && data.message === 'The receiver number is not exists.') {
          throw 'number not exists'
        }
        if (data.success) {
          // if (!this.stealthMode) {
          //   // const room = this.rooms.find(x => x.roomId == this.selectedRoom)
          //   const filteredMessages = this.messages
          //     .filter(x => !x.fromMe)
          //     .map(z => {
          //       return z.original_message.key
          //     })
          //   config.log(filteredMessages, 'inihasil kali<<<')
          //   if (filteredMessages.length > 0) {
          //     // this.setReadMessages(filteredMessages, room, this.user.sub_id)
          //   }
          // }
          // this.fetchMessages({ room: this.room, options: { reset: true } })
        }
      } catch (err) {
        console.log('🚀 ~ file: Chat.vue ~ line 3338 ~ sendMessage ~ err', err)
        this.errorChatNotOnWhatsapp = true
        if (err === 'number not exists') {
          // this.errorChatNotOnWhatsapp = true
          this.errorChatMessage = `Failed to send message, Invalid whatsapp number`
        } else {
          this.errorChatMessage = `Failed to send message, Please try again`
        }
      } finally {
        if (instance.type !== 'chatwoot') {
          this.loadSendMessage = false
        }
      }

      // }
    },
    setReadMessages(sourceIds, room, roomOwnerId) {
      // //console.log(sourceIds)
      // const endpoint = `${process.env.VUE_APP_API_GENERAL}messages/send-read`
      const subidInstance = `${this.user.sub_id}-${room.roomId.substring(0, room.roomId.indexOf('-'))}`
      const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/send-read?id=${subidInstance}`
      const messageKey = JSON.parse(JSON.stringify(sourceIds))

      // messageKey.forEach(key => delete key.fromMe)
      const body = {
        subId: this.user.sub_id,
        roomId: room.roomId,
        roomOwnerId,

        // messageId: sourceIds,
        messageKey,
        server: 'wabailey',
      }

      // if (senderId) {
      //   body.senderId = senderId
      // }

      this.$axios
        .post(endpoint, body)
        .then(response => {
          // db.showedRooms
          //   .update(room.id, { unread_count: 0, unreadCount: 0 })
          //   .then(res => {})
          //   .catch(err => {})
          // //console.log(body)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getSettingPg() {
      const labelsPg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'label',
      })

      if (labelsPg.status) {
        this.labelsOrigin = labelsPg.data.value
        this.labels = labelsPg.data.value
      }

      const labelsValuePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'label_value',
      })

      if (labelsValuePg.status) {
        this.label = labelsValuePg.data
      }

      const messageTemplatePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'message_template',
      })
      if (messageTemplatePg.status) {
        const returnData = messageTemplatePg.data
        const filteredData = messageTemplatePg.data.value.filter(el => {
          if (this.user.role !== 'Admin') {
            if (el.operator && el.operator === this.user.email) return true

            if (!el.operator) return true

            return false
          }

          return true
        })
        returnData.value = JSON.parse(JSON.stringify(filteredData))
        this.messageTemplates = returnData
      }

      // const assignOnReply = await this.$store.dispatch('global/getSettingsByKey', {
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      //   key: 'auto_assign',
      // })
      // if (assignOnReply.status && assignOnReply.data) {
      //   this.assignOnReply = assignOnReply.data.value
      // }

      // const readAcknowledge = await this.$store.dispatch('global/getSettingsByKey', {
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      //   key: 'bold_unreplied',
      // })
      // if (readAcknowledge.status) {
      //   this.readAcknowledge = readAcknowledge.data.value
      // }

      // const selectedNotificationSetting = await this.$store.dispatch('global/getSettingsByKey', {
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      //   key: 'notif_all_or_assigned',
      // })

      // if (selectedNotificationSetting.status && selectedNotificationSetting.data) {
      //   this.selectedNotificationSetting = selectedNotificationSetting.data.value
      // }

      const savedRepliesPg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'saved_replies',
      })
      if (savedRepliesPg.status) {
        this.savedReplies = savedRepliesPg.data
      }

      // check saved replies from message templates
      if (this.messageTemplates.value) {
        this.messageTemplates.value.forEach(messageTemplate => {
          if (messageTemplate.quick_reply) {
            if (this.user.role !== 'Admin') {
              if (messageTemplate.operator && messageTemplate.operator === this.user.email)
                this.savedReplies.value.push({
                  id: messageTemplate.id,
                  name: messageTemplate.message,
                  message_templates: messageTemplate,
                })

              if (!messageTemplate.operator)
                this.savedReplies.value.push({
                  id: messageTemplate.id,
                  name: messageTemplate.message,
                  message_templates: messageTemplate,
                })
            } else if (this.user.role === 'Admin') {
              this.savedReplies.value.push({
                id: messageTemplate.id,
                name: messageTemplate.message,
                message_templates: messageTemplate,
              })
            }

            // if (messageTemplate.hasOwnProperty('operator')) {
            //   // //console.log(messageTemplate, '0sjajkajks')
            //   if (messageTemplate.operator === this.user.email) {
            //     this.savedReplies.value.push({
            //       id: messageTemplate.id,
            //       name: messageTemplate.message,
            //       message_templates: messageTemplate,
            //     })
            //   }
            // } else if (!messageTemplate.hasOwnProperty('operator')) {
            //   this.savedReplies.value.push({
            //     id: messageTemplate.id,
            //     name: messageTemplate.message,
            //     message_templates: messageTemplate,
            //   })
            // }
          } else {
            const index = this.savedReplies.value.findIndex(item => item.id == messageTemplate.id)

            if (index !== -1) {
              this.savedReplies.value.splice(index, 1)
            }
          }
        })

        // remove duplicate by quick reply id
        this.savedReplies.value = this.savedReplies.value.filter((item, index, that) => {
          if (
            index === that.findIndex(t => t !== undefined && item !== undefined && t.id === item.id) &&
            !item.hasOwnProperty('operator')
          ) {
            return true
          }
          if (
            index === that.findIndex(t => t !== undefined && item !== undefined && t.id === item.id) &&
            item.hasOwnProperty('operator') &&
            item.operator === this.user.email
          ) {
            return true
          }
        })
      }

      const contactGroups = await this.$store.dispatch('global/getContactLists', {
        sub_id: this.user.sub_id,
        token: this.user.token,
      })

      if (contactGroups.status) {
        this.contactGroups = contactGroups.data.filter(list => {
          if (list.list_id !== 'default') {
            list.id = list.list_id
            list.label = list.list_name

            return true
          }

          return false
        })

        // //console.log(this.contactsPouch, this.contactList, `INI KONTAK`)
      }

      return {
        label: labelsValuePg.data,
        labels: labelsPg.data.value,
      }
    },
    async getTagsPb() {
      const allTag = await clientPocketBase.collection('crm_tags').getFullList(200, { $autoCancel: false })
      if (allTag.length) {
        this.allTag = allTag
      }
    },
    async getContactTags(source_id) {
      const getContact = await clientPocketBase.collection('crm_contact_tags').getFullList(200, {
        filter: `sourceId = '${source_id}'`,
        expand: 'contacts, tag, msgLabels',
        $autoCancel: false,
      })
      if (getContact?.length) {
        this.label.value = getContact
      }
    },
    setMessageLabels(message) {
      if (this.label && this.label.value && this.label.value.length) {
        if (message.source_id === this.label.value[0].sourceId) {
          message.labels = this.label.value[0].expand.msgLabels
        }
      }

      return message
    },
    parseTimestamp2(timestamp, format) {
      const { seconds, _seconds } = timestamp
      if (seconds) {
        return this.$moment.unix(seconds).format(format)
      }

      if (_seconds) {
        return this.$moment.unix(_seconds).format(format)
      }

      return timestamp
    },
    formatMessage(room, message, newStructure = false) {
      let dataMessage = null

      // //console.log('🚀 ~ file: FloatingChat.vue ~ line 2572 ~ formatMessage ~ message', message)

      // console.log(message, `INI MESSAGENYA`)

      if (newStructure) {
        dataMessage = message
      } else if (Number(message.msg_id)) {
        dataMessage = message
      } else {
        dataMessage = message.data()
      }

      if (
        !dataMessage.content &&
        dataMessage.files &&
        dataMessage.files.length === 0 &&
        dataMessage.data.hasOwnProperty('type') &&
        dataMessage.data.type !== 'buttons'
      ) {
      } else {
      }

      const { timestamp } = dataMessage

      // set labels
      // console.log(this.label.value, dataMessage, `INI MSG`)
      // dataMessage = this.setMessageLabels(dataMessage)

      // default
      dataMessage.selected = false
      dataMessage.isForwarded = !!message.original_message.message?.extendedTextMessage?.contextInfo?.isForwarded

      if (room && room.phone_number_show == 'Group') {
        const seenLength = dataMessage.seen_by ? dataMessage.seen_by.length || 0 : 0

        // //console.log(room.users.length - 1, '=== messageroom', seenLength, dataMessage.seen_by)

        // formattedMessage.seen = (room.users.length - 1) == seenLength
        dataMessage.seen = room.users.length - 1 == seenLength
      }

      let username = dataMessage.sender_name

      // dataMessage.content =
      //   dataMessage.data && dataMessage.data.caption ? dataMessage.data.caption : dataMessage.content

      if (/\d{10,11}/.test(username) || /\d{12,13}/.test(username) || (/\d{14,15}/.test(username) && !room.msg_id)) {
        username = this.formatPhoneNumber(username, false)
      }
      if (room && room.msg_id) {
        // //console.log(dataMessage, '-------------------------sssssssss')
        dataMessage.source_id = dataMessage.reply_id.toString()
        dataMessage.data = {}
        dataMessage.content = dataMessage.msg
        dataMessage.content_notification = dataMessage.msg
        if (dataMessage.is_opposite) {
          dataMessage.fromMe = false
        } else {
          dataMessage.fromMe = true
        }
        if (dataMessage.read_status === 1) {
          dataMessage.seen = false
        } else {
          dataMessage.seen = true
        }
      }

      // //console.log(dataMessage, `INI KONTENNYA`)

      const formattedMessage = {
        ...dataMessage,
        ...dataMessage.data,
        ...{
          senderId: dataMessage.sender_id ? dataMessage.sender_id.toString() : dataMessage.sender_id,
          _id: dataMessage.source_id,
          seconds: dataMessage.timestamp._seconds || dataMessage.reply_time,
          timestamp: timestamp
            ? this.parseTimestamp2(timestamp, 'HH:mm')
            : this.$moment.unix(dataMessage.reply_time / 1000).format('HH:mm'),
          date: timestamp
            ? this.parseTimestamp2(timestamp, 'DD MMMM YYYY')
            : this.$moment.unix(dataMessage.reply_time / 1000).format('DD MMMM YYYY'),
          username,

          // avatar: senderUser ? senderUser.avatar : null,
          distributed: true,
        },
      }

      return formattedMessage
    },
    formatPhoneNumber(phoneNumber, group = true) {
      phoneNumber = phoneNumber.toString()

      // console.log('🚀 ~ file: Chat.vue ~ line 3675 ~ formatPhoneNumber ~ phoneNumber, group = true', phoneNumber)
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      } else if (phoneNumber?.length > 16) {
        phoneNumber = 'Group'
      }

      return phoneNumber
    },
    getRoomName(room = null) {
      const indexContactName = this.contactListNames.findIndex(contact => contact.phone_number == room.phone_number)
      const indexContactData = this.allContactData.findIndex(contact => contact.phone_number == room.phone_number)

      if (indexContactName > -1) {
        if (
          this.contactListNames[indexContactName].name &&
          this.contactListNames[indexContactName].name.replace(/[+ -]/g, '') !== room.phone_number
        ) {
          return this.contactListNames[indexContactName].name
        }
      }

      if (indexContactData > -1) {
        if (
          this.allContactData[indexContactData].contact_list_name &&
          this.allContactData[indexContactData].contact_list_name.replace(/[+ -]/g, '') !== room.phone_number
        ) {
          return this.allContactData[indexContactData].contact_list_name
        }
        if (this.allContactData[indexContactData].bailey_name) {
          return this.allContactData[indexContactData].bailey_name
        }

        if (this.allContactData[indexContactData].notify_name) {
          if (this.allContactData[indexContactData].notify_name.replace(/[+ -]/g, '') !== room.phone_number) {
            return this.allContactData[indexContactData].notify_name
          }

          return room.phone_number.length > 16
            ? this.formatPhoneNumber(String(room.phone_number), true)
            : this.formatPhoneNumber(String(room.phone_number))
        }
      }

      if (room.name && String(room.name) !== String(room.phone_number)) {
        return String(room.name)
      }

      return String(room.phone_number).length > 16
        ? this.formatPhoneNumber(String(room.phone_number), true)
        : this.formatPhoneNumber(String(room.phone_number))
    },
    setLabels(roomId, room, arrTags = []) {
      this.loadingCheckRooms = true
      let labels = []
      let phone
      if (roomId.split('-').length === 2) {
        phone = roomId.split('-')[1] || ''
      } else {
        phone = roomId.split('-').slice(1).join('-') || ''
      }

      // let label = []
      try {
        if (arrTags.length) room.listTag = arrTags
        if (room.listTag?.length) {
          // console.log('LOG:TAG:' + JSON.stringify(room.listTag))
          for (let i = 0; i < room.listTag.length; i++) {
            const tag = room.listTag[i]
            const foundTag = this.allTag.find(ele => ele.id === tag)

            // labels = [...labels, foundTag]
            if (
              foundTag?.channels &&
              (foundTag.channels.channel.length === 0 ||
                foundTag.channels.channel.find(
                  element => element.id === room.instance || element.id === room.instance?.id,
                ))
            ) {
              labels = [...labels, foundTag]
            }
          }
        }
      } catch (error) {
        labels = []
      }
      this.loadingCheckRooms = false

      return labels
    },
    getContactListRoom(phoneNumber, showList = false, contactListNames = null) {
      let name = ``
      const listId = []
      if (this.contactListNames) {
        const foundContact = this.contactListNames.filter(x => x.phone_number === phoneNumber)
        if (foundContact.length > 0) {
          const foundContacts = foundContact.filter(x => x.list_name !== 'default')
          const defaultContacts = foundContact.filter(x => x.list_name === 'default')
          if (foundContacts.length !== 0) {
            foundContacts.forEach(contact => {
              name += `${contact.list_name}, `
              listId.push(contact.list_id)
            })

            if (!showList) {
              if (name !== '(') {
                name = name.substring(0, name.length - 2)
              }
              if (name.includes('(')) {
                name += ')'
              }

              if (name.endsWith('()')) {
                name = name.substring(0, name.length - 2)
              }

              return name
            }

            return listId
          }
        }
      }
      if (contactListNames) {
        const foundContact = contactListNames.filter(x => x.phone_number === phoneNumber)
        if (foundContact.length > 0) {
          const foundContacts = foundContact.filter(x => x.list_name !== 'default')
          const defaultContacts = foundContact.filter(x => x.list_name === 'default')
          if (foundContacts.length !== 0) {
            foundContacts.forEach(contact => {
              name += `${contact.list_name}, `
              listId.push(contact.list_id)
            })

            if (!showList) {
              if (name !== '(') {
                name = name.substring(0, name.length - 2)
              }
              if (name.includes('(')) {
                name += ')'
              }

              if (name.endsWith('()')) {
                name = name.substring(0, name.length - 2)
              }

              return name
            }

            return listId
          }
        }
      }
    },
    getContactListLabelRoom(phoneNumber, contactListNames = null) {
      if (this.contactListNames) {
        const listData = this.contactListNames.filter(
          x => x.phone_number === phoneNumber && x.use_color && x.list_name !== 'default',
        )

        return listData
      }

      if (contactListNames) {
        const listData = contactListNames.filter(
          x => x.phone_number === phoneNumber && x.use_color && x.list_name !== 'default',
        )

        return listData
      }

      return []
    },
    getUsername(user) {
      const indexContactName = this.contactListNames.findIndex(contact => contact.phone_number === user._id)
      const indexContactData = this.allContactData.findIndex(contact => contact.phone_number === user._id)

      // console.log(this.contactListNames, this.allContactData, 'wohoo<')

      if (indexContactName > -1) {
        if (
          this.contactListNames[indexContactName].name &&
          this.contactListNames[indexContactName].name.replace(/[+ -]/g, '') !== user._id
        ) {
          return this.contactListNames[indexContactName].name
        }
      }

      if (indexContactData > -1) {
        if (isNaN(this.allContactData[indexContactData].name)) {
          return this.allContactData[indexContactData].name
        }
        if (this.allContactData[indexContactData].contact_list_name) {
          return this.allContactData[indexContactData].contact_list_name
        }
        if (this.allContactData[indexContactData].bailey_name) {
          return this.allContactData[indexContactData].bailey_name
        }
        if (this.allContactData[indexContactData].notify_name) {
          if (this.allContactData[indexContactData].notify_name.replace(/[+ -]/g, '') !== user._id) {
            return this.allContactData[indexContactData].notify_name
          }

          return user._id.length > 16 ? this.formatPhoneNumber(user._id, true) : this.formatPhoneNumber(user._id)
        }
      }

      if (user.username && user.username !== user._id) {
        return user.username
      }

      return user._id.length > 16 ? this.formatPhoneNumber(user._id, true) : this.formatPhoneNumber(user._id)
    },
    async mappingRoom(room = {}, label = null, labels = null, contacts = null, fromRealtime = false) {
      // //console.log(
      //   '🚀 ~ file: FloatingChat.vue ~ line 3534 ~ mappingRoom ~ this.instances.find(instance => instance._id === room.instance_id)',
      //   this.instances.findIndex(instance => instance._id === room.instance_id),
      //   room.last_message,
      //   this.instances,
      // )
      if (
        this.instances.findIndex(instance => instance.id === room?.instance) > -1 ||
        this.instances.findIndex(instance => instance.id === room?.instance?.id) > -1
      ) {
        if (!room.users || room.users === 'unchanged_toast') {
          room.users = []
        }

        if (!room.typingUsers) {
          room.typingUsers = []
        }

        if (!room.roomStatus) {
          room.roomStatus = 'on_queue'
        }

        if (!room.archived) {
          room.archived = false
        }

        if (!room.assign_to) {
          room.assign_to = false
        }

        if (!room.last_interaction) {
          room.last_interaction = null
        }

        if (!room.notes) {
          room.notes = []
        }

        if (!room.pinned) {
          room.pinned = false
        }

        room.instance = this.instances.find(
          instance => instance.id === room.instance || instance.id === room.instance.id,
        )
        let vacIndex
        if (!fromRealtime) {
          if (room.lastMessage?.timestamp?._seconds) {
            // //console.log(room.lastMessage.timestamp._seconds, `KDKFKSDFKSKFSKDFKSDFK`)
            vacIndex = room.lastMessage.timestamp._seconds
            const format = this.$moment.unix(room.lastMessage.timestamp._seconds).isSame(this.$moment(), 'day')
              ? 'HH:mm'
              : 'DD/MM/YYYY'

            room.lastMessage.formattedTimestamp = this.$moment.unix(room.lastMessage.timestamp._seconds).format(format)
          }

          if (room.lastMessage?.msg) {
            vacIndex = room.lastMessage?.timestamp / 1000
            const format = this.$moment.unix(room.lastMessage?.timestamp / 1000).isSame(new Date(), 'day')
              ? 'HH:mm'
              : 'DD/MM/YYYY'

            room.lastMessage.formattedTimestamp = this.$moment.unix(room.lastMessage?.timestamp / 1000).format(format)
          }
          room.index = vacIndex
          room.lastMessage = this.formatLastMessage(room.lastMessage, room)
        } else {
          if (!room.index) {
            // //console.log(room.lastMessage, 'ini masalah2')
            room.index = room.lastMessage?.seconds || room.lastMessage?.timestamp?._seconds
          }

          if (room.lastMessage?.timestamp?._seconds) {
            room.lastMessage = this.formatLastMessage(room.lastMessage, room)
          }
        }

        if (room.roomId.substr(room.roomId.indexOf('-') + 1).length > 16) {
          // const response = await this.$store.dispatch('inboxNavbar/getUsers', {
          //   subId: this.user.sub_id,
          //   roomId: room.roomId,
          // })
          // if (response.status) {
          //   room.users = response.data
          room.users.forEach(user => {
            // //console.log('🚀 ~ file: Chat.vue ~ line 940 ~ mappingRooms ~ user', user)
            user.username = this.getUsername(user)
          })

          // }
          // //console.log('🚀 ~ file: Chat.vue ~ line 932 ~ mappingRooms ~ response', response)
        }
        room.labels =
          label || labels ? this.setLabels(room.roomId, room, label, labels) : this.setLabels(room.roomId, room)
        room.roomContactName = contacts
          ? this.getContactListRoom(room.phone_number, false, contacts)
          : this.getContactListRoom(room.phone_number)
        room.roomContactListLabel = contacts
          ? this.getContactListLabelRoom(room.phone_number, contacts)
          : this.getContactListLabelRoom(room.phone_number)
        room.roomContactList = contacts
          ? this.getContactListRoom(room.phone_number, true, contacts)
          : this.getContactListRoom(room.phone_number, true)
        room.avatar = room.profile_picture ? room.profile_picture : require('@/plugins/demo/assets/avatar.png')
        room.roomName = this.getRoomName(room)
        room.unreadCount = room.unread_count

        return room

        // const findRoom = await db.showedRooms.get({ roomId: room.roomId })
        // if (!findRoom) {
        //   await db.showedRooms.add(room)
        // } else {
        //   await db.showedRooms.update(room.id, room)
        // }
      }

      // //console.log('🚀 ~ file: FloatingChat.vue ~ line 3786 ~ mappingRoom ~ vacIndex', vacIndex)
      // //console.log('🚀 ~ file: FloatingChat.vue ~ line 3786 ~ mappingRoom ~ vacIndex', vacIndex)
    },
    async mappingRooms(rooms = [], label = null, labels = null, contacts = null) {
      // console.log('🚀 ~ file: FloatingChat.vue ~ line 3747 ~ mappingRooms ~ rooms', rooms)
      rooms.forEach(async room => {
        // //console.log(
        //   '🚀 ~ file: FloatingChat.vue ~ line 3681 ~ mappingRooms ~ this.instances.find(instance => instance._id === room.instance_id)',
        //   room,
        //   this.instances.find(instance => instance.id === room.instance),
        //   this.instances,
        //   room.instance_id,
        // )
        // if (this.instances.find(instance => this.$Md5Hash(instance._id) === room.instance)) {
        if (!room.users || room.users === 'unchanged_toast') {
          room.users = []
        }

        if (!room.typingUsers) {
          room.typingUsers = []
        }

        if (!room.roomStatus) {
          room.roomStatus = 'on_queue'
        }

        if (!room.archived) {
          room.archived = false
        }

        if (!room.assign_to) {
          room.assign_to = false
        }

        if (!room.last_interaction) {
          room.last_interaction = null
        }

        if (!room.notes) {
          room.notes = []
        }

        if (!room.pinned) {
          room.pinned = false
        }

        // room.instance = this.instances.find(instance =>instance.id=== room.id)
        room.instance = this.instances.find(instance => instance.id === room.instance)
        let vacIndex
        if (room.lastMessage?.timestamp?._seconds) {
          // //console.log(room.lastMessage.timestamp._seconds, `KDKFKSDFKSKFSKDFKSDFK`)
          vacIndex = room.lastMessage.timestamp._seconds
          const format = this.$moment.unix(room.lastMessage.timestamp._seconds).isSame(this.$moment(), 'day')
            ? 'HH:mm'
            : 'DD/MM/YYYY'

          room.lastMessage.formattedTimestamp = this.$moment.unix(room.lastMessage.timestamp._seconds).format(format)
        }

        if (room.lastMessage?.msg) {
          vacIndex = room.lastMessage?.timestamp / 1000
          const format = this.$moment.unix(room.lastMessage?.timestamp / 1000).isSame(new Date(), 'day')
            ? 'HH:mm'
            : 'DD/MM/YYYY'

          room.lastMessage.formattedTimestamp = this.$moment.unix(room.lastMessage?.timestamp / 1000).format(format)
        }

        if (room.roomId.substr(room.roomId.indexOf('-') + 1).length > 16) {
          // const response = await this.$store.dispatch('inboxNavbar/getUsers', {
          //   subId: this.user.sub_id,
          //   roomId: room.roomId,
          // })
          // if (response.status) {
          //   room.users = response.data
          room.users.forEach(user => {
            // //console.log('🚀 ~ file: Chat.vue ~ line 940 ~ mappingRooms ~ user', user)
            user.username = this.getUsername(user)
          })

          // }
          // //console.log('🚀 ~ file: Chat.vue ~ line 932 ~ mappingRooms ~ response', response)
        }
        room.labels =
          label || labels
            ? await this.setLabels(room.roomId, room, label, labels)
            : await this.setLabels(room.roomId, room)
        room.roomContactName = contacts
          ? this.getContactListRoom(room.phone_number, false, contacts)
          : this.getContactListRoom(room.phone_number)
        room.roomContactListLabel = contacts
          ? this.getContactListLabelRoom(room.phone_number, contacts)
          : this.getContactListLabelRoom(room.phone_number)
        room.roomContactList = contacts
          ? this.getContactListRoom(room.phone_number, true, contacts)
          : this.getContactListRoom(room.phone_number, true)
        if (!room.index) {
          room.index = vacIndex
        }
        if (this.formatLastMessage(room.lastMessage, room)) {
          room.lastMessage = this.formatLastMessage(room.lastMessage, room)
        }
        room.phone_number = String(room.phone_number).replaceAll("'", '')

        room.avatar = room.profile_picture ? room.profile_picture : require('@/plugins/demo/assets/avatar.png')
        room.name = String(room.name)
        room.roomName = this.getRoomName(room)
        room.unreadCount = room.unread_count
        const convertedRoomId = Buffer.from(room.roomId).toString('base64')
        const pocketBaseId = convertedRoomId.substring(convertedRoomId.length - 15, convertedRoomId.length)
        const roomIndex = this.rooms.findIndex(roomOnChat => roomOnChat?.roomId === room.roomId)

        // console.log(
        //   '🚀 ~ file: FloatingChat.vue ~ line 3846 ~ mappingRooms ~ roomIndex',
        //   this.rooms.findIndex(roomOnChat => roomOnChat?.roomId === room.roomId),
        //   room.roomId,
        //   this.rooms[roomIndex]?.roomId,
        //   this.rooms,
        // )
        if (roomIndex > -1) {
          if (this.user.chat_mode === 'Read and Write to assigned only') {
            if (!room.assign_to || (room && room.assign_to && room.expand?.assign_to?.email === this.user.email)) {
              // try {
              //   await clientPocketBase.collection('crm_contact').update( pocketBaseId, { ...room })
              // } catch (err) {
              //   console.log(err,'ini err upd')
              // }
              this.rooms[roomIndex] = room
            } else if (room && room.expand.assign_to.email !== this.user.email) {
              // try {
              //   await clientPocketBase.collection('crm_contact').delete( pocketBaseId)
              // } catch (err) {
              //   //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
              // }
              this.rooms = this.rooms.filter(ele => ele.roomId !== room.roomId)

              // this.rooms[roomIndex] = room
            }
          } else {
            this.rooms[roomIndex] = room
          }
        } else {
          if (this.user.chat_mode === 'Read and Write to assigned only') {
            if (!room.assign_to || (room && room.assign_to && room.expand?.assign_to?.email === this.user.email)) {
              // try {
              //   await clientPocketBase.collection('crm_contact').update( pocketBaseId, { ...room })
              // } catch (err) {
              //   console.log(err,'ini err upd')
              // }
              this.rooms = [...this.rooms, room]
            } else if (room && room.expand?.assign_to?.email !== this.user.email) {
              // try {
              // await clientPocketBase.collection('crm_contact').delete( pocketBaseId)
              // } catch (err) {
              // //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
              // }
              // this.rooms = [...this.rooms, room]
              this.rooms = this.rooms.filter(ele => ele.roomId !== room.roomId)
            }
          } else if (room) {
            this.rooms = [...this.rooms, room]
          }

          // }
          // const findRoom = await db.showedRooms.get({ roomId: room.roomId })
          // if (!findRoom) {
          //   if (this.user.chat_mode === 'Read and Write to assigned only') {
          //     if ((room && room.assign_to && room.assign_to.email === this.user.email) || !room.assign_to) {
          //       try {
          //         await clientPocketBase.collection('').update('contact', pocketBaseId, { ...room })
          //         // await db.showedRooms.add(room)
          //       } catch (err) {}
          //     } else if (room && room.assign_to.email !== this.user.email) {
          //       try {
          //         await db.showedRooms.where('roomId').equals(room.roomId).delete()
          //       } catch (err) {
          //         // //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
          //       }
          //     }
          //   } else {
          //     try {
          //       await db.showedRooms.add(room)
          //     } catch (err) {}
          //   }
          // } else if (findRoom) {
          //   if (this.user.chat_mode === 'Read and Write to assigned only') {
          //     if ((room && room.assign_to && room.assign_to.email === this.user.email) || !room.assign_to) {
          //       try {
          //         await db.showedRooms.update(room.id, room)
          //       } catch (err) {}
          //     } else if (room && room.assign_to.email !== this.user.email) {
          //       try {
          //         await db.showedRooms.where('roomId').equals(room.roomId).delete()
          //       } catch (err) {
          //         // //console.log('🚀 ~ file: Chat.vue ~ line 869 ~ deleteRoom ~ err', err)
          //       }
          //     }
          //   } else {
          //     try {
          //       await db.showedRooms.update(room.id, room)
          //     } catch (err) {}
          //   }
          // }
        }
      })

      // formattedRooms = _uniqBy(formattedRooms, 'phone_number')
      // //console.log('🚀 ~ file: Chat.vue ~ line 911 ~ formattedRooms ~ formattedRooms', formattedRooms)

      // formattedRooms = _orderBy(formattedRooms, ['pinned', 'index'], ['desc', 'desc'])

      // this.rooms = [...rooms]

      // try {
      //   await db.showedRooms.bulkAdd(formattedRooms)
      // } catch (err) {
      //   await db.showedRooms.bulkPut(formattedRooms)
      // }

      // //console.log('🚀 ~ file: Chat.vue ~ line 150 ~ mappingRooms ~ rooms', rooms)
    },
    formatLastMessage(message, room) {
      if (message) {
        if (!message.timestamp) return
        if (typeof message.timestamp !== 'object') return
        let { content, msg } = message

        if (message.msg_id || !message.data) {
          content = msg
        }
        if (message.files?.length) {
          const file = message.files[0]
          content = file.name
        }

        if (message.data.type == 'document') {
          const file = message.files[0]
          content = file.name
        } else if (message.data.type == 'vcard') {
          const file = message.files[0]
          content = file.name
        } else if (message.data.type == 'sticker') {
          content = 'Sticker'

          // isButtons
        } else if (message.data.type == 'buttons') {
          let buttonContent = ''

          if (message.data.buttonOptions.header) {
            buttonContent += message.data.buttonOptions.header
          }

          if (message.data.buttonOptions.content) {
            buttonContent += ` ${message.data.buttonOptions.content}`
          }

          if (message.data.buttonOptions.footer) {
            buttonContent += ` ${message.data.buttonOptions.footer}`
          }

          content = buttonContent
        }

        if (room.phone_number_show == 'Group') {
          if (!room.users || room.users === 'unchanged_toast') {
            room.users = []
          }
          message.username = !message.fromMe
            ? room.users
              ? room.users.find(user => message.sender_id === user._id)?.username
              : ''
            : ''
        }

        // message.username = message !== this.currentUserId
        //     ? room.users
        //       ? room.users.find(user => message.sender_id === user._id)?.username
        //       : ''
        //     : ''

        // const username =
        //   message.sender_id !== this.currentUserId
        //     ? room.users.find(user => message.sender_id === user._id)?.username
        //     : ''

        // check timestamp
        let timestamp = this.$moment.unix()
        let format = ''
        if (message.timestamp.seconds) {
          timestamp = message.timestamp.seconds
        } else if (message.timestamp._seconds) {
          timestamp = message.timestamp._seconds
          format = this.$moment.unix(message.timestamp._seconds).isSame(this.$moment(), 'day') ? 'HH:mm' : 'DD/MM/YYYY'
        } else if (typeof message.timestamp !== 'Object') {
          timestamp = message.timestamp / 1000
          format = this.$moment.unix(message.timestamp / 1000).isSame(new Date(), 'day') ? 'HH:mm' : 'DD/MM/YYYY'
        }

        // console.log('🚀 ~ file: Chat.vue ~ line 1049 ~ formatLastMessage ~ message', message, room)

        return {
          ...message,
          ...{
            content,
            formattedTimestamp: message.timestamp._seconds
              ? this.$moment.unix(message.timestamp._seconds).format(format)
              : this.$moment.unix(message.timestamp / 1000).format(format),
            timestamp: formatTimestamp(new Date(timestamp * 1000), message.timestamp),
            seconds: timestamp,

            // distributed: true,
            // seen: message.sender_id === this.currentUserId ? message.seen : null,
            // new: message.sender_id !== this.currentUserId && (!message.seen || !message.seen[this.currentUserId]),
          },
        }
      }
    },
    async loadContentContactsList() {
      const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
        /['"]+/g,
        '',
      )}superwa/postgres/contact-list/get-contact-lists`
      const obj = {
        sub_id: this.user.sub_id,
      }
      try {
        let allContacts = []
        const contactLists = await this.$axios.post(endpoint, obj)

        contactLists.data.data.forEach(list => {
          allContacts = allContacts.concat(
            list.contacts.map(x => {
              return {
                ...x,
                use_color: list.use_color,
                label_color: list.label_color,
                list_name: list.list_name,
                list_id: list.list_id,
              }
            }),
          )
        })

        this.contactListNames = allContacts

        return {
          contacts: allContacts,
        }
      } catch (err) {
        console.log(err)
      }
    },
    async roomActionHandler({ action, roomId, data }) {
      // console.log('🚀 ~ file: Chat.vue ~ line 2543 ~ roomActionHandler ~ action/', action)
      this.currentActionRoomMenu = action.name
      this.selectedRoomRoomMenu = roomId
      this.roomIdRoomMenu = roomId

      // //console.log(roomId, action)
      if (action.name === 'addLabel') {
        this.setRoomLabelsRoomMenu(roomId, 'addLabel', data)
      }

      //   else if (action.name === 'archiveContact') {
      //   await this.archiveContactRoomMenu()

      //   await this.fetchRoomAfterFilter()
      // } else if (action.name === 'shareRoom') {
      //   this.dialogSharedRoomRoomMenu = true
      //   this.sharedRoomUrl = null
      else if (action.name === 'deleteRoom') {
        this.$refs.confirm
          .open('Delete Chat', 'Are you sure you want to delete this chat ?', { color: 'red' }, 'Confirm')
          .then(async agree => {
            // //console.log('🚀 ~ file: Chat.vue ~ line 3053 ~ roomActionHandler ~ agree', agree)

            if (agree) {
              // const deleteRoom = await this.$store.dispatch('rooms/deleteRoom', {
              //   subId: this.user.sub_id,
              //   roomId,
              // })
              const deleteRoom = await clientPocketBase.collection('crm_contact').delete(data.id)

              // await this.deleteRoom(data)
              // //console.log('🚀 ~ file: Chat.vue ~ line 3053 ~ roomActionHandler ~ deleteRoom', deleteRoom)
            }
          })
          .catch(error => {
            console.log('🚀 ~ file: Chat.vue ~ line 4091 ~ roomActionHandler ~ error', error)
          })
      } else if (action.name === 'addToContactGroup') {
        await this.addContactGroup(roomId)
      } else if (action.name === 'addResponse') {
        this.addResponse(roomId, data)
      }

      // else if (action.name.includes('addToSurvey')) {
      //   const room = this.rooms.find(room => room.roomId === roomId)
      //   if (room) {
      //     await this.openSurvey(room, action.name.split('~')[1])
      //   }
      // }
    },
    async addResponse(roomId, data) {
      this.dataContact = data
      this.$refs.dialogAddSurvey.open()
    },
    async toFormResponse(item) {
      this.tagId = item
      this.errorResponseValidationSnackbar = false
      this.successResponseValidationSnackbar = false
      this.dialogFormResponse = true
      this.openForm(item)
    },
    async openForm(form) {
      try {
        this.formData = form
        let getPhoneContacts
        let isRegistered = false
        const inputPhone = this.dataContact.phone_number
        const formatInputPhone = this.dataContact.phone_number
        const id = this.$Md5Hash(`${this.user.sub_id}-${formatInputPhone}`)

        try {
          getPhoneContacts = await clientPocketBase
            .collection('crm_contact_phones')
            .getFirstListItem(`phone_number = '${formatInputPhone}'`, {
              expand: 'contact_id',
              $autoCancel: false,
            })
          if (getPhoneContacts) {
            this.errorPhoneValidationSnackbar = { status: true, phone: formatInputPhone.toString() }
            isRegistered = true
            this.contactIdResponse.id = getPhoneContacts.contact_id
            this.contactIdResponse.name = this.dataContact.name
          } else {
            this.contactIdResponse.id = ''
          }
        } catch (err) {
          if (err.status == 404) {
            isRegistered = false
          } else {
            console.log(err)
          }
        }
        if (this.contactIdResponse.id) {
          const foundResponse = await clientPocketBase.collection('crm_form_response').getFullList(200, {
            filter: `contacts ~ '${this.contactIdResponse.id}' && type = 'Response' && responses.form ~ '${form.id}'`,
            $autoCancel: false,
            expand: 'contacts, responses',
          })
          if (foundResponse.length > 0) {
            this.allResponse = foundResponse
          } else {
            this.allResponse = []
          }
        }
        this.loadResponse()
      } catch (error) {
        console.log(error)
      }
    },
    async loadResponse() {
      // console.log(this.allResponse, 'sayang all responses', this.dataContact.phone_number)
      if (this.allResponse.length === 0 && this.dataContact.source === 'wa') {
        this.allResponse = { name: this.dataContact.name, phoneNumber: this.dataContact.phone_number }
      }
      const surveyData = {
        form: this.formData.survey_json,
        formName: this.formData.name,
        answer: this.allResponse,
        isSingleton: this.formData.is_singleton,
      }

      // console.log(JSON.stringify(surveyData))

      // const elementResponse = document.getElementById('responseChat')
      // if (elementResponse) {
      //   const onElementResponseLoad = () => {
      //     elementResponse.removeEventListener('load', onElementResponseLoad);
      //     elementResponse.contentWindow.postMessage(surveyData, '*');
      //   };
      //   elementResponse.addEventListener('load', onElementResponseLoad);
      //   setTimeout(() => {
      //     elementResponse.removeEventListener('load', onElementResponseLoad);
      //     elementResponse.contentWindow.postMessage(surveyData, '*');
      //   }, 1000);
      //   // elementResponse.onload = () => {
      //   //   elementResponse.contentWindow.postMessage((surveyData, 'https://crm-survey.superwa.io')*')
      //   // }
      //   // elementResponse.contentWindow.postMessage((surveyData, 'https://crm-survey.superwa.io')*')
      // }
      const elementResponse = document.getElementById('responseChat')
      if (!elementResponse) return

      const onElementResponseLoad = () => {
        elementResponse.contentWindow.postMessage(surveyData, '*')
        elementResponse.removeEventListener('load', onElementResponseLoad)
      }

      if (elementResponse.contentWindow.document.readyState === 'complete') {
        onElementResponseLoad()
      } else {
        elementResponse.addEventListener('load', onElementResponseLoad)
      }
    },
    async saveEditResponse(data) {
      // console.log(this.dataContact, '<<test ya')
      try {
        const nanoId = this.$nanoid()
        const formatInputPhone = this.dataContact.phone_number
        const id = this.$Md5Hash(`${this.user.sub_id}-${formatInputPhone}`)
        let response_form = ''

        let countRequired = 0
        let countResponseRequired = 0
        for (let i = 0; i < this.formData?.survey_json?.length; i++) {
          const question = this.formData.survey_json[i]
          let requiredQuestion
          if (question.isRequired) {
            requiredQuestion = question.name
            countRequired++
          }
          if (requiredQuestion) {
            for (const question in data.response_data) {
              if (requiredQuestion === question) {
                countResponseRequired++
              }
            }
          }
        }

        const isPassable = countRequired === countResponseRequired

        if (!isPassable) {
          this.errorResponseValidationSnackbar = true

          // this.$refs.dialogAddResponse.errorResponseValidationSnackbar = true
          // this.$refs.dialogAddSurvey.errorResponseValidationSnackbar = true
        } else if (!this.contactIdResponse.id) {
          this.loading = true
          try {
            let platform = ''
            if (this.dataContact.source === 'wa') {
              platform = 'wa'
            } else if (this.dataContact.source === 'chatwoot') {
              platform = 'chatwoot'
            }
            const newContact = await clientPocketBase.collection('crm_contacts').create(
              {
                id,
                name: this.dataContact.name,
                avatar: this.dataContact.profile_picture,
                nickname: this.dataContact.nickname,
                subId: this.user.sub_id,
                assignedTo: this.dataContact.assign_to || null,
                phoneNumber: [`${formatInputPhone}`],
                platform,
              },
              { $autoCancel: false },
            )

            const newPhoneNumber = await clientPocketBase.collection('crm_contact_phones').create(
              {
                id: this.$Md5Hash(`${this.user.sub_id}-${formatInputPhone}`),
                phone_number: formatInputPhone,
                contact_id: id,
                inboxId: this.dataContact.source === 'chatwoot' ? this.dataContact.inbox_id : '',
                sub_id: this.user.sub_id,
              },
              { $autoCancel: false },
            )

            this.contactIdResponse.id = newContact.id
          } catch (error) {
            console.log(error)
          }
        }
        if (!isPassable) {
          this.errorResponseValidationSnackbar = true
        } else {
          // Make response
          if (data.id) {
            let foundResponse

            try {
              foundResponse = await clientPocketBase.collection('crm_form_response').getOne(data.id, {
                $autoCancel: false,
              })
            } catch (err) {
              console.log(
                `🥶🥶🥶🥶 ~ フ ク ロ ウ file: DialogAddResponse.vue:314 ~ フ ク ロ ウ saveResponse ~ フ ク ロ ウ err`,
                err,
              )
            }
            if (foundResponse.responses) {
              if (!data.idFormData) {
                const { id: idResponseData } = await clientPocketBase.collection('crm_form_response_data').create(
                  {
                    response: data.response_data,
                    subId: this.user.sub_id,
                    form_response: foundResponse.id,
                    form: this.formData.id,
                  },
                  {
                    $autoCancel: false,
                  },
                )
                foundResponse.responses.push(idResponseData)
                response_form = idResponseData
              } else {
                const foundResponseData = await clientPocketBase.collection('crm_form_response_data').update(
                  data.idFormData,
                  {
                    response: data.response_data,
                  },
                  {
                    $autoCancel: false,
                  },
                )
                response_form = data.idFormData
              }
              const updatedResponse = await clientPocketBase.collection('crm_form_response').update(
                foundResponse.id,
                {
                  responses: foundResponse.responses,
                },
                { $autoCancel: false },
              )
            } else {
              const { id: idResponseData } = await clientPocketBase.collection('crm_form_response_data').create(
                {
                  response: data.response_data,
                  subId: this.user.sub_id,
                  form_response: foundResponse.id,
                  form: this.formData.id,
                },
                {
                  $autoCancel: false,
                },
              )
              response_form = idResponseData
              const updatedResponse = await clientPocketBase.collection('crm_form_response').update(
                data.id,
                {
                  responses: [idResponseData],
                },
                { $autoCancel: false },
              )
            }
            try {
              if (this.formData.from_cms) {
                await this.$axios.post(this.formData.callback_urls.edit, {
                  formId: response_form,
                  answer: data.response_data,
                })
              }
            } catch (err) {
              console.log(err)
            }
            this.dialogFormResponse = false
            this.contactTagsMaker(this.formData.raw_data)
          } else {
            const newResponse = await clientPocketBase.collection('crm_form_response').create(
              {
                id: this.$Md5Hash(`${nanoId}-${this.user.sub_id}`),
                subId: this.user.sub_id,
                contacts: this.contactIdResponse.id,
                type: 'Response',
                response_data: data.response_data,
                user: this.user.id,
              },
              {
                $autoCancel: false,
              },
            )
            const { id: idResponseData } = await clientPocketBase.collection('crm_form_response_data').create(
              {
                response: data.response_data,
                subId: this.user.sub_id,
                form_response: newResponse.id,
                form: this.formData.id,
              },
              {
                $autoCancel: false,
              },
            )
            response_form = idResponseData
            const updateResponse = await clientPocketBase.collection('crm_form_response').update(
              newResponse.id,
              {
                responses: [idResponseData],
              },
              {
                $autoCancel: false,
              },
            )

            try {
              if (this.formData.from_cms) {
                await this.$axios.post(this.formData.callback_urls.add, {
                  subId: this.user.sub_id,
                  formId: response_form,
                  customerId: id,
                  answer: data.response_data,
                })
              }
            } catch (err) {
              console.log(err)
            }

            this.contactTagsMaker(this.formData.raw_data)
            this.contactIdResponse.id = ''
            this.dialogFormResponse = false
          }
        }
        this.allResponse = []
      } catch (error) {
        console.log(error)
      }
    },
    async contactTagsMaker(rawData) {
      // update contact tag & creat contact tag for set tags
      const contact = this.contactIdResponse.id
      let getRawTag = {}
      try {
        getRawTag = await clientPocketBase
          .collection('crm_contact_tags')
          .getFirstListItem(`contacts='${contact}' && sourceId = ''`, { expand: 'tag', $autoCancel: false })
      } catch (err) {}
      const newTag = { ...(getRawTag.rawData || {}), ...rawData }
      const selected = Object.keys(newTag)

      // console.log(getRawTag, contact, '<<get raw tag')
      try {
        const updateContact = await clientPocketBase.collection('crm_contacts').update(
          contact,
          {
            tags: selected.filter(key => newTag[key] !== null),
          },
          { $autoCancel: false },
        )
      } catch (err) {
        console.log(err)
      }
      for (let i = 0; i < selected.length; i++) {
        const id = this.$Md5Hash(`${selected[i]}-${contact}`)
        if (newTag[selected[i]] === null) {
          try {
            const updateContactTag = await clientPocketBase
              .collection('crm_contact_tags')
              .delete(id, { $autocancel: false })
          } catch (err) {}
        } else {
          try {
            const updateContactTag = await clientPocketBase
              .collection('crm_contact_tags')
              .delete(id, { $autoCancel: false })
          } catch (err) {}
          if (rawData[selected[i]] !== null) {
            const createContactTag = await clientPocketBase.collection('crm_contact_tags').create(
              {
                id,
                tag: selected[i],
                variant: newTag[selected[i]].value,
                contacts: contact,
                subId: this.user.sub_id,
                rawData: Object.fromEntries(Object.entries(newTag).filter(([_, v]) => v != null)),

                // msgTagData: thxis.action === 'msg' ? this.model : null,
              },
              { $autoCancel: false },
            )
          }
        }
      }
    },
    async setRoomLabelsRoomMenu(roomId, action, data, datafromMsg) {
      // const id = this.$Md5Hash(`${roomId}`)
      // this.contactId = id
      // this.tagMessage = ''
      this.loadingCheckRooms = true
      this.selectedRoomRoomMenu = roomId
      let findRoom
      let formatInputPhone
      if (action === 'labelMessage') {
        if (roomId.split('-').length === 2) {
          formatInputPhone = roomId.split('-')[1] || ''
        } else {
          formatInputPhone = roomId.split('-').slice(1).join('-') || ''
        }
        this.tagMessage = { content: datafromMsg.caption || datafromMsg.content, source_id: datafromMsg.source_id }
        if (datafromMsg.buttonOptions) {
          this.tagMessage = datafromMsg.buttonOptions.content
        }

        // this.messages = _orderBy(this.messages, 'seconds', 'asc')

        // setTimeout(() => {
        //   this.messages = [...this.messages]
        // })
        findRoom = this.rooms.find(item => item.roomId === roomId)
      } else {
        this.tagMessage = ''
        const inputPhone = data.phone_number
        formatInputPhone = inputPhone
      }
      const id = this.$Md5Hash(`${this.user.sub_id}-${formatInputPhone}`)

      let isExist
      let isRegistered = false
      let getPhoneContacts
      this.selectedRoomRoomMenu = roomId
      try {
        const findContact = await clientPocketBase.collection('crm_contacts').getOne(id)
        if (findContact) isExist = true
      } catch (error) {
        if (error.status == 404) {
          isExist = false
        } else {
          // console.log(error)
        }
      }
      if (!isNaN(formatInputPhone)) {
        try {
          getPhoneContacts = await clientPocketBase
            .collection('crm_contact_phones')
            .getFirstListItem(
              `phone_number = '${formatInputPhone}' && inboxId = '${data.source === 'chatwoot' ? data.inbox_id : ''}'`,
              {
                expand: 'contact_id',
                $autoCancel: false,
              },
            )

          if (getPhoneContacts) {
            this.errorPhoneValidationSnackbar = { status: true, phone: formatInputPhone.toString() }
            isRegistered = true
          }
        } catch (err) {
          if (err.status == 404) {
            isRegistered = false
          } else {
            console.log(err)
          }
        }

        // console.log({datafromMsg, data}, '<<labelllll')
        if (isExist) {
          if (action === 'labelMessage') {
            this.$refs.dialogSetTags.open('msg', id, datafromMsg, true)
          } else {
            this.$refs.dialogSetTags.open('tags', id, data, true)
          }
          this.contactId = id
        } else if (isRegistered) {
          if (action === 'labelMessage') {
            this.$refs.dialogSetTags.open('msg', getPhoneContacts.contact_id, datafromMsg, true)
          } else {
            this.$refs.dialogSetTags.open('tags', getPhoneContacts.contact_id, data, true)
          }
          this.contactId = getPhoneContacts.contact_id
        } else {
          if (action === 'labelMessage') {
            this.$refs.dialogSetTags.open('add', '', { ...data, msg: datafromMsg }, true)
          } else {
            this.$refs.dialogSetTags.open('add', '', data, true)
          }
          this.contactId = id
        }
      }
      this.loadingCheckRooms = false
    },
    async updateLabelRoomMenu(rawData, data) {
      if (this.tagMessage) {
        // let isRemoved = false
        let indexMessage = this.messages.findIndex(ele => ele.source_id === this.tagMessage.source_id)

        const selected = Object.keys(rawData)

        for (let i = 0; i < selected.length; i++) {
          const tag = selected[i]
          const id = this.$Md5Hash(`${tag}-${data?.source_id}`)
          if (rawData[selected[i]] !== null) {
            try {
              const createMsgTag = await clientPocketBase.collection('crm_contact_tags').update(
                id,
                {
                  tag: selected[i],
                  variant: rawData[selected[i]]?.value,
                  contacts: this.contactId,
                  subId: this.user.sub_id,
                  rawData: Object.fromEntries(Object.entries(rawData).filter(([_, v]) => v != null)),
                  messages: this.tagMessage,
                  sourceId: data?.source_id,
                  msgLabels: selected.filter(key => rawData[key] !== null),
                },
                { $autoCancel: false },
              )
              await this.getContactTags(data?.source_id)

              // console.log(createMsgTag, 'ini faker')
            } catch (err) {
              console.log(err, 'ini err faker')
            }
          }

          await this.getTagsPb()

          // }
          let findOneRoom
          try {
            findOneRoom = await clientPocketBase.collection('crm_contact_tags').getOne(this.$Md5Hash(id), {
              expand: `tag`,
              $autoCancel: false,
            })
          } catch (err) {}
          indexMessage = this.messages.findIndex(ele => ele.source_id === this.tagMessage.source_id)
          if (indexMessage > -1) {
            const obj = {
              expand: {},
            }
            if (findOneRoom) {
              obj.expand = findOneRoom.expand
              if (!this.messages[indexMessage].labels || this.messages[indexMessage].labels.length === 0) {
                this.messages[indexMessage].labels = [obj]
              } else if (!this.messages[indexMessage].labels?.msgLabels?.find(ele => ele === obj.expand.tag.id)) {
                this.messages[indexMessage].labels = [...this.messages[indexMessage].labels, obj]

                // console.log(this.messages[indexMessage].labels, '<<test update')
              }
            }
            this.messages = [...this.messages]

            // console.log(id, findOneRoom, this.messages[indexMessage].labels, '<<find one nih')
            this.messages[indexMessage] = this.setMessageLabels(data)
          }
        }

        // to remove label msg
        let getOldRawData = {}
        try {
          getOldRawData = await clientPocketBase
            .collection('crm_contact_tags')
            .getFirstListItem(`contacts='${this.contactId}' && sourceId = '${data?.source_id}'`, {
              expand: 'tag',
              $autoCancel: false,
            })
        } catch (err) {}
        const newTagData = { ...(getOldRawData.rawData || {}), ...rawData }
        const oldDataSelected = Object.keys(newTagData).filter(key => newTagData[key] === null)

        // const selectedNew = Object.keys(newTagData).filter(key => newTagData[key] !== null)

        for (let j = 0; j < oldDataSelected.length; j++) {
          this.messages[indexMessage].labels = this.messages[indexMessage].labels?.expand?.msgLabels?.filter(ele => {
            return selected.some(tag => tag === ele?.id)
          })
          this.messages = [...this.messages]

          // console.log(newTag, getRawTag, this.messages[indexMessage].labels, '<<find list tes nih')
          this.messages[indexMessage] = this.setMessageLabels(this.messages[indexMessage])
        }

        if (oldDataSelected.sort().join(',') === selected.sort().join(',')) {
          this.messages[indexMessage].labels = []
        }

        // console.log(
        //   { newTagData, oldDataSelected, selected, newTag, rawData },
        //   this.messages[indexMessage].labels,
        //   '<<old new',
        // )
        try {
        } catch (err) {
          console.log(err, 'ini err update')
        }

        const identifier = `${this.messages[indexMessage].dbRoomId}-${this.messages[indexMessage].source_id}`
        if (!this.labelMessageObj[identifier]) {
          this.labelMessageObj[identifier] = [...this.messages[indexMessage].labels?.expand?.msgLabels]
        } else {
          this.labelMessageObj[identifier] = [...this.messages[indexMessage].labels?.expand?.msgLabels]
        }
      }
    },
    async saveLabelsRoomMenu(rawData, data) {
      // console.log(data.source, data.source === 'chatwoot' ? data.inbox_id : '', '<<vira data')
      const tagsName = []
      const tagsColor = []

      // this.$emit('selected-tags', selected)
      const allTagMsg = []
      const contactTags = ''
      try {
        const phone = data.phone_number

        // console.log(rawData, data, this.tagMessage, 'cok<')
        const id = this.$Md5Hash(`${this.user.sub_id}-${data.phone_number}`)
        let platform = ''
        if (this.dataContact.source === 'wa') {
          platform = 'wa'
        } else if (this.dataContact.source === 'chatwoot') {
          platform = 'chatwoot'
        }
        const newContact = await clientPocketBase.collection('crm_contacts').create(
          {
            id,
            name: data.name,
            avatar: data.profile_picture,
            nickname: data.nickname,
            subId: this.user.sub_id,
            platform,
            assignedTo: data.assign_to ? data.assign_to : null,
            phoneNumber: [`${data.phone_number}`],
          },
          { $autoCancel: false },
        )
        this.contactId = id
        const newPhoneNumber = await clientPocketBase.collection('crm_contact_phones').create(
          {
            id: this.$Md5Hash(`${this.user.sub_id}-${data.phone_number}`),
            phone_number: data.phone_number,
            contact_id: id,
            inboxId: data.source === 'chatwoot' ? data.inbox_id : '',
            sub_id: this.user.sub_id,
          },
          { $autoCancel: false },
        )
        const getAllContactByPhone = await clientPocketBase.collection('crm_contact').getFullList(200, {
          expand: 'assign_to',
          filter: `phone_number = '${phone}'`,
          $autoCancel: false,
        })
        for (let i = 0; i < getAllContactByPhone.length; i++) {
          const idContact = getAllContactByPhone[i].id
          const updateContact = await clientPocketBase
            .collection('crm_contact')
            .update(idContact, { contacts: newContact.id }, { $autoCancel: false })
        }
      } catch (err) {
        console.log(err, 'save label')
      }

      const selected = Object.keys(rawData)

      for (let i = 0; i < selected.length; i++) {
        const id = this.$Md5Hash(`${selected[i]}-${this.tagMessage ? data?.source_id : this.contactId}`)

        if (rawData[selected[i]] !== null) {
          if (!this.tagMessage) {
            const createContactTag = await clientPocketBase.collection('crm_contact_tags').create(
              {
                id,
                tag: selected[i],
                variant: rawData[selected[i]].value,
                contacts: this.contactId,
                subId: this.user.sub_id,
                rawData: Object.fromEntries(Object.entries(rawData).filter(([_, v]) => v != null)),
              },
              { $autoCancel: false },
            )
            await this.getContactTags(data?.source_id)
          } else {
            try {
              const createMsgTag = await clientPocketBase.collection('crm_contact_tags').create(
                {
                  id,
                  tag: selected[i],
                  variant: rawData[selected[i]].value,
                  contacts: this.contactId,
                  subId: this.user.sub_id,
                  rawData: Object.fromEntries(Object.entries(rawData).filter(([_, v]) => v != null)),
                  messages: this.tagMessage,
                  sourceId: data?.source_id,
                },
                { $autoCancel: false },
              )

              // console.log(createMsgTag, 'ini create label msg')
            } catch (err) {
              console.log(err, 'ini err faker')
            }
            this.setMessageLabels(data.msg)
          }
        }
      }
      if (!this.tagMessage) {
        const updateTags = await clientPocketBase.collection('crm_contacts').update(this.contactId, {
          tags: selected.filter(key => rawData[key] !== null),
        })
      }
    },

    async updateRoomLabelsRoomMenu({ record, action }) {
      // console.log(record, this.tagMessage, action, '<<vira hit')
      this.loadingCheckRooms = true
      let allRoom
      let isExist = false
      for (let i = 0; i < record.phoneNumber.length; i++) {
        allRoom = this.rooms.filter(item => item.phone_number == record.phoneNumber[i])

        if (action === 'update' || action === 'create') {
          if (allRoom.length) {
            for (let i = 0; i < allRoom.length; i++) {
              const tags = []
              const room = allRoom[i]
              const roomIndex = this.rooms.findIndex(el => room.roomId === el.roomId)
              if (record.tags.length) {
                for (let i = 0; i < record.tags.length; i++) {
                  const tagId = record.tags[i]
                  let tag
                  try {
                    tag = await clientPocketBase.collection('crm_tags').getOne(tagId, {
                      $autoCancel: false,
                    })
                    if (tag?.channels?.channel?.length === 0) {
                      isExist = true
                      tags.push(tag)
                    } else {
                      tag?.channels?.channel?.forEach(ch => {
                        // console.log(ch.id, room.instance.id, room.instance, 'fak<')
                        if (ch.id === room.instance.id) {
                          isExist = true
                          tags.push(tag)
                        }
                      })
                    }

                    // tags.push(tag)
                    if (isExist) {
                      room.labels = tags
                      this.rooms[roomIndex] = room
                    }
                  } catch (err) {
                    // console.log(err, err.data, 'ini err<')
                  }
                }
              } else {
                this.rooms[roomIndex].labels = []
              }
            }
          }
        }
      }
      if (this.tagMessage) {
        await this.getContactTags(this.tagMessage?.source_id)
      }
      this.loadingCheckRooms = false
    },
    async messageActionHandler({ roomId, action, message, data, roomOwnerId }) {
      this.currentAction = action.name
      this.currentMessage = message
      switch (action.name) {
        case 'labelMessage':
          const data = this.rooms.find(item => item.roomId == roomId)
          this.setRoomLabelsRoomMenu(roomId, 'labelMessage', data, message)
          break
        case 'unsendMessage':
          await this.deleteMessage(message, roomId, roomOwnerId)
          break
        case 'broadcastMessage':
          this.$store
            .dispatch('broadcast/getContacts', {
              subId: this.user.sub_id,
              token: this.user.token,
            })
            .then(response => {
              this.isLoadContactsDone = true
              this.dataContactsFromApi = response
            })
            .catch(err => {
              console.log(err)
            })
          this.addBroadcastDialog = true
          this.newBroadcast += 1
          this.$store.dispatch('broadcast/setDefaultCustomMessage', { message })
          break
        case 'forwardMessage':
          this.showCheckbox = !this.showCheckbox
          this.currentMessage.selected = true
          break
        default:
          break
      }

      // if (action.name == 'labelMessage') {
      // this.setRoomLabels(roomId, action.name, message)
      // }

      // else if (action.name == 'noteMessage') {
      //   let note = message.content

      //   if (message.files && message.files.length > 0) {
      //     if (note) {
      //       note += ' | '
      //     }

      //     note += message.files[0].url
      //   }

      //   this.notesName = note
      //   await this.saveNotes()
      // }
      // else if (action.name === 'unsendMessage') {

      // await this.deleteMessage(message, roomId)
      // //console.log(message, roomId, `INI MESSAGE`)
      // }
    },

    // async setRoomLabels(roomId, action, message = null) {
    //   await this.getSettingPg()
    //   this.keyLabels++
    //   this.labels = JSON.parse(JSON.stringify(this.labelsOrigin))
    //   this.selectedLabels = []
    //   this.selectedRoom = roomId

    //   // get selected label from contact labels
    //   let alertText = null
    //   const selectedLabels = []
    //   if (action == 'addLabel') {
    //     const room = this.rooms.value.find(room => room.roomId == roomId)

    //     this.labels = this.labels.filter(
    //       el =>
    //         el.channelId === room.roomOwnerId ||
    //         el.channelId === room.instance.phone_number ||
    //         !el.channelId ||
    //         el.channelId === 'all',
    //     )

    //     if (room && room.labels && room.labels.length > 0) {
    //       room.labels.forEach(label => {
    //         const isLabelExists = this.labels.find(i => i.id == label.label.id)
    //         const indexLabel = this.labels.findIndex(i => i.id == label.label.id)

    //         if (isLabelExists) {
    //           selectedLabels.push(label.label)
    //         }

    //         // remove selected label
    //         // if (indexLabel >= 0) {
    //         //   this.labels.splice(indexLabel, 1)
    //         // }
    //       })
    //     }

    //     alertText = this.$t('inbox.addedLabelContact')
    //   } else if (action == 'labelMessage') {
    //     const room = this.rooms.value.find(room => room.roomId == roomId)
    //     this.labels = this.labels.filter(
    //       el =>
    //         el.channelId === room.roomOwnerId ||
    //         el.channelId === room.instance.phone_number ||
    //         !el.channelId ||
    //         el.channelId === 'all',
    //     )
    //     const messages = this.label.value.filter(item => item.chat != null)

    //     messages.forEach(item => {
    //       if (item.chat._id == message._id) {
    //         const isLabelExists = this.labels.find(i => i.id == item.label.id)
    //         const indexLabel = this.labels.findIndex(i => i.id == item.label.id)

    //         if (isLabelExists) {
    //           selectedLabels.push(item.label)
    //         }

    //         // remove selected label
    //         // if (indexLabel >= 0) {
    //         //   this.labels.splice(indexLabel, 1)
    //         // }
    //       }
    //     })

    //     alertText = this.$t('inbox.addedLabelMessage')
    //   }

    //   // uncomment to show selected label
    //   this.selectedLabels = selectedLabels

    //   if (this.labels && this.labels.length > 0) {
    //     this.dialogLabels = !this.dialogLabels
    //   } else {
    //     this.alertDialog = true
    //     this.alertTitle = 'Oops...'
    //     this.alertText = alertText
    //   }
    // },
    async saveLabels() {
      //   const label = []
      //   const room = this.rooms.value.find(item => item.roomId == this.selectedRoom)
      //   if (room) {
      //     const contact = {
      //       roomId: room.roomId,
      //       name: room.roomName,
      //       phone_number: room.phone_number,
      //       phone_number_show: room.phone_number_show,
      //       avatar: room.avatar,
      //       lastMessage: room.lastMessage,
      //       last_message: room.last_message,
      //     }
      //     if (this.currentAction == 'addLabel') {
      //       const deletedLabel = this.label.value.filter(
      //         x => x.chat == null && x.contact && x.contact.roomId == room.roomId,
      //       )
      //       for (const data of deletedLabel) {
      //         const idx = this.label.value.findIndex(x => x.id === data.id)
      //         if (idx > -1) {
      //           this.label.value.splice(idx, 1)
      //         }
      //       }
      //     } else if (this.currentAction == 'labelMessage') {
      //       const deletedLabel = this.label.value.filter(x => x.chat && x.chat.source_id == this.currentMessage.source_id)
      //       for (const data of deletedLabel) {
      //         const idx = this.label.value.findIndex(x => x.id === data.id)
      //         if (idx > -1) {
      //           this.label.value.splice(idx, 1)
      //         }
      //       }
      //     }
      //     for (const selected of this.selectedLabels) {
      //       if (this.currentAction == 'addLabel') {
      //         const obj = {
      //           id: this.$nanoid(),
      //           labelName: selected.label,
      //           label: selected,
      //           contact,
      //           chat: null,
      //         }
      //         this.label.value.push(obj)
      //         label.push(obj)
      //       } else if (this.currentAction == 'labelMessage') {
      //         const chatMessage = JSON.parse(JSON.stringify(this.currentMessage))
      //         delete chatMessage.labels
      //         const obj = {
      //           id: this.$nanoid(),
      //           labelName: selected.label,
      //           label: selected,
      //           contact,
      //           chat: chatMessage,
      //         }
      //         this.label.value.push(obj)
      //         label.push(obj)
      //       } else if (this.currentAction == 'labelMessages') {
      //         this.currentMessages.forEach(message => {
      //           const exists = this.label.value.find(
      //             item =>
      //               item.id &&
      //               item.chat !== null &&
      //               item.chat.source_id === message.source_id &&
      //               item.labelName === selected.label,
      //           )
      //           if (!exists) {
      //             const chatMessages = JSON.parse(JSON.stringify(message))
      //             delete chatMessages.labels
      //             const obj = {
      //               id: this.$nanoid(),
      //               labelName: selected.label,
      //               label: selected,
      //               contact,
      //               chat: chatMessages,
      //             }
      //             this.label.value.push(obj)
      //             label.push(obj)
      //           } else {
      //             label.push(exists)
      //           }
      //         })
      //       }
      //     }
      //     await this.$store.commit('inbox/setLabelValue', this.label.value)
      //     const res = await this.$store.dispatch('global/updateSettingsByKey', {
      //       sub_id: this.user.sub_id,
      //       token: this.user.token,
      //       key: 'label_value',
      //       value: this.label.value,
      //     })
      //     if (res.status) {
      //       if (this.currentAction == 'addLabel') {
      //         await this.updateRoomLabels(label)
      //       } else if (this.currentAction == 'labelMessage') {
      //         await this.updateMessageLabels(label)
      //       } else if (this.currentAction == 'labelMessages') {
      //         await this.updateMessageLabels(label, true)
      //         this.showCheckbox = false
      //       }
      //       this.dialogLabels = false
      //       this.selectedLabels = []
      //     }
      //     // await this.updateSettings(this.label, async payload => {
      //     //   if (payload && payload.ok) {
      //     //     if (this.currentAction == 'addLabel') {
      //     //       await this.updateRoomLabels(label)
      //     //     } else if (this.currentAction == 'labelMessage') {
      //     //       await this.updateMessageLabels(label)
      //     //     } else if (this.currentAction == 'labelMessages') {
      //     //       await this.updateMessageLabels(label, true)
      //     //       this.showCheckbox = false
      //     //     }
      //     //   }
      //     // })
      //   }
    },
    async updateRoomLabels(label) {
      const roomIndex = this.rooms.value.findIndex(item => item.roomId == this.selectedRoom)
      const room = this.rooms.value.find(item => item.roomId == this.selectedRoom)

      if (room) {
        // room.labels = [...label]
        // room.labels = room.labels.filter((item, index, that) => index === that.findIndex(t => t.id === item.id))
        await db.showedRooms.where({ roomId: this.selectedRoom }).modify({ labels: label })
      }

      // if (roomIndex >= 0) {
      //   this.rooms = [...this.rooms]
      // }
    },

    // async updateMessageLabels(label, batch = false) {
    //   // console.log(label, 'ini fak<')
    //   if (batch) {
    //     for (const message of this.currentMessages) {
    //       await this.putMessageLabels(label, message._id)
    //     }
    //   } else {
    //     await this.putMessageLabels(label, this.currentMessage._id)
    //   }
    // },
    // async putMessageLabels(label, id) {
    //   label = label.filter(item => item.chat._id == id)
    //   const messageIndex = this.messages.findIndex(item => item._id == id)

    //   if (messageIndex >= 0) {
    //     if (this.messages[messageIndex].labels && this.messages[messageIndex].labels.length > 0) {
    //       this.messages[messageIndex].labels = []
    //       this.messages[messageIndex].labels = label
    //       this.messages[messageIndex].labels = this.messages[messageIndex].labels.filter(
    //         (item, index, that) => index === that.findIndex(t => t.id === item.id),
    //       )
    //     } else {
    //       this.messages[messageIndex].labels = label
    //     }
    //   }

    //   // this.messages = this.messages.filter(
    //   //   (message, index, that) => index === that.findIndex(t => t._id === message._id),
    //   // )

    //   this.messages = _orderBy(this.messages, 'seconds', 'asc')

    //   setTimeout(() => {
    //     this.messages = [...this.messages]
    //   })
    // },
    async menuActionHandler({ roomId, action }) {
      this.selectedRoom = roomId
      this.currentAction = action.name
      if (action.name == 'selectMessages') {
        if (!this.showCheckbox) {
          this.clearSelectedMessages()
        }
        this.showCheckbox = true
        this.$store.commit('inbox/setShowCheckbox', false)
      } else if (action.name == 'addLabel') {
        const data = this.rooms.find(item => item.roomId == roomId)
        this.setRoomLabelsRoomMenu(roomId, 'addLabel', data)
      } else if (action.name == 'quickReply') {
        this.dialogShownSavedReplies = !this.dialogShownSavedReplies
      }

      // } else if (action.name == 'archiveContact') {
      //   await this.archiveContact()

      //   await this.fetchRoomAfterFilter()
      // }
      else if (action.name === 'addToContactGroup') {
        await this.addContactGroup(roomId)
      }

      // else if (action.name.includes('addToSurvey')) {
      //   const room = this.rooms.find(room => room.roomId === roomId)
      //   if (room) {
      //     await this.openSurvey(room, action.name.split('~')[1])
      //   }
      // }
    },
    async selectMessagesActionHandler({ roomId, action, messages }) {
      if (action == 'close') {
        this.showCheckbox = false
        this.clearSelectedMessages()
      } else if (action == 'labelMessages') {
        this.currentAction = action
        this.currentMessages = messages
        this.keyLabels++
        this.labels = JSON.parse(JSON.stringify(this.labelsOrigin))
        this.selectedLabels = []

        if (this.labels && this.labels.length > 0) {
          this.dialogLabels = !this.dialogLabels
        } else {
          this.alertDialog = true
          this.alertTitle = 'Oops...'
          this.alertText = `${this.$t('Label')} ${this.$t('notFound')}`
        }
      } else if (action == 'exportMessages') {
        const formattedMessages = []

        // const foundRoom = await db.showedRooms.where('roomId').equals(this.selectedRoom).toArray()
        const findRoom = await clientPocketBase.collection('crm_contact').getFullList(200, {
          expand: 'assign_to',
          filter: `roomId = '${roomId}'`,
        })
        let foundRoom
        let foundInstance
        if (findRoom) {
          foundRoom = findRoom[0]
          foundInstance = this.instances.find(instance => this.$Md5Hash(instance._id) === foundRoom.instance)
        }
        f
        messages.forEach(message => {
          message.sender_id = `+${message.sender_id}`
          message.instance_name = foundInstance?.label
          message.instance_phone_number = foundInstance?.phone_number

          if (message.files && message.files.length > 0) {
            message.attachment = message.files[0].url
          }

          const date = this.$moment.unix(message.seconds)
          message.formatted_timestamp = date.format()

          formattedMessages.push(message)
        })

        this.exportMessages(formattedMessages)
        this.showCheckbox = false
      } else if (action == 'forwardMessages') {
        // this.$store
        //   .dispatch('broadcast/getContacts', {
        //     subId: this.user.sub_id,
        //     token: this.user.token,
        //   })
        //   .then(response => {
        //     //console.log(roomId, ' == response')
        //     response.data.contacts.Whatsapp = response.data.contacts.Whatsapp.filter(
        //       contact => contact.instance_id === roomId.split('-')[0],
        //     )

        //     //console.log(response.data.contacts.Whatsapp, ' == response')
        //     this.isLoadContactsDone = true
        //     this.dataContactsFromApi = response
        //   })
        //   .catch(err => {
        //     console.log(err, ' == error')
        //   })
        try {
          const options = {
            page: 1,
            itemsPerPage: 5,
          }
          await this.fetchWaContacts(options, roomId, (searchBy = ''))

          // const allWaContacts = await clientPocketBase.collection('crm_contact').getFullList(400, {
          //   expand: 'assign_to',
          //   filter: `type = 'rooms' && instance = '${this.$Md5Hash(roomId.split('-')[0])}'`,
          // })
          // console.log(allWaContacts, 'woi<')

          // this.isLoadContactsDone = true
          // this.dataContactsFromApi = {
          //   data: {
          //     contacts_list: [],
          //     extra_field: { global: [], default: [] },
          //     message: [],
          //     status: true,
          //     contacts: { Whatsapp: allWaContacts },
          //   },
          //   status: true,
          // }
        } catch (err) {
          // console.log('🚀 ~ file: FloatingChat.vue ~ line 4366 ~ selectMessagesActionHandler ~ err', err)
        }

        this.dialogForwardMenu = true
        this.newForwardMessage += 1
        this.$store.commit('inbox/setShowCheckbox', true)
        this.$store.dispatch('broadcast/setForwardMessages', { messages, roomId })
      }
    },
    async fetchWaContacts(options, roomId, searchBy = '') {
      try {
        const { page, itemsPerPage } = options
        let allWaContacts
        if (searchBy) {
          allWaContacts = await clientPocketBase.collection('crm_contact').getList(page, itemsPerPage, {
            expand: 'assign_to',
            filter: `type = 'rooms' && instance = '${this.$Md5Hash(
              roomId.split('-')[0],
            )}' && (name ~ '${searchBy}' || phone_number ~ '${searchBy}') ${
              this.user.chat_mode === 'Read and Write to assigned only'
                ? `&& (assign_to = '${this.user.id}' || assign_to ='')`
                : ''
            }`,
            $autoCancel: false,
          })
        } else {
          console.log(
            `type = 'rooms' && instance = '${this.$Md5Hash(roomId.split('-')[0])}' ${
              this.user.chat_mode === 'Read and Write to assigned only' ? `&& assign_to = '${this.user.id}'` : ''
            }`,
            'ini filter<',
          )
          allWaContacts = await clientPocketBase.collection('crm_contact').getList(page, itemsPerPage, {
            expand: 'assign_to',
            filter: `type = 'rooms' && instance = '${this.$Md5Hash(roomId.split('-')[0])}' ${
              this.user.chat_mode === 'Read and Write to assigned only'
                ? `&& (assign_to = '${this.user.id}' || assign_to ='')`
                : ''
            }`,
            $autoCancel: false,
          })
        }
        if (allWaContacts && allWaContacts.items) {
          this.totalContactsForward = allWaContacts.totalItems
        }
        this.isLoadContactsDone = true
        this.dataContactsFromApi = {
          data: {
            contacts_list: [],
            extra_field: { global: [], default: [] },
            message: [],
            status: true,
            contacts: { Whatsapp: allWaContacts && allWaContacts.items.length ? allWaContacts.items : [] },
          },
          status: true,
        }
      } catch (err) {
        console.log(err, 'woi<')
        this.isLoadContactsDone = true
        this.dataContactsFromApi = {
          data: {
            contacts_list: [],
            extra_field: { global: [], default: [] },
            message: [],
            status: true,
            contacts: { Whatsapp: [] },
          },
          status: true,
        }

        // console.log('🚀 ~ file: FloatingChat.vue ~ line 4366 ~ selectMessagesActionHandler ~ err', err)
      }
    },
    clearSelectedMessages() {
      this.messages.forEach(message => {
        message.selected = false
      })
    },
    async exportMessages(data) {
      const columns = [
        {
          label: 'Timestamp',
          field: 'formatted_timestamp',
        },
        {
          label: 'Contact Name',
          field: 'sender_name',
        },
        {
          label: 'Contact Phone Number',
          field: 'sender_id',
        },
        {
          label: 'Channel Name',
          field: 'instance_name',
        },
        {
          label: 'Channel Phone Number',
          field: 'instance_phone_number',
        },
        {
          label: 'Message',
          field: 'content',
        },
        {
          label: 'Attachment',
          field: 'attachment',
        },
      ]

      // naive encoding to csv format
      const csv = [columns.map(col => this.wrapCsvValue(col.label))]
        .concat(
          data.map(row =>
            columns
              .map(col =>
                this.wrapCsvValue(
                  typeof col.field === 'function' ? col.field(row) : row[col.field === void 0 ? col.name : col.field],
                  col.format,
                ),
              )
              .join(','),
          ),
        )
        .join('\r\n')

      // //console.log('🚀 ~ file: Chat.vue ~ line 4993 ~ exportMessages ~ csv', csv, true, typeof csv)

      // const anchor = document.createElement('a')
      // anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
      // anchor.target = '_blank'
      // anchor.download = 'Export Messages.csv'
      // anchor.click()

      try {
        const { publicStorage } = await Filesystem.checkPermissions()

        if (publicStorage === 'granted') {
          await Filesystem.writeFile({
            path: `Export Messages-${Date.now()}.csv`,
            data: csv,
            directory: Directory.Documents,
            encoding: Encoding.UTF8,
          })

          const canSend = await LocalNotifications.requestPermissions()

          // //console.log(
          //   '🚀 ~ file: LayoutContentVerticalNav.vue ~ line 1336 ~ mounted ~ canSend',
          //   JSON.stringify(canSend),
          // )
          if (canSend) {
            await LocalNotifications.schedule({
              notifications: [
                {
                  title: 'Export Success',
                  body: 'Messages has been exported to documents folder',
                  id: Date.now(),
                  schedule: { at: new Date(Date.now() + 500) },
                  actionTypeId: '',
                  extra: null,
                },
              ],
            })
          }
        } else {
          const canImport = await Filesystem.requestPermissions()

          // //console.log('🚀 ~ file: FloatingChat.vue ~ line 4916 ~ exportMessages ~ canImport', JSON.stringify(canImport))
          if (canImport) {
            await Filesystem.writeFile({
              path: `Export Messages-${Date.now()}.csv`,
              data: csv,
              directory: Directory.Documents,
              encoding: Encoding.UTF8,
            })

            const canSend = await LocalNotifications.requestPermissions()

            // //console.log('🚀 ~ file: LayoutContentVerticalNav.vue ~ line 1336 ~ mounted ~ canSend', canSend)
            if (canSend) {
              await LocalNotifications.schedule({
                notifications: [
                  {
                    title: 'Export Success',
                    body: 'Messages has been exported to documents folder',
                    id: this.$nanoid(),
                    schedule: { at: new Date(Date.now() + 500) },
                    actionTypeId: '',
                    extra: null,
                  },
                ],
              })
            }
          }
        }
      } catch {}
    },
    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val

      formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

      formatted = formatted.split('"').join('""')

      /**
       * Excel accepts \n and \r in strings, but some other CSV parsers do not
       * Uncomment the next two lines to escape new lines
       */
      // .split('\n').join('\\n')
      // .split('\r').join('\\r')

      return `${formatted}`
    },
    async addContactGroup(roomId) {
      const res = await this.$store.dispatch('global/getContactLists', {
        sub_id: this.user.sub_id,
        token: this.user.token,
      })
      if (res.status) {
        if (res.data.length !== 0) {
          this.$refs.dialogSelectList
            .open('Select Contact List', res.data)
            .then(async data => {
              if (data.status) {
                const room = this.rooms.value.find(item => item.roomId == roomId)
                if (room) {
                  room.roomName = room.roomName ? room.roomName : room.phone_number
                  const contactData = data.data.contacts.concat(room)
                  data.data.contacts = _uniqBy(contactData, 'phone_number')
                  await this.$store.dispatch('global/insertOrUpdateContactList', {
                    sub_id: this.user.sub_id,
                    token: this.user.token,
                    ...data.data,
                  })

                  const phoneNumbers = []
                  const checkData = []
                  contactData.forEach(x => {
                    if (!x.has_wa) {
                      checkData.push(x)
                      phoneNumbers.push(x.phone_number)
                    }
                  })

                  // const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
                  //   sub_id: this.user.sub_id,
                  //   token: this.user.token,
                  //   key: 'instances',
                  // })

                  let instancesList = []

                  instancesList = this.instances

                  if (instancesList.length !== 0) {
                    const findWAInstance = instancesList.value.find(
                      instance => !instance.instance_id.includes('facebook'),
                    )
                    const instanceId = `${findWAInstance.sub_id}-${findWAInstance.instance_id}`
                    const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}exists/${instanceId}`
                    let isUpdate = false
                    this.$axiosLimit
                      .post(endpoint, { phone_numbers: phoneNumbers })
                      .then(async response => {
                        checkData.forEach(async x => {
                          if (response.data.data[x.phone_number]) {
                            const foundData = contactData.find(y => y.phone_number === x.phone_number)
                            if (foundData && !foundData.updated_at) {
                              isUpdate = true
                              foundData.has_wa = 'Yes'
                              foundData.created_at = Date.now()
                              foundData.updated_at = Date.now()
                              foundData.name = foundData.name ? foundData.name : foundData.phone_number
                            } else {
                              isUpdate = true
                              foundData.updated_at = Date.now()
                            }
                          }
                        })
                        if (isUpdate) {
                          data.data.contacts = _uniqBy(contactData, 'phone_number')
                          await this.$store.dispatch('global/insertOrUpdateContactList', {
                            sub_id: this.user.sub_id,
                            token: this.user.token,
                            ...data.data,
                          })
                        }
                      })
                      .catch(error => {
                        console.log(error)
                      })
                  }

                  await this.loadContentContactsList()

                  const roomContactListLabel = this.getContactListLabelRoom(room.phone_number)
                  const roomContactName = this.getContactListRoom(room.phone_number)
                  const roomContactList = this.getContactListRoom(room.phone_number, true)

                  await db.showedRooms.where({ phone_number: room.phone_number }).modify({
                    roomContactListLabel,
                    roomContactName,
                    roomContactList,
                  })
                }
              }
            })
            .catch(err => {
              // this.selectedActionContact = ''
              // this.selectedContact = []
              console.log(err)
            })
        } else {
          this.alertDialog = true
          this.alertTitle = 'Oops...'
          this.alertText = `${this.$t('contacts.emptyList')}`
        }
      } else {
        this.alertDialog = true
        this.alertTitle = 'Oops...'
        this.alertText = `Something Went Wrong`
      }
    },

    /// QUICK REPLIES START

    async openDialogSavedReplies(mode, replies = {}) {
      // check if replies have template id
      if (replies.messages_templates) {
        try {
          // check if template exists
          const getOneTemplate = await clientPocketBase
            .collection('crm_message_templates')
            .getOne(replies.messages_templates, {
              $$autoCancel: false,
            })

          const refs = this.$refs['message-template-dialog']
          refs.dialog.addMessageTEmplate = true
          refs.getDataCategory()
          refs.status_message_template = 'edit'
          refs.dataIndex = 0
          refs.formAddTemplate = getOneTemplate
        } catch (error) {
          console.error(error, 'error get one template, or template not found')
        }
      } else {
        // Initialize the dialog as open and reset the active saved replies
        this.dialogSavedReplies = true
        this.activeSavedReplies = null
        this.$eventBus.$emit('showDialogShownSavedReplies')

        // Set the mode for the dialog
        this.isNewModeSavedReplies = mode === 'new'

        // Set the saved replies name and tag based on the mode and provided replies
        this.savedRepliesTag = this.isNewModeSavedReplies ? '' : replies.tag || ''
        this.savedRepliesName = this.isNewModeSavedReplies ? '' : replies.name || ''

        // Set the active saved replies if in edit mode
        if (!this.isNewModeSavedReplies) {
          this.activeSavedReplies = replies
        }
      }
    },

    async saveSavedReplies(channelId) {
      // Check if the saved replies tag and name are present
      if (!this.savedRepliesTag || !this.savedRepliesName) {
        // Show the snackbar and set the validation message if the fields are not present
        this.showSnackbarQuickReply = true
        this.quickReplyValidationMessage = this.$t('EOrdering.formValidationMessage')
      } else {
        // Get the channel filter ID
        const channelFilterId = channelId.roomOwnerId

        // Check if the active saved replies is present
        if (this.activeSavedReplies) {
          // Update the saved reply in PocketBase
          await clientPocketBase.collection('crm_quick_reply').update(this.activeSavedReplies.id, {
            tag: this.savedRepliesTag,
            text: this.savedRepliesName,
          })
        } else {
          // Create a new saved reply in PocketBase
          await clientPocketBase.collection('crm_quick_reply').create({
            tag: this.savedRepliesTag,
            text: this.savedRepliesName,
            sub_id: this.user.sub_id,
            user_id: this.user.id,
            is_global: this.user.role === 'Admin',
          })
        }

        // Call the updateSavedReplies function
        await this.updateSavedReplies()
      }
    },

    async updateSavedReplies() {
      this.loadingQuickReplies = true

      // Dispatch the action to update saved replies in the store
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'saved_replies',
        value: this.savedReplies.value,
      })

      // Close the saved replies dialog and reset the saved replies tag and name
      this.dialogSavedReplies = false
      this.savedRepliesTag = null
      this.savedRepliesName = null

      // Setting the loading status for quick replies to false
      this.loadingQuickReplies = false
    },

    async runCloseTemplate(messageTemplate) {
      // Save the saved replies templates
      await this.saveSavedRepliesTemplates()

      // Add the message template to the saved replies value
      // this.savedReplies.value.push({
      //   id: messageTemplate.id,
      //   name: messageTemplate.message,
      //   message_templates: messageTemplate,
      // })

      // Close the saved replies templates dialog
      // this.dialogSavedRepliesTemplates = false
    },
    async saveSavedRepliesTemplates() {
      if (!this.savedRepliesTemplate) return

      // Find the index of the saved reply template in messageTemplates
      const index = this.messageTemplates.value.findIndex(item => item.id == this.savedRepliesTemplate.id)
      if (index < 0) return

      // Get the template
      const template = this.messageTemplates.value[index]

      // Create the quick reply record
      let createQuickReply
      let isMessageTemplateExists = false
      try {
        const getMessageTemplate = await clientPocketBase.collection('crm_quick_reply').getFullList(400, {
          filter: `messages_templates = "${template.id}" && user_id = '${this.user.id}'`,
        })
        if (getMessageTemplate.length) {
          isMessageTemplateExists = true
        }

        if (!isMessageTemplateExists) {
          createQuickReply = await clientPocketBase.collection('crm_quick_reply').create({
            tag: this.savedRepliesTemplate.template_name,
            text: this.savedRepliesTemplate.message,
            sub_id: this.user.sub_id,
            user_id: this.user.id,
            is_global: this.user.role === 'Admin',
            messages_templates: template.id,
          })
        } else {
          this.errorTemplateExists = true
        }
      } catch (error) {
        console.error(error, 'error create quick reply')
      }

      // update message template
      if (createQuickReply) {
        try {
          await clientPocketBase.collection('crm_message_templates').update(template.id, {
            quick_reply: true,
          })
        } catch (error) {
          console.error(error, 'error update message template')
        }

        createQuickReply.isHaveButtons = !!template?.listButton?.length

        // if (template?.listButton?.length) {
        //   createQuickReply.isHaveButtons = true
        // } else {
        //   createQuickReply.isHaveButtons = false
        // }

        // this.templatesText.push(createQuickReply)

        await this.updateMessageTemplates()
      }
    },

    async updateMessageTemplates() {
      this.loadingQuickReplies = true

      // await this.updateSettings(this.messageTemplates, async payload => {
      // })

      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'message_template',
        value: this.messageTemplates.value,
      })
      this.dialogSavedRepliesTemplates = false
      this.savedRepliesTemplate = null
      this.loadingQuickReplies = false
    },

    async deleteSavedReplies(id, messageTemplates = null) {
      let idTemplate
      if (messageTemplates) {
        const index = this.messageTemplates.value.findIndex(item => item.id == messageTemplates.id)

        if (index >= 0) {
          this.messageTemplates.value[index].quick_reply = false
        }

        await this.updateMessageTemplates()
        const data = this.savedReplies.value.filter(item => item.id !== id)
        this.$set(this.savedReplies, 'value', data)
      } else {
        let index = this.savedReplies.value.findIndex(item => item.id == id)

        idTemplate = this.savedReplies.value[index].messages_templates

        if (index == -1) {
          index = 0
        }

        if (index !== -1) {
          this.savedReplies.value.splice(index, 1)
        }
        this.quickReplies = JSON.parse(JSON.stringify(this.savedReplies.value))
        const templatesText = []
        if (this.savedReplies.value.length) {
          const savedReplies = JSON.parse(JSON.stringify(this.savedReplies.value))
          savedReplies.forEach(item => {
            if (item.tag === undefined) {
              item.tag = ''
            }

            if (item.text === undefined) {
              item.text = item.name
              delete item.name
            }

            if (item.message_templates) {
              item.tag = item.message_templates.template_name
            }
            if (item.message_templates) {
              if (!item.message_templates.hasOwnProperty('operator') || !item.message_templates.operator) {
                templatesText.push(item)
              } else if (item.message_templates.operator === this.user.email) {
                templatesText.push(item)
              }
            }

            // check if user role admin or not
            if (this.user.role !== 'Admin') {
              // check if user id equal to user id in quick
              if (item.user_id === this.user.id) {
                templatesText.push(item)
              }
            } else {
              templatesText.push(item)
            }

            //   templatesText.push(item)
          })
        }
        this.templatesText = JSON.parse(JSON.stringify(templatesText))

        // delete quick reply in pocketbase
        try {
          await clientPocketBase.collection('crm_quick_reply').delete(id)
        } catch (error) {
          console.error(error, 'error delete quick reply')
        }
        try {
          await clientPocketBase.collection('crm_message_templates').update(idTemplate, {
            quick_reply: false,
          })
        } catch (error) {
          console.error(error, 'error update message template')
        }
        await this.updateSavedReplies()
      }
    },
    showHideMessageTemplate() {
      const refs = this.$refs['message-template']
      refs.dialog.addMessageTEmplate = true
      refs.getDataCategory()
      refs.status_message_template = 'add'
      refs.dataIndex = 0
      // eslint-disable-next-line prefer-destructuring
      refs.contactList = refs.contactListItems[0]

      // refs.mustacheList = refs.mustacheAll[refs.contactList.value]
      refs.formAddTemplate = {
        template_name: '',
        category: {},
        language: '',
        message: '',
        quick_reply: true,
        link_attachment: '',
        listButton: [],
        last_update: '',
        attachments: [],
        contact_list: refs.contactListItems[0],
        id: '',
        operator: this.user.email,
      }
      refs.propsUppyUploader = null
      refs.categoryName = null
    },
    async filteringLabels({ label, fromChannel }) {
      // if (fromChannel) {
      //   this.filter.channels = label
      // } else {
      //   this.filter.filters.push(label)
      // }
      // await this.saveFilter()
    },
    showDialogQuickReply() {
      this.dialogShownSavedReplies = !this.dialogShownSavedReplies
    },

    /// QUICK REPLIES END
  },
}
</script>

<style lang="scss" scoped>
#chat {
  overflow-y: hidden;
}
.room-info {
  font-size: 10px;
  padding-left: 15px;
}
</style>
