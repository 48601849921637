<template>
  <div class="vac-room-container">
    <slot name="room-list-item" v-bind="{ room }">
      <slot name="room-list-avatar" v-bind="{ room }">
        <div v-if="room.unreadCount > 0" class="vac-avatar-frame" :style="`background: ${room.instance.color};`">
          <div v-if="room.avatar" class="vac-avatar mx-auto" :style="{ 'height': '50px', 'min-width': '50px', 'width': '50px', 'background-image': `url('${room.avatar}')` }">
            <div class="unread-chat" :style="`background: ${room.instance.color};`"></div>
          </div>
        </div>

        <div v-else class="vac-avatar-frame">
          <!-- :style="`background: ${room.instance.color};`" -->
          <div v-if="room.avatar" class="vac-avatar mx-auto" :style="{ 'height': '50px', 'min-width': '50px', 'width': '50px', 'background-image': `url('${room.avatar}')` }">
            <div class="unread-chat">
              <!-- :style="`background: ${room.instance.color};`" -->
            </div>
          </div>
        </div>
      </slot>
      <div class="vac-name-container vac-text-ellipsis">
        <div class="vac-title-container">
          <!-- :class="{ 'vac-state-online': userStatus === 'online' }" -->
          <!-- <div
            class="vac-state-circle"
          /> -->
          <div :class="room.unreadCount > 0 ? 'font-weight-black' : ''" class="vac-room-name vac-text-ellipsis">
            {{ room.roomName }}
          </div>
          <div v-if="room.lastMessage" class="vac-text-date" :class="room.unreadCount > 0 ? 'font-weight-black' : ''">
            {{ room.lastMessage.timestamp }}
          </div>
        </div>
        <div
          class="vac-text-last"
          :class="{
            'vac-message-new': room.lastMessage && room.lastMessage.new && !typingUsers,
          }"
        >
          <!-- <span v-if="isMessageCheckmarkVisible">
            <slot name="checkmark-icon" v-bind="{ message: room.lastMessage }">
              <svg-icon
                :name="room.lastMessage.distributed ? 'double-checkmark' : 'checkmark'"
                :param="room.lastMessage.seen ? 'seen' : ''"
                class="vac-icon-check"
              />
            </slot>
          </span> -->
          <div
            v-if="
              room.lastMessage && !room.lastMessage.deleted && room.lastMessage.files && room.lastMessage.files.length
            "
            class="mr-1"
          >
            <div v-if="isAudio">
              <slot name="microphone-icon">
                <svg-icon name="microphone" class="vac-icon-microphone" />
              </slot>
              {{ formattedDuration == 'Audio.undefined' ? '' : formattedDuration }}
            </div>
            <div v-else-if="isVideo">
              <v-icon fab size="15px">
                {{ icons.mdiVideo }}
              </v-icon>
              {{ formattedDuration == 'Video.undefined' ? '' : formattedDuration }}
            </div>
            <div v-else-if="isImage">
              <v-icon fab size="15px">
                {{ icons.mdiImage }}
              </v-icon>
            </div>
            <div v-else>
              <v-icon fab size="15px">
                {{ icons.mdiTextBox }}
              </v-icon>
            </div>
          </div>
          <div
            v-if="room.lastMessage && !room.lastMessage.deleted && room.lastMessage.data.type === 'location'"
            class="d-flex my-auto"
          >
            <v-icon class="me-1" size="20">
              {{ icons.mdiMapMarker }}
            </v-icon>
            <format-message
              :content="getLastMessage"
              :deleted="!!room.lastMessage.deleted && !typingUsers"
              :users="room.users"
              :linkify="false"
              :text-formatting="textFormatting"
              :is-message-location="true"
              :is-unread="room.unreadCount > 0 ? true : false"
              :link-options="linkOptions"
              :single-line="true"
            >
              <template #deleted-icon="data">
                <slot name="deleted-icon" v-bind="data" />
              </template>
            </format-message>
          </div>
          <format-message
            v-else-if="room.lastMessage"
            :content="getLastMessage"
            :deleted="!!room.lastMessage.deleted && !typingUsers"
            :not-supported="!!room.lastMessage.not_supported"
            :users="room.users"
            :linkify="false"
            :text-formatting="textFormatting"
            :is-unread="room.unreadCount > 0 ? true : false"
            :link-options="linkOptions"
            :single-line="true"
          >
            <template #deleted-icon="data">
              <slot name="deleted-icon" v-bind="data" />
            </template>
          </format-message>
          <div v-if="!room.lastMessage && typingUsers" class="vac-text-ellipsis">
            {{ typingUsers }}
          </div>
          <div class="vac-room-options-container">
            <!-- <pre>{{ room.unreadCount }}</pre> -->
            <div
              v-if="room.unreadCount > 0"
              class="vac-badge-counter vac-room-badge"
              :style="
                room.lastMessage.content.includes(`<usertag>${room.instance.phone_number}</usertag>`)
                  ? 'height: 18px;'
                  : 'height: 13px;'
              "
            >
              <!-- :style="room.instance && room.instance.color ? `background: ${room.instance.color}; color: ${room.instance.color}` : ''" -->
              <!-- <v-icon
                color="primary"
                size="18"
              >
                {{ icons.mdiAsterisk }}
              </v-icon> -->
              <!-- {{ room.lastMessage.content }} -->
              <div v-if="room.lastMessage.content.includes(`<usertag>${room.instance.phone_number}</usertag>`)">@</div>
              <!-- <span class="primary--text"></span> -->
            </div>

            <div v-if="room && room.pinned" class="vac-badge-counter">
              <v-icon>
                {{ icons.mdiPin }}
              </v-icon>
            </div>
            <slot name="room-list-options" v-bind="{ room }">
              <!-- <pre>{{roomActions}}</pre> -->
              <div
                v-if="newRoomActions.length"
                class="vac-svg-button vac-list-room-options"
                ref="triggerIcon"
                @click.stop="openMenu(room)"
              >
                <slot name="room-list-options-icon">
                  <svg-icon name="dropdown" param="room" />
                </slot>
              </div>
              <transition v-if="newRoomActions.length" name="vac-slide-left">
                <div
                  v-if="indexRoom ? roomMenuOpen === indexRoom : roomMenuOpen === room.roomId"
                  :style="triggerRect.top > viewHeight / 2 ? { top: `${-90}px` } : { top: `${10}px` }"
                  v-click-outside="closeRoomMenu"
                  class="vac-menu-options"
                >
                  <div class="vac-menu-list">
                    <div v-for="action in newRoomActions" :key="action.name">
                      <div class="vac-menu-item" @click.stop="roomActionHandler(action)">
                        {{ action.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </slot>
          </div>
        </div>
        <div class="d-flex">
          <div
            class="room-contact-name font-weight-black me-1 mb-1 mt-1"
            :style="`color: ${room.instance.color}; font-size: 12px;`"
            @click.stop="filterLabel(room.instance._id, true)"
          >
            {{
              (room &&
              room.instance &&
              room.instance.label &&
              (room.instance.label.includes('-') ||
                room.instance.label.includes('Default') ||
                room.instance.label.includes('62'))
                ? room.instance.label_server
                : room.instance.expand &&
                  room.instance.expand.instance_label_name &&
                  room.instance.expand.instance_label_name.edited_name
                ? room.instance.expand.instance_label_name.edited_name
                : room.instance.label_server
              )+((room.hashtag && room.hashtag.length > 0)?` (${room.hashtag.join(", ")})`:"")
            }}
          </div>
          <div v-if="room.labels && room.labels.length !== 0" class="mr-1">
            <v-tooltip v-for="(label, index) in room.labels" :key="index" top>
              <template v-slot:activator="{ on, attrs }" v-if="!label.hide">
                <v-icon
                  fab
                  size="15px"
                  class="room-labels"
                  v-bind="attrs"
                  :color="label.color"
                  v-on="on"
                  @click.stop="filterLabel(label.id)"
                >
                  <!-- @click="goToLabel()" -->
                  {{ icons.mdiLabel }}
                </v-icon>
              </template>
              <span>Label Name : {{ label.name }}</span>
            </v-tooltip>
          </div>
          <div v-if="room.roomContactListLabel && room.roomContactListLabel.length > 0" class="mr-1">
            <v-tooltip v-for="(label, index) in room.roomContactListLabel" :key="index" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  fab
                  size="15px"
                  class="room-labels"
                  v-bind="attrs"
                  :color="label.label_color"
                  v-on="on"
                  @click.stop="filterLabel(label.list_id)"
                >
                  <!-- @click="goToContacts(label)" -->
                  {{ icons.mdiLabelVariant }}
                </v-icon>
              </template>
              <span>Customer Group : {{ label.list_name }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import { mdiAsterisk, mdiImage, mdiLabel, mdiLabelVariant, mdiMapMarker, mdiPin, mdiTextBox, mdiVideo } from '@mdi/js'
import vClickOutside from 'v-click-outside'
import FormatMessage from '../../../components/FormatMessage/FormatMessage'
import SvgIcon from '../../../components/SvgIcon/SvgIcon'

import typingText from '../../../utils/typing-text'

const { isAudioFile, isVideoFile, isImageFile } = require('../../../utils/media-file')

export default {
  name: 'RoomsContent',
  components: {
    SvgIcon,
    FormatMessage,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    currentUserId: { type: [String, Number], required: true },
    room: { type: Object, required: true },
    textFormatting: { type: Object, required: true },
    linkOptions: { type: Object, required: true },
    textMessages: { type: Object, required: true },
    roomActions: { type: Array, required: true },
    allContacts: { type: Array, required: true },
    roomMenuOpen: { type: String },
    indexRoom: { type: String },
  },

  data() {
    return {
      roomMenuOpened: null,
      icons: {
        mdiAsterisk,
        mdiLabel,
        mdiLabelVariant,
        mdiPin,
        mdiMapMarker,
        mdiVideo,
        mdiImage,
        mdiTextBox,
      },
      triggerRect: 0,
      viewHeight: 0,
      // roomMenuOpen: '',
    }
  },

  computed: {
    newRoomActions() {
      if (this.indexRoom) return []
      if (String(this.room.phone_number).length > 16) {
        return [
          {
            name: 'deleteRoom',
            title: `${this.$t('inbox.deleteRoom')}`,
          },
        ]
      }
      return this.roomActions
    },
    allContactData() {
      return this.$store.getters['global/getAllContactData']
    },
    getLastMessage() {
      const isTyping = this.typingUsers
      if (isTyping) return isTyping

      const content = this.room.lastMessage.deleted ? this.textMessages.MESSAGE_DELETED : this.room.lastMessage.content

      if (String(this.room.phone_number).length < 16) {
        return content
      }

      if (this.room.instance.phone_number === this.room.lastMessage.sender_id) {
        return content
      }

      const user = this.room.users.find(user => user._id == this.room.lastMessage.senderId)

      if (this.room.lastMessage.username) {
        return `${this.getContactsName(this.room.lastMessage.sender_id, this.room)}: ${content}`
      }

      if (!user || user._id === this.currentUserId) {
        return content
      }

      return `${this.getContactsName(user._id, this.room)} - ${content}`
    },
    userStatus() {
      if (!this.room.users || this.room.users.length !== 2) return

      const user = this.room.users.find(u => u._id !== this.currentUserId)
      if (user && user.status) return user.status.state

      return null
    },
    typingUsers() {
      return typingText(this.room, this.currentUserId, this.textMessages)
    },
    isMessageCheckmarkVisible() {
      return (
        !this.typingUsers &&
        this.room.lastMessage &&
        !this.room.lastMessage.deleted &&
        this.room.lastMessage.senderId === this.currentUserId &&
        (this.room.lastMessage.saved || this.room.lastMessage.distributed || this.room.lastMessage.seen)
      )
    },
    formattedDuration() {
      const file = this.room.lastMessage.files[0]

      if (!file.duration) {
        return `${file.name}.${file.extension}`
      }

      let s = Math.floor(file.duration)

      return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s
    },
    isAudio() {
      return this.room.lastMessage.files ? isAudioFile(this.room.lastMessage.files[0]) : false
    },
    isVideo() {
      return this.room.lastMessage.files ? isVideoFile(this.room.lastMessage.files[0]) : false
    },
    isImage() {
      return this.room.lastMessage.files ? isImageFile(this.room.lastMessage.files[0]) : false
    },
  },

  emits: ['room-action-handler', 'filtering-labels', 'open-menu-room'],

  // watch: {
  //   roomMenuOpen(val) {
  //     if(val) {
  //       this.openTransition = true
  //     }
  //   }
  // },

  methods: {
    roomActionHandler(action) {
      this.closeRoomMenu()
      this.$emit('room-action-handler', { action, roomId: this.room.roomId, data: this.room })
    },
    async openMenu(room) {
      console.log(room, 'ini <')
      this.triggerRect = this.$refs.triggerIcon.getBoundingClientRect()
      this.viewHeight = window.innerHeight
      this.$emit('open-menu-room', this.indexRoom ? this.indexRoom : room.roomId)
      // this.roomMenuOpen = room.roomId
    },
    closeRoomMenu() {
      this.$emit('open-menu-room', '')
      this.roomMenuOpened = null
    },
    getContactsName(phoneNumber, room) {
      const roomContact = this.allContactData.filter(user => user.phone_number == phoneNumber)
      const roomUser = room.users.find(user => user._id == phoneNumber)

      if (this.allContacts) {
        const foundContact = this.allContacts.find(x => x.phone_number === phoneNumber)

        // //console.log(foundContact, `INI KONTAK LIST TABLE`)
        if (foundContact) {
          return foundContact.name
        }
      }

      // if (this.couchAllContact) {
      //   const foundContact = this.couchAllContact.find(x => x.phone_number === phoneNumber)

      //   // //console.log(foundContact, `INI KONTAK COUCH`)

      //   if (foundContact) {
      //     return foundContact.name
      //   }
      // }

      if (roomContact) {
        if (roomContact.bailey_name) {
          return roomContact.bailey_name
        }

        if (roomContact.notify_name) {
          return roomContact.notify_name
        }

        if (roomContact.contact_list_name) {
          return roomContact.contact_list_name
        }

        if (roomContact.username) {
          return roomContact.username
        }
      }

      if (roomUser) {
        if (roomUser.username !== phoneNumber) {
          return roomUser.username
        }
      }

      // if (roomContact) {
      //   if (roomContact.bailey_name) {
      //     return roomContact.bailey_name
      //   }

      //   if (roomContact.notify_name) {
      //     return roomContact.notify_name
      //   }

      //   if (!roomContact.notify_name && !roomContact.bailey_name) {
      //     // //console.log(roomContact, `INI ROOM KONTAK`)
      //     const foundContact = this.allContacts.find(contact => contact.phone_number === roomContact._id)
      //     if (foundContact) {
      //       return foundContact.name
      //     }

      //     if (roomContact.username.replace(/[+ -]/g, '') !== roomContact._id) {
      //       return roomContact.username
      //     }
      //   }
      // }

      return this.formatPhoneNumber(phoneNumber)

      // return roomContact && roomContact.username ? roomContact.username : phoneNumber
    },
    formatPhoneNumber(phoneNumber, group = false) {
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      } else if (group) {
        phoneNumber = 'Group'
      }

      return phoneNumber
    },
    filterLabel(label, fromChannel) {
      //console.log(label, 'ini labz')
      if (label) {
        this.$emit('filtering-labels', { label, fromChannel })
      }
    },
  },
}
</script>

<style scoped>
.vac-avatar-frame {
  height: 52px;
  width: 52px;
  min-height: 52px;
  min-width: 52px;
  margin-right: 15px;
  border-radius: 50%;
  margin-bottom: -1px;
}
.unread-chat {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}
/* .room-contact-name:hover{
  transform: scale(1.1);
  background-color: lightseagreen;
}
.room-labels:hover {
  background-color: lightseagreen;
} */
</style>
