<template>
  <div class="vac-card-window" :style="[{ height }, cssVars]">
    <div class="vac-chat-container">
      <!-- room list =>{{ showRoomsList }} room info  =>{{ roomInfoEnabled }} -->
      <rooms-list
        v-if="!singleRoom"
        :current-user-id="currentUserId"
        :rooms="orderedRooms"
        :loading-rooms="loadingRooms"
        :rooms-loaded="roomsLoaded"
        :room="room"
        :room-info-enabled="roomInfoEnabled"
        :all-contacts="allContacts"
        :on-chat-menu="onChatMenu"
        :selected-labels="selectedLabels"
        :selected-contact-groups="selectedContactGroups"
        :selected-channel="selectedChannel"
        :sort-setting="sortSetting"
        :filter-arr="filterArr"
        :rooms-chats="roomsChats"
        :mapped-rooms="mappedRooms"
        :rooms-messages="roomsMessages"
        :rooms-contacts="roomsContacts"
        :contact-groups="contactGroups"
        :realtime-status="realtimeStatus"
        :loading-check-rooms="loadingCheckRooms"
        :is-floating="isFloating"
        :room-actions="roomActions"
        :text-messages="t"
        :show-search="showSearch"
        :show-add-room="showAddRoom"
        :show-rooms-list="showRoomsList"
        :text-formatting="textFormatting"
        :link-options="linkOptions"
        :is-mobile="isMobile"
        :scroll-distance="scrollDistance"
        :is-on-filter="isOnFilter"
        :loading-search="loadingSearch"
        :no-room-search="noRoomSearch"
        :is-not-valid-search="isNotValidSearch"
        :filtered-form="filteredForm"
        :open-add-room="openAddRoom"
        :assignment="assignment"
        :mapping-id="mappingId"
        @change-room-id="changeRoomId"
        @fetch-room="fetchRoom"
        @room-info="roomInfo"
        @open-user-tag="openUserTag"
        @fetch-more-rooms="fetchMoreRooms"
        @fetch-more-search="$emit('fetch-more-search')"
        @loading-more-rooms="loadingMoreRooms = $event"
        @add-room="addRoom($event)"
        @room-action-handler="roomActionHandler"
        @update-room="updateRoom"
        @filter-rooms="$emit('filter-rooms')"
        @search-rooms="$emit('search-rooms', $event)"
        @open-config="$emit('open-config', $event)"
        @open-filter="$emit('open-filter', $event)"
        @clear-filter="$emit('clear-filter', $event)"
        @close-room-info="closeRoomInfo"
        @filtering-labels="filteringLabels"
      >
        <template v-for="(i, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </rooms-list>

      <room
        :current-user-id="currentUserId"
        :rooms="[...mappedRooms, ...rooms]"
        :rooms-messages="roomsMessages"
        :load-send-message="loadSendMessage"
        :all-contacts="allContacts"
        :room-id="roomId || ''"
        :is-floating="isFloating"
        :load-first-room="loadFirstRoom"
        :messages="messages"
        :rooms-chats="roomsChats"
        :room-message="roomMessage"
        :messages-loaded="messagesLoaded"
        :menu-actions="menuActions"
        :message-actions="messageActions"
        :show-send-icon="showSendIcon"
        :show-files="showFiles"
        :show-audio="showAudio"
        :show-checkbox="showCheckbox"
        :audio-bit-rate="audioBitRate"
        :audio-sample-rate="audioSampleRate"
        :show-emojis="showEmojis"
        :show-reaction-emojis="showReactionEmojis"
        :show-new-messages-divider="showNewMessagesDivider"
        :show-footer="showFooter"
        :text-messages="t"
        :single-room="singleRoom"
        :show-rooms-list="showRoomsList"
        :text-formatting="textFormatting"
        :link-options="linkOptions"
        :is-mobile="isMobile"
        :loading-rooms="loadingRooms"
        :room-info-enabled="roomInfoEnabled"
        :textarea-action-enabled="textareaActionEnabled"
        :scroll-distance="scrollDistance"
        :accepted-files="acceptedFiles"
        :templates-text="templatesText"
        :quick-replies="quickReplies"
        :index-message-search="indexMessageSearch"
        :index-message-hashtag="indexMessageHashtag"
        :mapping-id="mappingId"
        @toggle-rooms-list="toggleRoomsList"
        @room-info="roomInfo"
        @fetch-messages="fetchMessages"
        @send-message="sendMessage"
        @edit-message="editMessage"
        @delete-message="deleteMessage"
        @open-file="openFile"
        @open-user-tag="openUserTag"
        @update-room="$emit('update-room', $event)"
        @menu-action-handler="menuActionHandler"
        @message-action-handler="messageActionHandler"
        @assign-chat="$emit('assign-chat', $event)"
        @assign-chat-dropdown="$emit('assign-chat-dropdown', $event)"
        @send-message-reaction="sendMessageReaction"
        @typing-message="typingMessage"
        @textarea-action-handler="textareaActionHandler"
        @send-quick-reply="$emit('send-quick-reply', $event)"
        @select-messages-action-handler="selectMessagesActionHandler"
        @show-dialog-quick-reply="showDialogQuickReply"
      >
        <template v-for="(i, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </room>
      <transition name="vac-fade-preview" appear>
        <v-dialog v-model="showMediaPreview" width="70%">
          <v-card class="mx-auto">
            <media-preview
              v-if="showMediaPreview"
              :file="previewFile"
              @close-media-preview="showMediaPreview = false"
            />
          </v-card>
        </v-dialog>
      </transition>
    </div>
  </div>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import _orderBy from 'lodash/orderBy'
import locales from '../locales'
import { cssThemeVars, defaultThemeStyles } from '../themes'
import MediaPreview from './MediaPreview/MediaPreview'
import Room from './Room/Room'
import RoomsList from './RoomsList/RoomsList'

const { roomsValidation, partcipantsValidation } = require('../utils/data-validation')

export default {
  name: 'ChatContainer',
  components: {
    RoomsList,
    Room,
    MediaPreview,
  },

  props: {
    height: { type: String, default: '600px' },
    theme: { type: String, default: 'light' },
    styles: { type: Object, default: () => ({}) },
    responsiveBreakpoint: { type: Number, default: 900 },
    singleRoom: { type: Boolean, default: false },
    loadSendMessage: { type: Boolean, default: false },
    roomsListOpened: { type: Boolean, default: true },
    textMessages: { type: Object, default: null },
    selectedContactGroups: { type: Array, default: () => [] },
    selectedLabels: { type: Array, default: () => [] },
    selectedChannel: { type: String, default: 'All' },
    sortSetting: { type: String, default: 'newest' },
    currentUserId: { type: [String, Number], default: '' },
    rooms: { type: Array, default: () => [] },
    roomsChats: { type: Array, default: () => [] },
    roomsMessages: { type: Array, default: () => [] },
    roomsContacts: { type: Array, default: () => [] },
    contactGroups: { type: Array, default: () => [] },
    roomsOrder: { type: String, default: 'desc' },
    loadingRooms: { type: Boolean, default: false },
    realtimeStatus: { type: Boolean, default: false },
    loadingCheckRooms: { type: Boolean, default: false },
    noRoomSearch: { type: Boolean, default: false },
    roomsLoaded: { type: Boolean, default: false },
    roomId: { type: [String, Number], default: null },
    loadFirstRoom: { type: Boolean, default: true },
    messages: { type: Array, default: () => [] },
    allContacts: { type: Array, default: () => [] },
    messagesLoaded: { type: Boolean, default: false },
    roomActions: { type: Array, default: () => [] },
    menuActions: { type: Array, default: () => [] },
    roomsMessages: { type: Array, default: () => [] },
    messageActions: {
      type: Array,
      default: () => [
        { name: 'replyMessage', title: 'Reply' },

        // { name: 'editMessage', title: 'Edit Message', onlyMe: true },
        { name: 'deleteMessage', title: 'Delete Message', onlyMe: true },
      ],
    },
    mediaPreviewEnabled: { type: Boolean, default: true },
    showSearch: { type: Boolean, default: true },
    showAddRoom: { type: Boolean, default: true },
    showSendIcon: { type: Boolean, default: true },
    showFiles: { type: Boolean, default: true },
    showAudio: { type: Boolean, default: true },
    audioBitRate: { type: Number, default: 128 },
    audioSampleRate: { type: Number, default: 44100 },
    showEmojis: { type: Boolean, default: true },
    onChatMenu: { type: Boolean, default: false },
    showReactionEmojis: { type: Boolean, default: true },
    showNewMessagesDivider: { type: Boolean, default: true },
    showFooter: { type: Boolean, default: true },
    textFormatting: {
      type: Object,
      default: () => ({
        disabled: false,
        italic: '_',
        bold: '*',
        strike: '~',
        underline: '°',
        multilineCode: '```',
        inlineCode: '`',
      }),
    },
    linkOptions: {
      type: Object,
      default: () => ({ disabled: false, target: '_blank', rel: null }),
    },
    isFloating: { type: Boolean, default: false },
    isOnFilter: { type: Boolean, default: false },
    textareaActionEnabled: { type: Boolean, default: false },
    roomMessage: { type: String, default: '' },
    scrollDistance: { type: Number, default: 60 },
    acceptedFiles: { type: String, default: '*' },
    templatesText: { type: Array, default: null },
    mappedRooms: { type: Array, default: () => [] },
    filterArr: { type: Array, default: () => [] },
    showCheckbox: { type: Boolean, default: false },
    quickReplies: { type: Array, default: null },
    loadingSearch: { type: Boolean, default: false },
    isNotValidSearch: { type: Boolean, default: false },
    indexMessageSearch: { type: Number, default: -1 },
    indexMessageHashtag: { type: Number, default: -1 },
    openAddRoom: { type: Boolean, default: false },
    assignment: { type: String, default: 'All' },
  },

  emits: [
    'toggle-rooms-list',
    'room-info',
    'fetch-messages',
    'send-message',
    'filter-rooms',
    'edit-message',
    'send-quick-reply',
    'delete-message',
    'open-file',
    'open-user-tag',
    'open-filter',
    'open-config',
    'menu-action-handler',
    'message-action-handler',
    'send-message-reaction',
    'typing-message',
    'textarea-action-handler',
    'fetch-more-rooms',
    'add-room',
    'room-action-handler',
    'update-room',
    'search-rooms',
    'clear-filter',
    'select-messages-action-handler',
    'filtering-labels',
    'show-dialog-quick-reply',
    'change-room-id',
  ],

  data() {
    return {
      room: {},
      orderedRooms: [],
      loadingMoreRooms: false,
      showRoomsList: true,
      showContactDetail: true,
      roomInfoEnabled: false,
      isMobile: false,
      showMediaPreview: false,
      previewFile: {},
      filteredForm: [],
      mappingId: '',
    }
  },

  computed: {
    t() {
      return {
        ...locales,
        ...this.textMessages,
      }
    },
    cssVars() {
      const defaultStyles = defaultThemeStyles[this.theme]
      const customStyles = {}

      Object.keys(defaultStyles).map(key => {
        customStyles[key] = {
          ...defaultStyles[key],
          ...(this.styles[key] || {}),
        }
      })

      return cssThemeVars(customStyles)
    },

    // orderedRooms() {
    //   // formattedRooms = _orderBy(formattedRooms, ['pinned', 'index'], ['desc', 'desc'])
    //   //console.log('🚀 ~ file: ChatWindow.vue ~ line 228 ~ orderedRooms ~ this.rooms', this.rooms)

    //   return _orderBy(this.rooms, ['pinned', 'last_message'], ['desc', 'desc'])

    //   // return this.rooms.slice().sort((a, b) => {
    //   //   const aVal = a.index || 0
    //   //   const bVal = b.index || 0

    //   //   if (this.roomsOrder === 'asc') {
    //   //     return aVal < bVal ? -1 : bVal < aVal ? 1 : 0
    //   //   }

    //   //   return aVal > bVal ? -1 : bVal > aVal ? 1 : 0
    //   // })
    // },
  },

  watch: {
    rooms: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal[0] || !newVal.find(room => room?.roomId === this.room?.roomId)) {
          this.showRoomsList = true
        }
        if (!this.loadingMoreRooms && this.loadFirstRoom && newVal[0] && (!oldVal || newVal.length !== oldVal.length)) {
          if (this.roomId) {
            const room = newVal.find(r => r.roomId === this.roomId) || {}
            if (room) this.fetchRoom({ room })
          } else if (!this.isMobile || this.singleRoom) {
            this.room = {}

            // this.fetchRoom({ room: this.orderedRooms[0] })
          } else {
            this.showRoomsList = true
          }
        }

        this.orderedRooms = _orderBy(newVal, ['pinned', 'index'], ['desc', 'desc'])

        // //console.log(
        //   '🚀 ~ file: ChatWindow.vue ~ line 270 ~ handler ~ this.orderedRooms',
        //   this.orderedRooms.find(el => el.name === 'SI BERAT👨‍👩‍👦‍👦'),
        //   newVal,
        // )
      },
    },

    loadingRooms(val) {
      if (val) this.room = {}
    },

    roomId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && !this.loadingRooms && this.rooms.length) {
          let room
          // room = this.rooms.find(r => {
          //   return r?.roomId === newVal
          // }) ||
          room =
            this.roomsMessages.find(r => {
              return r?.mappingId === newVal
            }) ||
            this.rooms.find(r => {
              return r?.roomId === newVal
            })

          // if (this.roomsMessages.length) {
          //   room = this.roomsMessages.find(r => {
          //     return r?.mappingId === newVal
          //   })
          // } else {
          //   room = this.rooms.find(r => {
          //     return r?.roomId === newVal
          //   })
          // }
          // console.log(room, 'coi<')
          if (room) this.fetchRoom({ room })

          // //console.log('🚀 ~ file: ChatWindow.vue ~ line 298 ~ handler ~ room', room)
        } else if (oldVal && !newVal) {
          this.room = {}
        }
      },
    },

    room(val) {
      if (!val || Object.entries(val).length === 0) return

      roomsValidation(val)

      val.users.forEach(user => {
        if (!user.username && val.phone_number.length < 16) user.username = val.roomName
        partcipantsValidation(user)
      })

      // //console.log('🚀 ~ file: ChatWindow.vue ~ line 357 ~ room ~ al.users', val.users)
    },

    roomsListOpened(val) {
      this.showRoomsList = val
    },
  },

  created() {
    this.updateResponsive()
    window.addEventListener('resize', ev => {
      if (ev.isTrusted) this.updateResponsive()
    })
  },

  methods: {
    closeRoomInfo() {
      this.roomInfoEnabled = false
      this.showRoomsList = true
    },
    changeRoomId(data) {
      this.$emit('change-room-id', data)
    },
    updateResponsive() {
      if (this.isFloating) {
        this.isMobile = true
      } else {
        this.isMobile = window.innerWidth < this.responsiveBreakpoint
      }
    },
    toggleRoomsList() {
      this.showRoomsList = !this.showRoomsList
      if (this.isMobile) this.room = {}
      this.$emit('toggle-rooms-list', { opened: this.showRoomsList })
      this.roomInfoEnabled = false
    },
    fetchRoom({ room, mappingId, fromRoomsMessages }) {
      this.room = room
      // console.log({ mappingId, fromRoomsMessages }, this.mappingId, '<<viraaa fetch fetch')
      if (fromRoomsMessages && mappingId) {
        this.mappingId = mappingId
      } else if (!this.mappingId && !mappingId && !fromRoomsMessages) {
        this.mappingId = ''
      } else if (fromRoomsMessages === false) {
        this.mappingId = ''
      }
      if (!this.$route.query.source_id) {
        let fromRoomsMessages = false
        if (room.mappingId !== undefined) fromRoomsMessages = true
        this.fetchMessages({ reset: true, fromRoomsMessages })
      }
      if (this.isMobile) this.showRoomsList = false
    },
    fetchMoreRooms() {
      this.$emit('fetch-more-rooms')
    },
    async roomInfo() {
      // if (this.isMobile) this.room = {}
      this.roomInfoEnabled = !this.roomInfoEnabled
      this.$emit('room-info', { room: this.room, opened: this.roomInfoEnabled })
      // this.showRoomsList = !this.roomInfoEnabled
      await this.fetchContactForm()
    },
    async fetchContactForm() {
      let filterForm
      if (this.room.labels.length) {
        filterForm = await clientPocketBase.collection('crm_form_response').getFullList(200, {
          filter: `contacts = '${this.room.labels[0].contacts}'`,
          expand: 'form, contacts',
          sort: 'updated',
          $autoCancel: false,
        })
        this.filteredForm = filterForm
      } else {
        this.filteredForm = []
      }
    },
    addRoom(data) {
      this.$emit('add-room', data)
    },
    fetchMessages(options) {
      this.$emit('fetch-messages', { room: this.room, options })
    },
    updateRoom(room) {
      // //console.log(room,'ini vua')
      this.$emit('update-room', room)
    },
    sendMessage(message) {
      this.$emit('send-message', { ...message, roomId: this.room.roomId, room: this.room })
    },
    editMessage(message) {
      this.$emit('edit-message', { ...message, roomId: this.room.roomId })
    },
    deleteMessage(message) {
      this.$emit('delete-message', { message, roomId: this.room.roomId, roomOwnerId: this.room.instance.phone_number })
    },
    openFile({ message, file }) {
      if (this.mediaPreviewEnabled && file.action === 'preview') {
        this.previewFile = file.file
        this.showMediaPreview = true
      } else {
        this.$emit('open-file', { message, file })
      }
    },
    openUserTag(user) {
      this.$emit('open-user-tag', user)

      // //console.log(this.orderedRooms, '<<<chatwindow tag roomlist')
      const room = this.orderedRooms.find(contact => user._id.indexOf(contact.phone_number) !== -1)

      // //console.log(room, '<<<chatwindow tag')
      this.fetchRoom({ room })
    },
    menuActionHandler(ev) {
      this.$emit('menu-action-handler', {
        action: ev,
        roomId: this.room.roomId,
      })
    },
    roomActionHandler({ action, roomId, data }) {
      this.$emit('room-action-handler', {
        action,
        roomId,
        data,
      })
    },
    messageActionHandler(ev) {
      this.$emit('message-action-handler', {
        ...ev,
        roomId: this.room.roomId,
        roomOwnerId: this.room.instance.phone_number,
      })
    },
    sendMessageReaction(messageReaction) {
      this.$emit('send-message-reaction', {
        ...messageReaction,
        roomId: this.room.roomId,
      })
    },
    typingMessage(message) {
      this.$emit('typing-message', {
        message,
        roomId: this.room.roomId,
      })
    },
    textareaActionHandler(message) {
      this.$emit('textarea-action-handler', {
        message,
        roomId: this.room.roomId,
      })
    },
    selectMessagesActionHandler(ev) {
      this.$emit('select-messages-action-handler', {
        ...ev,
        roomId: this.room.roomId,
      })
    },
    filteringLabels({ label, fromChannel }) {
      this.$emit('filtering-labels', { label, fromChannel })
    },
    showDialogQuickReply() {
      this.$emit('show-dialog-quick-reply')
    },
  },
}
</script>

<style lang="scss">
@import '../styles/index.scss';
</style>
