var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vac-message-file-container"},[(_vm.isImage)?_c('div',{ref:'imageRef' + _vm.index,staticClass:"vac-message-image-container",on:{"mouseover":function($event){_vm.imageHover = true},"mouseleave":function($event){_vm.imageHover = false},"click":function($event){$event.stopPropagation();return _vm.openFile('preview')}}},[(_vm.file.progress < 100)?_c('progress-bar',{style:({ top: ((_vm.imageResponsive.loaderTop) + "px") }),attrs:{"progress":_vm.file.progress}}):_c('loader',{attrs:{"show":_vm.isImageLoading,"from-message-file":true},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(idx,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)}),(_vm.message.data.type !== 'document')?_c('div',{staticClass:"vac-message-image",class:{
        'vac-image-loading':
          _vm.isImageLoading && _vm.message.senderId === _vm.currentUserId
      }},[(_vm.message.data.type !== 'document')?_c('img',{class:{
          'loader-message-files': _vm.isImageLoading,
          'not-loading':!_vm.isImageLoading
        },attrs:{"aspect-ratio":16/9,"size":"250px","src":_vm.file.url},on:{"error":_vm.onImageError}}):_vm._e(),_c('transition',{attrs:{"name":"vac-fade-image"}},[(_vm.imageHover && !_vm.isImageLoading)?_c('div',{staticClass:"vac-image-buttons"},[(_vm.isFileValid)?_c('div',{staticClass:"vac-svg-button vac-button-view",on:{"click":function($event){$event.stopPropagation();return _vm.openFile('preview')}}},[_vm._t("eye-icon",function(){return [_c('svg-icon',{attrs:{"name":"eye"}})]})],2):_vm._e(),(_vm.isFileValid)?_c('div',{staticClass:"vac-svg-button vac-button-download",on:{"click":function($event){$event.stopPropagation();return _vm.openFile('download')}}},[_vm._t("document-icon",function(){return [_c('svg-icon',{attrs:{"name":"document"}})]})],2):_vm._e()]):_vm._e()])],1):_c('div',{style:({
        // 'background-image': `url('${
        //   isImageLoading ? file.preview || file.url : file.url
        // }')`,
        'border': '1px solid #1b1c21',
        'border-radius': '4px',
        'padding': '8px',
        'max-height': ((_vm.imageResponsive.maxHeight) + "px")
      })},[_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"me-4 mx-auto",attrs:{"size":"32px","color":"#1F979E"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocument)+" ")]),_c('span',{staticClass:"text--white my-auto font-weight-bold mx-auto",staticStyle:{"text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"}},[_vm._v(_vm._s(_vm.file.name))])],1)])],1):(_vm.isVideo)?_c('div',{staticClass:"vac-video-container",on:{"click":function($event){_vm.videoClicked = true}}},[(!_vm.videoClicked)?_c('div',{staticClass:"d-flex",staticStyle:{"background":"#000000","height":"150px !important"},on:{"click":function($event){_vm.videoClicked = true}}},[_c('v-icon',{staticClass:"mx-auto my-auto text-center",attrs:{"size":"50px","color":"#FFFFFF"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlay)+" ")])],1):_c('div',[_c('video',{staticStyle:{"max-height":"200px"},attrs:{"width":"100%","height":"100%","controls":"","autoplay":""}},[_c('source',{attrs:{"src":_vm.file.url}})])])]):(_vm.isAudio)?_c('div',{staticClass:"vac-video-container"},[_c('audio',{staticStyle:{"max-height":"200px"},attrs:{"width":"100%","height":"100%","controls":"","src":_vm.file.url}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }