<template>
  <div>
    <v-snackbar v-model="alertDialog" color="error" :timeout="2000" :top="true" style="z-index: 1 !important">
      {{ alertText }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="alertDialog = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="my-5 px-3 pt-5">
      <v-row>
        <v-chip color="#EEF1F4" class="ml-3">
          <span style="color: #1f979e">{{ $t('broadcasts.selected') }} contacts : {{ dataContacts.length }}</span>
        </v-chip>
        <v-spacer></v-spacer>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="searchDetail"
            :append-icon="icons.mdiMagnify"
            :label="$t('broadcasts.search')"
            single-line
            hide-details
            dense
            outlined
            class="mr-1 mb-1"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <v-card elevation="0">
      <!-- <pre>{{ dataContacts }}</pre> -->
      <v-data-table
        v-model="dataContacts"
        :headers="showListContact ? headerContactList : headerContact"
        :items="detailListContact"
        :search="searchDetail"
        :server-items-length="totalContacts"
        loading-text="Loading... Please wait"
        :options.sync="options"
        item-key="id"
        :show-select="(!fromContacts && !fromList) || isDraft"
        :items-per-page="5"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPageLabel'),
          'items-per-page-options': [5, 10, 30, 50, 100],
        }"
        class="table-broadcast"
        checkbox-color="#111b21"
      >
        <template slot="no-data">
          {{ $t('noDataLabel') }}
        </template>
        <template #[`item.no`]="{ item }">
          {{ item.no.length > 16 ? 'Group' : item.no }}
        </template>
        <template #[`item.custom`]="{ item }">
          <span v-html="item.custom"> </span>
        </template>
        <template #[`item.name`]="{ item }">
          <div class="display-lg align-center">
            <v-avatar v-if="item ? (item.profile_picture ? true : false) : false" size="40">
              <v-img :src="item.profile_picture || ''" height="40" width="40"> </v-img>
            </v-avatar>
            <div class="display-lg flex-column ms-3 display-mt-lg">
              <span class="d-block font-weight-semibold text-truncate text--primary sm-text">{{ item.name }}</span>
              <!-- <span class="text-xs">{{ item.phone_number }}</span> -->
            </div>
          </div>
        </template>
        <template #[`item.created_at`]="{ item }">
          {{ $moment(item.created_at).format('DD-MM-YYYY') }}
        </template>
        <template #[`item.updated_at`]="{ item }">
          {{ $moment(item.updated_at).format('DD-MM-YYYY') }}
        </template>
        <template #[`item.Media`]="{ item }">
          <v-img :src="item.Media" height="50" width="50"> </v-img>
        </template>
      </v-data-table>
    </v-card>
    <v-row class="d-flex justify-end text-end">
      <v-col cols="12">
        <!-- <v-btn
            color="error"
            class="mr-3"
            @click=" "
          >
            {{ $t('cancel') }}
          </v-btn> -->
        <v-btn
          :block="$vuetify.breakpoint.mdAndDown ? true : false"
          color="success"
          class="me-2"
          :class="$vuetify.breakpoint.mdAndDown ? 'mb-2' : ''"
          @click="complete()"
        >
          send
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import messageTemplate from '@/components/dialog/broadcast/message_template.vue'
import { default as clientPocketBase } from '@/helpers/pocketbase'
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiCalendarMonth, mdiCircle, mdiClockTimeEight, mdiDownload, mdiInformation, mdiMagnify } from '@mdi/js'
import _uniqBy from 'lodash/uniqBy'
import { adjectives, colors, uniqueNamesGenerator } from 'unique-names-generator'
import firestoreDbMixin from '../../mixins/firestoreDbMixin'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import PreviewMessage from './PreviewMessage.vue'

export default {
  components: { PreviewMessage, messageTemplate },
  setup() {
    const { isDark } = useAppConfig()

    return {
      icons: { mdiCalendarMonth, mdiCircle, mdiDownload, mdiClockTimeEight, mdiMagnify, mdiInformation },
      isDark,
    }
  },
  mixins: [pouchDbMixin, firestoreDbMixin],
  props: {
    fromContacts: { type: Boolean, default: false },
    fromList: { type: Boolean, default: false },
    contactsFromWa: { type: Array, default: () => [] },
    listSelected: { type: Array, default: () => [] },
    isDraft: { type: Boolean, default: false },
    broadcastData: { type: Object, default: () => {} },
    dataContactsFromApi: { type: Object, default: () => {} },
    showCheckbox: { type: Boolean, default: false },
    totalContacts: { type: Number, default: 0 },
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      // select channel
      dialogSelectChannels: false,

      // add broadcast
      changeMessage: false,
      loadingText: '',
      showMenuDateTime: false,
      alertDialog: false,
      alertText: '',
      datePicker: '',
      timePicker: '',
      menuTimePicker: false,
      listInstance: [],
      listTemplates: [],
      executionType: { text: 'Immediately', value: 'immediately' },
      channel: 0,
      channelList: [],
      messageTemplate: 0,
      messageTemplateList: [],
      broadcastName: uniqueNamesGenerator({
        dictionaries: [adjectives, colors],
        style: 'capital',
        separator: ' ',
      }),
      detailListContact: [],
      headerContact: [],
      headerContactList: [],

      dataContacts: [],

      loadingDialog: false,
      delayType: 'fast',
      delayMin: 0,
      delayMax: 0,
      stopAfter: 0,
      additionalDelay: 0,

      messagePreview: { message: '', attachments: [] },
      trackLink: false,
      unsubscribe: false,
      execTypeOptions: [],
      contactsList: {},
      sourceContact: 'Whatsapp',
      selectedListContacts: [],
      listContactsItem: [],
      showListContact: false,
      duplicateContactCount: 0,
      unsubscribeContactCount: 0,
      localExtraFieldContainer: [],
      broadcastContactsTemp: {},
      broadcastMode: { text: 'Safe', value: 'safe' },
      broadcastModeOptions: [],
      dataExtraFieldProps: {},
      options: {},
      searchDetail: '',
    }
  },
  computed: {
    allContactData() {
      return this.$store.getters['global/getAllContactData']
    },
    screenSize() {
      return this.isMini()
    },
    forwardMessages() {
      return this.$store.getters['broadcast/getForwardMessages']
    },
    localContacts() {
      return this.dataContactsFromApi && this.dataContactsFromApi.status
        ? this.dataContactsFromApi.data.contacts.Whatsapp
        : []
    },
  },

  async mounted() {
    if (this.forwardMessages) {
      this.changeMessage = true
      // this.alertDialog = true
    }
    // if(this.alertDialog == false ){
    //   this.changeMessage =  false
    // }
    // //console.log(this.$vuetify, `INI VUE`)

    this.loadingText = this.$t('broadcasts.checkChannels')
    this.loadingDialog = true

    this.headerContact = [
      { text: this.$t('phoneNumber'), value: 'no', filterable: true },
      { text: this.$t('name'), value: 'name', filterable: true },

      // { text: 'Has Wa', value: 'has_wa' },
      { text: 'Contact', value: 'contact_source', filterable: true },
    ]
    this.headerContactList = [
      { text: this.$t('phoneNumber'), value: 'no', sortable: true },
      { text: this.$t('name'), value: 'name', sortable: true },

      { text: 'Has Wa', value: 'has_wa' },
      { text: 'Custom Attributes', value: 'custom', sortable: false },
      { text: 'Created At', value: 'created_at', sortable: true },
      { text: 'Updated At', value: 'updated_at', sortable: false },
    ]
    this.executionType = { text: this.$t('broadcasts.immediately'), value: 'immediately' }
    this.messagePreview = { message: this.$t('broadcasts.chooseTemplateFirst'), attachments: [] }

    this.execTypeOptions = [
      { text: this.$t('broadcasts.immediately'), value: 'immediately' },
      { text: this.$t('broadcasts.datetime'), value: 'datetime' },
      { text: this.$t('broadcasts.today08am'), value: '08:00' },
      { text: this.$t('broadcasts.noon01pm'), value: '13:00' },
      { text: this.$t('broadcasts.tonight7pm'), value: '19:00' },
    ]

    this.broadcastModeOptions = [
      { text: 'Aggressive', value: 'aggressive' },
      { text: 'Safe', value: 'safe' },
    ]

    // await this.connectSettings(true, false)
    // const settings = await this.mixinLoadSettingsRemote()
    // const firestoreSettings = await this.fdbGetSettings(this.user.sub_id)
    const listInstances = await clientPocketBase.collection('crm_instances').getFullList(400)
    if (listInstances?.length > 0) {
      // //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1465 ~ mounted ~ listInstances', listInstances)
      this.listInstance = listInstances.map(instance => {
        instance.is_loading = false

        return instance
      })
    }
    // this.listInstance = settings.find(x => x._id == 'instances').value

    if (this.listInstance.length === 0) {
      this.alertDialog = true
      this.alertText = 'No Channels'
      this.$store.dispatch('link/sendLinkToBroadcast', '')
      this.$emit('close-dialog')
    }
    for (let idx = 0; idx < this.listInstance.length; idx++) {
      const el = this.listInstance[idx]
      //console.log('🚀 ~ file: ForwardMessage.vue ~ line 303 ~ mounted ~ el', el)
      let channelStatus = await this.$store.dispatch('broadcast/getChannelStatus', `${el.sub_id}-${el._id}`)
      // //console.log(`${el.sub_id}-${el.instance_id}`);
      // //console.log(el, 'el === label')
      if (!el.fs_id || !el.access_token) {
        let retData = {
          text:
            el.label === '-' || el.label.includes('Default 62') || el.label.includes('62') ? el.label_server : el.label,
          channelStatus: channelStatus,
          value: idx,
          ...el,
        }
        this.channelList.push(retData)
      }
    }
    //  = this.listInstance.map((el, idx) => {
    //   // let statusChannel = await
    //   //console.log(idx, el);
    //   let retData = {
    //     text: el.label,
    //     channelStatus: el.status,
    //     value: idx,
    //   }
    //   return retData
    // }

    // get contact
    this.loadingText = this.$t('broadcasts.getContacts')
    let contacts = []
    let contactsList = []
    const tempContactListValue = []
    const dataContactsFromApi = await this.$store.dispatch('broadcast/getContacts', {
      subId: this.user.sub_id,
      token: this.user.token,
    })
    // if(!this.dataContactsFromApi) this.dataContactsFromApi = dataContactsFromApi

    if (!this.fromContacts && !this.fromList) {
      // if(this.fromList) {
      //   this.broadcastContactsTemp = { ...dataContactsFromApi.data.contacts}
      // } else {
      // }
      this.broadcastContactsTemp = { ...this.dataContactsFromApi.data.contacts }
      if (this.dataContactsFromApi.statusCode === 401) {
        this.$router.replace({ name: 'login' })
      }

      //console.log(
      //   '🚀 ~ file: ForwardMessage.vue ~ line 352 ~ mounted ~ this.dataContactsFromApi',
      //   this.dataContactsFromApi,
      // )
      if (this.dataContactsFromApi.status) {
        const tempContacts = this.dataContactsFromApi.data.contacts
        tempContacts.Whatsapp = tempContacts.Whatsapp.filter(el => el.phone_number !== 'status')
        contacts = tempContacts.Whatsapp.map(el => {
          el.no = el.phone_number.length > 16 ? 'Group' : el.phone_number
          el.contact_source = 'Whatsapp'

          return el
        })
        contactsList = []
        let totalCustomer = 0
        this.dataContactsFromApi.data.contacts_list.forEach(el => {
          tempContactListValue.push(el.id)
          // //console.log(el.list_name)

          if (el.list_name !== 'default') {
            contactsList.push({
              // 13/07/2022
              // dio mau ngubah yang awalnya semua default jadi all
              text: `${el.list_name} (${tempContacts[el.id].length})`,
              // dibawah sebelumnya masih default
              // text: `${!el.list_name ? 'All' : el.list_name} (${tempContacts[el.id].length})`,
              value: el.id,
            })
          }

          totalCustomer += Object.keys(tempContacts[el.id]).length
        })
        contactsList.unshift({
          text: `All Customer (${totalCustomer})`,
          value: 'all',
        })
      } else contacts = []
      this.listContactsItem = contactsList
      let tempContact = []
      // console.log(contacts, 'ini apa contacts coy')
      contacts.forEach(contact => {
        for (const instance of this.channelList) {
          if (contact.instance === this.$Md5Hash(instance._id)) {
            // //console.log('🚀 ~ file: ForwardMessage.vue ~ line 388 ~ mounted ~ instance', contact, instance)
            // const indexContact = this.allContactData.findIndex(
            //   contactData =>
            //     contactData.phone_number === contact.phone_number ||
            //     contactData.phone_number == contact['Phone Number'],
            //     )
            //     //console.log(indexContact)
            // if (indexContact > -1) {
            //   if (this.allContactData[indexContact].contact_list_name)
            //     contact.name = this.allContactData[indexContact].contact_list_name
            //   else if (this.allContactData[indexContact].bailey_name)
            //     contact.name = this.allContactData[indexContact].bailey_name
            //   else if (this.allContactData[indexContact].notify_name)
            //     contact.name = this.allContactData[indexContact].notify_name
            // }
            tempContact = contacts.filter(contact => this.$Md5Hash(instance._id) === contact.instance)
          }
        }
      })
      this.detailListContact = _uniqBy(tempContact, 'phone_number')
      //console.log(
      //   this.detailListContact.filter(room => room.no === 'status'),
      //   'ini from',
      // )
      // //console.log(this.detailListContact, ' == detailListContact')

      this.dataExtraFieldProps = this.dataContactsFromApi.data.extra_field
      // this.dataContacts = contacts
      this.loadingDialog = false
      this.dialogSelectChannels = true
      const text = this.listTemplates[this.messageTemplate]
      this.messagePreview = text
    } else {
      this.broadcastContactsTemp = { ...dataContactsFromApi.data.contacts }
    }

    if (this.fromContacts || this.fromList) {
      const tempContactFrom = this.contactsFromWa.map(el => {
        el.no = el.phone_number.length > 16 ? 'Group' : el.phone_number
        el.contact_source = 'Whatsapp'
        return el
      })
      this.dataContacts = tempContactFrom
      if (!this.isDraft) this.detailListContact = tempContactFrom
    }
    if (this.fromList) {
      this.sourceContact = 'Custom'
      this.selectedListContacts = this.listSelected

      this.showContactInTable()
      this.loadingDialog = false
      this.showListContact = true
      //console.log(this.showListContact, this.selectedListContacts, `INI FROM`)
    }
    //console.log(this.selectedListContacts, 'ini selected list contacts')

    if (this.isDraft) {
      this.broadcastName = this.broadcastData.broadcast_name
      let channelIdx = 0
      this.listInstance.forEach((el, idx) => {
        if (el.instance_id === this.broadcastData.sender.instance_id) {
          channelIdx = idx
        }
      })
      this.channel = channelIdx
      this.changeMessage = this.broadcastData.message_template.ad_hoc === true
      if (!this.changeMessage) {
        let tempEditMessageTemplate = -1
        this.listTemplates.forEach((el, idx) => {
          if (el.id === this.broadcastData.message_template.id) {
            tempEditMessageTemplate = idx
          }
        })
        this.messageTemplate = tempEditMessageTemplate
      }
      this.trackLink = this.broadcastData.track_link
      this.delayType = this.broadcastData.delay_type.trim()
      if (this.delayType === 'custom') {
        this.delayMin = this.broadcastData.delay.min
        this.delayMax = this.broadcastData.delay.max
        this.stopAfter = this.broadcastData.delay.stop_after
        this.additionalDelay = this.broadcastData.delay.additional
      }
      this.executionType = {
        text: this.$t(`broadcasts.${this.broadcastData.execution_type}`),
        value: this.broadcastData.execution_type,
      }
      if (this.executionType.value !== 'immediately') {
        this.showMenuDateTime = true
        const execDateTime = this.broadcastData.broadcast_time
        this.datePicker = execDateTime.substring(0, 10)
        this.timePicker = execDateTime.substring(11, 16)
      }
      // eslint-disable-next-line no-underscore-dangle
      this.broadcastId = this.broadcastData.broadcastId
    }
    if (!this.fromList && !this.fromContacts) {
      this.populateLocalField(this.dataContactsFromApi.data.extra_field)
    }

    // get global channel
    if (this.getGlobalChannel && !this.getGlobalChannel.value.all_sender) {
      const globalChannel = this.channelList.find(item => item.text == this.getGlobalChannel.label)

      if (globalChannel) {
        this.channel = globalChannel.value
      }
    }
  },
  watch: {
    options: {
      handler() {
        // this.getDataFromApi()
        this.$emit('change-options', this.options, this.forwardMessages.roomId, this.searchDetail)
      },
      deep: true,
    },
    localContacts(val, newVal) {
      let contacts = []
      contacts = JSON.parse(JSON.stringify(val))
      this.detailListContact = contacts.map(el => {
        el.no = el.phone_number.length > 16 ? 'Group' : el.phone_number
        el.contact_source = 'Whatsapp'

        return el
      })
    },
    searchDetail(val, newVal) {
      this.$emit('change-options', this.options, this.forwardMessages.roomId, this.searchDetail)
    },
  },
  methods: {
    // selectInstanceData(data) {
    //   this.dialogSelectChannels = false
    //   this.channel = this.listInstance.findIndex(instance => instance._id === data.instance_id)
    // },

    populateLocalField(dataField) {
      const extraField = dataField
      const globalField = extraField.global.map(el => el.field)

      const tempLocalField = []
      const keys = Object.keys(extraField)
      // eslint-disable-next-line no-plusplus
      for (let index = 2; index < keys.length; index++) {
        const el = keys[index]
        extraField[el].forEach(elm => {
          if (!globalField.includes(elm.field)) tempLocalField.push(`{{${elm.field}}}`)
        })
      }
      this.localExtraFieldContainer = tempLocalField
    },
    showContactInTable() {
      // //console.log(this.listInstance.findIndex(instance => instance._id === data.instance_id), '<<<okok')
      this.messageTemplate = ''
      const tempPhoneNumber = {}
      let countDuplicate = 0
      let countUnsubscribe = 0
      //console.log(this.selectedListContacts, this.broadcastContactsTemp, `INI SELECTEDs`)
      if (this.selectedListContacts.length > 0) {
        this.detailListContact = []
        this.dataContacts = []
        this.selectedListContacts.forEach(el => {
          const tempContact = []
          if (this.broadcastContactsTemp[this.fromList ? el.list_id : el.value] !== undefined) {
            this.broadcastContactsTemp[this.fromList ? el.list_id : el.value].forEach(elm => {
              //       //console.log(elm, '===========================')
              //           for (let key in item) {
              //   if (
              //     key !== 'phone_number' &&
              //     key !== 'data' &&
              //     key !== 'contactName' &&
              //     key !== 'phone_number_show' &&
              //     key !== '_id' &&
              //     key !== 'name' &&
              //     key !== 'updated_at' &&

              //     key !== 'created_at' &&
              //     key !== '_uid' &&
              //     key !== 'contact_source' &&
              //     key !== 'no' &&
              //     key !== 'phoneNumber'
              //   )
              //     data += `${key}: ${item[key]} `
              // }
              let obj = JSON.parse(JSON.stringify(elm))
              if (obj.hasOwnProperty('answerData')) {
                obj.answerData.forEach(item => {
                  for (let key in item) {
                    obj[key] = item[key]
                  }
                })
              }
              delete obj.custom
              delete obj.phone_number
              delete obj.data
              delete obj.contactName
              delete obj.phone_number_show
              delete obj._id
              delete obj.name
              delete obj.updatedAt
              delete obj.createdAt
              delete obj.channel_source
              delete obj.updated_at
              delete obj.created_at
              delete obj._uid
              delete obj.contact_source
              delete obj.phoneNumber
              delete obj.no
              delete obj.has_wa
              delete obj.archived
              delete obj.message_from_me
              delete obj.last_reply
              delete obj.sync_firestore
              delete obj.chunkReady
              delete obj.last_message_status
              delete obj.path
              delete obj.roomStatus
              delete obj.lastUpdatedMessageId
              delete obj.lastFirestoreMessageId
              delete obj._rev
              delete obj.message_container
              delete obj.pinned
              delete obj.subId
              delete obj.last_interaction
              delete obj.assign_to
              delete obj.instance_id
              delete obj.unreplied
              delete obj.roomId
              delete obj.users
              delete obj.id
              delete obj.index
              delete obj.notes
              delete obj.avatar
              delete obj.labels
              delete obj.instance
              delete obj.typingUsers
              delete obj.unreadCount
              delete obj.roomContactList
              delete obj.roomContactName
              delete obj.formattedTimestamp
              delete obj.roomContactListLabel
              delete obj.roomName
              delete obj.roomOwnerId
              delete obj.lastUpdated
              delete obj.lastMessage
              delete obj.roomOwnerName
              delete obj.profile_picture
              delete obj.unread_count
              delete obj.last_message
              delete obj.lastChunkMessageId
              delete obj.answerData
              delete obj.list_name
              delete obj.list_id
              delete obj[`last read`]
              delete obj.last_read
              delete obj.createdAt
              delete obj.updatedAt
              delete obj.channel_source
              var str = Object.keys(obj)
                .map(
                  key =>
                    `${key}: ${
                      key === 'last_order_time' ? this.$moment(obj[key]).format('DD-MM-YYYY') : obj[key]
                    }</br>`,
                )
                .join(`\n`)
              // //console.log(str)

              // //console.log(str, '===========================')
              elm.custom = str
              if (!tempPhoneNumber[elm.phone_number] && elm.phone_number) {
                tempPhoneNumber[elm.phone_number] = true
                // eslint-disable-next-line no-underscore-dangle
                elm.no = elm.phone_number
                if (elm.profile_picture) {
                  let picture = elm.profile_picture.split('https://')
                  if (picture.length > 1) {
                    elm.profile_picture = `https://${picture[picture.length - 1].replace('//ppic', '/ppic')}`
                  }
                }
                elm.profile_picture = elm.profile_picture
                  ? elm.profile_picture
                  : require('../../plugins/demo/assets/avatar.png')
                elm.list_id = el.list_id
                elm.list_name = el.text
                elm.contact_source = el.text
                elm._uid = elm._uid ? elm._uid : this.$nanoid()
                elm.id = elm.id ? elm.id : this.$nanoid()
                if (elm.subscribe_broadcast !== false) tempContact.push(elm)
                else countUnsubscribe += 1
              } else countDuplicate += 1
            })
            this.detailListContact = [...this.detailListContact, ...tempContact]
            // //console.log(this.detailListContact, ' == detailListContact 2')
            this.dataContacts = [...this.dataContacts, ...tempContact]
            this.detailListContact = _uniqBy(this.detailListContact, 'phone_number')
            this.dataContacts = _uniqBy(this.dataContacts, 'phone_number')
          } else if (el.value === 'all' && el.value !== 'Whatsapp') {
            for (let key in this.broadcastContactsTemp) {
              if (key !== 'Whatsapp') {
                this.broadcastContactsTemp[key].forEach(elm => {
                  let obj = JSON.parse(JSON.stringify(elm))
                  if (obj.hasOwnProperty('answerData')) {
                    obj.answerData.forEach(item => {
                      for (let key in item) {
                        obj[key] = item[key]
                      }
                    })
                  }
                  delete obj.custom
                  delete obj.phone_number
                  delete obj.data
                  delete obj.contactName
                  delete obj.phone_number_show
                  delete obj._id
                  delete obj.name
                  delete obj.updatedAt
                  delete obj.createdAt
                  delete obj.channel_source
                  delete obj.updated_at
                  delete obj.created_at
                  delete obj._uid
                  delete obj.contact_source
                  delete obj.phoneNumber
                  delete obj.no
                  delete obj.has_wa
                  delete obj.archived
                  delete obj.message_from_me
                  delete obj.last_reply
                  delete obj.sync_firestore
                  delete obj.chunkReady
                  delete obj.last_message_status
                  delete obj.path
                  delete obj.roomStatus
                  delete obj.lastUpdatedMessageId
                  delete obj.lastFirestoreMessageId
                  delete obj._rev
                  delete obj.message_container
                  delete obj.pinned
                  delete obj.subId
                  delete obj.last_interaction
                  delete obj.assign_to
                  delete obj.instance_id
                  delete obj.unreplied
                  delete obj.roomId
                  delete obj.users
                  delete obj.id
                  delete obj.roomName
                  delete obj.roomOwnerId
                  delete obj.lastUpdated
                  delete obj.lastMessage
                  delete obj.roomOwnerName
                  delete obj.profile_picture
                  delete obj.unread_count
                  delete obj.last_message
                  delete obj.lastChunkMessageId
                  delete obj.answerData
                  delete obj.list_name
                  delete obj.index
                  delete obj.notes
                  delete obj.avatar
                  delete obj.labels
                  delete obj.instance
                  delete obj.typingUsers
                  delete obj.unreadCount
                  delete obj.roomContactList
                  delete obj.roomContactName
                  delete obj.formattedTimestamp
                  delete obj.roomContactListLabel
                  delete obj.list_id
                  delete obj[`last read`]
                  delete obj.last_read
                  delete obj.createdAt
                  delete obj.updatedAt
                  delete obj.channel_source
                  var str = Object.keys(obj)
                    .map(
                      key =>
                        `${key}: ${
                          key === 'last_order_time' ? this.$moment(obj[key]).format('DD-MM-YYYY') : obj[key]
                        }</br>`,
                    )
                    .join(`\n`)
                  // //console.log(str)

                  // //console.log(str, '===========================')
                  elm.custom = str
                  if (!tempPhoneNumber[elm.phone_number] && elm.phone_number) {
                    tempPhoneNumber[elm.phone_number] = true
                    // eslint-disable-next-line no-underscore-dangle
                    elm.no = elm.phone_number
                    if (elm.profile_picture) {
                      let picture = elm.profile_picture.split('https://')
                      if (picture.length > 1) {
                        elm.profile_picture = `https://${picture[picture.length - 1].replace('//ppic', '/ppic')}`
                      }
                    }
                    elm.profile_picture = elm.profile_picture
                      ? elm.profile_picture
                      : require('../../plugins/demo/assets/avatar.png')
                    elm.list_id = el.value
                    elm.list_name = el.text
                    elm.contact_source = el.text
                    elm._uid = elm._uid ? elm._uid : this.$nanoid()
                    elm.id = elm.id ? elm.id : this.$nanoid()
                    if (elm.subscribe_broadcast !== false) tempContact.push(elm)
                    else countUnsubscribe += 1
                  } else countDuplicate += 1
                })
                this.detailListContact = [...this.detailListContact, ...tempContact]
                // //console.log(this.detailListContact, ' == detailListContact 2')
                this.dataContacts = [...this.dataContacts, ...tempContact]
                this.detailListContact = _uniqBy(this.detailListContact, 'phone_number')
                this.dataContacts = _uniqBy(this.dataContacts, 'phone_number')
              }
            }
          }
        })
      }
      //  else if (this.selectedListContacts.length > 0) {
      //   const tempContact = []
      //   this.detailListContact.forEach(elm => {
      //     // //console.log(elm, `INI ELM`)
      //     let obj = JSON.parse(JSON.stringify(elm))
      //     if (obj.hasOwnProperty('answerData')) {
      //       obj.answerData.forEach(item => {
      //         for (let key in item) {
      //           obj[key] = item[key]
      //         }
      //       })
      //     }
      //     delete obj.custom
      //     delete obj.phone_number
      //     delete obj.data
      //     delete obj.contactName
      //     delete obj.phone_number_show
      //     delete obj._id
      //     delete obj.name
      //     delete obj.updatedAt
      //     delete obj.createdAt
      //     delete obj.updated_at
      //     delete obj.created_at
      //     delete obj.channel_source

      //     delete obj._uid
      //     delete obj.contact_source
      //     delete obj.phoneNumber
      //     delete obj.no
      //     delete obj.has_wa
      //     delete obj.archived
      //     delete obj.message_from_me
      //     delete obj.last_reply
      //     delete obj.sync_firestore
      //     delete obj.chunkReady
      //     delete obj.last_message_status
      //     delete obj.path
      //     delete obj.roomStatus
      //     delete obj.lastUpdatedMessageId
      //     delete obj.lastFirestoreMessageId
      //     delete obj._rev
      //     delete obj.message_container
      //     delete obj.pinned
      //     delete obj.subId
      //     delete obj.last_interaction
      //     delete obj.assign_to
      //     delete obj.instance_id
      //     delete obj.unreplied
      //     delete obj.roomId
      //     delete obj.users
      //     delete obj.id
      //     delete obj.roomName
      //     delete obj.roomOwnerId
      //     delete obj.lastUpdated
      //     delete obj.lastMessage
      //     delete obj.roomOwnerName
      //     delete obj.profile_picture
      //     delete obj.unread_count
      //     delete obj.last_message
      //     delete obj.lastChunkMessageId
      //     delete obj.answerData
      //     delete obj.list_name
      //     delete obj.list_id
      //     delete obj[`last read`]
      //     delete obj.last_read
      //     var str = Object.keys(obj)
      //       .map(
      //         key =>
      //           `${key}: ${key === 'last_order_time' ? this.$moment(obj[key]).format('DD-MM-YYYY') : obj[key]}</br>`,
      //       )
      //       .join(`\n`)
      //     // //console.log(str)

      //     // //console.log(str, '===========================')
      //     elm.custom = str
      //     if (!tempPhoneNumber[elm.phone_number]) {
      //       tempPhoneNumber[elm.phone_number] = true
      //       // eslint-disable-next-line no-underscore-dangle
      //       elm.no = elm.phone_number
      //       if (elm.subscribe_broadcast !== false) tempContact.push(elm)
      //       else countUnsubscribe += 1
      //     } else countDuplicate += 1
      //   })
      // }
      else if (this.selectedListContacts.length === 0) {
        this.detailListContact = []
        this.dataContacts = []
      }

      this.duplicateContactCount = countDuplicate
      this.unsubscribeContactCount = countUnsubscribe

      this.messageTemplateList = this.listTemplates
        .filter((elm, idx) => {
          elm.index = idx

          return elm.contact_list.value === 'whatsapp'
        })
        .map(el => ({
          text: el.template_name,
          value: el.index,
        }))
    },
    fetchContactForwards() {
      let contacts = []
      if (this.dataContactsFromApi.status) {
        const tempContacts = this.dataContactsFromApi.data.contacts
        tempContacts.Whatsapp = tempContacts.Whatsapp.filter(el => el.phone_number !== 'status')
        contacts = tempContacts.Whatsapp.map(el => {
          el.no = el.phone_number.length > 16 ? 'Group' : el.phone_number
          el.contact_source = 'Whatsapp'

          return el
        })
      }
      let tempContact = []
      contacts.forEach(contact => {
        for (const instance of this.channelList) {
          if (contact.instance === this.$Md5Hash(instance._id)) {
            // //console.log('🚀 ~ file: ForwardMessage.vue ~ line 388 ~ mounted ~ instance', contact, instance)
            // const indexContact = this.allContactData.findIndex(
            //   contactData =>
            //     contactData.phone_number === contact.phone_number ||
            //     contactData.phone_number == contact['Phone Number'],
            //     )
            //     //console.log(indexContact)
            // if (indexContact > -1) {
            //   if (this.allContactData[indexContact].contact_list_name)
            //     contact.name = this.allContactData[indexContact].contact_list_name
            //   else if (this.allContactData[indexContact].bailey_name)
            //     contact.name = this.allContactData[indexContact].bailey_name
            //   else if (this.allContactData[indexContact].notify_name)
            //     contact.name = this.allContactData[indexContact].notify_name
            // }
            tempContact = contacts.filter(contact => this.$Md5Hash(instance._id) === contact.instance)
          }
        }
      })
      this.detailListContact = _uniqBy(tempContact, 'phone_number')
    },

    async complete() {
      // //console.log(
      //   '🚀 ~ file: ForwardMessage.vue ~ line 887 ~ complete ~ complete',
      //   this.dataContacts,
      //   this.forwardMessages,
      // )
      const { messages, roomId } = this.forwardMessages
      const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/forward?id=${this.user.sub_id}-${
        roomId.split('-')[0]
      }`
      const body = {
        messages,
        receivers: this.dataContacts.map(recipient => {
          let phone_number
          if (recipient.phone_number.length > 16) {
            phone_number = `${recipient.phone_number}@g.us`
          } else {
            phone_number = `${recipient.phone_number}@s.whatsapp.net`
          }

          return phone_number
        }),
      }
      this.$emit('close-dialog')
      this.showCheckbox = false
      //console.log('🚀 ~ file: ForwardMessage.vue ~ line 902 ~ complete ~ body', body)
      try {
        await this.$axiosLimit.post(endpoint, body)

        // //console.log('🚀 ~ file: RoomHeader.vue ~ line 227 ~ changePinStatus ~ res', res)
      } catch (err) {
        // //console.log('🚀 ~ file: RoomHeader.vue ~ line 230 ~ changePinStatus ~ err', err)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.table-broadcast ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
  .v-data-table-header__icon {
    opacity: 1;
  }
}
@media only screen and (max-width: 578px) {
  .sm-text {
    font-size: 12px;
  }
  .display-none {
    display: none;
  }
  .display-mt-lg {
    margin-top: 10px !important;
  }
}
@media only screen and (min-width: 578px) {
  .display-lg {
    display: flex;
  }
}
</style>
