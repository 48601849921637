<template>
  <div class="vac-message-files-container">
    <div v-if="isLoading">
      <loader :show="isLoading">
        <template v-for="(idx, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </loader>
    </div>
    <div v-else>
      <div v-for="(file, idx) in imageVideoFiles" :key="idx + 'iv'">
        <message-file
          :file="file"
          :current-user-id="currentUserId"
          :message="message"
          :index="idx"
          @open-file="$emit('open-file', $event)"
        >
          <template v-for="(i, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data" />
          </template>
        </message-file>
      </div>

      <div v-for="(file, idx) in otherFiles" :key="idx + 'a'">
        <div class="d-flex" style="background-color: #e9ecef; border-radius: 16px; padding: 8px 12px">
          <img class="my-auto justify-center" width="20" height="20" :src="previewfile(file)" />

          <v-spacer />

          <div style="max-width: 80%">
            <span class="message-file-name my-auto mx-2 justify-center">{{ file.name }}</span>
          </div>

          <v-spacer />

          <v-btn
            v-if="!isLoadingDownload"
            class="justify-center my-auto"
            icon
            fab
            @click.stop="openFile(file, 'download')"
          >
            <v-icon color="#1976d2">
              {{ icons.mdiDownload }}
            </v-icon>
          </v-btn>
          <loader :loading-download="isLoadingDownload" :show="isLoadingDownload"> </loader>

          <!-- <div class="message-file-download-link vac-svg-button" @click.stop="openFile(file, 'download')">
            <slot name="document-icon" />
            <svg-icon name="document" />
            <loader :loading-download="isLoadingDownload" :show="isLoadingDownload"> </loader>
          </div> -->
        </div>

        <!-- <div class="message-container">
          <div class="message message-sent">
            <div class="message-text">
              <div class="message-file-icon-container">
                <div class="message-file-icon-wrapper">
                  <img width="20" :src="previewfile(file)" />
                </div>
              </div>
              <div class="message-file-info">
                <span class="message-file-name">{{ file.name }}</span>
              </div>
              <div class="message-file-download-link vac-svg-button" @click.stop="openFile(file, 'download')">
                <slot name="document-icon" />
                <svg-icon name="document" />
                <loader :loading-download="isLoadingDownload" :show="isLoadingDownload"> </loader>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <format-message
        :content="message.content || captionDoc"
        :users="roomUsers"
        :text-formatting="textFormatting"
        :link-options="linkOptions"
        @open-user-tag="openUserTag"
      >
        <template v-for="(i, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </format-message>
    </div>
  </div>
</template>

<script>
import { mdiDownload } from '@mdi/js'
import FormatMessage from '../../../components/FormatMessage/FormatMessage'
import Loader from '../../../components/Loader/Loader'
import SvgIcon from '../../../components/SvgIcon/SvgIcon'
import MessageFile from './MessageFile/MessageFile'

const { isImageVideoFile } = require('../../../utils/media-file')

export default {
  name: 'MessageFiles',
  components: { SvgIcon, FormatMessage, MessageFile, Loader },

  props: {
    currentUserId: { type: [String, Number], required: true },
    message: { type: Object, required: true },
    roomUsers: { type: Array, required: true },
    textFormatting: { type: Object, required: true },
    linkOptions: { type: Object, required: true },
    fromChatwoot: { type: Boolean, required: true },
  },
  data() {
    return {
      isLoading: false,
      downloading: false,
      icons: {
        mdiDownload,
      },
    }
  },

  emits: ['open-file', 'open-user-tag'],

  computed: {
    imageVideoFiles() {
      return this.message.files.filter(file => isImageVideoFile(file))
    },
    otherFiles() {
      return this.message.files.filter(file => !isImageVideoFile(file))
    },
    captionDoc() {
      return this.message.original_message.message.documentMessage?.caption || ''
    },
    isLoadingDownload() {
      return this.$store.getters['inbox/getLoadingDownloadFile']
    },
  },

  watch: {
    // isLoadingDownload(val, newVal) {
    //   console.log(val, 'woi<')
    //   if (val) {
    //     this.isLoading = true
    //   } else this.isLoading = false
    // },
  },

  methods: {
    openFile(file, action) {
      this.$emit('open-file', { file, action })

      // Simulate download delay
      setTimeout(() => {
        this.downloading = false
      }, 3000) // Change 3000 to the actual download time in milliseconds
    },
    openUserTag(user) {
      // //console.log(user, '<<<filesssfile')
      this.$emit('open-user-tag', user)
    },
    previewfile(file) {
      if (this.fromChatwoot) {
        if (file.preview.includes('pdf')) {
          return '/file-manager/icons/application-pdf.svg'
        }
        if (file.preview.includes('csv')) {
          return 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
        }
        if (file.preview.includes('xlsx')) {
          return '/file-manager/icons/application-vnd.ms-excel.svg'
        }
        if (file.preview.includes('txt')) {
          return 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
        }
        if (file.preview.includes('docx')) {
          return '/file-manager/icons/application-msword-template.svg'
        }

        return '/file-manager/icons/text.svg'
      }
      if (file.type === 'pdf') {
        return '/file-manager/icons/application-pdf.svg'
      }
      if (file.type === 'csv') {
        return 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
      }
      if (file.type.includes('spreadsheetml') || file.name.includes('xlsx')) {
        return '/file-manager/icons/application-vnd.ms-excel.svg'
      }
      if (file.type === 'plain') {
        return 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
      }
      if (file.type.includes('document')) {
        return '/file-manager/icons/application-msword-template.svg'
      }

      return '/file-manager/icons/text.svg'
    },
  },
}
</script>

<style>
.message-container {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.message {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message-sent {
  margin-left: auto;
}

.message-text {
  background-color: #e9ecef;
  border-radius: 16px;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message-file-icon-container {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.message-file-icon-wrapper {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #3f51b5;
}

.message-download-highlight {
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.11), 0 1px 5px -1px rgba(0, 0, 0, 0.11);
}

.message-file-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-right: 8px; */
  width: 9vw;
}

.message-file-name {
  font-weight: bold;
  font-size: 11px;
  color: #000;
  max-width: 100%;
  max-width: 100%;
  white-space: normal; /* allow text to wrap */
  word-wrap: break-word; /* break long words if necessary */
  display: inline-block;
}

.message-file-size {
  font-size: 12px;
  color: #8e8e8e;
}

.message-file-download-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #d2d3d7;
  text-decoration: none;
  /* margin-left: 8px; */
  opacity: 0.7;
}

.message-file-download-icon {
  font-size: 20px;
  color: #fff;
}
</style>
