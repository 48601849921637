<template>
  <div :style="'max-width: 420px !important'">
    <div
      v-if="showRoomsList"
      class="vac-rooms-container vac-app-border-r"
      :class="{ 'vac-rooms-container-full': isMobile }"
      :style="isMobile ? 'width: 100vw !important' : 'width: 420px !important'"
    >
      <slot name="rooms-header" />
      <div class="d-flex mx-4 mt-2">
        <v-row class="match-height">
          <v-col class="justify-start match-height" cols="7">
            <v-btn
              id="no-background-hover"
              outlined
              :style="$vuetify.theme.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
              color="primary"
              @click="localAddRoom"
            >
              <v-icon color="primary" class="me-1">
                {{ icons.mdiMessageText }}
              </v-icon>
              <span class="text-capitalize me-1">New Chat</span>

              <div
              :style="`background-color: ${realtimeStatus ? 'green' : 'red'}`"
              class="vac-state-circle my-auto"
              :class="isFloating && $vuetify.breakpoint.xs ? 'justify-start' : ''"
            />

            <v-progress-circular
            v-if="loadingCheckRooms"
              class="my-auto"
              :class="isFloating && $vuetify.breakpoint.xs ? 'ms-1 justify-start' : 'mx-auto'"
              size="24"
              color="primary"
              :indeterminate="loadingCheckRooms"
            />
            </v-btn>
          </v-col>

          <v-col cols="5" class="d-flex match-height">
            <v-select
              class="my-select"
              :items="filters"
              v-model="selectedFilter"
              outlined
              dense
              @change="changeFilter($event)"
            >
              <template v-slot:item="{ item }">
                <span class="text-capitalize">{{ item }}</span>
              </template>
              <template v-slot:selection="{ item }">
                <span class="text-capitalize">{{ item }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </div>
      <slot name="rooms-list-search">
        <rooms-search
          :rooms="rooms"
          :loading-rooms="loadingRooms"
          :text-messages="textMessages"
          :show-search="showSearch"
          :rooms-chats="roomsChats"
          :rooms-messages="roomsMessages"
          :is-on-filter="isOnFilter"
          :show-add-room="showAddRoom"
          :is-not-valid-search="isNotValidSearch"
          @search-room="searchRoom"
          @add-room="$emit('add-room')"
          @open-config="$emit('open-config', $event)"
          @open-filter="$emit('open-filter', $event)"
          @clear-filter="$emit('clear-filter', $event)"
        >
          <template v-for="(i, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data" />
          </template>
        </rooms-search>
      </slot>

      <loader :show="loadingRooms">
        <template v-for="(idx, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </loader>

      <div
        v-if="
          !loadingRooms &&
          ((!mappedRooms.length && filterArr.length > 0) || (!rooms.length && !onSearch) || noRoomSearch)
        "
        class="vac-rooms-empty"
      >
        <slot name="rooms-empty">
          {{ textMessages.ROOMS_EMPTY }}
        </slot>
      </div>

      <div v-if="!loadingRooms" id="rooms-list" class="vac-room-list">
        <div v-if="mappedRooms.length === 0 && !onSearch && !filterArr.length">
          <div
            v-for="(fRoom, index) in filteredRooms"
            :id="fRoom.roomId"
            :key="index"
            class="vac-room-item"
            :class="{ 'vac-room-selected': selectedRoomId === fRoom.roomId }"
            @click="openRoom(fRoom)"
          >
            <room-content
              :all-contacts="allContacts"
              :current-user-id="currentUserId"
              :room="fRoom"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              :text-messages="textMessages"
              :room-actions="roomActions"
              :room-menu-open="roomMenuOpen"
              @room-action-handler="$emit('room-action-handler', $event)"
              @filtering-labels="filteringLabels"
              @open-menu-room="openMenuRoom"
            >
              <template v-for="(i, name) in $scopedSlots" #[name]="data">
                <slot :name="name" v-bind="data" />
              </template>
            </room-content>
          </div>
        </div>
        <div v-if="mappedRooms.length !== 0 && !onSearch && filterArr.length">
          <div
            v-for="(fRoom, index) in mappedRooms"
            :id="fRoom.roomId"
            :key="index"
            class="vac-room-item"
            :class="{ 'vac-room-selected': selectedRoomId === fRoom.roomId }"
            @click="openRoom(fRoom)"
          >
            <room-content
              :all-contacts="allContacts"
              :current-user-id="currentUserId"
              :room="fRoom"
              :text-formatting="textFormatting"
              :link-options="linkOptions"
              :text-messages="textMessages"
              :room-actions="roomActions"
              :room-menu-open="roomMenuOpen"
              @room-action-handler="$emit('room-action-handler', $event)"
              @filtering-labels="filteringLabels"
              @open-menu-room="openMenuRoom"
            >
              <template v-for="(i, name) in $scopedSlots" #[name]="data">
                <slot :name="name" v-bind="data" />
              </template>
            </room-content>
          </div>
        </div>
        <div v-if="onSearch">
          <div v-if="roomsChats.length > 0">
            <span class="font-weight-black">Chats</span>
            <div
              v-for="(cRoom, index) in roomsChats"
              :id="cRoom.roomId"
              :key="cRoom.roomId"
              class="vac-room-item"
              :class="{ 'vac-room-selected': selectedRoomId === cRoom.roomId }"
              @click="openRoom(cRoom, false)"
            >
              <room-content
                :all-contacts="allContacts"
                :current-user-id="currentUserId"
                :room="cRoom"
                :text-formatting="textFormatting"
                :link-options="linkOptions"
                :text-messages="textMessages"
                :room-actions="roomActions"
                :index-room="`chat${index}${cRoom.roomId}`"
                :room-menu-open="roomMenuOpen"
                @open-menu-room="openMenuRoom"
                @room-action-handler="$emit('room-action-handler', $event)"
                @filtering-labels="filteringLabels"
              >
                <template v-for="(i, name) in $scopedSlots" #[name]="data">
                  <slot :name="name" v-bind="data" />
                </template>
              </room-content>
            </div>
          </div>
          <div v-if="roomsContacts.length > 0">
            <span class="font-weight-black">Contacts</span>
            <div
              v-for="(contRoom, index) in roomsContacts"
              :id="contRoom.roomId"
              :key="contRoom.roomId"
              class="vac-room-item"
              :class="{ 'vac-room-selected': selectedRoomId === contRoom.roomId }"
              @click="openRoom(contRoom, false)"
            >
              <room-content
                :all-contacts="allContacts"
                :current-user-id="currentUserId"
                :room="contRoom"
                :text-formatting="textFormatting"
                :link-options="linkOptions"
                :text-messages="textMessages"
                :room-actions="roomActions"
                :index-room="`cont${index}${contRoom.roomId}`"
                :room-menu-open="roomMenuOpen"
                @open-menu-room="openMenuRoom"
                @room-action-handler="$emit('room-action-handler', $event)"
                @filtering-labels="filteringLabels"
              >
                <template v-for="(i, name) in $scopedSlots" #[name]="data">
                  <slot :name="name" v-bind="data" />
                </template>
              </room-content>
            </div>
          </div>
          <div v-if="roomsMessages.length > 0">
            <span class="font-weight-black">Messages</span>
            <div
              v-for="(mesRoom, index) in roomsMessages"
              :id="mesRoom.mappingId"
              :key="mesRoom.mappingId"
              class="vac-room-item"
              :class="{ 'vac-room-selected': selectedRoomId === mesRoom.mappingId }"
              @click="openRoom(mesRoom, true)"
            >
              <room-content
                :all-contacts="allContacts"
                :current-user-id="currentUserId"
                :room="mesRoom"
                :text-formatting="textFormatting"
                :link-options="linkOptions"
                :text-messages="textMessages"
                :room-actions="roomActions"
                :index-room="`mess${index}${mesRoom.roomId}`"
                :room-menu-open="roomMenuOpen"
                @open-menu-room="openMenuRoom"
                @room-action-handler="$emit('room-action-handler', $event)"
                @filtering-labels="filteringLabels"
              >
                <template v-for="(i, name) in $scopedSlots" #[name]="data">
                  <slot :name="name" v-bind="data" />
                </template>
              </room-content>
            </div>
          </div>
        </div>
        <transition name="vac-fade-message">
          <div
            v-if="((rooms.length || (roomsMessages.length && !roomsLoaded)) && !loadingRooms) || loadingSearch"
            id="infinite-loader-rooms"
          >
            <loader :show="showLoader || loadingSearch" :infinite="true">
              <template v-for="(idx, name) in $scopedSlots" #[name]="data">
                <slot :name="name" v-bind="data" />
              </template>
            </loader>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/helpers/db'
import {
  mdiChevronDown,
  mdiChevronUp,
  mdiDownload,
  mdiLabelVariant,
  mdiMessageText,
  mdiPencil,
  mdiPin,
  mdiPinOff,
  mdiWindowClose,
} from '@mdi/js'
import { useObservable } from '@vueuse/rxjs'
import { liveQuery } from 'dexie'
import _debounce from 'lodash/debounce'
import _orderBy from 'lodash/orderBy'
import _uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import Loader from '../../components/Loader/Loader'
import RoomContent from './RoomContent/RoomContent'
import RoomsSearch from './RoomsSearch/RoomsSearch'

export default {
  name: 'RoomsList',
  components: {
    Loader,
    RoomsSearch,
    RoomContent,
    VuePhoneNumberInput,
    moment,
  },

  props: {
    currentUserId: { type: [String, Number], required: true },
    mappingId: { type: String, default: '' },
    textMessages: { type: Object, required: true },
    showRoomsList: { type: Boolean, required: true },
    roomInfoEnabled: { type: Boolean, required: false },
    isFloating: { type: Boolean, required: true },
    isOnFilter: { type: Boolean, required: true },
    showSearch: { type: Boolean, required: true },
    showAddRoom: { type: Boolean, required: true },
    textFormatting: { type: Object, required: true },
    linkOptions: { type: Object, required: true },
    isMobile: { type: Boolean, required: true },
    rooms: { type: Array, required: true },
    roomsChats: { type: Array, required: true },
    roomsMessages: { type: Array, required: true },
    roomsContacts: { type: Array, required: true },
    contactGroups: { type: Array, default: () => [] },
    realtimeStatus: { type: Boolean, required: true },
    onChatMenu: { type: Boolean, required: true },
    loadingCheckRooms: { type: Boolean, required: true },
    loadingRooms: { type: Boolean, required: true },
    roomsLoaded: { type: Boolean, required: true },
    selectedContactGroups: { type: Array, required: true },
    selectedLabels: { type: Array, required: true },
    selectedChannel: { type: String, default: 'All' },
    sortSetting: { type: String, required: true },
    room: { type: Object, required: true },
    roomActions: { type: Array, required: true },
    allContacts: { type: Array, required: true },
    scrollDistance: { type: Number, required: true },
    mappedRooms: { type: Array, default: () => [] },
    filterArr: { type: Array, default: () => [] },
    loadingSearch: { type: Boolean, default: false },
    noRoomSearch: { type: Boolean, default: false },
    filteredForm: { type: Array, required: false },
    openAddRoom: { type: Boolean, default: false },
    assignment: { type: String, default: 'All' },
    isNotValidSearch: { type: Boolean, default: false },
  },

  data() {
    return {
      options: 'Group',
      filteredRooms: [],

      // filteredRoomsOrigin: this.rooms || [],
      filteredRoomsOrigin: useObservable(liveQuery(() => db.showedRooms.toArray())),

      // tesRoom: useObservable(liveQuery(() => db.showedRooms.toArray())) ,
      observer: null,
      showLoader: false,
      phoneNumber: '',
      errors: [],
      flag: 0,
      loadingMoreRooms: false,
      selectedRoomId: '',
      roomContactList: [],
      filters: ['all', 'unread', 'unreplied'],

      // onSearch: false,
      selectedFilter: 'all',
      icons: {
        mdiMessageText,
        mdiPin,
        mdiDownload,
        mdiPinOff,
        mdiChevronUp,
        mdiChevronDown,
        mdiPencil,
        mdiWindowClose,
        mdiLabelVariant,
      },
      labelVal: [],

      editName: false,
      results: {},
      roomMenuOpen: '',
      newRoomChannel: {},
      isOpened: false,
      nameContact: '',
      instances: [],
    }
  },

  computed: {
    newRoomActions() {
      // console.log(this.room, '<<viraaa')
      return this.roomActions
    },
    teamMembers() {
      return this.$store.getters['auth/getListUsers']
    },
    filterRoom() {
      return this.$store.getters['inbox/getFilterRoom']
    },
    // instance() {
    //   return this.$store.getters['auth/getListInstance']
    // },
    user() {
      return this.$store.getters['auth/getUser']
    },
    listInstances() {
      if (this.instance.value?.length)
        return this.instance.value.filter(ele => ele.status === 1 && ele.type !== 'chatwoot' && !ele.broadcast_group)

      return []
    },

    // filteredRoom() {
    //   return this.$store.getters['inbox/getFilterChats']
    // },
    flagFilter() {
      if (this.selectedContactGroups.length > 0 || this.selectedLabels.length > 0) {
        return true
      }

      return false
    },
    onSearch() {
      return !!this.$store.getters['global/getSearch']
    },
    filterContactGroups() {
      // //console.log(this.roomContactList, '<<<groupid room')
      // //console.log(
      //   this.contactGroups.filter(contactGroups => this.roomContactList.includes(contactGroups.id)),
      //   '<<filtered',
      // )

      return this.contactGroups.filter(contactGroups => this.roomContactList.includes(contactGroups.id))
    },
    getSearch() {
      const search = this.$store.getters['global/getSearch']

      return search
    },
  },

  watch: {
    sortSetting(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filteredRooms = _orderBy(
          this.filteredRooms,
          ['pinned', 'index'],
          ['desc', newVal === 'newest' ? 'desc' : 'asc'],
        )
      }
    },
    isOpened(val) {
      if (!val) {
        this.newRoomChannel = {}
        this.phoneNumber = ''
        this.nameContact = ''
        if (this.$route.query.phone_number) {
          this.$router.replace('/inbox')
        }
      }
    },

    // openAddRoom(val) {
    //   if (val) {
    //     this.isOpened = true
    //     this.phoneNumber = this.$route.query.phone_number
    //     this.nameContact = this.$route.query.name
    //   }
    // },
    rooms: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.length && newVal !== oldVal && !this.getSearch) {
          setTimeout(() => this.initIntersectionObserver())
        }
        if (this.user.chat_mode === 'Read and Write to assigned only') {
          if (this.filterRoom === 'all' || this.filterRoom === 'unreplied') {
            if (this.selectedChannel === 'All') {
              this.filteredRooms = _uniqBy(
                JSON.parse(JSON.stringify(newVal)).filter(
                  room =>
                    room?.lastMessage &&
                    (this.assignment === 'All'
                      ? !room.assign_to || room.expand?.assign_to?.email === this.user.email
                      : this.assignment === 'empty'
                      ? !room.assign_to
                      : room.expand?.assign_to?.email === this.user.email),
                ),
                'roomId',
              )
            } else {
              this.filteredRooms = _uniqBy(
                JSON.parse(JSON.stringify(newVal)).filter(
                  room =>
                    room?.lastMessage &&
                    room.instance._id === this.selectedChannel &&
                    (this.assignment === 'All'
                      ? !room.assign_to || room.expand?.assign_to?.email === this.user.email
                      : this.assignment === 'empty'
                      ? !room.assign_to
                      : room.expand?.assign_to?.email === this.user.email),
                ),
                'roomId',
              )
            }
          } else if (this.selectedChannel === 'All') {
            this.filteredRooms = _uniqBy(
              JSON.parse(JSON.stringify(newVal)).filter(
                room =>
                  room?.index &&
                  room?.lastMessage &&
                  room?.unread_count > 0 &&
                  (this.assignment === 'All'
                    ? !room.assign_to || room.expand?.assign_to?.email === this.user.email
                    : this.assignment === 'empty'
                    ? !room.assign_to
                    : room.expand?.assign_to?.email === this.user.email),
              ),
              'roomId',
            )
          } else {
            this.filteredRooms = _uniqBy(
              JSON.parse(JSON.stringify(newVal)).filter(
                room =>
                  room?.index &&
                  room?.lastMessage &&
                  room?.unread_count > 0 &&
                  room.instance._id === this.selectedChannel &&
                  (this.assignment === 'All'
                    ? !room.assign_to || room.expand?.assign_to?.email === this.user.email
                    : this.assignment === 'empty'
                    ? !room.assign_to
                    : room.expand?.assign_to?.email === this.user.email),
              ),
              'roomId',
            )
          }
        } else if (this.filterRoom === 'all' || this.filterRoom === 'unreplied') {
          if (this.selectedChannel === 'All') {
            this.filteredRooms = _uniqBy(
              JSON.parse(JSON.stringify(newVal)).filter(
                room =>
                  room?.lastMessage &&
                  (this.assignment === 'All'
                    ? room
                    : this.assignment === 'empty'
                    ? !room.assign_to
                    : room.expand?.assign_to?.email === this.user.email),
              ),
              'roomId',
            )
          } else {
            this.filteredRooms = _uniqBy(
              JSON.parse(JSON.stringify(newVal)).filter(
                room =>
                  room?.lastMessage &&
                  room.instance._id === this.selectedChannel &&
                  (this.assignment === 'All'
                    ? room
                    : this.assignment === 'empty'
                    ? !room.assign_to
                    : room.expand?.assign_to?.email === this.user.email),
              ),
              'roomId',
            )
          }
        } else if (this.selectedChannel === 'All') {
          this.filteredRooms = _uniqBy(
            JSON.parse(JSON.stringify(newVal)).filter(
              room =>
                room?.index &&
                room?.lastMessage &&
                room?.unread_count > 0 &&
                (this.assignment === 'All'
                  ? room
                  : this.assignment === 'empty'
                  ? !room.assign_to
                  : room.expand?.assign_to?.email === this.user.email),
            ),
            'roomId',
          )
        } else {
          this.filteredRooms = _uniqBy(
            JSON.parse(JSON.stringify(newVal)).filter(
              room =>
                room?.index &&
                room?.lastMessage &&
                room?.unread_count > 0 &&
                room.instance._id === this.selectedChannel &&
                (this.assignment === 'All'
                  ? room
                  : this.assignment === 'empty'
                  ? !room.assign_to
                  : room.expand?.assign_to?.email === this.user.email),
            ),
            'roomId',
          )
        }

        // //console.log('🚀 ~ file: RoomsList.vue ~ line 10892 ~ handler ~ this.filteredRooms', this.filteredRooms)
        this.filteredRooms = _orderBy(
          this.filteredRooms,
          ['pinned', 'index'],
          ['desc', this.sortSetting === 'newest' ? 'desc' : 'asc'],
        )

        // //console.log('🚀 ~ file: RoomsList.vue ~ line 1089 ~ handler ~ this.filteredRooms', this.filteredRooms)

        // if (this.user.chat_mode === 'Read and Write to assigned only') {
        //   this.filteredRooms = JSON.parse(JSON.stringify(this.filteredRooms)).filter(
        //     room => !room.assign_to || room.assign_to.email === this.user.email,
        //   )
        // }
        if (newVal.length !== oldVal.length || this.roomsLoaded) {
          this.loadingMoreRooms = false
        }

        // if (this.filterRoom === 'unread') {
        //   // const oldFilteredRooms = JSON.parse(JSON.stringify(oldVal)).filter(room => room.unread_count > 0)
        //   // //console.log('🚀 ~ file: RoomsList.vue ~ line 686 ~ handler ~ oldFilteredRooms', oldFilteredRooms)
        //   // this.filteredRooms = this.filteredRooms.concat(oldFilteredRooms)

        //   this.filteredRooms = _uniqBy(
        //     JSON.parse(JSON.stringify(newVal)).filter(room => room.index && room.lastMessage),
        //     'roomId',
        //   )

        //   this.filteredRooms = _orderBy(
        //     this.filteredRooms,
        //     ['pinned', 'index'],
        //     ['desc', this.sortSetting === 'newest' ? 'desc' : 'asc'],
        //   )
        // }
      },
    },
    roomsMessages: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.length && newVal !== oldVal) {
          if (!this.roomsLoaded) {
            setTimeout(() => this.initIntersectionObserver())
          }
        }

        // if (newVal.length !== oldVal.length || this.roomsLoaded) {
        //   this.loadingMoreRooms = false
        // }
      },
    },
    showRoomsList(val, oldVal) {
      if (val && (!this.$vuetify.breakpoint.xs || this.isFloating)) {
        setTimeout(() => this.initIntersectionObserver())
      }
    },
    filterRoom(val) {
      // this.selectedFilter = this.filters.findIndex(el => el === val)
      this.selectedFilter = val
    },
    loadingRooms(val) {
      if (!val) {
        // //console.log('masuk kondisi loading rooms')
        setTimeout(() => this.initIntersectionObserver())
      }
    },
    loadingMoreRooms(val) {
      this.$emit('loading-more-rooms', val)
    },
    roomsLoaded: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadingMoreRooms = false
          if (!this.loadingRooms) {
            this.showLoader = false
          }
        }
      },
    },
    room: {
      immediate: true,
      handler(val) {
        // //console.log(this.roomContactList, 'ini room list val')
        if (val && !this.roomsMessages.length) {
          this.selectedRoomId = val.roomId
        }
      },
    },
  },

  async mounted() {
    // //console.log(
    //   '🚀 ~ file: RoomsList.vue ~ line 380 ~ mounted ~ mounted',
    //   this.selectedContactGroups,
    //   this.selectedLabels,
    //   this.sortSetting,
    // )
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.instances = instances?.value || []
    this.selectedFilter = this.filterRoom
    await this.getLabelVals()

    // await this.fetchContactForm()
  },

  emits: [
    'update-room',
    'add-room',
    'search-rooms',
    'filter-rooms',
    'room-action-handler',
    'loading-more-rooms',
    'fetch-room',
    'fetch-contact',
    'fetch-more-rooms',
    'fetch-more-search',
    'open-config',
    'open-filter',
    'clear-filter',
    'close-room-info',
    'filtering-labels',
    'open-user-tag',
    'change-room-id',
  ],

  methods: {
    toEditform(form) {
      this.$router.push({ name: 'formDetail', params: { formId: form.id } })
    },
    localAddRoom() {
      this.$emit('add-room', true)
    },
    openNewRoomFromContacts() {
      ;(this.isOpened = true), (this.phoneNumber = this.$route.query.phone_number)
      if (this.$route.query.name) {
        this.nameContact = this.$route.query.name
      }
    },
    async assignChat() {
      // console.log(this.mappingId, '<<tes nih')
      const room = JSON.parse(JSON.stringify(this.room))
      this.$emit('assign-chat', {
        roomId: this.room.roomId,
        selectedMember: this.room.assign_to,
        status: mappingId ? 'msg' : 'room',
        mappingId,
      })
    },
    openMenuRoom(data) {
      console.log(data, 'INI DATA<')
      this.roomMenuOpen = data
    },
    async changePinStatus() {
      const room = JSON.parse(JSON.stringify(this.room))
      if (room.pinned) {
        room.pinned = false
        this.room.pinned = false
      } else {
        this.room.pinned = true
        room.pinned = true
      }

      // room.pinned = !room.pinned
      // this.pinnedRoom = room.pinned

      // try {
      //   // const res = await this.$axiosLimit.post(endpoint, body)
      //   const res = await clientPocketBase.collection('crm_contact').update(room.id, {
      //     pinned: room.pinned,
      //   })
      //   // //console.log('🚀 ~ file: RoomList.vue ~ line 1316 ~ changePinStatus ~ res', res)
      // } catch (err) {
      //   // //console.log('🚀 ~ file: RoomList.vue ~ line 1318 ~ changePinStatus ~ err', err)
      // }

      this.$emit('update-room', room)
    },
    closedRoomInfo() {
      this.$emit('close-room-info')
      setTimeout(() => this.initIntersectionObserver())
    },
    searchRoom(ev) {
      // this.$store.commit('global/setSearch', ev.target.value)
      // this.$store.commit('global/setSearch', ev.target.value)
      this.updateValue(ev)

      // this.$emit('search-rooms', ev.target.value)
    },
    updateValue: _debounce(
      function (ev) {
        // console.log('🚀 nyund ~ file: RoomsList.vue:1130 ~ ev:', ev)

        // //console.log('🚀 ~ file: RoomsList.vue ~ line 350 ~ ev', ev)
        // this.currentSearch = ev
        // this.$store.commit('global/setSearch', ev.target.value)
        // console.log(ev.target.value, '<<search')
        this.$store.commit('global/setSearch', ev.target.value)

        // if (this.currentSearch.target && this.currentSearch.target.value) {
        //   this.$emit('filter-new-contacts', this.currentSearch.target.value)
        // }
        // this.onSearch = !!ev.target.value

        this.$emit('search-rooms', ev.target.value)

        // this.emitFetchRooms()
      },
      1500,
      {
        trailing: true,
      },
    ),
    async startChat() {
      // String.prototype.replaceAt = function (index, replacement) {
      //   return this.substr(0, index) + replacement + this.substr(index + 1)
      // }
      // let formatPhoneNumberCode = this.phoneNumber
      // this.phoneNumber.charAt(0) === '0'
      //   ? (formatPhoneNumberCode = this.phoneNumber.replaceAt(0, ''))
      //   : (formatPhoneNumberCode = this.phoneNumber)
      // const formattedPhoneNumber = `62${formatPhoneNumberCode.replace(/[-]/g, '')}`

      // const validPhoneNumber = await this.validatePhoneNumber(formattedPhoneNumber)
      // if (!validPhoneNumber) return
      // this.$emit('add-room', formattedPhoneNumber)
      // //console.log(this.showLoader, 'ini show')
      // this.phoneNumber = ''
      // this.errors = []

      // const formattedPhoneNumber = this.results.formattedNumber.slice(1)

      // // const validPhoneNumber = await this.validatePhoneNumber(formattedPhoneNumber)
      // // if (!validPhoneNumber) return
      // const obj = {
      //   phone_number: formattedPhoneNumber,
      //   instance: this.newRoomChannel,
      // }
      console.log('apa aja')
      this.$emit('add-room', true)

      // this.isOpened = false
      // this.phoneNumber = ''
      // this.results = {}
      // this.errors = []
    },
    async validatePhoneNumber(formattedPhoneNumber) {
      const validatedPhoneNumber = await this.$store.dispatch('manageSalary/validateIfPhoneNumberAlreadyRegistered', {
        sub_id: this.user.sub_id,
        instance_ID: this.instance.value[0]?._id || '',
        phoneNumber: formattedPhoneNumber,
      })

      // //console.log(
      //   '🚀 ~ file: RoomsList.vue ~ line 1238 ~ validatePhoneNumber ~ validatedPhoneNumber',
      //   validatedPhoneNumber,
      // )

      if (!validatedPhoneNumber?.data?.data) {
        this.errors = ['This phone number is not registered.']

        return false
      }
      if (!validatedPhoneNumber?.data?.success) {
        this.errors = ['This phone number is not registered.']

        return false
      }

      return true
    },
    changeFilter(filter) {
      this.$store.commit('inbox/setFilterRoom', filter)
      this.$emit('filter-rooms')
    },
    async getLabelVals() {
      const labelsValuePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'label_value',
      })

      if (labelsValuePg.status && labelsValuePg.data) {
        this.labelVal = labelsValuePg.data.value
      }
    },
    initIntersectionObserver() {
      if (this.observer) {
        this.showLoader = true
        this.observer.disconnect()
      }

      const loader = document.getElementById('infinite-loader-rooms')

      if (loader) {
        const options = {
          root: document.getElementById('rooms-list'),
          rootMargin: `${this.scrollDistance}px`,
          threshold: 0,
        }

        this.observer = new IntersectionObserver(entries => {
          // console.log(entries, 'ini entry')
          if (entries[0].isIntersecting) {
            if (this.getSearch) {
              if (this.roomsMessages.length) {
                this.$emit('fetch-more-search')
              }
            } else {
              this.loadMoreRooms()
            }
          }
        }, options)

        this.observer.observe(loader)
      }
    },

    // searchRoom(ev) {
    //   this.filteredRooms = filteredItems(this.rooms, 'roomName', ev.target.value)
    // },
    async openRoom(room, fromRoomsMessages) {
      // try {
      //   if (room.unreadCount > 0) {
      //     await clientPocketBase.collection('crm_contact').update(room.id, {
      //       unread_count: 0,
      //     })
      //     room.unreadCount = 0
      //   }

      // console.log(room, this.rooms, 'ini room<<<setelah')
      //   this.$store.commit('inbox/setNewChat', false)
      // } catch (err) {
      //   // console.log(err, 'error update<<')
      // }

      // //console.log(this.contactGroups, '<<group')
      if (
        ((room.roomId === this.room.roomId && !this.roomsMessages.length) ||
          (this.roomsMessages.length && room.mappingId !== undefined && room.mappingId === this.room.mappingId)) &&
        !this.isMobile
      )
        return
      if (!this.isMobile) {
        this.selectedRoomId = fromRoomsMessages ? room.mappingId : room.roomId
      }

      if (this.getSearch) {
      }

      const mappingId = fromRoomsMessages ? room?.mappingId : ''
      console.log(mappingId, 'kesini ora<')
      // this.$emit('change-room-id', room.roomId)
      this.$emit('fetch-room', { room, mappingId, fromRoomsMessages })
    },
    roomInfo({ room }) {
      this.roomId = room.roomId
      if (this.$vuetify.breakpoint.xs) {
        window.history.pushState({ roomId: room.roomId }, '', '')
      }

      // setTimeout(async () => {
      //   if (options.reset) {
      //     this.messages = []
      //     this.messagesLoaded = false
      //     this.lastCouchTimestamp = 0
      //   }
      //   let fetchMessages
      //   if (options.reset) {
      //     fetchMessages = await this.$store.dispatch('rooms/getMessagesPostgres', {
      //       sub_id: this.user.sub_id,
      //       roomId: room.roomId,
      //       couch_timestamp: room.lastMessage.couch_timestamp,
      //       querySearch: false,
      //     })
      //   } else {
      //     fetchMessages = await this.$store.dispatch('rooms/getMessagesPostgres', {
      //       sub_id: this.user.sub_id,
      //       roomId: room.roomId,
      //       couch_timestamp: this.lastCouchTimestamp,
      //       querySearch: false,
      //     })
      //   }
      // })
    },
    loadMoreRooms() {
      // console.log(this.loadingMoreRooms, this.roomsLoaded, this.mappedRooms, this.onSearch, 'masuk ini dia load')

      // if (this.loadingMoreRooms) return

      if (this.roomsLoaded) {
        this.loadingMoreRooms = false
        this.showLoader = false

        return
      }

      if (this.onSearch) {
        this.showLoader = false

        return
      }

      if (this.mappedRooms.length === 0) {
        // //console.log('masuk filter kosong')
        this.$emit('fetch-more-rooms')
      }

      this.loadingMoreRooms = true
    },

    formatPhoneNum(phone) {
      // console.log(phone, 'helo<')
      phone = phone.toString()
      phone = phone.replace(/[^\d]/g, '')
      if (phone.length == 12) {
        // validate 11 digit nomor handphone
        return phone.replace(/(\d{2})(\d{3})(\d{4})(\d{3})/, '+$1 $2-$3-$4')
      }
      if (phone.length == 13) {
        // validate 12 digit nomor handphone
        return phone.replace(/(\d{2})(\d{3})(\d{4})(\d{4})/, '+$1 $2-$3-$4')
      }
      if (phone.length == 14) {
        // validate 13 digit nomor handphone
        return phone.replace(/(\d{2})(\d{3})(\d{4})(\d{5})/, '+$1 $2-$3-$4')
      }
      if (phone.length == 11) {
        // validate 10 digit nomor handphone
        return phone.replace(/(\d{2})(\d{3})(\d{4})(\d{1})/, '+$1 $2-$3-$4')
      }

      return this.room.phone_number_show
    },
    onContainerScroll(e) {
      this.hideOptions = true

      if (!e.target) return

      const bottomScroll = this.getBottomScroll(e.target)
      if (bottomScroll < 60) this.scrollMessagesCount = 0
      this.scrollIcon = bottomScroll > 500 || this.scrollMessagesCount
    },
    getBottomScroll(element) {
      const { scrollHeight, clientHeight, scrollTop } = element

      return scrollHeight - clientHeight - scrollTop
    },
    filteringLabels({ label, fromChannel }) {
      this.$emit('filtering-labels', { label, fromChannel })
    },
  },
}
</script>

<style scoped>
#no-background-hover::before {
  background-color: transparent !important;
}
.v-btn.action-btn {
  width: 32px !important;
  min-width: 32px !important;
}
.action-btn-profile {
  justify-content: center;
}

.card-contact {
  background-color: #f0f2f5;
}

.title-contact {
  color: black;
  font-weight: 400;
  font-size: 14px;
}

.room-info-name {
  height: 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #111b21;
}

.room-info-title-text {
  color: white;
  font-size: 16px;
  font-weight: 700;
}
.room-info-number {
  margin: 10px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #999999;
}
.room-info-avatar {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: #ddd;
  height: 180px;
  width: 180px;
  margin-top: 11.32%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8%;
  min-height: 50px;
  min-width: 50px;
  /* margin-right: 25%;
  margin-left: 25%; */
  border-radius: 50%;
}

.room-info-text-wrap {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-group {
  color: black;
  font-weight: 600;
  font-size: 14px;
}

.name-contact {
  color: #111b21;
  font-weight: 400;
  font-size: 16px;
}

.text-contact {
  color: #777777;
  font-weight: 400;
  font-size: 12px;
}

.date-contact {
  color: black;
  font-weight: 400;
  font-size: 12px;
}

.no-shadow::before {
  box-shadow: none;
}
.colorline-chat {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.vac-avatar-frame {
  height: 51px;
  width: 52px;
  min-height: 53px;
  min-width: 52px;
  margin-right: 15px;
  margin-left: 5%;
  border-radius: 50%;
  margin-bottom: -1px;
}

.match-height {
  height: 60px;
}
</style>
