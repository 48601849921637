<template>
  <div class="vac-reply-message">
    <div class="vac-reply-username">
      {{ replyUsername }}
    </div>

    <div
      v-if="isImage"
      class="vac-image-reply-container"
    >
      <div
        class="vac-message-image vac-message-image-reply"
        :style="{
          'background-image': `url('${firstFile.url}')`
        }"
      />
    </div>

    <div
      v-else-if="isVideo"
      class="vac-video-reply-container"
    >
    <div v-if="!videoClicked">
          <v-btn
          width="75%"
          style="height: 150px !important"
          color="#000000"
        >
          <v-icon
          class="mx-auto text-center"
          size="50px"
          color="#FFFFFF"
          @click="videoClicked = true"
          >
          {{ icons.mdiPlay }}
        </v-icon>
      </v-btn>
    </div>
    <div v-else>
      <video width="100%" height="100%" style="max-height: 200px;" controls autoplay>
        <source :src="firstFile.url" />
      </video>
    </div>
    </div>

    <audio-player
      v-else-if="isAudio"
      :src="firstFile.url"
      @update-progress-time="progressTime = $event"
      @hover-audio-progress="hoverAudioProgress = $event"
    >
      <template
        v-for="(i, name) in $scopedSlots"
        #[name]="data"
      >
        <slot
          :name="name"
          v-bind="data"
        />
      </template>
    </audio-player>
    <div v-else-if="isLocation" class="mt-3 mb-3">
      <gmapsMap
        :options="{
          center: { lat: message.data.lat, lng: message.data.lng },
          zoom: 20,
        }"
        style="width: 200px; height: 100px"
        @click="openUrlButton(`https://maps.google.com/?q=${message.data.lat},${message.data.lng}`)"
      >
        <gmapsMarker
          :position="{ lat: message.data.lat, lng: message.data.lng }"
          @click="openUrlButton(`https://maps.google.com/?q=${message.data.lat},${message.data.lng}`)"
        />
      </gmapsMap>
    </div>
    <v-container v-else-if="isOtherFile" fluid>
      <v-row>
        <v-col cols="12" sm="2" offset-sm="2" md="10" class="ml-0">
          <div class="message-container">
            <div class="message message-sent">
              <div class="message-text">
                <div class="message-file-icon-container">
                  <div class="message-file-icon-wrapper">
                    <img width="20" :src="previewfile(firstFile)" />
                  </div>
                </div>
                <div class="message-file-info">
                  <span class="message-file-name">{{ firstFile.name }}</span>
                </div>
                <div class="message-file-download-link vac-svg-button"">
                  <slot name="document-icon" />
                  <svg-icon name="document" />
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div class="vac-reply-content">
      <format-message
        :content="truncateReplyMessage(message.replyMessage.content)"
        :users="roomUsers"
        :text-formatting="textFormatting"
        :link-options="linkOptions"
        :reply="reply"
      >
        <template
          v-for="(i, name) in $scopedSlots"
          #[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </format-message>
    </div>
  </div>
</template>

<script>
import { gmapsMap, gmapsMarker } from 'x5-gmaps'
import { mdiPlay } from '@mdi/js'
import FormatMessage from '../../../components/FormatMessage/FormatMessage'
import SvgIcon from '../../../components/SvgIcon/SvgIcon'

import AudioPlayer from '../AudioPlayer/AudioPlayer'

const { isAudioFile, isImageFile, isVideoFile } = require('../../../utils/media-file')

export default {
  name: 'MessageReply',
  components: { AudioPlayer, SvgIcon, FormatMessage, 
    gmapsMap,
    gmapsMarker,},

  props: {
    message: { type: Object, required: true },
    textFormatting: { type: Object, required: true },
    isGroup: { type: Boolean, required: true },
    roomName: { type: String, required: true },
    currentUserId: { type: String, required: true },
    linkOptions: { type: Object, required: true },
    roomUsers: { type: Array, required: true },
  },

  data() {
    return {
      reply: true,
      videoClicked: false,
      icons: {
        mdiPlay
      },
    }
  },

  computed: {
    replyUsername() {
      const { senderId } = this.message.replyMessage
      const replyUser = this.roomUsers.find(user => user._id === senderId)

      if (senderId === this.currentUserId) {
        return 'You'
      }

      if (!this.isGroup) {
        return this.roomName
      }

      // //console.log(
      //   '🚀 ~ file: MessageReply.vue ~ line 114 ~ replyUsername ~ this.roomUsers',
      //   this.message,
      //   this.roomUsers,
      //   this.isGroup,
      // )

      return replyUser ? replyUser.username : ''
    },
    firstFile() {
      return this.message.replyMessage.files?.length ? this.message.replyMessage.files[0] : {}
    },
    isAudio() {
      return isAudioFile(this.firstFile)
    },
    isImage() {
      return isImageFile(this.firstFile)
    },
    isVideo() {
      return isVideoFile(this.firstFile)
    },
    isLocation() {
      const isLoc = !!(
        this.message?.quotedData?.quotedMessage?.locationMessage ||
        this.message?.original_message?.message?.extendedTextMessage?.contextInfo?.quotedMessage?.locationMessage
      );
      if(isLoc){
        const loc = this.message?.original_message?.message?.extendedTextMessage?.contextInfo?.quotedMessage?.locationMessage || this.message?.quotedData?.quotedMessage?.locationMessage
        this.message.data.lat = loc.degreesLatitude
        this.message.data.lng = loc.degreesLongitude
      }
      // console.log("LOC:"+JSON.stringify([isLoc, this.message]))
      return isLoc
    },
    isOtherFile() {
      return this.message.replyMessage.files?.length && !this.isAudio && !this.isVideo && !this.isImage
    },
  },

  methods: {
    formatPhoneNumber(phoneNumber, group = true) {
      phoneNumber = phoneNumber.toString()

      // //console.log('🚀 ~ file: Chat.vue ~ line 3675 ~ formatPhoneNumber ~ phoneNumber, group = true', phoneNumber)
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      } else if (group) {
        phoneNumber = 'Group'
      }

      return phoneNumber
    },
    previewfile(file) {
      if (this.fromChatwoot) {
        if (file.preview.includes('pdf')) {
          return '/file-manager/icons/application-pdf.svg'
        } else if (file.preview.includes('csv')) {
          return 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
        } else if (file.preview.includes('xlsx')) {
          return '/file-manager/icons/application-vnd.ms-excel.svg'
        } else if (file.preview.includes('txt')) {
          return 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
        } else if (file.preview.includes('docx')) {
          return '/file-manager/icons/application-msword-template.svg'
        } else {
          return '/file-manager/icons/text.svg'
        }
      } else {
        if (file.type === 'pdf') {
          return '/file-manager/icons/application-pdf.svg'
        } else if (file.type === 'csv') {
          return 'https://cdn-icons-png.flaticon.com/512/8242/8242984.png'
        } else if (file.type.includes('spreadsheetml') || file.name.includes('xlsx')) {
          return '/file-manager/icons/application-vnd.ms-excel.svg'
        } else if (file.type === 'plain') {
          return 'https://cdn-icons-png.flaticon.com/512/3979/3979306.png'
        } else if (file.type.includes('document')) {
          return '/file-manager/icons/application-msword-template.svg'
        } else {
          return '/file-manager/icons/text.svg'
        }
      }
    },
    formatTags(content) {
      const firstTag = '<usertag>'
      const secondTag = '</usertag>'
      let usertags = []
      if (content) {
        usertags = [...content.matchAll(new RegExp(firstTag, 'gi'))].map(a => a.index)
      }

      const initialContent = content

      if (usertags && usertags.length > 0) {
        usertags.forEach(index => {
          const userId = initialContent.substring(index + firstTag.length, initialContent.indexOf(secondTag, index))
          const userData = this.roomUsers.find(userInfo => userInfo._id == userId)
          const user = this.$store.getters['global/getAllContactData'].find(user => user.phone_number == userId)

          // const userContact = this.allContacts.find(contact => contact.phone_number == userId)
          //console.log(user,userData,userId,'ini data user<<')
          if(user) {
            //console.log(content,'masuk kondisi sini euy')
          }

          if (user) {
            //console.log(content,'ini content seb')
            content = content.replaceAll(userId, `@${user.name ? user.name : this.formatPhoneNumber(userId, false)}`)
            //console.log(content,'ini content sebset')
          } else if (userData) {
            //console.log('masuk sini seb')
            if (userData._id == userData.username.replace(/[+ -]/g, '')) {
              userData.username = this.formatPhoneNumber(userData._id, false)
            }

            content = content.replaceAll(
              firstTag + userId + secondTag,
              `@${userData.username ? userData.username : this.formatPhoneNumber(userData._id, false)}`,
            )
          }

          // if (userContact) {
          //   if (userContact.name == userId) {
          //     if (user) {
          //       if (user.bailey_name) {
          //         userContact.name = user.bailey_name
          //       } else if (user.notify_name) {
          //         userContact.name = user.notify_name
          //       } else if (user.contact_list_name) {
          //         userContact.name = user.contact_list_name
          //       } else {
          //         userContact.name = this.formatPhoneNumber(userId, false)
          //       }
          //     } else {
          //       userContact.name = this.formatPhoneNumber(userId, false)
          //     }

          //     // userContact.username = this.formatPhoneNumber(userId, false)
          //   } else if (userContact.name !== userId) {
          //     userContact.username = userContact.name
          //   }
          //   this.userTampungan2.push(userContact)

          //   content = content.replaceAll(userId, `@${userContact.name ? userContact.name : userId}`)
          // } else if (user && user.bailey_name) {
          //   if (user._id == user.bailey_name) {
          //     user.bailey_name = this.formatPhoneNumber(user._id, false)
          //   }

          //   // if(this.userTampungan && (this.userTampungan.length === 0 || this.userTampungan.findIndex(el => {
          //   //   if(el.bailey_name) {
          //   //     el.bailey_name === user.bailey_name
          //   //   }
          //   //   }) < 0 )) {
          //   //   this.userTampungan.push(user)
          //   // }

          //   content = content.replaceAll(userId, `@${user.bailey_name ? user.bailey_name : user._id}`)
          // } else if (user && user.notify_name) {
          //   if (user._id == user.notify_name) {
          //     user.notify_name = this.formatPhoneNumber(user._id, false)
          //   }

          //   // if(this.userTampungan && (this.userTampungan.length === 0 || this.userTampungan.findIndex(el => {
          //   //   if(el.notify_name) {
          //   //     el.notify_name === user.notify_name
          //   //   }
          //   //   }) < 0 )) {
          //   //   this.userTampungan.push(user)
          //   // }
          //   let test = 0
          //   if (this.userTampungan.length !== 0) {
          //     test = this.userTampungan.findIndex(el => {
          //       if (el && el.notify_name) return el.notify_name === user.notify_name
          //     })
          //   }
          //   if (this.userTampungan.length === 0 || test < 0) {
          //     this.userTampungan.push(user)
          //   }
          //   content = content.replaceAll(userId, `@${user.notify_name ? user.notify_name : user._id}`)
          // } else if (!user) {
          //   if (userData) {
          //     if (userData._id == userData.username.replace(/[+ -]/g, '')) {
          //       userData.username = this.formatPhoneNumber(userData._id, false)
          //     }
          //     content = content.replaceAll(userId, `@${userData.username ? userData.username : userData._id}`)
          //   }
          // }
          // //console.log(user, userId, initialContent, index,'ini user')

          // content = content.replaceAll(userId, `@${user?.username || 'unknown'}`)
        })
      } else {
        if(content.includes('@')) {
          let split = content.split(' ')

          for(let i = 0; i < split.length; i++) {
            const word = split[i]
            //console.log(word,'ini usedd')
            if(word.startsWith('@')) {
              let newWord = new RegExp(word, 'g')
              let used = word.slice(1)
              if(used) {
                content = content.replace(newWord, `${firstTag}${used}${secondTag}`)
              }
            }
          }
        }
      }
      //console.log(content,'ini cons after')
      /// INI UNTUK YG FORMATING NANTI
      // usertags.forEach(index => {
      //   const userId = initialContent.substring(index + firstTag.length, initialContent.indexOf(secondTag, index))

      //   // //console.log(userId, `INI USERTAGS`)

      //   const userData = this.users.find(userInfo => userInfo._id === userId)
      //   const user = this.$store.getters['global/getAllContactData'].find(
      //     user => user.phone_number == userId && user.notify_name !== userId,
      //   )
      //   const userContact = this.allContacts.find(contact => contact.phone_number === userId)
      //   //console.log(userContact, 'ini usercon')

      //   // if (user._id.includes('5130')) {
      //   //   //console.log(true, this.currentRoomContact, user, user.username.replace(/[+ -]/g, ''), `INI NAMA KONTAK`)
      //   // }

      //   // //console.log(userId, userContact, `INI USER CONTACT`)

      //   // //console.log(userId, this.allContacts, `INI USER ID`)
      //   // //console.log(user, `INI USER`)
      //   // if (userId == '6282260567687') {
      //   //   //console.log(
      //   //     '🚀 ~ file: FormatMessage.vue ~ line 346 ~ formatTags ~ userContact',
      //   //     userContact,
      //   //     userContact.name == userId,
      //   //     user,
      //   //   )
      //   // }
      //   this.userTampungan.push(userData)
      //   if (userContact) {
      //     if (userContact.name == userId) {
      //       if (user) {
      //         if (user.bailey_name) {
      //           userContact.name = user.bailey_name
      //         } else if (user.notify_name) {
      //           userContact.name = user.notify_name
      //         } else if (user.contact_list_name) {
      //           userContact.name = user.contact_list_name
      //         } else {
      //           userContact.name = this.formatPhoneNumber(userId, false)
      //         }
      //       } else {
      //         userContact.name = this.formatPhoneNumber(userId, false)
      //       }

      //       // userContact.username = this.formatPhoneNumber(userId, false)
      //     } else if (userContact.name !== userId) {
      //       userContact.username = userContact.name
      //     }
      //     this.userTampungan2.push(userContact)

      //     content = content.replaceAll(userId, `@${userContact.name ? userContact.name : userId}`)
      //   } else if (user && user.bailey_name) {
      //     if (user._id == user.bailey_name) {
      //       user.bailey_name = this.formatPhoneNumber(user._id, false)
      //     }
      //     // if(this.userTampungan && (this.userTampungan.length === 0 || this.userTampungan.findIndex(el => {
      //     //   if(el.bailey_name) {
      //     //     el.bailey_name === user.bailey_name
      //     //   }
      //     //   }) < 0 )) {
      //     //   this.userTampungan.push(user)
      //     // }

      //     content = content.replaceAll(userId, `@${user.bailey_name ? user.bailey_name : user._id}`)
      //   } else if (user && user.notify_name) {
      //     if (user._id == user.notify_name) {
      //       user.notify_name = this.formatPhoneNumber(user._id, false)
      //     }
      //     // if(this.userTampungan && (this.userTampungan.length === 0 || this.userTampungan.findIndex(el => {
      //     //   if(el.notify_name) {
      //     //     el.notify_name === user.notify_name
      //     //   }
      //     //   }) < 0 )) {
      //     //   this.userTampungan.push(user)
      //     // }
      //     let test = 0
      //     if(this.userTampungan.length !== 0) {
      //       test = this.userTampungan.findIndex(el=> {
      //         if(el && el.notify_name) return el.notify_name === user.notify_name
      //       })
      //     }
      //     if(this.userTampungan.length === 0 || test < 0) {
      //       this.userTampungan.push(user)
      //     }
      //     content = content.replaceAll(userId, `@${user.notify_name ? user.notify_name : user._id}`)
      //   } else if (!user) {
      //     if (userData) {
      //       if (userData._id == userData.username.replace(/[+ -]/g, '')) {
      //         userData.username = this.formatPhoneNumber(userData._id, false)
      //       }
      //       content = content.replaceAll(userId, `@${userData.username ? userData.username : userData._id}`)
      //     }
      //   }

      //   // format phone number
      // })

      // //console.log(content, 'ini contes')

      return content
    },
    truncateReplyMessage(replyMessage) {
      replyMessage = this.formatTags(replyMessage)
      // //console.log('🚀 ~ file: MessageReply.vue ~ line 378 ~ truncateReplyMessage ~ replyMessage', replyMessage)
      if (this.$vuetify.breakpoint.xs) {
        if (replyMessage.length <= 50) return replyMessage.slice(0, 50)

        return `${replyMessage.slice(0, 50)}...`
      }
      if (replyMessage.length <= 100) {
        return `${replyMessage.slice(0, 100)}`
      }

      return `${replyMessage.slice(0, 100)}...`
    },
  },
}
</script>

<style scoped>
.truncate {
  /* display: inline-block;
  width: 10vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
</style>

