var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{staticStyle:{"z-index":"1 !important"},attrs:{"color":"error","timeout":2000,"top":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.alertDialog = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])]}}]),model:{value:(_vm.alertDialog),callback:function ($$v) {_vm.alertDialog=$$v},expression:"alertDialog"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('v-card',{staticClass:"my-5 px-3 pt-5"},[_c('v-row',[_c('v-chip',{staticClass:"ml-3",attrs:{"color":"#EEF1F4"}},[_c('span',{staticStyle:{"color":"#1f979e"}},[_vm._v(_vm._s(_vm.$t('broadcasts.selected'))+" contacts : "+_vm._s(_vm.dataContacts.length))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{staticClass:"mr-1 mb-1",attrs:{"append-icon":_vm.icons.mdiMagnify,"label":_vm.$t('broadcasts.search'),"single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.searchDetail),callback:function ($$v) {_vm.searchDetail=$$v},expression:"searchDetail"}})],1)],1)],1),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"table-broadcast",attrs:{"headers":_vm.showListContact ? _vm.headerContactList : _vm.headerContact,"items":_vm.detailListContact,"search":_vm.searchDetail,"server-items-length":_vm.totalContacts,"loading-text":"Loading... Please wait","options":_vm.options,"item-key":"id","show-select":(!_vm.fromContacts && !_vm.fromList) || _vm.isDraft,"items-per-page":5,"footer-props":{
        'items-per-page-text': _vm.$t('rowsPerPageLabel'),
        'items-per-page-options': [5, 10, 30, 50, 100],
      },"checkbox-color":"#111b21"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.no.length > 16 ? 'Group' : item.no)+" ")]}},{key:"item.custom",fn:function(ref){
      var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.custom)}})]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"display-lg align-center"},[(item ? (item.profile_picture ? true : false) : false)?_c('v-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":item.profile_picture || '',"height":"40","width":"40"}})],1):_vm._e(),_c('div',{staticClass:"display-lg flex-column ms-3 display-mt-lg"},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary sm-text"},[_vm._v(_vm._s(item.name))])])],1)]}},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('DD-MM-YYYY'))+" ")]}},{key:"item.updated_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.updated_at).format('DD-MM-YYYY'))+" ")]}},{key:"item.Media",fn:function(ref){
      var item = ref.item;
return [_c('v-img',{attrs:{"src":item.Media,"height":"50","width":"50"}})]}}],null,true),model:{value:(_vm.dataContacts),callback:function ($$v) {_vm.dataContacts=$$v},expression:"dataContacts"}},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('noDataLabel'))+" ")])],2)],1),_c('v-row',{staticClass:"d-flex justify-end text-end"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"me-2",class:_vm.$vuetify.breakpoint.mdAndDown ? 'mb-2' : '',attrs:{"block":_vm.$vuetify.breakpoint.mdAndDown ? true : false,"color":"success"},on:{"click":function($event){return _vm.complete()}}},[_vm._v(" send ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }